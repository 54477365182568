<div class="row row-cols-xl-3 row-cols-lg-2 row-cols-1 justify-content-center g-4">
    <div class="col d-flex align-items-stretch">
        <article class="card border-0 shadow-primary bg-white w-100">
            <div class="card-header border-0 text-center mt-3">
                <div class="position-relative bg-position-center bg-repeat-0 bg-size-cover m-auto"
                    style=" min-height: 15rem; max-width: 17rem;border-radius: 0.7rem;"
                    [ngStyle]="{'background-image': 'url(https://spapi.etec.gov.sa:2443/ar/MediaAssets/CEO-Walid.jpeg)'}">
                </div>
            </div>
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <p class="mb-2 fs-sm text-primary" [innerText]="etecLeaderPrefixId"></p>
                <h3 class="fs-lg fw-semibold text-primary mb-4 text-center" [innerText]="etecLeaderName">
                </h3>
                <div class="bg-primary text-white p-3 px-2 fs-sm text-center w-100" style="border-radius: 0.7rem;"
                    [innerText]="etecceo">
                </div>
            </div>
        </article>
    </div>
</div>

<!-- ar -->
<div class="row d-flex justify-content-center my-5 g-4" *ngIf="_lang.toString() == 'ar'">
    <div class="col-12">
        <h2 class="fs-lg fw-semibold d-inline-block align-middle text-primary mb-3">الخبرات العملية والأكاديمية:</h2>
        <div class="row row-cols-1 g-3 service-goals">
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        نائب رئيس هيئة تقويم التعليم والتدريب للاستراتيجية والتطوير.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        نائب محافظ الهيئة الوطنية للأمن السيبراني لبناء القدرات.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        المشرف على التخطيط والمعلومات في مؤسسة تكافل.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        وكيل عمادة التعاملات الإلكترونية والاتصالات في جامعة الملك سعود.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        أستاذ مشارك في قسم علوم الحاسب في جامعة الملك سعود.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        أستاذ مساعد في قسم علوم الحاسب في جامعة الملك سعود.
                    </p>
                </div>
            </div>

        </div>
    </div>
    <div class="col-12">
        <h2 class="fs-lg fw-semibold d-inline-block align-middle text-primary mb-3">المؤهلات العلمية:</h2>
        <div class="row row-cols-1 g-3 service-goals">
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        الدكتوراه في علوم الحاسب من جامعة كوينز في كندا.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        الماجستير في علوم الحاسب من جامعة كوينز في كندا.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        البكالوريوس في علوم الحاسب من جامعة الملك سعود.
                    </p>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- en -->
<div class="row d-flex justify-content-center my-5 g-4" *ngIf="_lang.toString() == 'en'">
    <div class="col-12">
        <h2 class="fs-lg fw-semibold d-inline-block align-middle text-primary mb-3">Academic and Work Experience:</h2>
        <div class="row row-cols-1 g-3 service-goals">
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        Vice President for Strategy and Development at the Education and Training Evaluation Commission
                        (ETEC).
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        Deputy Governor for Capacity Building at the National Cybersecurity Authority (NCA).
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        Supervisor of Planning and Information at Takaful Foundation.

                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        Vice Dean of the Deanship of e-Transaction and Communication at King Saud University (KSU).
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        Associate Professor in the Computer Science Department at KSU.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        Assistant Professor in the Computer Science Department at KSU.

                    </p>
                </div>
            </div>

        </div>
    </div>
    <div class="col-12">
        <h2 class="fs-lg fw-semibold d-inline-block align-middle text-primary mb-3">Qualifications:</h2>
        <div class="row row-cols-1 g-3 service-goals">
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        PhD in Computer Science, Queen's University, Canada.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        M.Sc. in Computer Science, Queen's University, Canada.
                    </p>
                </div>
            </div>
            <!-- col -->
            <div class="col">
                <div class="d-flex flex-row align-items-start justify-content-start">
                    <div class="me-3">
                        <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                            style="height: 1.2rem; width: 1.2rem;"></div>
                    </div>
                    <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 m-0">
                        Bachelor’s Degree in Computer Science, KSU.
                    </p>
                </div>
            </div>
        </div>

    </div>
</div>