import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { News } from 'src/app/models/ResponseModel';
import { NewsList } from 'src/app/models/NewsList';
import { SwiperOptions, Pagination, Swiper, Autoplay } from 'swiper';
import { environment } from 'src/environments/environment';
import { ResourcesService } from 'src/app/services/resources.service';



@Component({
  selector: 'app-latestnews',
  templateUrl: './latestnews.component.html',
  styleUrls: ['./latestnews.component.css']
})
export class LatestnewsComponent implements OnInit {

  configNews: SwiperOptions = {
    slidesPerView: 1,
    breakpoints: {
      '576': {
        slidesPerView: 1
      },
      '768': {
        slidesPerView: 2
      },
      '1200': {
        slidesPerView: 3
      }
    },
    spaceBetween: 5,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: true,
    // navigation: true,
    pagination: { clickable: true }
  };
  latestNews: News[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  more: string = '';
  EtecNews: string = '';
  isLoad = false;
  _lang = '';

  constructor(private apiServices: ApiService, private apiResources: ResourcesService) {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {
        if (window.scrollY >= 400 && this.latestNews.length === 0 && !this.isLoad) {
          this.isLoad = true;

          this.apiServices.getListOrder('news', 3, false, "NewsDate").pipe(takeUntil(this.destroy$)).subscribe(
            (data: NewsList[]) => {
              data.forEach(element => {
                var obj: News = {
                  newsId: element.ItemId,
                  newsDate: element.ItemFields.NewsDate,
                  newsTitle: element.ItemFields.NewsTitle,
                  newsDetails: this.cutString(element.ItemFields.NewsDetails?.replace(/<[^>]*>/g, '')),
                  newsUrl: element.ItemFields?.NewsFile?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
                  NewsImage: element.ItemFields?.NewsImage?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
                  NewsImageThumbnail: element.ItemFields?.NewsImageThumbnail?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
                  isActive: element.ItemFields.IsActive1,
                  NewsCode: element.ItemFields.NewsCode,
                  NewsKeyword: ''
                };
                this.latestNews.push(obj);
              });
            }
          );

          Swiper.use([Pagination]);
          Swiper.use([Autoplay]);


        }
      });

    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  /** Custom methods */
  private cutString(str: string) {
    var output = ' ';
    var data = str.split(' ');
    var i = 0;
    for (var i = 0; i < 25; i++) {

      output = output.concat(data[i], ' ');
    };
    return output;
  }

  /** Component events  */
  async ngOnInit() {
    await this.apiServices.logInAsync();
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
      .subscribe(async (e: Event) => {
        if (window.scrollY >= 400 && this.latestNews.length === 0 && !this.more) {
          this.more = await this.apiResources.getByKey("more");
          this.EtecNews = await this.apiResources.getByKey("etecnews");
        }
      }
      );

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
