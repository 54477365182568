import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ListResponse } from 'src/app/models/ListResponse';
import { NewsList } from 'src/app/models/NewsList';
import { News } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';
import { StringLiteralType } from 'typescript';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  NewsCode: string;
  newsDetails: News;
  keyword: string;
  AllNews: News[] = [];
  relatedNews: News[] = [];
  more: string = '';
  relatednews: string = '';
  PageTitle: string = '';
  news: string = '';
  home: string = '';
  _lang = '';

  constructor(private centerService: ApiService, private actRoute: ActivatedRoute
    , private Title: Title, private Meta: Meta, private apiRersources: ResourcesService
    ,private loading: LoadingService
    , private apiResources: ResourcesService) {

    this.actRoute.params.subscribe(async params => {
      var isRoute =  localStorage.getItem('lang') != params["language"] && params["language"];
         await this.changeLang(params["language"]);

         if(isRoute){
          window.location.reload();
         }
         
      this.NewsCode = params["id"];
      this.AllNews = [];
      this.relatedNews = [];
      
      this.getNewsById();
      this.getRelatedNews();

    });

    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }
  /** Custom methods */
  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
}

  cloneObj(data: NewsList): News {
    return ({
      newsId: data.ItemId,
      newsDate: data.ItemFields.NewsDate,
      newsTitle: data.ItemFields.NewsTitle,
      newsDetails: this.cutString(data.ItemFields.NewsDetails),
      newsUrl: data.ItemFields?.NewsFile?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
      NewsImage: data.ItemFields?.NewsImage?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
      NewsImageThumbnail: data.ItemFields?.NewsImageThumbnail?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
      NewsCode: data.ItemFields.NewsCode,
      isActive: data.ItemFields.IsActive1,
      NewsKeyword: ''
    } as News)
  }
/** Custom methods */
  getNewsById() {
 
    this.centerService.GetListsItemsFilterd('news', 0, false, 'NewsCode', this.NewsCode ).pipe(takeUntil(this.destroy$)).subscribe(
      (data: NewsList[]) => {
        var obj: News = {
          newsId: data[0].ItemFields.ID,
          newsDate: data[0].ItemFields.NewsDate,
          newsTitle: data[0].ItemFields.NewsTitle,
          newsDetails: data[0].ItemFields.NewsDetails?.replace('&#58;', ':').replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          newsUrl: data[0].ItemFields?.NewsFile?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          NewsImage: data[0].ItemFields?.NewsImage?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            NewsImageThumbnail: data[0].ItemFields?.NewsImageThumbnail?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          NewsKeyword: data[0].ItemFields.NewsKeyword,
          NewsCode: data[0].ItemFields.NewsCode,
          isActive: data[0].ItemFields.IsActive1
        }
        this.Title.setTitle(obj.newsTitle);
        this.Meta.updateTag({ name: 'keywords', content: obj.NewsKeyword });
        //this.PageTitle = obj.newsTitle;
        this.newsDetails = obj;
        //this.keyword = (obj.NewsKeyword?.toString() === null ? '' : obj.NewsKeyword?.toString() );
      }
    );
  }

  getRelatedNews() {

    this.centerService.getList('news').pipe(takeUntil(this.destroy$)).subscribe(
      (data: NewsList[]) => {
        data.forEach(element => {
          var obj: News = {
            newsId: Number(element.ItemFields.ID),
            newsDate: element.ItemFields.NewsDate,
            newsTitle: element.ItemFields.NewsTitle,
            newsDetails: this.cutString(element.ItemFields.NewsDetails),
            newsUrl: element.ItemFields?.NewsFile?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            NewsImage: element.ItemFields?.NewsImage?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            NewsImageThumbnail: element.ItemFields?.NewsImageThumbnail?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            NewsKeyword: element.ItemFields?.NewsKeyword,
            NewsCode: element.ItemFields.NewsCode,
            isActive: element.ItemFields.IsActive1,
          };

          this.AllNews.push(obj);
        });

        this.AllNews.sort((a: any, b: any) => {
          return <any>new Date(b.newsDate) - <any>new Date(a.newsDate);
        });
        this.relatedNews = this.AllNews.filter(x => x.NewsKeyword?.toUpperCase().indexOf(this.newsDetails?.newsTitle) && x.newsId !== Number(this.newsDetails.newsId));

        this.relatedNews.slice(0, 3);
      }
    );
  }

  updateTitle(title: string) {
    this.Title.setTitle(title);
  }

  private cutString(str: string) {
    var output = ' ';
    var data = str.split(' ');
    var i = 0;
    for (var i = 0; i < 25; i++) {

      output = output.concat(data[i], ' ');
    };
    return output;
  }

/** Component events  */
  async ngOnInit() {

    this.more = await this.apiRersources.getByKey("more");
    this.relatednews = await this.apiRersources.getByKey("relatednews");
    this.home = await this.apiRersources.getByKey("home");

    this.centerService.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ListResponse[]) => {
        data.forEach(element => {
          if (element.ItemFields.Title.toLowerCase() === 'mediacenter') {
            this.PageTitle = element.ItemFields.MenuName;
          }
          if (element.ItemFields.Title.toLowerCase() === 'new') {
            this.news = element.ItemFields.MenuName;
          }


        });
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

}