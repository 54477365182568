import { Component, Input, OnInit } from '@angular/core';
import { FAQList } from 'src/app/models/FAQList';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.css']
})
export class FaqListComponent implements OnInit {

  @Input() faqList: FAQList[] = [];
  @Input() faq: string;
  constructor() { }

  ngOnInit(): void {
  }

}
