import { Component, Input, OnInit } from '@angular/core';
import { ServiceAudience } from 'src/app/models/ResponseModel';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
export class AudienceComponent implements OnInit {

  @Input() Audnices: ServiceAudience[] = [];
  @Input() serviceaudience : string
  constructor() { }

  ngOnInit(): void {
  }

}
