                <!--begin::TimeTable-->
                <article class="col-12 my-lg-4 my-3" *ngIf="TestTimeTable.length > 0" #table>
                    <h3 class="h3 text-center mt-0 mb-4 fw-semibold">{{timetable}}</h3>

                    <!-- ETEC table -->
                    <div class="table-responsive rounded-5">
                        <table class="table fs-sm etec-table">
                            <thead>
                                <tr>
                                    <th rowspan="2" class="rounded-start fs-sm fw-normal">{{period}}</th>
                                    <th rowspan="2" class="fs-sm fw-normal" style="background-color: #5D54A3;">{{testType}}</th>
                                    <th rowspan="2" class="fs-sm fw-normal">{{language}}</th>
                                    <th rowspan="2" class="fs-sm fw-normal" style="background-color: #5D54A3;">{{sex}}
                                    </th>

                                    <th class="fs-sm fw-normal" colspan="2">{{earlyRegistrationPeriod}}</th>

                                    <th class="fs-sm fw-normal" colspan="2" style="background-color: #5D54A3;">{{TestPeriod}}</th>
                                </tr>
                                <tr>
                                    <th class="fs-sm fw-normal" style="background-color: #443984;">{{from}}</th>
                                    <th class="fs-sm fw-normal" style="background: #3f347c;">{{to}}</th>
                                    <th class="fs-sm fw-normal" style="background-color: #504793;">{{from}}</th>
                                    <th class="fs-sm fw-normal" style="background: #453b85;">{{to}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of TestTimeTable">
                                    <td class="fs-sm fw-normal">
                                        {{ item.ItemFields.TestTimeTablePeriod }}
                                    </td>
                                    <td class="fs-sm fw-normal">
                                        {{item.ItemFields.TestTimeTableTestType}}
                                    </td>
                                    <td class="fs-sm fw-normal">
                                        {{item.ItemFields.TestTimeTableTestLanguage}}
                                    </td>
                                    <td class="fs-sm fw-normal">
                                        {{item.ItemFields.TestTimeTableTestGender}}
                                    </td>
                                    <td class="fs-sm fw-normal">
                                        {{item.ItemFields.TestTimeTabletStartOfEarlyRegist | date:
                                        'yyyy/MM/dd'}}
                                    </td>
                                    <td class="fs-sm fw-normal">
                                        {{item.ItemFields.TestTimeTabletStartOfEndRegistra | date:
                                        'yyyy/MM/dd'}}
                                    </td>
                                    <td class="fs-sm fw-normal">
                                        {{item.ItemFields.TestTimeTabletDateFrom | date: 'yyyy/MM/dd'}}
                                    </td>
                                    <td class="fs-sm fw-normal">
                                        {{item.ItemFields.TestTimeTabletDateTo | date: 'yyyy/MM/dd'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </article>