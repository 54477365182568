<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/about.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="h1 text-white fw-semibold m-0 position-relative zindex-5" [innerText]="PageTitle">
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="{{_lang}}/home" [innerText]="home"
                                class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page" [innerText]="PageTitle">
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>
<!--begin::tabs-->
<section class="container">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs content-tabs flex-nowrap justify-content-md-center justify-content-start overflow-auto mb-0 pb-5"
        role="tablist">
        <li class="nav-item mb-0" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab1" data-bs-toggle="tab" data-bs-target="#foundation" role="tab" aria-controls="tab1"
                aria-selected="true" routerLink="/etec/foundation"
                [ngClass]="activeTab.toLocaleLowerCase() === 'foundation'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bxs-book-open me-2 fs-2"></i>
                {{foundation}}
            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab2" data-bs-toggle="tab" data-bs-target="#vision" role="tab" aria-controls="tab2"
                aria-selected="true" routerLink="/etec/vision"
                [ngClass]="activeTab.toLocaleLowerCase() === 'vision'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bx-show me-2 fs-2"></i>
                {{visionmission}}
            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab3" data-bs-toggle="tab" data-bs-target="#board" role="tab" aria-controls="tab3"
                aria-selected="true" routerLink="/etec/board"
                [ngClass]="activeTab.toLocaleLowerCase() === 'board'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bx-group me-2 fs-2"></i>
                {{etecboard}}

            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab5" data-bs-toggle="tab" data-bs-target="#ceo" role="tab"
                aria-controls="tab5" aria-selected="true" routerLink="/etec/ceo"
                [ngClass]="activeTab.toLocaleLowerCase() === 'ceo'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bx-user me-2 fs-2" ></i>
                {{etecceo}}

            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab4" data-bs-toggle="tab" data-bs-target="#organizationalstructure" role="tab"
                aria-controls="tab4" aria-selected="true" routerLink="/etec/organizationalstructure"
                [ngClass]="activeTab.toLocaleLowerCase() === 'organizationalstructure'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bx-sitemap me-2 fs-2"></i>
                {{organizationalchart}}

            </a>
        </li>
    </ul>
    <!-- Tab content -->
    <div class="tab-content rounded-3">
        <!-- tab-pane -->
        <div class="tab-pane fade" id="foundation" role="tabpanel" aria-labelledby="tabs-tab1"
            [ngClass]="activeTab.toLocaleLowerCase() === 'foundation'? 'show active':''">
            <!-- start tab content -->
            <app-establishing></app-establishing>
            <!-- end tab content -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade" id="vision" role="tabpanel" aria-labelledby="pills-tab2"
            [ngClass]="activeTab.toLocaleLowerCase() === 'vision'? 'show active':''">
            <!-- start tab content -->
            <div class="vision-container">
                <app-vision [center]="center"></app-vision>
            </div>
            <!-- end tab content -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade" id="board" role="tabpanel" aria-labelledby="pills-tab3"
            [ngClass]="activeTab.toLocaleLowerCase() === 'board'? 'show active':''">
            <!-- start tab content -->
            <app-board></app-board>
            <!-- end tab content -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade" id="organizationalstructure" role="tabpanel" aria-labelledby="pills-tab4"
            [ngClass]="activeTab.toLocaleLowerCase() === 'organizationalstructure'? 'show active':''">
            <!-- start tab content -->
            <app-organizationalchart [center]="center" [centerOrgUrl]="centerOrgUrl"></app-organizationalchart>
            <!-- end tab content -->
        </div>

        <div class="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tabs-tab5" [ngClass]="activeTab.toLocaleLowerCase() === 'ceo'? 'show active':''">
            <!-- content -->
            <!-- <app-center-leader [centerId]="1" [cenetrLeader]="centerLeader"></app-center-leader> -->
            <app-ceo></app-ceo>
            <!-- end content  -->
        </div>


    </div>
</section>












<!-- <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
  
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img class="bd-placeholder-img" src="assets/img/BG/student.png" />
        </div>
    </div>
</div> -->


<!-- <div class="tab-nav">
    <ul class="nav tab justify-content-center large-screen-tab">
        <li class="nav-item ">
            <a class="nav-link  " [ngClass]="activeTab.toLocaleLowerCase() === 'foundation'? 'active':''" id="home-tab"
                routerLink="/etec/foundation" data-bs-toggle="tab" data-bs-target="#home" role="tab"
                aria-controls="home" aria-selected="true">
                <span class="tab-span">
                    <span class="tab-icon">
                        <img width="50" src="assets/img/icon/about/book.svg" />
                    </span>
                    <span class="tab-text">
                        التأسيس
                        &nbsp;
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item">

            <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase() === 'vision'? 'active':''"
                routerLink="/etec/vision" id="vision-tab" data-bs-toggle="tab" data-bs-target="#vision" role="tab"
                aria-controls="vision" aria-selected="false">
                <span class="tab-span">
                    <span class="tab-icon">
                        <img width="50" src="assets/img/icon/about/mail.svg" />
                    </span>

                    <span class="tab-text">
                        الرؤية والرسالة
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item">

            <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase() === 'board'? 'active':''"
                routerLink="/etec/board" id="BoardOfDirectors-tab" data-bs-toggle="tab"
                data-bs-target="#BoardOfDirectors" role="tab" aria-controls="BoardOfDirectors" aria-selected="false">
                <span class="tab-span">
                    <span class="tab-icon">
                        <img width="50" src="assets/img/icon/about/users.svg" />

                    </span>

                    <span class="tab-text">
                        مجلس الإدارة
                    </span>
                </span>
            </a>
        </li>
        <li class="nav-item">

            <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase() === 'organizationalstructure'? 'active':''"
                routerLink="/etec/organizationalstructure" id="OrganizationalStructure-tab" data-bs-toggle="tab"
                data-bs-target="#OrganizationalStructure" role="tab" aria-controls="OrganizationalStructure"
                aria-selected="false">
                <span class="tab-span">
                    <span class="tab-icon">
                        <img width="50" src="assets/img/icon/about/sitemap.svg" />

                    </span>

                    <span class="tab-text">
                        الهيكل التنظيمي
                    </span>
                </span>
            </a>
        </li>

    </ul>
</div>
 


<div class="container ">
    <div class="card card-tab shadow-sm border-0">
        <div class="card-body card-body-padding">
            <div class="row small-screen-tab">
                <div class="col-lg-12">
                    <div class="list-group mb-3 ">
                        <a class="list-group-item list-group-item-action  p-3"
                            [ngClass]="activeTab.toLocaleLowerCase() === 'foundation'? 'active':''" id="home-tab"
                            routerLink="/etec/foundation" data-bs-toggle="tab" data-bs-target="#home" role="tab"
                            aria-controls="home" aria-selected="true">
                            <img width="30" src="assets/img/icon/about/mail.svg" />
                            التأسيس
                        </a>
                        <a class="list-group-item list-group-item-action  p-3 "
                            [ngClass]="activeTab.toLocaleLowerCase() === 'vision'? 'active':''"
                            routerLink="/etec/vision" id="vision-tab" data-bs-toggle="tab" data-bs-target="#vision"
                            role="tab" aria-controls="vision" aria-selected="false">
                            <img width="30" src="assets/img/icon/about/mail.svg" />
                            الرؤية والرسالة</a>

                        <a class="list-group-item list-group-item-action p-3 "
                            [ngClass]="activeTab.toLocaleLowerCase() === 'board'? 'active':''" routerLink="/etec/board"
                            id="BoardOfDirectors-tab" data-bs-toggle="tab" data-bs-target="#BoardOfDirectors" role="tab"
                            aria-controls="BoardOfDirectors" aria-selected="false">
                            <img width="30" src="assets/img/icon/about/users.svg" />
                            مجلس الإدارة
                        </a>
                        <a class="list-group-item list-group-item-action p-3 "
                            [ngClass]="activeTab.toLocaleLowerCase() === 'organizationalstructure'? 'active':''"
                            routerLink="/etec/organizationalstructure" id="OrganizationalStructure-tab"
                            data-bs-toggle="tab" data-bs-target="#OrganizationalStructure" role="tab"
                            aria-controls="OrganizationalStructure" aria-selected="false">
                            <img width="30" src="assets/img/icon/about/sitemap.svg" />
                            الهيكل التنظيمي
                        </a>
                    </div>
                </div>
            </div>


            <div class="tab-content  tab-content-card" id="myTabContent">
                <div class="tab-pane fade " [ngClass]="activeTab.toLocaleLowerCase() === 'foundation'? 'show active':''"
                    id="home" role="tabpanel" aria-labelledby="home-tab">
                    <app-establishing></app-establishing>
                </div>
                <div class="tab-pane fade" [ngClass]="activeTab.toLocaleLowerCase() === 'vision'? 'show active':''"
                    id="vision" role="tabpanel" aria-labelledby="vision-tab">
                    <div class="vision-container">
                        <app-vision [center]="center"></app-vision>
                    </div>

                </div>
                <div class="tab-pane fade" [ngClass]="activeTab.toLocaleLowerCase() === 'board'? 'show active':''"
                    id="BoardOfDirectors" role="tabpanel" aria-labelledby="BoardOfDirectors-tab">

                    <app-board></app-board>

                </div>
                <div class="tab-pane fade"
                    [ngClass]="activeTab.toLocaleLowerCase() === 'organizationalstructure'? 'show active':''"
                    id="OrganizationalStructure" role="tabpanel" aria-labelledby="OrganizationalStructure-tab">
                    <app-organizationalchart [center]="center"></app-organizationalchart>
                </div>
            </div>

        </div>
        <app-shareicons></app-shareicons>
    </div>
</div> -->