import { HttpClient, HttpEventType, HttpErrorResponse, HttpResponse, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectableObservable, catchError, map, throwError } from 'rxjs';
import { fileExtensionValidator, fileSizeValidator } from 'src/app/file-extension-validator.directive';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-researcher',
  templateUrl: './researcher.component.html',
  styleUrls: ['./researcher.component.css']
})
export class ResearcherComponent implements OnInit {

  progress: number;
  message: string;
  educationdata: any[] = []

  @Input() showCompare!:  any[];
  @Output() showCompareChange = new EventEmitter<any[]>();
  @Output() step = new EventEmitter<number>();

  @Input()  GUID: string;
  @Input() localGUID : string = '';
  isCorrectSize : boolean = true;

  myForm = new FormGroup({
    nationalId: new FormControl('', [Validators.required, Validators.minLength(10),Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
    name: new FormControl('', [Validators.required,Validators.maxLength(10)]),
    degree: new FormControl('', [Validators.required]),
    degreeDate: new FormControl('', [Validators.required]),
    spciality: new FormControl('', [Validators.required,Validators.maxLength(100)]),
    workOn: new FormControl('', [Validators.required,Validators.maxLength(100)]),
    email: new FormControl('', [Validators.required,Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]),
    mobile: new FormControl('', [Validators.required,Validators.maxLength(14), Validators.pattern("^05[0-8]*$"), Validators.minLength(10)]),
    role: new FormControl('', [Validators.required]),
    resumeText: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    anyConflict: new FormControl('', [Validators.required,Validators.maxLength(300)]),
    researchDate: new FormControl('', [Validators.required]),
    file: new FormControl('', [Validators.required, fileExtensionValidator('pdf'),fileSizeValidator(200)]),
    GUID: new FormControl(this.localGUID),
  });


  constructor(private http: HttpClient) { }

  get f() {
    return this.myForm.controls;
  }

  upload(event: any) {
    this.isCorrectSize = true;
    const file = event.target.files[0];

    if(file.size > environment.maxFileSize){
      this.isCorrectSize = false;
      return;
    }
    else{
    //const file = event.target.files[0];
    this.progress = 1;
    const formData = new FormData();
    formData.append("file", file);

    this.http
      .post(`${environment.tarteebUrl}Upload?nationalId=${this.myForm.get('nationalId')?.value}`, formData, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = 0;
          }
        }),
        catchError((err: any) => {
          this.progress = 0;
          alert(err.message);
          return throwError(err.message);
        })
      )
      .toPromise();
    }
  }

  submit() {

    this.myForm.get("GUID")?.patchValue(this.GUID);

    this.http
      .post(`${environment.tarteebUrl}Save`, this.myForm.value, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = 0;
          }
        }),
        catchError((err: any) => {
          this.progress = 0;
          alert(err.message);
          return throwError(err.message);
        })
      )
      .toPromise();

    this.educationdata.push(this.myForm.getRawValue())
    this.showCompareChange.emit(this.educationdata);
    this.step.emit(3);

    if(this.myForm.get('role')?.value! != 'باحث رئيس'){
      this.myForm.reset();
    }
    
  }

  finish(){
    this.step.emit(4);
    console.log(4);
  }
  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.GUID)
      this.localGUID = this.GUID;
    else
      this.GUID = localStorage.getItem('GUID')!;

    this.educationdata = this.showCompare;
    // if(this.educationdata.length === 0 )
    //    this.myForm.get("role")?.setValue('باحث رئيس');
    // else{
    //   this.myForm.get("role")?.setValue('باحث مشارك');
    // }
  }
}
