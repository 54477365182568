import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators , ValidatorFn} from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, throwError } from 'rxjs';
import { fileExtensionValidator } from 'src/app/file-extension-validator.directive';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-research-details',
  templateUrl: './research-details.component.html',
  styleUrls: ['./research-details.component.css']
})
export class ResearchDetailsComponent implements OnInit {
  @Output() GUID = new EventEmitter<string>();
  @Output() step = new EventEmitter<number>();
  progress: number;
  message: string;
  isCorrectSize : boolean = true;

  myForm = new FormGroup({
    title: new FormControl('', [Validators.required,Validators.maxLength(100)]),
    major: new FormControl('', [Validators.required,Validators.maxLength(100)]),
    subField: new FormControl('', [Validators.required,Validators.maxLength(100)]),
    specialization: new FormControl('', [Validators.required,Validators.maxLength(100)]),
    suggestedDuration: new FormControl('', [Validators.required,Validators.pattern("^[0-9]*$"), Validators.maxLength(2)]),
    summary: new FormControl('', [Validators.required,Validators.maxLength(300)]),
    summary2: new FormControl('', [Validators.required,Validators.maxLength(300)]),
    summary3: new FormControl('', [Validators.required,Validators.maxLength(300)]),
    file: new FormControl('', [Validators.required, fileExtensionValidator('xlsx,xls')]),
    GUID:new FormControl(crypto.randomUUID(), [Validators.required])
  });
  
 

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  get f() {
    return this.myForm.controls;
  }

  upload(event: any) {
    this.isCorrectSize = true;
    const file = event.target.files[0];

    if(file.size > environment.maxFileSize ){
      this.isCorrectSize = false;
      return;
    }
    else{
      this.progress = 1;
      const formData = new FormData();
      formData.append("file", file);
  
      this.http
        .post(`${environment.tarteebUrl}Upload?nationalId=${this.myForm.get('GUID')?.value}`, formData, {
          reportProgress: true,
          observe: "events"
        })
        .pipe(
          map((event: any) => {
            if (event.type == HttpEventType.UploadProgress) {
              this.progress = Math.round((100 / event.total) * event.loaded);
            } else if (event.type == HttpEventType.Response) {
              this.progress = 0;
            }
          }),
          catchError((err: any) => {
            this.progress = 0;
            alert(err.message);
            return throwError(err.message);
          })
        )
        .toPromise();
    }
  }

  submit() {
    this.http
      .post(`${environment.tarteebUrl}SaveResearch`, this.myForm.value, {
        reportProgress: true,
        observe: "events"
      })
      .pipe(
        map((event: any) => {
          if(this.myForm.get('GUID')?.value){

            this.cookieService.set('GUID', this.myForm.get('GUID')?.value!);
            this.GUID.emit(this.myForm.get('GUID')?.value!);
            this.step.emit(2);
          }
          
        }),
        catchError((err: any) => {
          
          return throwError(err.message);
        })
      )
      .toPromise();
   // this.myForm.reset();
  }

  ngOnInit(): void {
  }

  public OnlyNumbers($event: { key: string; }) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight','ArrowLeft'];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
    }
}

