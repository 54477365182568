<div class="row d-flex justify-content-center">
    <div class="col-12">
        <p class="text-primary lh-base" style="text-align: justify;">
            {{center?.CenterMoreDetails}}
        </p>
    </div>
</div>
<div class="row row-cols-1 g-4 justify-content-start my-0">
    <div class="col" *ngIf="center?.centerVision">
        <div class="d-flex flex-lg-row flex-column align-items-center">
            <div class="me-lg-5 me-0  mb-3">
                <div class="d-flex align-items-center justify-content-center bg-secondary text-white m-0 fw-semibold fs-lg text-center vision-icons" style="height: 5rem; width: 5rem;border-radius: 0.7rem;">
                 <img src="assets/media/icons/vision-icon.svg" alt="vision icon" style="width: 2rem;"/>
                </div>
            </div>
            <h3 class="h3 fw-semibold">
                {{vision}}
               
            </h3>
            <p class="d-inline-block align-middle text-primary fs-sm ms-lg-5">
                {{center?.centerVision}}
            </p>
        </div>
    </div>
    <div class="col"  *ngIf="center?.centerMission">
        <div class="d-flex flex-lg-row flex-column align-items-center">
            <div class="me-lg-5 me-0 mb-3">
                <div class="d-flex align-items-center justify-content-center bg-secondary text-white m-0 fw-semibold fs-lg text-center vision-icons" style="height: 5rem; width: 5rem;border-radius: 0.7rem;">
                 <img src="assets/media/icons/mission-icon.svg" alt="mission icon" style="width: 2rem;"/>
                </div>
            </div>
            <h3 class="h3 fw-semibold">
                {{mission}}
            </h3>
            <p class="d-inline-block align-middle text-primary fs-sm ms-lg-5">
                {{center?.centerMission}}
            </p>
        </div>
    </div>
    <div class="col" *ngIf="center?.CenterValues">
        <div class="d-flex flex-lg-row flex-column align-items-center">
            <div class="me-lg-5 me-0 mb-3">
                <div class="d-flex align-items-center justify-content-center bg-secondary text-white m-0 fw-semibold fs-lg text-center vision-icons" style="height: 5rem; width: 5rem;border-radius: 0.7rem;">
                 <img src="assets/media/icons/value-icon.svg" alt="value icon" style="width: 2rem;"/>
                </div>
            </div>
            <h3 class="h3 fw-semibold">
                {{ourvalue}}
            </h3>
            <p class="d-inline-block align-middle text-primary fs-sm ms-lg-5">
                {{center?.CenterValues}}
            </p>
        </div>
    </div>
</div>
 <!--begin::share-->
 <app-shareicons></app-shareicons>


<!-- 
<div class="container">
    <div class="row">
        <div class="col-lg-12  text-center">

            <p class="text-primary mt-2">
                {{center?.CenterMoreDetails}}            
            </p>

        </div>
    </div>
</div>



<div class="row">
    <div class="col-lg-12 mt-5">

        <div class="d-flex flex-column container">
            <div class=" my-2">
                <div class="d-flex vision-item vcenter-item justify-content-start h-100">
                    <div class="py-1">
                        <img width="120" src="assets/img/icon/about/vision.svg" />
                    </div>
                    <div class="py-2">
                        <h2 class="text-primary px-lg-5 px-2">
                            الرؤيــــة
                        </h2>
                    </div>
                    <div class="py-1">
                        <h6 class="text-primary  px-2 ">
                                {{center?.centerVision}}
                        </h6>
                    </div>
                </div>

            </div>
            <div class=" my-2">
                <div class="d-flex vision-item vcenter-item justify-content-start h-100">
                    <div class="py-1">
                        <img width="120" src="assets/img/icon/about/Msg.svg" />
                    </div>
                    <div class="py-2">
                        <h2 class="text-primary px-lg-5 px-2">
                            الرسالة
                        </h2>
                    </div>
                    <div class="py-1">
                        <h6 class="text-primary px-2" >

                            {{center?.centerMission}}
                        </h6>
                    </div>
                </div>

            </div>
            <div class=" my-2">
                <div class="d-flex vision-item vcenter-item justify-content-start h-100">
                    <div class="py-1">
                        <img width="120" src="assets/img/icon/about/rate.svg" />
                    </div>
                    <div class="py-2">
                        <h2 class="text-primary px-lg-5 px-2">
                            قيمنـــــا
                        </h2>
                    </div>
                    <div class="py-1">
                        <h6 class="text-primary px-2">

                            {{center?.CenterValues}}
                        </h6>
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>
<app-shareicons></app-shareicons> -->
<!-- 
<div class="d-flex justify-content-center bd-highlight mb-3">
    <div class="share-card bg-warning">
        <div class="row">
            <div class="col-auto">
                <h5 class="font-light text-primary text-center" i18n="@@shareOpinion">
                    شارك على
                </h5>
            </div>
            <div class="col pt-1">
                <a href="#" class="m-1">
                    <img src="./assets/img/icon/social/facebook.svg" width="12" />
                </a>
                <a href="#" class="m-1">
                    <img src="./assets/img/icon/social/instagram.svg" width="20" />
                </a>
                <a href="#" class="m-1">
                    <img src="./assets/img/icon/social/linkedin.svg" width="20" />
                </a>
                <a href="#" class="m-1">
                    <img src="./assets/img/icon/social/twitter.svg" width="20" />
                </a>
                <a href="#" class="m-1">
                    <img src="./assets/img/icon/social/youtube.svg" width="20" />
                </a>
            </div>
        </div>
    </div>
</div> -->