import { Component,  OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {  map, Subject, takeUntil } from 'rxjs';
import { CenterLeaderList } from 'src/app/models/CenterLeaderList';
import { CenterLeader } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
 

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})

export class BoardComponent implements OnInit {
  boardMembers: CenterLeader[] = [];
  allMembers: CenterLeader[] = [];
  cEOonly: CenterLeader;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private centerService: ApiService, private actRoute: ActivatedRoute) {

    this.actRoute.params.subscribe(params => {
      if (params["id"] === 'board') {
        if (!this.boardMembers.length) {
          this.getLeaders();
        }
      }
    }
    );
  }

  /** Custom methods */
  cloneObj(data: CenterLeaderList):CenterLeader {
    return (
      {
        centerLeaderId: data.ItemFields.ID,
        centerLeaderPosition: data.ItemFields.CentersLeaderPositionId.LookupValue,
        centerLeaderPrefixId: data.ItemFields.CentersLeaderPrefixId.LookupValue,
        centerLeaderName: data.ItemFields.CentersLeaderName,
        centerLeaderPortrait: data.ItemFields.CentersLeaderPortrait?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
        centerLeaderCurriculumVite: '',
        isActive: false,
        Sequence: data.ItemFields.Sequence,
        IsCenterCEO: data.ItemFields.IsCenterCEO,
        IsChairman: data.ItemFields.IsChairman,
        IsBoardMember: data.ItemFields.IsBoardMember
      }
    )
  }

  getLeaders() {

    this.centerService.getList('CentersLeader').pipe(takeUntil(this.destroy$), map((res: CenterLeaderList[]) => res.map(re => {
      return this.cloneObj(re)
    }))).subscribe(data => {
      this.allMembers = data;
      this.allMembers.forEach(element => {
        if (element.IsChairman == false && element.IsBoardMember == true) {
          this.boardMembers.push(element)
        }
        else if (element.IsChairman == true){
          this.cEOonly = element;
        }
      })
  
      this.boardMembers = this.boardMembers.sort((a, b) => a.Sequence - b.Sequence);
      console.log(this.boardMembers);
    })
  }

  /** Component events  */
  ngOnInit(): void { }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}  