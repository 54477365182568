import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Service } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { Subject } from 'rxjs';
import { ResourcesService } from 'src/app/services/resources.service';
import { CenterList } from 'src/app/models/CenterList';

@Component({
  selector: 'app-center-services',
  templateUrl: './center-services.component.html',
  styleUrls: ['./center-services.component.css']
})

export class CenterServicesComponent implements OnInit {
  @Input() centerId: number;
  @Input() cenetrservices: Service[];
  @Input() cenetrservicesCategory: Service[];
  @Input() serviceCengerIcon: string;
  @Input() ServiceCategoryTag: string;
  //@Input() dynamicUrl! : string;
  @Output()  dynamicUrl = new EventEmitter<string>();
  searchText: any = '';
  more: string = '';
  search: string = '';
  noServices: string = '';
  centerCode = '';
  showCount = 12;
  servicesof = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  cid: number;
  _lang = '';


  constructor(private apiResources: ResourcesService, private apiServices: ApiService) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  /** Custom methods */
  route(product: Service) {

    //return "/service/" + product.GUID;
    return "/service/" + product.ServiceCode;
  }

  changeUrl(title: string){
    let url = `${this._lang}/${this.centerCode?.toLocaleLowerCase()}/services/${title}`;
     window.history.pushState("object or string", "Title", `/${url}`);
    //  let urlTemp = window.location.origin;
    //  this.dynamicUrl.emit(urlTemp+'/'+url);
    this.getUrl();

  }

  getUrl(){
    this.dynamicUrl.emit(window.location.href);
  }
  findItems(people: any[], categoryid?: number): any[] {
    return people.filter(p => p.ServiceCategoryId == categoryid);
  }

  cateogry(categoryitems: any[]): any[] {
    let sortedcategoryitems = categoryitems.sort((a, b) => (a.ServiceCategoryId < b.ServiceCategoryId) ? -1 : ((b.ServiceCategoryId > a.ServiceCategoryId) ? 1 : 0))
    return sortedcategoryitems;
  }

  haveCateogry(categoryitems: Service[]): boolean {
    if (categoryitems.length > 1) {
      return true;
    }
    else {
      return false;
    }

  }

  /** Component events  */
  async ngOnInit() {


    this.more = await this.apiResources.getByKey("more");
    this.search = await this.apiResources.getByKey("search");
    this.noServices = await this.apiResources.getByKey("noservices");
    this.servicesof = await this.apiResources.getByKey("servicesof");
  }


  ngOnChanges() {
    this.cid = this.centerId;
    this.getCenterCode(this.cid);
  }

  getCenterCode(id: number) {
    this.apiServices.getListById('centers', id).subscribe(
      (res: CenterList) => {
        this.centerCode = res.ItemFields?.CenterCode;

      }
    )
  }

}
