<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
    <div class="row">
        <div class="col m-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="{{_lang}}/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                    <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page">جائزة البحث العلمي </li>
                </ol>
            </nav>
        </div>
    </div>
</div>
</section>
<section class="container pb-5">
    <div class="row">
        <div class="col-12">
            <div class="wizard position-relative">
                <!-- Nav pills -->
                <ul class="nav nav-pills mb-4 d-flex justify-content-between mt-5 pt-3" role="tablist">
                    <li class="nav-item me-2 d-flex flex-column align-items-center">
                        <a href="#pills1"
                            class="nav-link d-flex align-items-center rounded-circle position-relative zindex-1 {{firstStep == 1 ? 'active':''}}"
                            id="pills-pills1" data-bs-toggle="pill" role="tab" aria-controls="pills1" aria-selected="true" style="width: 41px;height: 41px;">
                            1
                        </a>
                        <p class="mb-0 mt-2 fs-6 text-primary">بيانات البحث</p>
                    </li>
                    <li class="nav-item me-2  d-flex flex-column align-items-center">
                        <a href="#pills2" class="nav-link d-flex align-items-center rounded-circle position-relative zindex-1
                            {{(firstStep == 2 && educationdata.length == 0) ? 'active':'disabled'}}"
                            id="pills-pills2" data-bs-toggle="pill" role="tab" aria-controls="pills2" aria-selected="false" style="width: 41px;height: 41px;">
                            2
                        </a>
                        <p class="mb-0 mt-2 fs-6 text-primary">الباحث الرئيسي</p>
                    </li>
                    <li class="nav-item me-2  d-flex flex-column align-items-center">
                        <a href="#pills3" class="nav-link d-flex align-items-center rounded-circle position-relative zindex-1 
                            {{(firstStep == 3 && educationdata.length > 0) ? 'active':'disabled'}}"
                            id="pills-pills3" data-bs-toggle="pill" role="tab" aria-controls="pills3" aria-selected="false" style="width: 41px;height: 41px;">
                            3
                        </a>
                        <p class="mb-0 mt-2 fs-6 text-primary">الباحث المشارك</p>
                    </li>
                    <li class="nav-item  d-flex flex-column align-items-center">
                        <a href="#pills4"
                            class="nav-link d-flex align-items-center rounded-circle position-relative zindex-1  {{(firstStep == 4 && educationdata.length > 0) ? 'active':'disabled'}}"
                            id="pills-pills4" data-bs-toggle="pill" role="tab" aria-controls="pills4" aria-selected="false"
                            aria-disabled="true" style="width: 41px;height: 41px;">
                            4
                        </a>
                        <p class="mb-0 mt-2 fs-6 text-primary">تم الإرسال </p>
                    </li>
                </ul>
        
                <!-- Pills content -->
                <div class="tab-content bg-light p-4 rounded-5">
                    <div id="pills1" class="tab-pane fade  {{firstStep == 1 ? 'show active':''}}" role="tabpanel" aria-labelledby="pills-pills1">
                        <h1 class="fs-4">
                            بيانات حول البحث المقدم
                        </h1>
                        <app-research-details (GUID)="getChildData($event)" (step)="getStep($event)"></app-research-details>
                       
                    </div>
                    <div id="pills2" class="tab-pane fade {{(firstStep == 2 && educationdata.length == 0) ? 'show active':''}}" role="tabpanel" aria-labelledby="pills-pills2">
                        <h1 class="fs-4" >
                            البيانات الأولية للباحث الرئيس (متطلب)
                        </h1>
                        <app-researcher [(showCompare)]="educationdata" [GUID]="GUID" (step)="getStep($event)"></app-researcher>
                    </div>
                    <div id="pills3" class="tab-pane fade  {{(firstStep == 3 && educationdata.length > 0) ? 'show active':''}}" role="tabpanel" aria-labelledby="pills-pills3">
                        <h1 class="fs-4" >
                            البيانات الأولية للباحث المشارك أو المساعد
                        </h1>
                        <app-researcher [(showCompare)]="educationdata" [GUID]="GUID" (step)="getStep($event)"></app-researcher>
                        <app-researcher-list [(educationList)]="educationdata"></app-researcher-list>
                    </div>
                    <div id="pills4" class="tab-pane fade text-center text-primary {{(firstStep == 4 && educationdata.length > 0) ? 'show active':''}}" role="tabpanel" aria-labelledby="pills-pills4" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-check-circle text-success" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                          </svg>
                       <h5 class="mt-3">تم إرسال النموذج بنجاح </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>