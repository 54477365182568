import { Component, Input, OnInit } from '@angular/core';
import { ServiceGoal } from 'src/app/models/ResponseModel';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnInit {

  @Input() Goals: ServiceGoal[] = []
  @Input() servicegoal : string

  constructor() { }

  ngOnInit(): void {
  }
  
  cateogry(categoryitems: any[]): any[] {
    let sortedcategoryitems = categoryitems.sort((a, b) => (a.ServiceGoalNumber < b.ServiceGoalNumber) ? -1 : ((b.ServiceGoalNumber > a.ServiceGoalNumber) ? 1 : 0));
    return sortedcategoryitems;
  }


}
