<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/faq.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="text-white fw-semibold m-0 position-relative zindex-5">
                    {{faq}}
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
        <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="/home" [innerText]="home"></a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{faq}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>


<!--begin::card-->
<div class="container">

    <!-- pageTitle -->
    <!-- <div class="row justify-content-md-start justify-content-center mb-4">
        <div
            class="col-lg-6 col-md-8 col-sm-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-md-start align-items-center text-md-start text-center">
            <h1 class="text-primary h4 mb-2">
                {{faq}}
            </h1>
            <span class="bg-warning mt-1" style="height: 0.35rem;width: 3.5rem;"></span>
        </div>
    </div> -->
    <div class="row justify-content-md-start justify-content-center mb-2">
        <div class="col-12 text-primary" style="text-align: justify;">

            <div class="accordion" id="accordionAlt">
                <!-- Item -->
                <div class="accordion-item border-0 rounded-3 shadow-sm mb-3"
                    *ngFor="let elm of faqList ; let i = index">
                    <h3 class="accordion-header" id="heading{{i}}">
                        <button class="accordion-button shadow-none rounded-3 text-primary collapsed" type="button"
                            data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="false"
                            [attr.aria-controls]="'collapse'+i">
                            {{elm.ItemFields.FAQQuestion}}
                        </button>
                    </h3>
                    <div class="accordion-collapse collapse" id="collapse{{i}}" [attr.aria-labelledby]="'heading'+i"
                        data-bs-parent="#accordionAlt">
                        <div class="accordion-body text-primary pt-0" [innerHTML]="elm.ItemFields.FAQAnswer">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <!--begin::share-->
    <app-shareicons></app-shareicons>

</div>