<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/MediaCenter.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="h1 text-white fw-semibold m-0 position-relative zindex-5" [innerText]="PageTitle">
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
        <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="{{_lang}}/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page" [innerText]="PageTitle">11 </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>
<!--begin::tabs-->
<section class="container">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs content-tabs flex-nowrap justify-content-md-center justify-content-start overflow-auto mb-0 pb-4 pt-0"
        role="tablist">
        <li class="nav-item mb-0" role="presentation" *ngIf="News">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab1" data-bs-toggle="tab" data-bs-target="#news" role="tab" aria-controls="tab1"
                aria-selected="true" routerLink="/mediacenter/news"
                [ngClass]="activeTab.toLocaleLowerCase()  === 'news'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important" (click)="isNews = true">
                <i class="bx bx-news me-2 fs-2"></i>
                {{News}}
            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation" *ngIf="infographic">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab2" data-bs-toggle="tab" data-bs-target="#infographic" role="tab" aria-controls="tab2"
                aria-selected="true" routerLink="/mediacenter/infographic"
                [ngClass]="activeTab.toLocaleLowerCase() === 'infographic'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important" (click)="isInfographic = true">
                <i class="bx bxs-file-image me-2 fs-2"></i>
                {{infographic}}
            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation" *ngIf="videolibrary">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab3" data-bs-toggle="tab" data-bs-target="#Youtube" role="tab" aria-controls="tab3"
                aria-selected="true" routerLink="/mediacenter/videolibrary"
                [ngClass]="activeTab.toLocaleLowerCase() === 'videolibrary'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important" (click)="isVideolibrary = true">
                <i class="bx bx-video me-2 fs-2"></i>
                {{videolibrary}}

            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation" *ngIf="flyers">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab4" data-bs-toggle="tab" data-bs-target="#BulletinsAndPublications" role="tab"
                aria-controls="tab4" aria-selected="true" routerLink="/mediacenter/flyers"
                [ngClass]="activeTab.toLocaleLowerCase() === 'flyers'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important" (click)="isFlyers = true">
                <i class="bx bx-list-check me-2 fs-2"></i>
                {{flyers}}

            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation"  *ngIf="idguide">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab5" data-bs-toggle="tab" data-bs-target="#AuthorityIdentityGuide" role="tab"
                aria-controls="tab5" aria-selected="true" routerLink="/mediacenter/idguide"
                [ngClass]="activeTab.toLocaleLowerCase() === 'idguide'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important" (click)="isIdguide = true">
                <i class="bx bx-palette me-2 fs-2"></i>
                {{idguide}}
            </a>
        </li>
    </ul>
    <!-- Tab content -->
    <div class="tab-content rounded-3">
        <!-- tab-pane -->
        <div class="tab-pane fade" id="news" role="tabpanel" aria-labelledby="tabs-tab1"
            [ngClass]="activeTab.toLocaleLowerCase() === 'news'? 'show active':''" *ngIf="News">
            <!-- start tab content -->
            <app-allnews  *ngIf="isNews"></app-allnews>
            <!-- end tab content -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade " id="infographic" role="tabpanel" aria-labelledby="tabs-tab1"
            [ngClass]="activeTab.toLocaleLowerCase() === 'infographic'? 'show active':''" *ngIf="infographic">
            <!-- start tab content -->
            <app-infographic *ngIf="isInfographic"></app-infographic>
            <!-- end tab content -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade " id="Youtube" role="tabpanel" aria-labelledby="tabs-tab1"
            [ngClass]="activeTab.toLocaleLowerCase() === 'videolibrary'? 'show active':''" *ngIf="videolibrary">
            <!-- start tab content -->
            <app-videolibrary *ngIf="isVideolibrary"></app-videolibrary>
            <!-- end tab content -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade " id="BulletinsAndPublications" role="tabpanel" aria-labelledby="tabs-tab1"
            [ngClass]="activeTab.toLocaleLowerCase() === 'flyers'? 'show active':''" *ngIf="flyers">
            <!-- start tab content -->
            <app-publicationsandreleases *ngIf="isFlyers"></app-publicationsandreleases>
            <!-- end tab content -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade " id="AuthorityIdentityGuide" role="tabpanel" aria-labelledby="tabs-tab1"
            [ngClass]="activeTab.toLocaleLowerCase() === 'idguide'? 'show active':''" *ngIf="idguide">
            <!-- start tab content -->
            <app-identityguideline *ngIf="isIdguide"></app-identityguideline>
            <!-- end tab content -->
        </div>

    </div>
</section>

















<!-- <nav class="bg-primary" aria-label="breadcrumb">

    <div class="container">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
            <li class="breadcrumb-item"><a href="#">تواصل معنا</a></li>
        </ol>
    </div>

</nav> -->
<!-- <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">

    </div>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img class="bd-placeholder-img" src="assets/img/BG/MediaCenter.png" />
            <div class="container">
                <div class="carousel-caption text-start">
                    <h1>
                        المركز
                    </h1>
                    <h1 class="px-3 small-bottom-border ">
                        الإعلامي
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <main>
    <div class="container ">
        <div class="tab-nav">
            <ul class="nav tab justify-content-center  large-screen-tab">
                <li class="nav-item ">
                    <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase()  === 'news'? 'active':''"
                        routerLink="/mediacenter/news" id="news-tab" data-bs-toggle="tab" data-bs-target="#news"
                        role="tab" aria-controls="news" aria-selected="true">
                        <span class="tab-span">
                            <span class="tab-icon">
                                <img width="50" src="assets/img/tab-news.svg" />
                            </span>
                            <span class="tab-text">
                                الأخبار
                                &nbsp;
                            </span>
                        </span>
                    </a>
                </li>
                <li class="nav-item">

                    <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase()  === 'infographic'? 'active':''"
                        routerLink="/mediacenter/infographic" href="#" id="infographic-tab" data-bs-toggle="tab"
                        data-bs-target="#infographic" role="tab" aria-controls="infographic" aria-selected="false">
                        <span class="tab-span">
                            <span class="tab-icon">
                                <img width="50" src="assets/img/tab-infographic.svg" />
                            </span>

                            <span class="tab-text">
                                الأنفوجرافيك
                            </span>
                        </span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase()  === 'videolibrary'? 'active':''"
                        routerLink="/mediacenter/videolibrary" id="Youtube-tab" data-bs-toggle="tab"
                        data-bs-target="#Youtube" role="tab" aria-controls="Youtube" aria-selected="false">
                        <span class="tab-span">
                            <span class="tab-icon">
                                <img width="50" src="assets/img/tab-Youtube.svg" />

                            </span>

                            <span class="tab-text">
                                مكتبة الفيديو
                            </span>
                        </span>
                    </a>
                </li>
                <li class="nav-item">

                    <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase() === 'flyers'? 'active':''"
                        routerLink="/mediacenter/flyers" id="BulletinsAndPublications-tab" data-bs-toggle="tab"
                        data-bs-target="#BulletinsAndPublications" role="tab" aria-controls="BulletinsAndPublications"
                        aria-selected="false">
                        <span class="tab-span">
                            <span class="tab-icon">
                                <img width="50" src="assets/img/tab-BulletinsAndPublications.svg" />

                            </span>

                            <span class="tab-text">
                                النشرات والاصدارات
                            </span>
                        </span>
                    </a>
                </li>
                <li class="nav-item">

                    <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase()  === 'idguide'? 'active':''"
                        routerLink="/mediacenter/idguide" id="AuthorityIdentityGuide-tab" data-bs-toggle="tab"
                        data-bs-target="#AuthorityIdentityGuide" role="tab" aria-controls="AuthorityIdentityGuide"
                        aria-selected="false">
                        <span class="tab-span">
                            <span class="tab-icon">
                                <img width="50" src="assets/img/tab-AuthorityIdentityGuide.svg" />

                            </span>

                            <span class="tab-text">
                                دليل الهوية
                            </span>
                        </span>
                    </a>
                </li>
            </ul>




        </div>
        <div class="card card-tab shadow-sm border-0">
            <div class="card-body card-body-padding">
                <div class="row small-screen-tab">
                    <div class="col-lg-12">
                        <div class="list-group mb-3 ">
                            <a class="list-group-item list-group-item-action  p-3"
                                [ngClass]="activeTab.toLocaleLowerCase()  === 'news'? 'active':''"
                                routerLink="/mediacenter/news" id="news-tab" data-bs-toggle="tab" data-bs-target="#news"
                                role="tab" aria-controls="news" aria-selected="true">
                                <img width="30" src="assets/img/tab-news.svg" />
                                الأخبار
                            </a>
                            <a class="list-group-item list-group-item-action  p-3"
                                [ngClass]="activeTab.toLocaleLowerCase()  === 'infographic'? 'active':''"
                                routerLink="/mediacenter/infographic" href="#" id="infographic-tab" data-bs-toggle="tab"
                                data-bs-target="#infographic" role="tab" aria-controls="infographic"
                                aria-selected="false">
                                <img width="30" src="assets/img/tab-infographic.svg" />
                                الأنفوجرافيك


                            </a>
                            <a class="list-group-item list-group-item-action  p-3"
                                [ngClass]="activeTab.toLocaleLowerCase()  === 'videolibrary'? 'active':''"
                                routerLink="/mediacenter/videolibrary" id="Youtube-tab" data-bs-toggle="tab"
                                data-bs-target="#Youtube" role="tab" aria-controls="Youtube" aria-selected="false">
                                <img width="30" src="assets/img/tab-Youtube.svg" />
                                مكتبة الفيديو

                            </a>
                            <a class="list-group-item list-group-item-action  p-3"
                                [ngClass]="activeTab.toLocaleLowerCase() === 'flyers'? 'active':''"
                                routerLink="/mediacenter/flyers" id="BulletinsAndPublications-tab" data-bs-toggle="tab"
                                data-bs-target="#BulletinsAndPublications" role="tab"
                                aria-controls="BulletinsAndPublications" aria-selected="false">
                                <img width="30" src="assets/img/tab-BulletinsAndPublications.svg" />
                                النشرات والاصدارات

                            </a>


                            <a class="list-group-item list-group-item-action  p-3"
                                [ngClass]="activeTab.toLocaleLowerCase()  === 'idguide'? 'active':''"
                                routerLink="/mediacenter/idguide" id="AuthorityIdentityGuide-tab" data-bs-toggle="tab"
                                data-bs-target="#AuthorityIdentityGuide" role="tab"
                                aria-controls="AuthorityIdentityGuide" aria-selected="false">
                                <img width="30" src="assets/img/tab-AuthorityIdentityGuide.svg" />
                                دليل الهوية

                            </a>
                        </div>
                    </div>
                </div>

                <div class="tab-content tab-content-card" id="myTabContent">
                    <div class="tab-pane fade show "
                        [ngClass]="activeTab.toLocaleLowerCase()  === 'news'? 'active show':''" id="news"
                        role="tabpanel" aria-labelledby="news-tab">
                        <app-allnews></app-allnews>
                    </div>
                    <div class="tab-pane fade"
                        [ngClass]="activeTab.toLocaleLowerCase()  === 'infographic'? 'active show':''" id="infographic"
                        role="tabpanel" aria-labelledby="infographic-tab">
                        <app-infographic></app-infographic>
                    </div>
                    <div class="tab-pane fade"
                        [ngClass]="activeTab.toLocaleLowerCase()  === 'videolibrary'? 'active show':''" id="Youtube"
                        role="tabpanel" aria-labelledby="Youtube-tab">
                        <app-videolibrary></app-videolibrary>
                    </div>
                    <div class="tab-pane fade" [ngClass]="activeTab.toLocaleLowerCase() === 'flyers'? 'active show':''"
                        id="BulletinsAndPublications" role="tabpanel" aria-labelledby="BulletinsAndPublications-tab">
                        <app-publicationsandreleases></app-publicationsandreleases>
                    </div>
                    <div class="tab-pane fade"
                        [ngClass]="activeTab.toLocaleLowerCase()  === 'idguide'? 'active show':''"
                        id="AuthorityIdentityGuide" role="tabpanel" aria-labelledby="AuthorityIdentityGuide-tab">
                        <app-identityguideline></app-identityguideline>
                    </div>
                </div> -->
<!-- <div class="d-flex justify-content-center bd-highlight mb-3">
                <div class="share-card bg-warning">
                    <div class="row">
                        <div class="col-auto">
                            <h5 class="font-light text-primary text-center" i18n="@@shareOpinion">
                                شارك على
                            </h5>
                        </div>
                        <div class="col pt-1">
                            <a href="#" class="m-1">
                                <img src="./assets/img/icon/social/facebook.svg" width="12" />
                            </a>
                            <a href="#" class="m-1">
                                <img src="./assets/img/icon/social/instagram.svg" width="20" />
                            </a>
                            <a href="#" class="m-1">
                                <img src="./assets/img/icon/social/linkedin.svg" width="20" />
                            </a>
                            <a href="#" class="m-1">
                                <img src="./assets/img/icon/social/twitter.svg" width="20" />
                            </a>
                            <a href="#" class="m-1">
                                <img src="./assets/img/icon/social/youtube.svg" width="20" />
                            </a>
                        </div>
                    </div>
                </div>
            </div> -->

<!-- </div>


            <app-shareicons></app-shareicons>
        </div>


    </div>


</main> -->