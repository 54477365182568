import { Component, OnInit } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { ETECNoList } from 'src/app/models/ETECNoList';
import { Statistics } from 'src/app/models/Statistics';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  statistics: Statistics[] = [];
  etecinnumbers = '';
  isLoad = false;

  constructor(private apiServices: ApiService, private apiResources: ResourcesService) {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {

        if (window.scrollY >= 1100 && this.statistics.length === 0 && !this.isLoad) {
          this.isLoad = true;
          this.apiServices.getList('EtecInNumbers').pipe(takeUntil(this.destroy$)).subscribe(
            (data: ETECNoList[]) => {
              data.forEach(element => {
                var obj: Statistics = {
                  id: element.ItemId,
                  numbersText: element.ItemFields.EtecinNumbersNumbers,
                  msg: element.ItemFields.EtecinNumbersTitle
                };
                this.statistics.push(obj);
              });
            }
          );


        }
      });


  }


  /** Component events  */
  async ngOnInit() {
    await this.apiServices.logInAsync();

    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
      .subscribe(async (e: Event) => {
        if (window.scrollY >= 1100 && this.statistics.length === 0 && !this.etecinnumbers) {
          this.etecinnumbers = await this.apiResources.getByKey("etecinnumbers");
        }
      }
      );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
