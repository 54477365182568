import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Title } from '@angular/platform-browser';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { Accreditationresultslist } from 'src/app/models/accreditationresultslist';
import { Configurationlist } from 'src/app/models/configurationlist';
import { Organazationlist } from 'src/app/models/organazationlist';
// import { ResourcesList } from 'src/app/models/ResourcesList';
import { Accreditationresults, Configuration, Organazation } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-accreditationresult',
  templateUrl: './accreditationresult.component.html',
  styleUrls: ['./accreditationresult.component.css']
})
export class AccreditationresultComponent implements OnInit {

  Itemsinstitution: Accreditationresults[] = [];
  ItemsinstitutionDDL: Accreditationresults[] = [];
  viewItemsinstitution: Accreditationresults[] = [];
  @Input() centerId!: number;
  all: string ='';
  Itemsprograms: Accreditationresults[] = [];
  viewItemsprograms: Accreditationresults[] = [];
  Organazation: Organazation[] = [];
  TypeAccrediation: Organazation[] = [];
  allTitle : string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText: any = '';
  selectedValue: string;
  public p: number = 1;
  AccreditationresultsSearch: Accreditationresults[] = [];
  showCount = 6;
  cId: number;
  selectedItemCategory: string = '0';
  selectedItemAccType: string = '0';
  selectedItemProgram: string = '0';
  selectedItemAccrediation: string = '0';
  selectedIteminstitution: string = '0';
  selectedTabinstitution: boolean = false;
  selectedTabPrograms: boolean = false;
  LabelTitle: string = '';
  LabelTitle2: string = '';
  more: string = '';
  servicesof: string = '';
  EtecFullName = '';
  programs: string = '';
  Institutions: string = '';
  Typeofaccreditation: string = '';
  nameofuniversity: string = '';
  OrganizationType: string = '';
  DateAccreditation: string = '';
  TypeAccreditation: string = '';
  DegreeName: string = '';
  ProgramName: string = '';
  accreditationCategory: string = '';
  AccreditationResults: string = '';
  HaveProgramsItems: boolean;
  HaveInstitutionsItems: boolean;
  expired = '';
  full = '';
  ShowExpireAcrediationResult?: boolean = false;
  outsideIndex = 0;
  myControl = new FormControl<string>('');
  filteredOptions: Observable<Organazation[]>;
  filterControl = new FormControl();

  myControl2 = new FormControl<string>('');
  filteredOptions2: Observable<Organazation[]>;

  constructor(private apiServices: ApiService, private Title: Title, private apiResources: ResourcesService) {
    //this.FillinstitutionDDL();

  }

   ngOnInit() {
    this.FillTypeAccrediationDDL();
    
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => value !=''?  this._filter(value || '') : this.Organazation),
    // );

    // this.filteredOptions2 = this.myControl2.valueChanges.pipe(
    //   startWith(''),
    //   map(value => value !=''?  this._filter2(value || '') : this.TypeAccrediation),
    // );
   
   
  }

  // displayFn(value: string): string {
  //   let _lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';

  //   return value == '0' ? (_lang == 'en'?'All': 'الكل') : value ;
  // }

  // private  _filter(value: string): Organazation[] {
  //   const filterValue = value.toLowerCase();
  //   return this.Organazation.filter(option => option.Title.toLowerCase().includes(filterValue));
  // }

  // private  _filter2(value: string): Organazation[] {
    
  //   const filterValue = value.toLowerCase();
  //   return this.TypeAccrediation.filter(option => option.Title.toLowerCase().includes(filterValue));
  // }


  updateTitle(title: string) {
    // console.log(title);
    this.Title.setTitle(this.EtecFullName + " - " + title);
  }

  getConfigurations() {

    this.apiServices.getList('Configurations').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Configurationlist[]) => {
        data.forEach(element => {
          if (element.ItemFields.ConfigurationName == "ShowExpireAcrediationResult") {

            this.ShowExpireAcrediationResult = element.ItemFields.ShowExpireAcrediationResult;
          }
        }
        );
      }
    );
  }
  institutionAccrediation() {
    var now = new Date();
    const Today = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

    //console.log("institutionAccrediation"+this.centerId);
    this.selectedTabinstitution = true;
    this.selectedItemCategory = this.Institutions;
    this.HaveInstitutionsItems = false;
    //this.HaveProgramsItems = false;

    //console.log("test");
    this.Itemsinstitution.splice(0, this.Itemsinstitution.length);
    this.viewItemsinstitution.splice(0, this.viewItemsinstitution.length);
    //getListWithTwoConditionsTopN('AccrediationsResults',100,false,'CenterId',this.cId,'Category', 'مؤسسي', 'Eq','And','Eq' )
    this.apiServices.getListWithTwoConditionsTopN('AccrediationsResults', 0, false, 'CenterId', this.cId, 'Category', 'مؤسسي', 'Eq', 'And', 'Eq').pipe(takeUntil(this.destroy$)).subscribe(
      //this.apiServices.getList('AcademicAccreditationResults').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Accreditationresultslist[]) => {
        data.forEach(element => {
          if (this.ShowExpireAcrediationResult) {
            var obj: Accreditationresults = {
              ID: element.ItemFields.ID,
              Title: '',
              ServiceName: '',
              CenterName: '',
              Link: element.ItemFields?.link?.Url,
              OrganizationName: element.ItemFields.OrganizationId.LookupValue,
              OrganizationProgramName: '',
              OrganizationDegreeProgramName: '',
              OrgTypeName: element.ItemFields.OrgTypeId.LookupValue,
              AccTypeName: (formatDate(element.ItemFields.AccTo, 'yyyy-MM-dd', 'en_US') < formatDate(Today, 'yyyy-MM-dd', 'en_US') ? 'منتهي' : element.ItemFields.AccTypeId.LookupValue),
              AccFrom: element.ItemFields.AccFrom,
              AccTo: element.ItemFields.AccTo,
              IsActive: element.ItemFields.IsActive1
              
            };
            
            this.Itemsinstitution.push(obj);
            this.viewItemsinstitution.push(obj);
            this.AccreditationresultsSearch = this.viewItemsinstitution;
            if (this.viewItemsinstitution.length > 0) {
              this.HaveInstitutionsItems = true;
            }
          } else {
            if (formatDate(element.ItemFields.AccTo, 'yyyy-MM-dd', 'en_US') >= formatDate(Today, 'yyyy-MM-dd', 'en_US')) {
              var obj: Accreditationresults = {
                ID: element.ItemFields.ID,
                Title: '',
                ServiceName: '',
                CenterName: '',
                Link: element.ItemFields?.link?.Url,
                OrganizationName: element.ItemFields.OrganizationId.LookupValue,
                OrganizationProgramName: '',
                OrganizationDegreeProgramName: '',
                OrgTypeName: element.ItemFields.OrgTypeId.LookupValue,
                AccTypeName: element.ItemFields.AccTypeId.LookupValue,
                AccFrom: element.ItemFields.AccFrom,
                AccTo: element.ItemFields.AccTo,
                IsActive: element.ItemFields.IsActive1
              };
              
              this.Itemsinstitution.push(obj);
              this.viewItemsinstitution.push(obj);
              this.AccreditationresultsSearch = this.viewItemsinstitution;
              if (this.viewItemsinstitution.length > 0) {
                this.HaveInstitutionsItems = true;
              }
            }
          }
        });
      }
    );
    // this.FillinstitutionDDL();

  }
  SortItemsinstitution(categoryitems: any[]): any[] {
    let sortedcategoryitems = categoryitems.sort((a, b) => (a.OrganizationName < b.OrganizationName) ? -1 : ((b.OrganizationName > a.OrganizationName) ? 1 : 0));
    return sortedcategoryitems;
  }
  SortItemsprograms(categoryitems: any[]): any[] {
    let sortedcategoryitems = categoryitems.sort((a, b) => (a.OrganizationProgramName < b.OrganizationProgramName) ? -1 : ((b.OrganizationProgramName > a.OrganizationProgramName) ? 1 : 0));
    return sortedcategoryitems;
  }
  ProgramsAccrediation() {
    const Today = new Date();
    //console.log("ProgramsAccrediation"+ this.cId);
    this.selectedTabinstitution = false;
    this.selectedTabPrograms = true;
    this.Itemsprograms.splice(0, this.Itemsprograms.length);
    this.viewItemsprograms.splice(0, this.viewItemsprograms.length);

    this.apiServices.getListWithTwoConditionsTopN('AccrediationsResults', 0, false, 'CenterId', this.cId, 'Category', 'برامجي', 'Eq', 'And', 'Eq').pipe(takeUntil(this.destroy$)).subscribe(

      (data: Accreditationresultslist[]) => {
        data.forEach(element => {
          if (this.ShowExpireAcrediationResult) {
            var obj: Accreditationresults = {
              ID: element.ItemFields.ID,
              Title: element.ItemFields.Title,
              ServiceName: '',
              CenterName: element.ItemFields.CenterId.LookupValue,
              OrganizationName: element.ItemFields.OrganizationId.LookupValue,
              OrganizationProgramName: element.ItemFields.OrganizationProgramId.LookupValue,
              OrganizationDegreeProgramName: element.ItemFields.OrganizationDegreeProgramId.LookupValue,
              OrgTypeName: element.ItemFields.OrgTypeId.LookupValue,
              Link: element.ItemFields?.link?.Url,
              AccTypeName: (formatDate(element.ItemFields.AccTo, 'yyyy-MM-dd', 'en_US') < formatDate(Today, 'yyyy-MM-dd', 'en_US') ? this.expired : element.ItemFields.AccTypeId.LookupValue),
              AccFrom: element.ItemFields.AccFrom,
              AccTo: element.ItemFields.AccTo,
              IsActive: element.ItemFields.IsActive1
            };


            this.Itemsprograms.push(obj);
            this.viewItemsprograms.push(obj);
            this.AccreditationresultsSearch = this.viewItemsprograms;
            if (this.viewItemsprograms.length > 0) {
              this.HaveProgramsItems = true;
            }
          }
          else {
            if (formatDate(element.ItemFields.AccTo, 'yyyy-MM-dd', 'en_US') >= formatDate(Today, 'yyyy-MM-dd', 'en_US')) {
              var obj: Accreditationresults = {
                ID: element.ItemFields.ID,
                Title: element.ItemFields.Title,
                ServiceName: '',
                CenterName: element.ItemFields.CenterId.LookupValue,
                OrganizationName: element.ItemFields.OrganizationId.LookupValue,
                OrganizationProgramName: element.ItemFields.OrganizationProgramId.LookupValue,
                OrganizationDegreeProgramName: element.ItemFields.OrganizationDegreeProgramId.LookupValue,
                OrgTypeName: element.ItemFields.OrgTypeId.LookupValue,
                AccTypeName: element.ItemFields.AccTypeId.LookupValue,
                AccFrom: element.ItemFields.AccFrom,
                Link: element.ItemFields?.link?.Url,
                AccTo: element.ItemFields.AccTo,
                IsActive: element.ItemFields.IsActive1
              };


              this.Itemsprograms.push(obj);
              this.viewItemsprograms.push(obj);
              this.AccreditationresultsSearch = this.viewItemsprograms;
              if (this.viewItemsprograms.length > 0) {
                this.HaveProgramsItems = true;
              }
            }
          }
        });
      }
    );
    //console.log(" this.viewItemsprograms" +  this.viewItemsprograms.length);
  }

  changeRatio() {
    if (this.selectedTabinstitution) {

      if (this.selectedItemAccrediation != '0' && this.selectedIteminstitution == '0') {

        this.viewItemsinstitution.splice(0, this.viewItemsinstitution.length);
        this.viewItemsinstitution = this.Itemsinstitution.filter(
          item => item.AccTypeName === this.selectedItemAccrediation
        );
      }
      if (this.selectedItemAccrediation == '0' && this.selectedIteminstitution != '0') {

        this.viewItemsinstitution.splice(0, this.Itemsinstitution.length);
        this.viewItemsinstitution = this.Itemsinstitution.filter(
          item => item.OrganizationName === this.selectedIteminstitution
        );
      }
      if (this.selectedItemAccrediation != '0' && this.selectedIteminstitution != '0') {
        this.viewItemsinstitution.splice(0, this.Itemsinstitution.length);
        this.viewItemsinstitution = this.Itemsinstitution.filter(
          item => item.AccTypeName === this.selectedItemAccrediation && item.OrganizationName == this.selectedIteminstitution
        );
      }

      if (this.selectedItemAccrediation == '0' && this.selectedIteminstitution == '0') {
        this.viewItemsinstitution.splice(0, this.Itemsinstitution.length);
        this.institutionAccrediation();
        this.ProgramsAccrediation();
      }
    }
    if (this.selectedTabPrograms) {


      if (this.selectedItemAccrediation != '0' && this.selectedIteminstitution == '0') {

        this.viewItemsprograms.splice(0, this.viewItemsprograms.length);
        this.viewItemsprograms = this.Itemsprograms.filter(
          item => item.OrganizationProgramName === this.selectedItemAccrediation
        );
      }
      if (this.selectedItemAccrediation == '0' && this.selectedIteminstitution != '0') {

        this.viewItemsprograms.splice(0, this.viewItemsprograms.length);
        this.viewItemsprograms = this.Itemsprograms.filter(
          item => item.OrganizationName === this.selectedIteminstitution
        );
      }
      if (this.selectedItemAccrediation != '0' && this.selectedIteminstitution != '0') {
        this.viewItemsprograms.splice(0, this.viewItemsprograms.length);
        this.viewItemsprograms = this.Itemsprograms.filter(
          item => item.OrganizationProgramName === this.selectedItemAccrediation && item.OrganizationName == this.selectedIteminstitution
        );
      }

      if (this.selectedItemAccrediation == '0' && this.selectedIteminstitution == '0') {
        this.viewItemsprograms.splice(0, this.viewItemsprograms.length);
        this.institutionAccrediation();
        this.ProgramsAccrediation();
      }
    }
  }
  ShowItems(category: any, org: any, AccType: any, Program: any) {
    if (category == this.Institutions) {
      //console.log("category"+ category +  " -- "+ this.Institutions );
      if (AccType != '0' && org == '0') {

        this.viewItemsinstitution.splice(0, this.viewItemsinstitution.length);
        this.viewItemsinstitution = this.Itemsinstitution.filter(
          item => item.AccTypeName === AccType
        );
      }
      if (AccType == '0' && org != '0') {

        this.viewItemsinstitution.splice(0, this.Itemsinstitution.length);
        this.viewItemsinstitution = this.Itemsinstitution.filter(
          item => item.OrganizationName === org
        );
      }
      if (AccType != '0' && org != '0') {
        this.viewItemsinstitution.splice(0, this.Itemsinstitution.length);
        this.viewItemsinstitution = this.Itemsinstitution.filter(
          item => item.AccTypeName === AccType && item.OrganizationName == org
        );
      }

      if (AccType == '0' && org == '0') {
        this.viewItemsinstitution.splice(0, this.Itemsinstitution.length);
        this.institutionAccrediation();
        //this.ProgramsAccrediation();
      }
    }
    if (category == this.programs) {

      //console.log("category"+ category +  " -- "+ this.programs );
      if (Program != '0' && org == '0') {

        this.viewItemsprograms.splice(0, this.viewItemsprograms.length);
        this.viewItemsprograms = this.Itemsprograms.filter(
          item => item.OrganizationProgramName === Program
        );
      }
      if (Program == '0' && org != '0') {

        this.viewItemsprograms.splice(0, this.viewItemsprograms.length);
        this.viewItemsprograms = this.Itemsprograms.filter(
          item => item.OrganizationName === org
        );
      }
      if (Program != '0' && org != '0') {
        this.viewItemsprograms.splice(0, this.viewItemsprograms.length);
        this.viewItemsprograms = this.Itemsprograms.filter(
          item => item.OrganizationProgramName === Program && item.OrganizationName == org
        );
      }

      if (Program == '0' && org == '0') {
        this.viewItemsprograms.splice(0, this.viewItemsprograms.length);
        //this.institutionAccrediation();
        this.ProgramsAccrediation();
      }
    }
  }
  async ngOnChanges() {
    this.getConfigurations();
    this.cId = this.centerId;
    this.institutionAccrediation();
    this.ProgramsAccrediation();
     this.FillinstitutionDDL();
    //this.FillTypeAccrediationDDL();

    this.selectedTabinstitution = true;
    this.selectedTabPrograms = false;


    this.selectedItemCategory = await this.apiResources.getByKey("Institutions");


    this.more = await this.apiResources.getByKey("more");
    this.servicesof = await this.apiResources.getByKey("servicesof");
    this.EtecFullName = await this.apiResources.getByKey("etecfullname");
    this.programs = await this.apiResources.getByKey("programs");
    this.Institutions = await this.apiResources.getByKey("Institutions");
    this.Typeofaccreditation = await this.apiResources.getByKey("Typeofaccreditation");
    this.nameofuniversity = await this.apiResources.getByKey("nameofuniversity");
    this.Typeofaccreditation = await this.apiResources.getByKey("Typeofaccreditation");
    if (this.cId == 4) {
      this.LabelTitle = await this.apiResources.getByKey("nameoffacility");
    } else {
      this.LabelTitle = await this.apiResources.getByKey("nameofuniversity");
    }

    this.LabelTitle2 = await this.apiResources.getByKey("Typeofaccreditation");
    this.OrganizationType = await this.apiResources.getByKey("OrganizationType");
    this.DateAccreditation = await this.apiResources.getByKey("DateAccreditation");
    this.DegreeName = await this.apiResources.getByKey("DegreeName");
    this.ProgramName = await this.apiResources.getByKey("ProgramName");
    this.accreditationCategory = await this.apiResources.getByKey("accreditationCategory");
    this.AccreditationResults = await this.apiResources.getByKey("AccreditationResults");
    this.all =  await this.apiResources.getByKey("all");
    this.expired = await this.apiResources.getByKey("Expired");
    this.full = await this.apiResources.getByKey("Full");
  }
  onChangeOrganazation(event: any) {

    this.showCount = 6;
    this.selectedIteminstitution = event;//.target.value;
    //console.log("selectedItemCategory "+this.selectedItemCategory+" selectedIteminstitution"+this.selectedIteminstitution+" selectedItemAccType"+this.selectedItemAccType+" selectedItemProgram"+this.selectedItemProgram);
    this.ShowItems(this.selectedItemCategory, this.selectedIteminstitution, this.selectedItemAccType, this.selectedItemProgram);
    // I want to do something here with the new selectedDevice, but what I
    // get here is always the last selection, not the one I just selected.
  }
  onChangeTypeAccrediation(event: any) {

    this.selectedItemAccType = event;//.target.value;
    this.selectedItemProgram = event;//.target.value;
    //console.log(" hi :" +  this.selectedItemAccType);
    this.ShowItems(this.selectedItemCategory, this.selectedIteminstitution, this.selectedItemAccType, this.selectedItemProgram);
    // I want to do something here with the new selectedDevice, but what I
    // get here is always the last selection, not the one I just selected.
  }
  async onChangeCategory(event: any) {
    this.outsideIndex = this.selectedItemCategory == this.Institutions? 1:0;
    this.selectedItemCategory = event.target.value;
    this.showCount = 6;
    if (this.selectedItemCategory === this.Institutions) {
      this.selectedTabinstitution = true;
      this.selectedTabPrograms = false;
      //this.LabelTitle2 ='نوع الاعتماد';
      this.LabelTitle2 = await this.apiResources.getByKey("Typeofaccreditation");
      this.FillTypeAccrediationDDL();
      this.institutionAccrediation();
      //this.FillinstitutionDDL();

      // this.myControl2.reset();
      // this.filteredOptions2 = this.myControl2.valueChanges.pipe(
      //   startWith(''),
      //   map(value =>  this.TypeAccrediation),
      // );
    }
    if (this.selectedItemCategory === this.programs) {
      this.selectedTabinstitution = false;
      this.selectedTabPrograms = true;
      //this.LabelTitle2 = 'اسم البرنامج' ;
      this.LabelTitle2 = await this.apiResources.getByKey("ProgramName");
      this.FillProgramsDDL();
      //this.FillTypeAccrediationDDL();
      this.ProgramsAccrediation();
      this.FillinstitutionDDL();

      // this.myControl2.reset();
      // this.filteredOptions2 = this.myControl2.valueChanges.pipe(
      //   startWith(''),
      //   map(value =>  this.TypeAccrediation),
      // );

    }
    //this.institutionAccrediation();
    // I want to do something here with the new selectedDevice, but what I
    // get here is always the last selection, not the one I just selected.
  }


  FillinstitutionDDL() {
    this.Organazation.splice(0, this.Organazation.length);
    this.apiServices.getListTopN('AccOrganization', 0, false, 'CenterId', this.cId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: Organazationlist[]) => {
        data.forEach(element => {
          var obj: Organazation = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            //name: element.ItemFields.name
          };
          //console.log("FillinstitutionDDL :" +obj.Title);
          this.Organazation.push(obj);
        });
      }
    );
  }
  FillProgramsDDL() {
    //this.TypeAccrediation.splice(0, this.TypeAccrediation.length);
    this.TypeAccrediation =[];
    this.TypeAccrediation.length = 0;
    this.apiServices.getList('AccOrganizationPrograms').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Organazationlist[]) => {
        data.forEach(element => {
          var obj: Organazation = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            //name: element.ItemFields.name
          };

          this.TypeAccrediation.push(obj);
        });
      }
    );
  }

  FillTypeAccrediationDDL() {
    //this.TypeAccrediation.splice(0, this.TypeAccrediation.length);
    this.TypeAccrediation =[];
    this.TypeAccrediation.length = 0;
    this.apiServices.getList('AccreditationTypes').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Organazationlist[]) => {
        data.forEach(element => {
          var obj: Organazation = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            //name: element.ItemFields.name
          };

          this.TypeAccrediation.push(obj);
          
        });
      }
    );
  }

  /* async clickTabPrograms()
   { this.showCount = 5;
     //this.LabelTitle ='الجهة التعليمية';
     //this.LabelTitle2 = 'اسم البرنامج';
     this.LabelTitle = await this.apiResources.getByKey("nameofuniversity");
     this.LabelTitle2 = await this.apiResources.getByKey("ProgramName");
     this.selectedTabPrograms = true;
     this.selectedTabinstitution = false;
     this.FillinstitutionDDL();
     this.FillProgramsDDL();
   }
   async clickTabinstitution()
   { 
     this.showCount = 5;
     //this.LabelTitle = 'الجهة التعليمية';
     //this.LabelTitle2 = 'نوع الاعتماد';
     this.LabelTitle = await this.apiResources.getByKey("nameofuniversity");
     this.LabelTitle2 = await this.apiResources.getByKey("Typeofaccreditation");
     this.selectedTabPrograms = false;
     this.selectedTabinstitution = true;
     this.FillinstitutionDDL();
     this. FillTypeAccrediationDDL();
     this.institutionAccrediation();
   }*/
}
