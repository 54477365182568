import { Component, OnInit } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { RelatedLinkList } from 'src/app/models/RelatedLinkList';
import { RelatedLinks } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import   { SwiperOptions, Pagination, Swiper, Autoplay } from 'swiper';


@Component({
  selector: 'app-relatedlinks',
  templateUrl: './relatedlinks.component.html',
  styleUrls: ['./relatedlinks.component.css']
})

export class RelatedlinksComponent implements OnInit {
  configRelatedlinks: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
    },
    breakpoints: {
      '560': {
        slidesPerView: 1
      },
      '768': {
        slidesPerView: 2
      },
      '1000': {
        slidesPerView: 3
      },
      '1200': {
        slidesPerView: 4
      }
    }
  };

  destroy$: Subject<boolean> = new Subject<boolean>();
  suggestedlinks: string = '';
  Relatedlinks: RelatedLinks[] = [];
  isLoad = false;
  BrowseSite = '';

  constructor(private relatedService: ApiService, private apiResources: ResourcesService) {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {
        if (window.scrollY >= 1750 && this.Relatedlinks.length === 0 && !this.isLoad) {
          this.isLoad = true;
          this.getRelatedLinks();
          Swiper.use([Pagination]);
          Swiper.use([Autoplay]);

        }
      });
  }

  /** Custom methods */
  getRelatedLinks() {
    this.relatedService.getListTopN('relatedlinks', 100, false, "IsFooterLink", false).pipe(takeUntil(this.destroy$)).subscribe(
      (data: RelatedLinkList[]) => {
        data.forEach(element => {
          var obj: RelatedLinks = {
            title: element.ItemFields.RelatedLinkName,
            url: element.ItemFields.RelatedLinkTarget
          };
          this.Relatedlinks.push(obj);
        });
      }
    );
  }

  /** Component events  */
  async ngOnInit() {
    await this.relatedService.logInAsync();

    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
      .subscribe(async (e: Event) => {
        if (window.scrollY >= 1750 && this.Relatedlinks.length === 0 && !this.suggestedlinks) {
          this.suggestedlinks = await this.apiResources.getByKey("suggestedlinks");
          this.BrowseSite = await this.apiResources.getByKey("BrowseSite");
        }
      }
      );

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
