<!--begin::latest News-->
<section class="latest-news position-relative py-5" style="background:var(--lightColor)!important;min-height: 50vh;">
    <!-- <section class="position-relative py-5" style="background:#F4F3F8;"> -->
    <div class="container">
        <div class="row justify-content-center" data-aos="fade-up" data-aos-duration="1000">
            <h3 *ngIf="EtecNews" class="h3 text-center fw-semibold pb-4 mb-2 mb-lg-4" [innerText]="EtecNews"> </h3>
        </div>
        <div class="row" *ngIf="latestNews.length">
            <swiper [config]='configNews' class="news-item pb-5" [dir]="_lang =='ar'?'rtl':'ltr'">
                <ng-template *ngFor="let item of latestNews" swiperSlide>
                    <article class="card h-100 border-0 shadow rounded-5 mx-3" data-aos="zoom-in-up"
                        data-aos-duration="600" data-aos-offset="200">
                        <div class="card-header my-3 pb-0 bg-white border-0 rounded-5 overflow-hidden"
                            style="min-height: 250px;height: 250px;">
                            <div class="position-relative" data-aos="fade-up" data-aos-duration="1000">
                                <img src="{{item.NewsImageThumbnail}}" class="card-img-top rounded-9" alt="Image">
                            </div>
                        </div>
                        <div class="card-body d-flex flex-column justify-content-center align-items-start">
                            <h4 class="fs-lg mb-3 overflow-hidden"
                                style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow:ellipsis;">
                                <a href="{{_lang+'/news/'+item.NewsCode}}"
                                    class="text-decoration-none text-primary lh-base">
                                    {{item.newsTitle}}
                                </a>
                            </h4>
                            <p class="fs-sm fw-normal text-primary mb-0" style="text-align: justify;"
                                [innerHtml]="item.newsDetails">

                            </p>
                        </div>
                        <div
                            class="card-footer py-3 border-0 rounded-5 d-flex align-items-center justify-content-between">
                            <span class="fs-sm fw-normal text-primary">
                                <i class="bx bx-calendar fs-sm me-2"></i>{{item.newsDate | date: 'yyyy/MM/dd'}}
                            </span>
                            <a href="{{_lang+'/news/'+item.NewsCode}}" style="width: 6rem;"
                                class="btn btn-secondary p-2 fs-sm border-0"> {{more}}
                            </a>
                        </div>
                    </article>
                </ng-template>
            </swiper>
        </div>
        <div class="row" *ngIf="latestNews.length === 0">
            <ng-template *ngFor="let number of [1,2,3]" swiperSlide>
                <div class="card border-0 shadow" aria-hidden="true">
                    <div class="position-relative placeholder-wave">
                        <div class="card-img-top placeholder ratio ratio-16x9"></div>
                        <i
                            class="bx bx-image position-absolute top-50 start-50 translate-middle display-3 fw-normal opacity-35"></i>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title placeholder-glow">
                            <span class="placeholder col-6"></span>
                        </h5>
                        <p class="card-text placeholder-glow">
                            <span class="placeholder placeholder-sm col-7 me-2"></span>
                            <span class="placeholder placeholder-sm col-4"></span>
                            <span class="placeholder placeholder-sm col-4 me-2"></span>
                            <span class="placeholder placeholder-sm col-6"></span>
                            <span class="placeholder placeholder-sm col-8"></span>
                        </p>
                        <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6 placeholder-wave">
                        </a>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</section>