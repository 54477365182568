import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ResearchAndReportList } from 'src/app/models/ResearchAndReportList';
import { ResearchReportTypeLIst } from 'src/app/models/ResearchReportTypeList';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListResponse } from 'src/app/models/ListResponse';
import { Title } from '@angular/platform-browser';
import { ResourcesService } from 'src/app/services/resources.service';
import { Author, Year } from 'src/app/models/ResponseModel';
import { Authorlist } from 'src/app/models/authorlist';
import { LoadingService } from 'src/app/services/loading.service';


@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {

  activeTab = 'report';
  imptempPublicUrl = environment.imptempPublicUrl;
  imgPublicUrl = environment.imgPublicUrl;
  reasearchList: ResearchAndReportList[] = [];
  reasearchListSearch: ResearchAndReportList[] = [];
  reasearchList2: ResearchAndReportList[] = [];
  reasearchList2Search: ResearchAndReportList[] = [];
  reasearchList3: ResearchAndReportList[] = [];
  reasearchList3Search: ResearchAndReportList[] = [];
  reasearchListwithFiltriation: ResearchAndReportList[] = [];
  titles: ResearchReportTypeLIst[];
  Authors: any[] = [];
  Years: any[] = [];
  searchText: any = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  showCount1 = 10;
  showCount2 = 10;
  showCount3 = 10;
  modalData = '';
  modalTitle = '';
  reportdownload = '';
  reportsearch = '';
  reportquotes = '';
  researchreward='';
  PageTitle = '';
  more: string = '';
  home = '';
  details = '';
  Author = '0';
  Year = '0';
  nocontent = '';
  EtecFullName = 'هيئة تقويم التعليم والتدريب';
  select = '';
  all = 'الكل';
  Reports = '';
  TechnicalReports = '';
  PublishedReports = '';
  servicesof = '';
  _lang = '';


  constructor(private actRoute: ActivatedRoute, private service: ApiService, config: NgbModalConfig, private modalService: NgbModal
    , private Title: Title, private apiResources: ResourcesService, private loading: LoadingService) {

    this.actRoute.params.subscribe(async params => {
      this.activeTab = params["id"] == null ? 'report' : params["id"]
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }
    });

    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }

    config.backdrop = 'static';
    config.keyboard = false;
  }

  /** Custom methods */
  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  getResearchReportList() {

    this.service.getListOrder('researchreportlist', 0, false, 'RRYear').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ResearchAndReportList[]) => {
        data.forEach(element => {
          if (element.ItemFields?.RRType?.LookupId === 2) {
            this.reasearchList.push(element);
          }
          if (element.ItemFields?.RRType?.LookupId === 1) {
            this.reasearchList2.push(element);
          }
          if (element.ItemFields?.RRType?.LookupId === 3) {
            this.reasearchList3.push(element);
          }
        });

        this.reasearchListSearch = [... this.reasearchList];
        this.reasearchList2Search = [... this.reasearchList2];
        this.reasearchList3Search = [... this.reasearchList3];
      }
    );
  }


  getsiteMenu() {
    this.service.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ListResponse[]) => {
        data.forEach(element => {
          if (element.ItemFields.Title.toLowerCase() === 'data') {
            this.PageTitle = element.ItemFields.MenuName;
            this.Title.setTitle(this.EtecFullName + " - " + this.PageTitle);
          }

        });
      }
    );
  }

  open(content: any, citationDetails: string, mTitle: string) {
    this.modalData = citationDetails;
    this.modalTitle = mTitle;
    this.modalService.open(content, { centered: true });
    // this.modalService.open(content);
  }

  public cutString(str: string) {
    var output = ' ';
    var data = str.split(' ');
    var i = 0;
    for (var i = 0; i < 50; i++) {

      output = output.concat(data[i], ' ');
    };
    return output + ' ...';
  }

  onChangeAuthor(value: any) {
    this.Author = value.target.value;

    this.ShowItems(this.Author, this.Year);
  }

  onChangeYear(value: any) {
    this.Year = value.target.value;

    this.ShowItems(this.Author, this.Year);
  }

  ShowItems(Author: any, Year: any) {
    //console.log( this.activeTab);
    if (Author != '0' && Year != '0') {
      this.reasearchList3.splice(0, this.reasearchList3.length);
      this.reasearchList2.splice(0, this.reasearchList2.length);
      this.service.getListWithTwoConditionsTopN('researchreportlist', 0, false, 'RRYear', Author, 'RRYear', Year, 'Eq', 'And', 'Eq').pipe(takeUntil(this.destroy$)).subscribe(
        (data: ResearchAndReportList[]) => {
          data.forEach(element => {

            if (element.ItemFields?.RRType?.LookupId === 1) {
              this.reasearchList2.push(element);
            }
            if (element.ItemFields?.RRType?.LookupId === 3) {
              this.reasearchList3.push(element);
            }
          });
        }
      );
    }
    if (Author == '0' && Year != '0') {
      this.reasearchList3.splice(0, this.reasearchList3.length);
      this.reasearchList2.splice(0, this.reasearchList2.length);
      this.service.getListsItemsFilterdOrderd('researchreportlist', 0, false, 'RRYear', 'RRYear', Year, 'Eq', '', 'Eq', '', '').pipe(takeUntil(this.destroy$)).subscribe(
        (data: ResearchAndReportList[]) => {
          data.forEach(element => {
            if (element.ItemFields?.RRType?.LookupId === 1) {
              this.reasearchList2.push(element);
            }

            if (element.ItemFields?.RRType?.LookupId === 3) {
              this.reasearchList3.push(element);
            }
          });
        }
      );

    }
    if (Author != '0' && Year == '0') {

      this.reasearchList3.splice(0, this.reasearchList3.length);
      this.reasearchList2.splice(0, this.reasearchList2.length);
      this.service.getListsItemsFilterdOrderd('researchreportlist', 0, false, 'RRYear', 'RRWriter', Author, 'Eq', '', 'Eq', '', '').pipe(takeUntil(this.destroy$)).subscribe(
        (data: ResearchAndReportList[]) => {
          data.forEach(element => {
            if (element.ItemFields?.RRType?.LookupId === 1) {
              this.reasearchList2.push(element);
            }

            if (element.ItemFields?.RRType?.LookupId === 3) {
              this.reasearchList3.push(element);
            }
          });
        }
      );

    }
    if (Author == '0' && Year == '0') {
      this.reasearchList3.splice(0, this.reasearchList3.length);
      this.reasearchList2.splice(0, this.reasearchList2.length);
      this.service.getListOrder('researchreportlist', 0, true, 'RRYear').pipe(takeUntil(this.destroy$)).subscribe(
        (data: ResearchAndReportList[]) => {
          data.forEach(element => {
            if (element.ItemFields?.RRType?.LookupId === 1) {
              this.reasearchList2.push(element);
            }
            if (element.ItemFields?.RRType?.LookupId === 3) {
              this.reasearchList3.push(element);
            }
          });
        }
      );

      //this.reasearchList3 = this.reasearchList3.filter(a=>a.ItemFields.RRWriter == Author ) ;
    }


  }

  FillAuthorsDDL() {
    this.Authors.splice(0, this.Authors.length);
    this.service.getList('ResearchReportWriters').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Authorlist[]) => {
        data.forEach(element => {
          var obj: Author = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            NameAR: element.ItemFields.NameAR,
            NameEN: element.ItemFields.NameEN
          };

          this.Authors.push(obj);
        });
      }
    );
  }

  FillYearsDDL() {
    this.Years.splice(0, this.Authors.length);
    this.service.getListOrder('ResearchReportYears', 0, false, "Year").pipe(takeUntil(this.destroy$)).subscribe(
      (data: Authorlist[]) => {
        data.forEach(element => {
          var obj: Year = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            Year: element.ItemFields.Year
          };
          this.Years.push(obj);
        });
      }
    );
  }

  search() {
    this.showCount1 = 10;
    this.reasearchList = this.reasearchListSearch;
    this.reasearchList = this.reasearchList.filter(x => x.ItemFields?.RRName?.toUpperCase().includes(this.searchText.toUpperCase()));
  }
  search2() {
    this.showCount2 = 10;
    this.reasearchList2 = this.reasearchList2Search;
    this.reasearchList2 = this.reasearchList2.filter(x => x.ItemFields?.RRName?.toUpperCase().includes(this.searchText.toUpperCase()));
  }
  search3() {
    this.showCount3 = 10;
    this.reasearchList3 = this.reasearchList3Search;
    this.reasearchList3 = this.reasearchList3.filter(x => x.ItemFields?.RRName?.toUpperCase().includes(this.searchText.toUpperCase()));
  }
  /** Component events  */
  async ngOnInit() {

    this.getResearchReportList();
    this.getsiteMenu();
    this.FillAuthorsDDL();
    this.FillYearsDDL();

    this.reportsearch = await this.apiResources.getByKey("reportsearch");
    this.reportdownload = await this.apiResources.getByKey("reportdownload");
    this.reportquotes = await this.apiResources.getByKey("reportquotes");
    this.home = await this.apiResources.getByKey("home");
    this.details = await this.apiResources.getByKey("details");
    this.select = await this.apiResources.getByKey("select");
    this.nocontent = await this.apiResources.getByKey("nocontent");
    this.Reports = await this.apiResources.getByKey("Reports");
    this.TechnicalReports = await this.apiResources.getByKey("TechnicalReports");
    this.PublishedReports = await this.apiResources.getByKey("PublishedReports");
    this.more = await this.apiResources.getByKey("more");
    this.servicesof = await this.apiResources.getByKey("servicesof");
    this.researchreward = await this.apiResources.getByKey("research-reward");
  }

}
