<!--begin::share icons component-->

    <div class="row">
        <div class="col-12">
            <div class="share-icons">
                <div class="position-relative d-flex justify-content-center align-items-center mb-0 mt-5">
                    <div class="card bg-warning border-0 shadow-warning">
                        <div class="card-body py-1 px-2 d-flex justify-content-center align-items-center">
                            <h6 class="fs-xs fw-semibold m-0 px-2 share-opinion" i18n="@@shareOpinion">{{share}} 
                            </h6>
        
        
                             <share-buttons theme="circles-dark"
                                [include]="['copy', 'twitter', 'linkedin', 'facebook',  'whatsapp' , 'email']" [showIcon]="true"
                                [showText]="false" url="{{dynamicUrl?dynamicUrl:currentUrl}}" description="{{etec}}" twitterAccount="">
                            </share-buttons> 
        
                            <!-- <qrcode [qrdata]="currentUrl" [width]="100" [errorCorrectionLevel]="'M'"></qrcode> -->
        
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
    
        </div>
    </div>