import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
 
import { Service, ServiceContent, ServiceGoal, ServicePrerequirements, ServiceSteps } from 'src/app/models/ResponseModel';
import { ServiceContentResponse } from 'src/app/models/ServiceContentResponse';
import { ServiceGoalsResponse } from 'src/app/models/ServiceGoalsResponse';
import { ServicePrerequirementsResponse } from 'src/app/models/ServicePrerequirementsResponse';
import { ServicesResponse } from 'src/app/models/ServicesResponse';
import { ServiceStepsResponse } from 'src/app/models/ServiceStepsResponse';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-product-accreditation',
  templateUrl: './product-accreditation.component.html',
  styleUrls: ['./product-accreditation.component.css']
})
export class ProductAccreditationComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  productId: number;
  Product: Service;
  Goals: ServiceGoal[] = [];
  Content: ServiceContent[] = [];
  Steps: ServiceSteps[] = [];
  Prerequirements: ServicePrerequirements;

  constructor(private actRoute: ActivatedRoute, private apiServices: ApiService) { }

  /** Custom methods */
  getService() {
    this.apiServices.getListById('Services', this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServicesResponse) => {
        var obj: Service = {
          serviceTitle: data.ItemFields.Title,
          serviceId: data.ItemId,
          serviceName: data.ItemFields.ServiceName,
          ServiceCenterId: 1,
          ServiceDescription: data.ItemFields.ServiceDescription!,
          serviceIconUrl: './assets/img/icon/exam/GAT.svg',
          serviceLevel: 0,
          serviceVisitCount: 0,
          serviceDetailsFileUrl: '',
          serviceKeyword: data.ItemFields.ServiceKeyword,

          isActive: data.ItemFields.IsActive1,
          ShowDetailsButton: data.ItemFields.ShowDetailsButton
        }
        this.Product = obj;


      }
    );

  }

  getServiceGoals() {
    this.apiServices.getListTopN('ServiceGoals', 100, true, "ServiceId", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceGoalsResponse[]) => {
        data.forEach(element => {
          var obj: ServiceGoal = {
            ServiceGoalNumber: element.ItemFields.ServiceGoalNumber,
            ServiceGoalName: element.ItemFields.ServiceGoalName
          };

          this.Goals.push(obj);

        });
      });


  }

  getServiceContent() {
    this.apiServices.getListTopN('ServiceContent', 100, true, "ServiceId", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceContentResponse[]) => {
        data.forEach(element => {
          var obj: ServiceContent = {
            ServiceContentTitle: element.ItemFields.ServiceContentTitle,
            ServiceContentDetailes: element.ItemFields.ServiceContentDetails,
            ServiceContentIcon: element.ItemFields.ServiceContentIcon!
          };

          this.Content.push(obj);
        });
      });


  }

  getServiceSteps() {
    this.apiServices.getListTopN('ServiceSteps', 100, true, "ServiceId", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceStepsResponse[]) => {
        data.forEach(element => {
          var obj: ServiceSteps = {
            ServiceStepsDetails: element.ItemFields.ServiceStepsDetails
          };

          this.Steps.push(obj);
        });
      });


  }

  getServicePrerequirements() {
    this.apiServices.getListTopN('ServicePrerequirements', 100, true, "ServiceId", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServicePrerequirementsResponse[]) => {
        data.forEach(element => {
          var obj: ServicePrerequirements = {
            ServicePrerequirementsDetails: element.ItemFields.ServicePrerequirementsDetails
          };

          this.Prerequirements = obj;
        });
      });


  }
  
  /** Component events  */
  ngOnInit(): void {
    this.actRoute.params.subscribe(params =>
      this.productId = params["id"]);

    this.getService();
    this.getServiceGoals();
    this.getServiceContent();
    this.getServiceSteps();
    this.getServicePrerequirements();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
