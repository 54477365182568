import { Component, Input, OnInit } from '@angular/core';
import { ServiceSteps } from 'src/app/models/ResponseModel';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css']
})
export class StepsComponent implements OnInit {
  @Input() Steps: ServiceSteps[] = [];
  @Input() servicesteps : string
  constructor() { }

  ngOnInit(): void {
  }

}
