import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, observable, take } from 'rxjs';
import { ResourcesService } from 'src/app/services/resources.service';
import { interval, finalize, tap, noop, timer } from 'rxjs';
// import lightGallery from 'lightgallery';
// import lgZoom from 'lightgallery/plugins/zoom';
// import { BeforeSlideDetail } from 'lightgallery/lg-events';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  code404: String = '';
  PageTitle: String;
  pagenotexists: string = '';
  redirectMessage = '';
  timerString = '.';

  // name = "Angular ";
  // settings = {
  //   counter: false,
  //   plugins: [lgZoom]
  // };

  // onBeforeSlide = (detail: BeforeSlideDetail): void => {
  //   const { index, prevIndex } = detail;
  //   console.log(index, prevIndex);
  // };

  constructor(private apiResources: ResourcesService,  private router: Router) {

    // setInterval(() => {
    //   this.timerString +='.';
    // }, 1000);

    // setInterval(() => {
    //   //this.tickTock();
    //   this.router.navigateByUrl('/home' );
    // }, 3000);
    

  }

 /** Component events  */
  async ngOnInit(){

    this.code404 = await this.apiResources.getByKey("404code");
    this.pagenotexists = await this.apiResources.getByKey("pagenotexists");
    this.redirectMessage = await this.apiResources.getByKey("redirectmessage");
    this.PageTitle = this.code404.toString();

    
    const source = interval(1000);
    const example = source.pipe(
      take(3), //take only the first 5 values
      finalize(() => this.router.navigateByUrl('/home' )) // Execute when the observable completes
    );
    const subscribe = example.subscribe(val => this.timerString +='.');

  }

}
