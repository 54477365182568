import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FAQList } from 'src/app/models/FAQList';

import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {
  
  faqList: FAQList[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  faq : string = '';
  home : string = '';

  constructor(private apiServices: ApiService, private apiRersources: ResourcesService) { }

  /** Custom methods */
  getNonServiceFaq(){

    this.apiServices.getListOrder('faq',0,true,'OrderFAQ' ).pipe(takeUntil(this.destroy$)).subscribe((data: FAQList[])=> this.faqList = data.filter((x) => x.ItemFields.ServiceId == null));
  }
  
  /** Component events  */
  async ngOnInit() {
    this.getNonServiceFaq();

    this.faq = await this.apiRersources.getByKey("faq");
    this.home = await this.apiRersources.getByKey("home");
  }

  ngOnDestroy() {
    this.destroy$.next(true);
     // Unsubscribe from the subject
    this.destroy$.unsubscribe();
   }
}
