<div class="combobox">
  <input type="text" [(ngModel)]="inputItem" (ngModelChange)="getFilteredList()" class="combobox-input"
    (keyup)="onKeyPress($event)" (blur)="toggleListDisplay(0)" (focus)="toggleListDisplay(1)" placeholder="{{Title}}"
    [ngClass]="{'error': showError}">
  <!-- <span *ngIf="showError" class="error-text"><i>Invalid Selection.</i></span> -->
  <button (blur)="toggleListDisplay(0)" (click)="toggleListDisplay(1)"
    class="bx bx-chevron-down text-secondary bg-transparent border-0 px-0 position-absolute top-50 end-0 translate-middle"
    style="font-size: 1.5rem !important;"></button>
  <div class="combobox-options" *ngIf="!listHidden">
    <list-item (click)="selectItem(0)" [ngClass]="{'selected': 0===selectedIndex}">{{all}}</list-item>
    <list-item *ngFor="let item of filteredList;let i = index" (click)="selectItem(i+1)"
      [ngClass]="{'selected': i+1 === selectedIndex}">{{item?.Title}}</list-item>
  </div>
</div>