import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { EServiceGuidList } from 'src/app/models/EServiceGuidList';
import { ServiceStructureTagsList } from 'src/app/models/ServiceStructureTagsList';
import { ServiceStructureTagsResponse } from 'src/app/models/ServiceStructureTagsResponse';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-servicecatalog',
  templateUrl: './servicecatalog.component.html',
  styleUrls: ['./servicecatalog.component.css'],
})
export class ServicecatalogComponent implements OnInit {
  tags: ServiceStructureTagsResponse[] = [];
  tagsNumber: number[] = [];
  tagsStaus: boolean[] = [];
  selectedTags: string[] = [];
  responseService: EServiceGuidList[] = [];
  response: EServiceGuidList[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  all: string = '';
  Category: string = '';
  more: string = '';
  searchtxt: string = '';
  searchText: string = '';
  serviceGuid : string = '';
  home: string = '';
  tagsAll = true;
  showCount =12;
  servicesof='';

  constructor(
    private apiServices: ApiService,
    private apiRersources: ResourcesService,
    private router: Router,
    private loading: LoadingService,
    private actRoute: ActivatedRoute
  ) {
    this.actRoute.params.subscribe(async params =>
      {
         var isRoute =  localStorage.getItem('lang') != params["language"] && params["language"];
         await this.changeLang(params["language"]);

         if(isRoute){
          window.location.reload();
         }
      });
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiRersources.clearResource();
  }


  async ngOnInit(): Promise<void> {
    this.Category = await this.apiRersources.getByKey('Category');
    this.all = await this.apiRersources.getByKey('all');
    this.more = await this.apiRersources.getByKey('more');
    this.home = await this.apiRersources.getByKey('home');
    this.serviceGuid  =  await this.apiRersources.getByKey("ServiceGuide");
    this.servicesof = await this.apiRersources.getByKey("servicesof");

    this.getStructureTags();
    this.getServiceGuid();
  }

  getStructureTags() {
    this.apiServices
      .getList('ServiceStructureTags')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ServiceStructureTagsResponse[]) => {
        this.tags = data;
        data.forEach((element) => {
          this.tagsStaus.push(true);
          this.selectedTags.push(element.ItemFields.ServiceTagName);
          this.tagsNumber.push(0);
        });

        this.filterChange();
      });
  }

  getServiceGuid() {
    this.apiServices
      .getList('EServiceGuide')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: EServiceGuidList[]) => {
        this.responseService = data.sort((a, b) => (a.ItemFields?.EServiceOrder -b.ItemFields?.EServiceOrder));
        this.response =  [... this.responseService ]; 
        this.responseService.forEach((element) => {
          var index = this.tags.findIndex(
            (x) =>
              x.ItemFields.ServiceTagName ===
              element.ItemFields?.ServiceStructureTag.LookupValue!
          );
          if (index != -1) {
            this.tagsNumber[index]++;
          }
        });
      });
  }

  getSorted(data: EServiceGuidList[]){
    return data.sort((a, b) => (a.ItemFields?.EServiceOrder -b.ItemFields?.EServiceOrder));
  }

  getServiceGuidFilter() {
    this.tagsStaus.forEach((element, index) => {
      if (element === true) {
        this.selectedTags.push(this.tags[index].ItemFields.ServiceTagName);
      }
    });

    this.apiServices
      .getList('EServiceGuide')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: EServiceGuidList[]) => {
        this.responseService = data.filter(x => this.selectedTags.indexOf(x.ItemFields?.ServiceStructureTag.LookupValue) != -1 );
        this.response = data.filter(x => this.selectedTags.indexOf(x.ItemFields?.ServiceStructureTag.LookupValue) != -1 );
        this.responseService.forEach(element => {
          element.ItemFields.ServiceGuidIcon = this.tags.filter(m => m.ItemId == element.ItemFields.ServiceStructureTag.LookupId)[0].ItemFields.TagImage?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl);
        });
      });
  }

  changeTage() {
    this.tagsStaus.forEach((element, index) => {
      this.tagsStaus[index] = this.tagsAll;
    });
    this.filterChange();
  }

  filterChange() {
    //this.tagsNumber = [];
    this.selectedTags = [];

    this.tagsStaus.forEach((element, index) => {
      if (element === true) {
        this.selectedTags.push(this.tags[index].ItemFields.ServiceTagName);
      }
      this.tagsNumber.push(0);
    });

    this.tagsAll = true;
    this.tagsStaus.forEach((element) => {
      if (!element) {
        this.tagsAll = false;
      }
    });

    this.getServiceGuidFilter();
  }

  search(){
    this.responseService = this.response.filter(x=> x.ItemFields?.ServiceGuideTitle?.toUpperCase().includes(this.searchText.toUpperCase()))
  }

  putVisitNo(itemId: string) {
    this.apiServices.putVisitingCount('EServiceGuide', itemId).subscribe();
  }

  navigateUrl(product: EServiceGuidList) {
  
    this.putVisitNo(product.ItemId.toString());
    this.router.navigateByUrl('/servicecatalogdetails/' + product.ItemFields?.Title);

  }
}
