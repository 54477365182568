<form [formGroup]="myForm" (ngSubmit)="submit()" class="row g-4">
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="nationalId">رقم الهوية الوطنية </label>
            <input formControlName="nationalId" id="nationalId" type="text" class="form-control"
                maxlength="10" />
            <div *ngIf="f['nationalId'].touched && f['nationalId'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['nationalId'].errors && f['nationalId'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['nationalId'].errors && f['nationalId'].errors['pattern']">
                    الرجاء إدخال رقم صحيح
                </div>
                <div *ngIf="f['nationalId'].errors &&( f['nationalId'].errors['minlength'] || f['nationalId'].errors['maxlength']) ">
                    الرجاء إدخال رقم صحيح
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="name">اسم الباحث أو المشارك في البحث</label>
            <input formControlName="name" id="name" type="text" class="form-control"
                maxlength="100" />
            <div *ngIf="f['name'].touched && f['name'].invalid" class="alert alert-danger">
                <div *ngIf="f['name'].errors && f['name'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['name'].errors && f['name'].errors['maxlength']">
                    الرجاء إدخال 100 حرف كحد أقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <label>آخر درجة علمية حصل عليها الباحث </label>
        <select class="form-control" formControlName="degree">
            <option value="أستاذ" selected="selected">أستاذ</option>
            <option value="أستاذ مشارك">أستاذ مشارك</option>
            <option value="أستاذ مساعد">أستاذ مساعد</option>
            <option value="ماجستير">ماجستير</option>
        </select>
        <div *ngIf="f['degree'].touched && f['degree'].invalid" class="alert alert-danger">
            <div *ngIf="f['degree'].errors && f['degree'].errors['required']">
                حقل مطلوب.
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="degreeDate">تاريخ الحصول عليها </label>
            <input formControlName="degreeDate" id="degreeDate" type="date" class="form-control" />
            <div *ngIf="f['degreeDate'].touched && f['degreeDate'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['degreeDate'].errors && f['degreeDate'].errors['required']">
                    حقل مطلوب.
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="spciality">التخصص </label>
            <input formControlName="spciality" id="spciality" type="text" class="form-control" maxlength="100"/>
            <div *ngIf="f['spciality'].touched && f['spciality'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['spciality'].errors && f['spciality'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['spciality'].errors && f['spciality'].errors['maxlength']">
                    الرجاء إدخال 100 حرف كحد أقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="spciality">جهة العمل </label>
            <input formControlName="workOn" id="workOn" type="text" class="form-control" maxlength="100" />
            <div *ngIf="f['workOn'].touched && f['workOn'].invalid" class="alert alert-danger">
                <div *ngIf="f['workOn'].errors && f['workOn'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['workOn'].errors && f['workOn'].errors['maxlength']">
                    الرجاء إدخال 100 حرف كحد أقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="email">البريد الإلكتروني </label>
            <input formControlName="email" id="email" type="text" class="form-control"
                maxlength="100" />
            <div *ngIf="f['email'].touched && f['email'].invalid" class="alert alert-danger">
                <div *ngIf="f['email'].errors && f['email'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['email'].errors && f['email'].errors['pattern']">
                    الرجاء إدخال بريد الكتروني صحيح
                </div>
                <div *ngIf="f['email'].errors && f['email'].errors['maxlength']">
                    الرجاء إدخال 100 حرف كحد أقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="spciality">رقم الجوال </label>
            <input formControlName="mobile" id="mobile" type="text" class="form-control"
                maxlength="13" />
            <div *ngIf="f['mobile'].touched && f['mobile'].invalid" class="alert alert-danger">
                <div *ngIf="f['mobile'].errors && f['mobile'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="
    f['mobile'].errors &&
    (f['mobile'].errors['pattern'] || f['mobile'].errors['minlength'])
  ">
                    الرجاء إدخال رقم جوال صحيح
                </div>
                <div *ngIf="f['email'].errors && f['email'].errors['maxlength']">
                    الرجاء إدخال 13 رقم كحد أقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <label>آدور الباحث في البحث </label>
        <select class="form-control" formControlName="role">
            <option value="باحث رئيس"  >
                باحث رئيس
            </option>
            <option value="باحث مشارك" selected="selected"  >
                باحث مشارك
            </option>
            <option value="مساعد باحث ">
                مساعد باحث
            </option>
            <option value="سكرتارية وأعمال إدارية" >
                سكرتارية وأعمال إدارية
            </option>
        </select>
        <div *ngIf="f['role'].touched && f['role'].invalid" class="alert alert-danger">
            <div *ngIf="f['role'].errors && f['role'].errors['required']">
                حقل مطلوب.
            </div>
            <div *ngIf="f['role'].errors && f['role'].errors['maxlength']">
                الرجاء إدخال 100 حرف كحد أقصى 
            </div>
        </div>
    </div>
    <div class="col-md-12 col-lg-12">
        <label>السيرة الذاتية المختصرة (لا تتجاوز 100 كلمة) </label>
        <textarea formControlName="resumeText" id="resumeText" type="text"
            class="form-control" maxlength="100"></textarea>
        <div *ngIf="f['resumeText'].touched && f['resumeText'].invalid" class="alert alert-danger">
            <div *ngIf="f['resumeText'].errors && f['resumeText'].errors['required']">
                حقل مطلوب.
            </div>
            <div *ngIf="f['resumeText'].errors && f['resumeText'].errors['maxlength']">
                الرجاء إدخال 100 حرف كحد أقصى 
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="file">السيرة الذاتية التفصيلية ( مرفق ) </label>
            <input formControlName="file" id="file" type="file" class="form-control"
                (change)="upload($event)" />
            <div *ngIf="f['file'].touched && f['file'].invalid" class="alert alert-danger">
                <div *ngIf="f['file'].errors && f['file'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f.file.errors?.['inValidExt']" class="error">
                  الإمتداد غير صالح يجب أن يكون pdf
                  </div> 
                  <div *ngIf="myForm.get('file')?.hasError('fileSizeExceeded')">
                    File size exceeds the limit
                  </div>
            </div>
            <div class="alert alert-danger" *ngIf="!isCorrectSize">
                حجم الملف كبير
             </div>   
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="anyConflict" placement="bottom">تعارض المصالح
                <i class="bx bx-info-circle fw-semibold"
                    ngbTooltip="هل لدى الباحث/الباحثين أي تعارض مصالح قد ينشأ من تنفيذ هذا البحث (وفقًا لمنصبهم أو مصالحهم الذاتية)"></i>
            </label>
            <input formControlName="anyConflict" id="anyConflict" type="text"
                class="form-control"  maxlength="300"/>
            <div *ngIf="f['anyConflict'].touched && f['anyConflict'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['anyConflict'].errors && f['anyConflict'].errors['required'] ">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['anyConflict'].errors && f['anyConflict'].errors['maxlength']">
                    الرجاء إدخال 300 حرف كحد أقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="researchDate">تاريخ تقديم البحث </label>
            <input formControlName="researchDate" id="researchDate" type="date"
                class="form-control" />
            <div *ngIf="f['researchDate'].touched && f['researchDate'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['researchDate'].errors && f['researchDate'].errors['required']">
                    حقل مطلوب.
                </div>
                
            </div>
          
        </div>
    </div>
    <div class="col-12">
        <div class="progress" *ngIf="progress">
            <div class="progress-bar" [style.width]="progress + '%'">
                {{ progress }}%
            </div>
        </div>
    </div>
    <div class="col-12 mt-4 d-flex justify-content-end">
        <div class="d-flex w-lg-25 w-md-50 w-100">
            <button class="btn btn-primary w-100 me-2" type="submit" [disabled]="myForm.invalid">إضافة باحث</button>
            <button class="btn btn-secondary w-100 me-2" type="button"  *ngIf="educationdata.length > 0" (click)="finish()">إنهاء وإرسال</button>
        </div>
    </div>
</form>