<div class="row row-cols-xl-3 row-cols-lg-2 row-cols-1 justify-content-center g-4" *ngIf="cenetrLeader">
    <div class="col d-flex align-items-stretch">
        <article class="card border-0 shadow-primary bg-white w-100">
            <div class="card-header border-0 text-center mt-3">
                <div class="position-relative bg-position-center bg-repeat-0 bg-size-cover m-auto"
                    style=" min-height: 15rem; max-width: 17rem;border-radius: 0.7rem;"
                    [ngStyle]="{'background-image': 'url(' +'\'' +cenetrLeader.centerLeaderPortrait +'\'' +')'}">
                </div>
            </div>
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <p class="mb-2 fs-sm text-primary">{{cenetrLeader?.centerLeaderPrefixId}}</p>
                <h3 class="fs-lg fw-semibold text-primary mb-4 text-center">
                    {{cenetrLeader?.centerLeaderName}}
                </h3>
                <div class="bg-primary text-white p-3 px-2 fs-sm text-center w-100" style="border-radius: 0.7rem;">
                    {{cenetrLeader?.centerLeaderPosition}}
                </div>
            </div>
        </article>
    </div>
</div>

<!--begin::share-->
<!-- <app-shareicons></app-shareicons> -->

<div class="row" *ngIf="!cenetrLeader">
    <div class="col">
        Loading...
    </div>
</div>
<div class="row">
    <div class="col">
        <p class="text-primary lh-base">
            {{ cenetrLeader?.centerLeaderCurriculumVite }}
        </p>
    </div>
</div>