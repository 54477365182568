import { Component, Input, OnInit } from '@angular/core';
import { Center } from 'src/app/models/ResponseModel';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import { BeforeSlideDetail } from 'lightgallery/lg-events';

@Component({
  selector: 'app-organizationalchart',
  templateUrl: './organizationalchart.component.html',
  styleUrls: ['./organizationalchart.component.css']
})
export class OrganizationalchartComponent implements OnInit {
  @Input() centerId!: number;
  @Input() center!: Center;
  @Input() centerOrgUrl : string;

  settings = {
    fourceAutoply: false,
    autoplay: false,
    thumbnail: false,
    speed: 400,
    scale: 1,
    keypress: true,
    rotateLeft: false,
    flipHorizontal: false,
    flipVertical: false,
    mobileSettings: { controls: true},
    plugins: [lgFullscreen, lgZoom],
    licenseKey: 'D4194FDD-48924833-A54AECA3-D6F8E646',
  };

  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };
  
  constructor() { }

  ngOnInit(): void {
  }

}
