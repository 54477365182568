import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ListResponse } from 'src/app/models/ListResponse';
 
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { RouteApiService } from 'src/app/services/route-api.service';

@Component({
  selector: 'app-mediacenter',
  templateUrl: './mediacenter.component.html',
  styleUrls: ['./mediacenter.component.css']
})
export class MediacenterComponent implements OnInit {

  activeTab = 'news';
  PageTitle: string = '';
  idguide = '';
  MediaCenter = '';
  News = '';
  flyers = '';
  videolibrary = '';
  infographic = '';
  home: string = '';
  EtecFullName = 'هيئة تقويم التعليم والتدريب';
  destroy$: Subject<boolean> = new Subject<boolean>();
  isNews = false;
  isInfographic = false;
  isFlyers = false;
  isVideolibrary = false;
  isIdguide = false;
  _lang = '';

  constructor(private apiServices: ApiService, private apiRoute: RouteApiService, private actRoute: ActivatedRoute
    , private Title: Title, private apiResources: ResourcesService,  private loading: LoadingService) {

    this.actRoute.params.subscribe(params =>
      this.activeTab = params["id"] == null ? 'news' : params["id"]);

    if (this.activeTab === 'news') {
      this.isNews = true;
    }
    if (this.activeTab === 'infographic') {
      this.isInfographic = true;
    }
    if (this.activeTab === 'videolibrary') {
      this.isVideolibrary = true;
    }
    if (this.activeTab === 'flyers') {
      this.isFlyers = true;
    }
    if (this.activeTab === 'idguide') {
      this.isIdguide = true;
    }

    this.actRoute.params.subscribe(async params =>
      {
         var isRoute =  localStorage.getItem('lang') != params["language"] && params["language"];
         await this.changeLang(params["language"]);

         if(isRoute){
          window.location.reload();
         }
      });

      if (!localStorage.getItem('lang')) {
        this._lang = 'ar';
      }
      else {
        this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
      }
  }

  /** Custom methods */
  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  setTitle() {
    this.apiServices.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ListResponse[]) => {
        data.forEach(element => {
          if (element.ItemFields.Title.toLowerCase() === 'mediacenter') {
            this.Title.setTitle(this.EtecFullName + " - " + element.ItemFields.MenuName);
            this.PageTitle = element.ItemFields.MenuName;
          }
          if (element.ItemFields.Title.toLowerCase() === 'infographic') {
            this.infographic = element.ItemFields.MenuName;
          }
          if (element.ItemFields.Title.toLowerCase() === 'mediacenter') {
            this.MediaCenter = element.ItemFields.MenuName;
          }
          if (element.ItemFields.Title.toLowerCase() === 'new') {
            this.News = element.ItemFields.MenuName;
          }
          if (element.ItemFields.Title.toLowerCase() === 'flyers') {
            this.flyers = element.ItemFields.MenuName;
          }
          if (element.ItemFields.Title.toLowerCase() === 'videolibrary') {
            this.videolibrary = element.ItemFields.MenuName;
          }
          if (element.ItemFields.Title.toLowerCase() === 'idguide') {
            this.idguide = element.ItemFields.MenuName;
          }
        });
      }
    );
  }

  updateTitle(title: string) {
    this.Title.setTitle(title);
  }

  /** Component events  */
  async ngOnInit(): Promise<void> {
    this.home = await this.apiResources.getByKey("home");


    this.setTitle();
    this.updateTitle(this.PageTitle.toString());
  }


}
