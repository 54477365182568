<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/notFound.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="text-white display-3 fw-semibold m-0 position-relative zindex-5">
                    {{code404}}
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::404-->
<div class="container mt-5">
    <div class="row justify-content-center text-center">
        <div class="col-12">
            <i class="bx bx-message-error text-secondary mb-3" style="font-size: 8rem;"></i>
            <h1 class="display-3 text-primary fw-semibold">
                {{code404}}
            </h1>

            <p class="h5 text-primary ">
                {{pagenotexists}}  
            </p>
            <p class="h5 text-primary ">
                {{redirectMessage}} {{timerString}}
            </p>
        </div>
    </div>
</div>

<!-- <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
    <a data-lg-size="480-475-480, 800-791-800, 1406-1390" class="gallery-item"
      data-src="https://spapi.etec.gov.sa:2443/ar/MediaAssets/qiyas-logoicon.svg?csf=1&e=oEr87X"
      data-responsive="https://spapi.etec.gov.sa:2443/ar/MediaAssets/qiyas-logoicon.svg?csf=1&e=oEr87X"
     >
      <img class="img-responsive"
        src="https://spapi.etec.gov.sa:2443/ar/MediaAssets/qiyas-logoicon.svg?csf=1&e=oEr87X" />
    </a>
    
  </lightgallery> -->
<!-- <main>
    <div>
        <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="bd-placeholder-img" src="assets/img/BG/notFound.png" />
                    <div class="container">
                        <div class="carousel-caption text-start">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container ">
            <div class="container ">
                <div class="card card-tab shadow-sm border-0">
                    <div class="card-body card-body-padding">
                        <div class="col-lg-12 text-center">
                            <svg class="p-3" xmlns="http://www.w3.org/2000/svg" width="255.12" height="252.153"
                                viewBox="0 0 255.12 252.153">
                                <g id="bx-message-error" transform="translate(-3 -3)">
                                    <path id="Path_8558" data-name="Path 8558"
                                        d="M232.608,3H28.512A25.532,25.532,0,0,0,3,28.512V181.584A25.532,25.532,0,0,0,28.512,207.1H66.78v48.056L146.845,207.1h85.763a25.532,25.532,0,0,0,25.512-25.512V28.512A25.532,25.532,0,0,0,232.608,3Zm0,178.584H139.787l-47.495,28.48v-28.48H28.512V28.512h204.1Z"
                                        transform="translate(0 0)" fill="#b2b6fc" />
                                    <path id="Path_8559" data-name="Path 8559"
                                        d="M16.5,9H42.012V72.78H16.5Zm0,76.536H42.012v25.512H16.5Z"
                                        transform="translate(101.304 45.024)" fill="#b2b6fc" />
                                </g>
                            </svg>
                        </div>
                        <div class="col-lg-12 text-center">
                            <h1 class="text-primary not-found-title">
                                {{code404}}
                            </h1>
                            <p class="text-primary ">
                                {{pagenotexists}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main> -->