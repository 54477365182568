import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { Center } from 'src/app/models/ResponseModel';

import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.css']
})
export class VisionComponent implements OnInit {

  @Input() center!: Center;
  destroy$: Subject<boolean> = new Subject<boolean>();
  ourvalue ='';
  mission = '';
  vision = '';
  constructor(private apiResources: ResourcesService) { 
  }

  async ngOnInit() {
   this.ourvalue = await this.apiResources.getByKey("ourvalue");
   this.mission = await this.apiResources.getByKey("mission");
   this.vision = await this.apiResources.getByKey("vision");
  }

}
