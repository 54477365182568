import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProductsComponent } from './components/products/products.component';
import { CentersComponent } from './components/centers/centers.component';
import { AboutComponent } from './components/about/about.component';
import { EtecComponent } from './components/eteccenters/etec/etec.component';
import { QiyasComponent } from './components/eteccenters/qiyas/qiyas.component';
import { NewsComponent } from './components/news/news.component';
import { NcseeComponent } from './components/eteccenters/ncsee/ncsee.component';
import { NcaaComponent } from './components/eteccenters/ncaa/ncaa.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MasarComponent } from './components/eteccenters/masar/masar.component';
import { MediacenterComponent } from './components/mediacenter/mediacenter.component';
import { ProductAccreditationComponent } from './components/product-accreditation/product-accreditation.component';
import { ProductQiyasComponent } from './components/product-qiyas/product-qiyas.component';
import { ServiceDetailsComponent } from './components/service-details/service-details.component';
import { DataComponent } from './components/reports/data/data.component';
import { OpenDataComponent } from './components/reports/open-data/open-data.component';
import { PublicResearchComponent } from './components/reports/public-research/public-research.component';
import { ReportsComponent } from './components/reports/reports/reports.component';
import { ReportsResearchComponent } from './components/reports/reports-research/reports-research.component';
import { ScientificActivityComponent } from './components/reports/scientific-activity/scientific-activity.component';
import { AccreditationresultComponent } from './components/accreditationresult/accreditationresult.component';
import { PrivcayComponent } from './components/shared/privcay/privcay.component';
import { NotfoundComponent } from './components/shared/notfound/notfound.component';
import { WorkshopComponent } from './components/reports/workshop/workshop.component';
import { ConferenceComponent } from './components/reports/conference/conference.component';
import { FAQComponent } from './components/shared/faq/faq.component';
import { RelatednewsComponent } from './components/relatednews/relatednews.component';
import { NcmaaComponent } from './components/eteccenters/ncmaa/ncmaa.component';
import { ImportsNotUsedAsValues } from 'typescript';
import { ConnectComponent } from './components/connect/connect.component';
import { PageComponent } from './page/page.component';
import { ServicecatalogComponent } from './components/service-catalog/servicecatalog/servicecatalog.component';
import { ServicecatalogdetailsComponent } from './components/service-catalog/servicecatalogdetails/servicecatalogdetails.component';
import { LightgalleryComponent } from './lightgallery/lightgallery.component';
import { ScrollTotopComponent } from './components/scroll-totop/scroll-totop.component';
import { DynamicCenterComponent } from './components/dynamic-center/dynamic-center.component';
import { GreatingsComponent } from './components/greatings/greatings.component';
import { ResearchPrizeComponent } from './forms/research-prize/research-prize.component';
import { ReactiveFormComponent } from './forms/reactive-form/reactive-form.component';
import { TranscriptsComponent } from './components/transcripts/transcripts.component';
import { OpenDataAllComponent } from './components/reports/open-data-all/open-data-all.component';
import { SecComponent } from './components/sec/sec.component';
import { SecRegistrationComponent } from './components/sec-registration/sec-registration.component';
import { SEFComponent } from './components/sef/sef.component';
import { SEFRegistrationComponent } from './components/sefregistration/sefregistration.component';
import { SEFBadgeComponent } from './components/sefbadge/sefbadge.component';

const routes: Routes = [
  // { path: 'services', component: ProductsComponent },
  { path: 'opendataall', component: OpenDataAllComponent },
  { path: ':language/opendataall', component: OpenDataAllComponent },
  { path: 'services/:id', component: ProductsComponent },
  { path: 'Accreditationresult', component: AccreditationresultComponent },
  { path: 'prize', component: ResearchPrizeComponent },
  // { path: 'reactive',component:ReactiveFormComponent},
  { path: 'home', component: HomeComponent },
  { path: ':language/home', component: HomeComponent },
  { path: 'connect', component: ConnectComponent },
  // { path: 'about', component: AboutComponent},
  { path: 'centers/:id', component: CentersComponent },
  { path: 'mediacenter/:id', component: MediacenterComponent },
  { path: ':language/mediacenter/:id', component: MediacenterComponent },
  { path: 'mediacenter', component: MediacenterComponent },
  { path: ':language/mediacenter', component: MediacenterComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'etec', component: EtecComponent },
  { path: 'etec/:id', component: EtecComponent },
  { path: ':language/etec', component: EtecComponent },
  { path: ':language/etec/:id', component: EtecComponent },

  { path: ':language/greatings', component: GreatingsComponent },
  { path: 'greatings', component: GreatingsComponent },
  // { path: 'qiyas', component: QiyasComponent },
  // { path: 'qiyas/:id', component: QiyasComponent },
  // { path: 'qiyas/:id/:type', component: QiyasComponent },
  // { path: ':language/qiyas', component: QiyasComponent },
  // { path: ':language/qiyas/:id', component: QiyasComponent },
  // { path: ':language/qiyas/:id/:type', component: QiyasComponent },
  // { path: 'ncsee', component: NcseeComponent },
  // { path: 'ncsee/:id', component: NcseeComponent },
  // { path: ':language/ncsee', component: NcseeComponent },
  // { path: ':language/ncsee/:id', component: NcseeComponent },
  // { path: 'ncaaa', component: NcaaComponent},
  // { path: 'ncaaa/:id', component: NcaaComponent},
  // { path: ':language/ncaaa', component: NcaaComponent},
  // { path: ':language/ncaaa/:id', component: NcaaComponent},
  // { path: 'nctaa', component: MasarComponent },
  // { path: 'nctaa/:id', component: MasarComponent },
  // { path: ':language/nctaa', component: MasarComponent },
  // { path: ':language/nctaa/:id', component: MasarComponent },
  // { path: 'ncmaa', component: NcmaaComponent },
  // { path: 'ncmaa/:id', component: NcmaaComponent },
  // { path: 'ncmaa/:id/:type', component: NcmaaComponent },
  // { path: ':language/ncmaa', component: NcmaaComponent },
  // { path: ':language/ncmaa/:id', component: NcmaaComponent },
  // { path: ':language/ncmaa/:id/:type', component: NcmaaComponent },
  { path: 'news/:id', component: NewsComponent },
  { path: ':language/news/:id', component: NewsComponent },
  { path: 'contactus', component: ContactUsComponent },
  { path: ':language/contactus', component: ContactUsComponent },
  { path: 'productAccreditation/:id', component: ProductAccreditationComponent },
  { path: ':language/productAccreditation/:id', component: ProductAccreditationComponent },
  { path: 'productQiyas/:id', component: ProductQiyasComponent },
  { path: ':language/productQiyas/:id', component: ProductQiyasComponent },
  { path: 'service/:id', component: ServiceDetailsComponent },
  { path: ':language/service/:id', component: ServiceDetailsComponent },
  { path: 'service/:id/:tab', component: ServiceDetailsComponent },
  { path: ':language/service/:id/:tab', component: ServiceDetailsComponent },
  { path: 'data', component: DataComponent },
  { path: ':language/data', component: DataComponent },
  { path: 'data/:id', component: DataComponent },
  { path: ':language/data/:id', component: DataComponent },
  { path: 'opendata', loadComponent: () => import("./components/reports/open-data/open-data.component").then((m) => m.OpenDataComponent) },
  { path: ':language/opendata', loadComponent: () => import("./components/reports/open-data/open-data.component").then((m) => m.OpenDataComponent) },
  { path: 'opendata/:id', loadComponent: () => import("./components/reports/open-data/open-data.component").then((m) => m.OpenDataComponent) },
  { path: ':language/opendata/:id', loadComponent: () => import("./components/reports/open-data/open-data.component").then((m) => m.OpenDataComponent) },


  { path: 'publicresearch', component: PublicResearchComponent },
  { path: ':language/publicresearch', component: PublicResearchComponent },
  { path: ':language/report', component: ReportsComponent },
  { path: 'transcripts', component: TranscriptsComponent },
  { path: ':language/transcripts', component: TranscriptsComponent },
  { path: 'report', component: ReportsComponent },
  { path: 'reportsresearch', component: ReportsResearchComponent },
  { path: 'privacy', component: PrivcayComponent },
  { path: 'notfound', component: NotfoundComponent },
  { path: 'workshops', component: WorkshopComponent },
  { path: 'workshops/:id', component: WorkshopComponent },
  { path: 'conferences', component: ConferenceComponent },
  { path: 'faq', component: FAQComponent },
  { path: 'scientificactivities', component: ScientificActivityComponent },
  { path: 'scientificactivities/:id', component: ScientificActivityComponent },
  { path: 'personalinfo/:id/:type', loadComponent: () => import("./components/personal-info/personal-info.component").then((m) => m.PersonalInfoComponent) },
  { path: 'relatednews', component: RelatednewsComponent },
  { path: 'serviceguidecatalog', component: ServicecatalogComponent },
  { path: 'sec', component: SEFComponent },
  { path: 'secRegistration', component: SEFRegistrationComponent },
  { path: 'secbadge', component: SEFBadgeComponent },
  { path: ':language/SEF', component: SEFComponent},
  { path: ':language/SEFRegistration', component: SEFRegistrationComponent},
  { path: ':language/SEFBadge', component: SEFBadgeComponent},
  // { path: 'secRegistration/:k', component: SEFRegistrationComponent },
  // { path: ':language/SEFRegistration/:k', component: SEFRegistrationComponent},
  { path: 'servicecatalogdetails/:id', component: ServicecatalogdetailsComponent },
  { path: 'page/:id', component: PageComponent },
  { path: 'page/:id/:route', component: PageComponent },
  { path: 'lightgallery', component: LightgalleryComponent },
  { path: 'scroll-totop', component: ScrollTotopComponent },
  { path: 'c/:id/:tab/:type', component: DynamicCenterComponent },
  { path: 'c/:id/:tab', component: DynamicCenterComponent },

  { path: ':language/services/:id', component: ProductsComponent },
  { path: ':language/Accreditationresult', component: AccreditationresultComponent },
  { path: ':language/report', component: ReportsComponent },
  { path: ':language/reportsresearch', component: ReportsResearchComponent },
  { path: ':language/privacy', component: PrivcayComponent },
  { path: ':language/notfound', component: NotfoundComponent },
  { path: ':language/workshops', component: WorkshopComponent },
  { path: ':language/workshops/:id', component: WorkshopComponent },
  { path: ':language/conferences', component: ConferenceComponent },
  { path: ':language/faq', component: FAQComponent },
  { path: ':language/scientificactivities', component: ScientificActivityComponent },
  { path: ':language/scientificactivities/:id', component: ScientificActivityComponent },
  // { path: ':language/personalinfo/:id/:type', component: PersonalInfoComponent},
  { path: ':language/personalinfo/:id/:type', loadComponent: () => import("./components/personal-info/personal-info.component").then((m) => m.PersonalInfoComponent) },

  { path: ':language/relatednews', component: RelatednewsComponent },
  { path: ':language/serviceguidecatalog', component: ServicecatalogComponent },
  // { path: ':language/servicecatalogdetails/:id', component: ServicecatalogdetailsComponent },
  { path: ':language/page/:id', component: PageComponent },
  { path: ':language/page/:id/:route', component: PageComponent },
  { path: ':language/lightgallery', component: LightgalleryComponent },
  { path: ':language/scroll-totop', component: ScrollTotopComponent },
  { path: ':language/:id/:tab/:type', component: DynamicCenterComponent },
  { path: ':language/:id/:tab', component: DynamicCenterComponent },
  { path: ':language/:id/:tab', component: DynamicCenterComponent },
  { path: ':language/:id', component: DynamicCenterComponent },
  { path: ':id', component: DynamicCenterComponent },

  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// 