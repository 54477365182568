import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CenterLeaderList } from 'src/app/models/CenterLeaderList';
import { CenterList } from 'src/app/models/CenterList';
import { ListResponse } from 'src/app/models/ListResponse';

import { Center, CenterLeader } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-etec',
  templateUrl: './etec.component.html',
  styleUrls: ['./etec.component.css']
})

export class EtecComponent implements OnInit {

  centerId!: number;
  center: Center;
  centerOrgUrl: string;
  centerLeader: CenterLeader;
  activeTab = 'foundation';
  destroy$: Subject<boolean> = new Subject<boolean>();
  organizationalchart: string = '';
  etecceo: string = '';
  foundation: string = '';
  visionmission: string = '';
  etecboard: string = '';
  PageTitle: string = '';
  home: string = '';
  _lang = '';
  EtecFullName = 'هيئة تقويم التعليم والتدريب';

  constructor(private centerService: ApiService, private actRoute: ActivatedRoute, private Title: Title
    , private apiResources: ResourcesService, private loading: LoadingService) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }


    this.actRoute.params.subscribe(async params => {
      this.activeTab = params["id"] == null ? 'foundation' : params["id"];
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }

      if (params["id"] === 'vision' || params["id"] === 'organizationalstructure') {
        if (!this.center) {
          this.getCenter();
        }
      }
    }
    );
  }
  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }
  /** Custom methods */
  getLeaders(centerId: number) {
    this.centerService.getList('centersleader').pipe(takeUntil(this.destroy$)).subscribe(
      (data: CenterLeaderList[]) => {
        data.forEach(element => {
          if (element.ItemFields.CenterId.LookupId === this.centerId && element.ItemFields.IsCenterCEO == true) {
            var obj: CenterLeader = {
              centerLeaderId: element.ItemId,
              centerLeaderPosition: element.ItemFields.CentersLeaderPositionId?.LookupValue,
              centerLeaderPrefixId: element.ItemFields.CentersLeaderPrefixId?.LookupValue == null ? '' : element.ItemFields.CentersLeaderPrefixId?.LookupValue,
              centerLeaderName: element.ItemFields.CentersLeaderName,
              centerLeaderPortrait: element.ItemFields?.CentersLeaderPortrait.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
              centerLeaderCurriculumVite: element.ItemFields.CentersLeaderCurriculumVitae,
              Sequence: element.ItemFields.Sequence,
              isActive: element.ItemFields.IsActive1,

            };
            this.centerLeader = obj;
          }
        });
      }
    );
  }


  getCenter() {
    this.centerService.getList('centers').pipe(takeUntil(this.destroy$)).subscribe(
      (data: CenterList[]) => {
        data.forEach(element => {
          if (element.ItemFields?.CenterCode?.toUpperCase() === 'ETEC') {
            var obj: Center = {
              centerId: element.ItemId,
              centerName: element.ItemFields.CenterName,
              centerDescription: element.ItemFields.CenterDescription?.toString(),
              centerVision: element.ItemFields.CenterVision?.toString(),
              centerVisionIcon: '',
              CenterValues: element.ItemFields.CenterValues,
              centerMission: element.ItemFields.CenterMission?.toString(),
              centerMissionIcon: '',
              CenterMoreDetails: element.ItemFields.CenterMoreDetails?.toString(),
              centerOrgnizationalStructureUrl: element.ItemFields?.CenterOrganizationalStructure?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl)!,
              centerMainImageCoverUrl: '',
              centerParenetId: -1,
              isActive: element.ItemFields.IsActive1
            };
            // this.center = obj;
            // this.centerId = Number(element.ItemFields.CenterId);
            this.center = obj;
            this.centerId = obj.centerId;
            this.centerOrgUrl = obj.centerOrgnizationalStructureUrl
            this.getLeaders(this.centerId);
          }
        });
      }
    );
  }

  getEtecName() {
    this.centerService.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ListResponse[]) => {
        data.forEach(element => {
          if (element.ItemFields.Title.toLowerCase() === 'etec') {

            this.PageTitle = element.ItemFields.MenuName;
            this.Title.setTitle(this.EtecFullName + " - " + this.PageTitle);
          }

        });
      }
    );
  }

  /** Component events  */
  async ngOnInit() {
    this.foundation = await this.apiResources.getByKey("foundation");
    this.visionmission = await this.apiResources.getByKey("visionmission");
    this.etecboard = await this.apiResources.getByKey("etecboard");
    this.organizationalchart = await this.apiResources.getByKey("organizationalchart");
    this.etecceo = await this.apiResources.getByKey("etecceo");
    this.home = await this.apiResources.getByKey("home");

    this.getEtecName();

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

}
