<article class="col-12 my-lg-4 my-3 mt-0" *ngIf="Content.length>0">
    <div class="card border-1 bg-white shadow-primary overflow-hidden p-5 rounded-5">
        <div class="row g-4 justify-content-center align-items-center">
            <div class="col-lg-3 col-12">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <img src="assets/img/icon/PGATE-Search.svg" width="80" alt="Target Icon"
                        class="mb-2">
                    <div class="pt-3">
                        <h3 class="h3 mb-0 text-primary fw-semibold text-center"
                            [innerText]="infoAboutService"></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-12">
                <div
                    class="row row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-1 g-lg-4 g-3 service-goals">
                    <div class="col" *ngFor="let item of Content">
                        <div
                            class="d-flex flex-lg-row flex-column align-items-start justify-content-lg-start justify-content-center">
                            <div class="d-table bg-white rounded-3 flex-shrink-0 pt-0 p-3">
                                <img src="assets/media/icons/steps-icon.svg" alt="Icon">
                            </div>
                            <div class="text-center">
                                <p class="fs-sm text-primary fw-semibold pb-1 mb-2 d-none"
                                    style="width:150px;">
                                    {{item.ServiceContentTitle}}
                                </p>
                            </div>
                            <p class="fs-sm text-primary mb-0" *ngIf="item.ServiceContentDetailes"
                                [innerHTML]="item.ServiceContentDetailes">

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>