<article class="col-12 my-lg-4 my-3 mt-0" *ngIf="Notes.length>0">
    <div class="row row-cols-1 row-cols-xl-4 row-cols-md-2 g-3 g-sm-4">
        <div class="col d-flex" *ngFor="let item of Notes">
            <div class="card bg-primary rounded-5 w-100 h-100 pb-3" *ngIf="item.serviceNoteDetailes">
                <div class="card-body pb-3 text-center">
                    <h3 class="fs-lg mb-0 text-white" [innerText]="item.serviceNoteTitle">
                    </h3>
                </div>
                <div class="card-footer border-0 text-center d-flex h-100">
                    <div class="fs-sm text-white rounded-5 p-3 w-100 link-color-force"
                        [innerHTML]="item.serviceNoteDetailes">
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>