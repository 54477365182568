<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/ScientificActivities.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="text-white h1 fw-semibold m-0 position-relative zindex-5" [innerText]="PageTitle">
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
        <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="{{_lang}}/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page" [innerText]="PageTitle">11 </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>
<!--begin::tabs-->
<section class="container">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs content-tabs flex-nowrap justify-content-md-center justify-content-start overflow-auto mb-0 py-4 pb-4 pt-0"
        role="tablist">
        <li class="nav-item mb-0" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center" id="tabs-tab1"
                data-bs-toggle="tab" data-bs-target="#conferences" role="tab" aria-controls="tab1" aria-selected="true"
                routerLink="/scientificactivities/conferences"
                [ngClass]="activeTab.toLocaleLowerCase() === 'conferences'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bx-calendar-event me-2 fs-2"></i>
                {{conv}}
            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center" id="tabs-tab2"
                data-bs-toggle="tab" data-bs-target="#labs" role="tab" aria-controls="tab2" aria-selected="true"
                routerLink="/scientificactivities/labs"
                [ngClass]="activeTab.toLocaleLowerCase() === 'labs'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bx-slideshow me-2 fs-2"></i>
                {{LecturesAndDiscussions}}
            </a>
        </li>
    </ul>
    <!-- Tab content -->
    <div class="tab-content rounded-3">
        <!-- tab-pane -->
        <div class="tab-pane fade" id="conferences" role="tabpanel" aria-labelledby="tabs-tab1"
            [ngClass]="activeTab.toLocaleLowerCase() === 'conferences'? 'show active':''">
            <!-- content -->
            <!-- search bar -->
            <div class="row justify-content-center">
                <div class="col-12 mb-5">
                    <div class="input-group position-relative">
                        <input type="text" class="form-control form-control-lg ps-5"
                            placeholder="  {{reportsearch}}     .." [(ngModel)]="searchText">
                        <i
                            class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                        <!-- <mat-select value="option0" (selectionChange)=changeRatio($event)
                            class="btn btn-sm btn-outline-primary ms-1 px-2 fw-normal btn-sort"
                            style="width:150px;border-radius: 0.5rem;">
                            <mat-option value="option0" [disabled]="true" aria-selected="true">الترتيب
                            </mat-option>
                            <mat-option value="ReportTitle-false"> تصاعدي - ابجدي</mat-option>
                            <mat-option value="ReportTitle-true"> تنازلي - ابجدي </mat-option>
                            <mat-option value="Created-false"> تصاعدي - بالتاريخ</mat-option>
                            <mat-option value="Created-true"> تنازلي - بالتاريخ</mat-option>
                        </mat-select> -->
                    </div>
                </div>
            </div>

            <!-- data -->
            <div class="row row-cols-xxl-2 row-cols-1 g-4">
                <div class="col" *ngFor="let element of reasearchList | filter:searchText; let i = index">

                    <div class="card h-100 border-1 bg-white shadow-sm overflow-hidden mb-3 p-3">
                        <div class="row g-0">
                            <!-- img -->
                            <div *ngIf="element.ItemFields?.ImageThumbnails?.Url"
                                class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 bg-primary"
                                style="    min-height: 12rem;background-size: 150px !important;"
                                [ngStyle]="{'background-image': 'url(' +'\'' +element.ItemFields?.ImageThumbnails?.Url?.replace(imptempPublicUrl,imgPublicUrl) +'\'' +')'}">
                            </div>
                            <div *ngIf="!element.ItemFields?.ImageThumbnails?.Url"
                                class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 "
                                style="    min-height: 12rem;background-size: 150px !important;" [ngStyle]="{'background-image': 'url(assets/img/data/converances.png)'}">
                                <!-- <i
                                    class="bx bx-image bx-flip-horizontal text-white display-3 d-flex h-100 justify-content-center align-items-center"></i> -->
                            </div>
                            <!-- content -->
                            <div class="col-xxl-9 col-lg-9 col-md-12 col-12">
                                <div class="px-0 px-lg-4 py-0 d-flex flex-column justify-content-center h-100">
                                    <h3 class="fs-lg text-primary" [innerHTML]="element.ItemFields.ActivityTitle"></h3>
                                    <p class="mb-2 text-primary" [innerHTML]="element.ItemFields?.PublishedLocation">
                                    </p>
                                    <div *ngIf="element.ItemFields?.ActivitySpeakers"
                                        class="d-flex align-items-start text-primary flex-column flex-lg-row">
                                        <div>
                                            <i class="bx bx-user fs-lg me-1"></i>
                                            <span class="fs-sm me-3" [innerHTML]="element.ItemFields?.ActivitySpeakers">
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex flex-column flex-lg-row justify-content-between text-primary mt-3">
                                        <div class="d-flex flex-column flex-lg-row">
                                            <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-lg-0 mb-2 d-flex justify-content-between"
                                                target="_blank" *ngIf="element.ItemFields?.ActivityLink"
                                                href="{{element.ItemFields?.ActivityLink?.Url?.replace(imptempPublicUrl,imgPublicUrl)}}">
                                                <span class="d-inline me-3 fs-sm"> {{reportdownload}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bx-show-alt  fs-4"></i>
                                                </span>
                                            </a>

                                        </div>
                                        <div class="d-flex align-items-center h-100"
                                            *ngIf="element.ItemFields?.ActivityDate">
                                            <div class="mb-2" *ngIf="element.ItemFields?.ActivityDate">
                                                <i class="bx bx-calendar fs-lg me-1"></i>
                                                <span class="fs-sm"
                                                    [innerHTML]="element.ItemFields?.ActivityDate"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end content  -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade" id="labs" role="tabpanel" aria-labelledby="tabs-tab2"
            [ngClass]="activeTab.toLocaleLowerCase() === 'labs'? 'show active':''">
            <!-- content -->
            <!-- search bar -->
            <div class="row justify-content-center">
                <div class="col-12 mb-5">
                    <div class="input-group mt-3 position-relative">
                        <input type="text" class="form-control form-control-lg ps-5" placeholder=" {{reportsearch}}  .."
                            [(ngModel)]="searchText">
                        <i
                            class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                    </div>
                </div>
            </div>

            <div class="row row-cols-xxl-2 row-cols-1 g-4">
                <div class="col" *ngFor="let element of reasearchList2 | filter:searchText; let i = index">
                    <div class="card h-100 border-1 bg-white shadow-sm overflow-hidden mb-3 p-3">
                        <div class="row g-0">
                            <!-- img -->
                            <div *ngIf="element.ItemFields?.ImageThumbnails"
                                class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 bg-primary"
                                style="    min-height: 12rem;background-size: 150px !important;"
                                [ngStyle]="{'background-image': 'url(' +'\'' +element.ItemFields?.ImageThumbnails?.replace(imptempPublicUrl,imgPublicUrl) +'\'' +')'}">
                            </div>
                            <div *ngIf="!element.ItemFields?.ImageThumbnails"
                                class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 "
                                style="    min-height: 12rem;background-size: 150px !important;" [ngStyle]="{'background-image': 'url(assets/img/data/discussions.png)'}">
                                <!-- <i
                                    class="bx bx-image bx-flip-horizontal text-white display-3 d-flex h-100 justify-content-center align-items-center"></i> -->
                            </div>
                            <!-- content -->
                            <div class="col-xxl-9 col-lg-9 col-md-12 col-12">
                                <div class="px-0 px-lg-4 py-0 d-flex flex-column justify-content-center h-100">
                                    <h3 class="fs-lg text-primary" [innerHTML]="element.ItemFields.ActivityTitle"></h3>
                                    <p class="mb-2 text-primary" [innerHTML]="element.ItemFields?.PublishedLocation">
                                    </p>
                                    <div *ngIf="element.ItemFields?.ActivitySpeakers"
                                        class="d-flex align-items-start text-primary flex-column flex-lg-row">
                                        <div>
                                            <i class="bx bx-user fs-lg me-1"></i>
                                            <span class="fs-sm me-3" [innerHTML]="element.ItemFields?.ActivitySpeakers">
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex flex-column flex-lg-row justify-content-between text-primary mt-3">
                                        <div class="d-flex flex-column flex-lg-row">
                                            <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-lg-0 mb-2 d-flex justify-content-between"
                                                target="_blank" *ngIf="element.ItemFields?.ActivityLink"
                                                href="{{element.ItemFields?.ActivityLink?.Url?.replace(imptempPublicUrl,imgPublicUrl)}}">
                                                <span class="d-inline me-3 fs-sm"> {{reportdownload}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bx-show-alt  fs-4"></i>
                                                </span>
                                            </a>

                                        </div>
                                        <div class="d-flex align-items-center h-100"
                                            *ngIf="element.ItemFields?.ActivityDate">
                                            <div class="mb-2" *ngIf="element.ItemFields?.ActivityDate">
                                                <i class="bx bx-calendar fs-lg me-1"></i>
                                                <span class="fs-sm"
                                                    [innerHTML]="element.ItemFields?.ActivityDate | date: 'yyyy/MM/dd'"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end content  -->
        </div>

    </div>
</section>


<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"> {{reportquotes}} </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p [innerHTML]="modalData"></p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" aria-label="Close" (click)="d('Cross click')">{{close}}</button>
    </div>

</ng-template>