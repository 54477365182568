import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LoadingService } from './services/loading.service';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  constructor(private loader: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loader.show();
    const idToken = localStorage.getItem('id_token');

    if(request.url.endsWith('Authenticate')){
      return next.handle(request).pipe(
        finalize ( () =>  this.loader.hide())
      );
    }
    
    if(idToken){
      const cloned = request.clone({
       headers: request.headers.set('Authorization', `Bearer ${idToken}`).set('Content-Type', 'application/x-www-form-urlencoded')
       
      });

      return next.handle(cloned).pipe(
        finalize ( () =>  this.loader.hide())
      );
    }
    else{
      return next.handle(request).pipe(
        finalize ( () =>  this.loader.hide())
      );
    }

    return next.handle(request).pipe(
      finalize ( () =>  this.loader.hide())
    );
  }
}
