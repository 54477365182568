import { HttpClient, HttpEventType, HttpErrorResponse, HttpResponse, HttpRequest } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-research-prize',
  templateUrl: './research-prize.component.html',
  styleUrls: ['./research-prize.component.css']
})
export class ResearchPrizeComponent {
  educationdata: any[] = []
  GUID : string ='';
  firstStep = 1 ;
  _lang = '';
  home = '';
  constructor(private http: HttpClient, private fb: FormBuilder, private apiResources: ResourcesService) {
    this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
  }

  async ngOnInit(): Promise<void> {
    this.home = await this.apiResources.getByKey("home");
  }
  getChildData(data: string){
    this.GUID = data
  }

  getStep(data: number){
    this.firstStep = data
  }
}
