<div class="tab-pane fade show active" id="education" role="tabpanel" aria-labelledby="contact-tab5">
    <div class="table-responsive rounded-5 mt-4">
        <table class="table fs-sm etec-table"  *ngIf="localEducationList.length > 0">
            <thead>
                <tr>
                    <th class="fs-sm fw-normal">رقم الهوية الوطنية</th>
                    <th class="fs-sm fw-normal">اسم الباحث </th>
                    <th class="fs-sm fw-normal">آخر درجة علمية</th>
                    <th class="fs-sm fw-normal">التخصص</th>
                    <th class="fs-sm fw-normal">جهة العمل </th>
                    <th class="fs-sm fw-normal">البريد الإلكتروني</th>
                    <th class="fs-sm fw-normal">رقم الجوال</th>
                    <th class="fs-sm fw-normal">دور الباحث</th>
                    <th class="fs-sm fw-normal">حذف</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of  localEducationList" >
                    <td>{{item.nationalId}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.degree}}</td>
                    <td>{{item.spciality}}</td>
                    <td>{{item.workOn}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.mobile}}</td>
                    <td>{{item.role}}</td>
                    <td><button class="btn btn-danger"  (click)="removeResearcher(item.nationalId, item.GUID)" *ngIf="item.role != 'باحث رئيس'" >حذف</button></td>
                </tr>
            </tbody>

        </table>
    </div>

</div>