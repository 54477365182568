<!--begin::hero-->
<section *ngIf="center"
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="height: 300px;" [ngStyle]="{'background-image': 'url(' +'\'' +center.CenterMainImageCover}">
    <div class="container position-relative zindex-5 py-5 mb-5">
        <div class="row justify-content-center">
            <div *ngIf="center.centerLogo"
                class="col-lg-6 col-md-8 col-sm-12 order-md-1 order-2 d-flex flex-column justify-content-center align-items-center text-center pt-2">
                <!-- img -->
                <!-- <img src="assets/media/logos/qiyas-logo.svg" alt="masar logo" style="width: 500px;min-width: 300px;"> -->
                <img src="{{center.centerLogo}}" alt="masar logo" style="width: 500px;min-width: 300px;"
                    data-aos="zoom-out" data-aos-duration="1500">
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page" [innerText]="PageTitle">
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<section class="container">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs content-tabs flex-nowrap justify-content-md-center justify-content-start overflow-auto mb-0 pb-5"
        role="tablist">
        <li class="nav-item mb-0" role="presentation" *ngFor="let li of CenterTabs; let i = index">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                href="#tab{{li.ItemId}}" id="tabs-tab{{li.ItemId}}" data-bs-toggle="tab" role="tab"
                attr.aria-controls="tab{{li.ItemId}}" aria-selected="true"
                routerLink="/{{_lang}}/{{centerCode}}/{{li.ItemFields?.Title}}/" [ngClass]="activeTab.toLocaleLowerCase() === li.ItemFields?.Title?.toLocaleLowerCase()
                ||(activeTab === '' && i == 0)  ? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="{{li.ItemFields?.Icon}} me-2 fs-2"></i>
                {{li.ItemFields?.PublicContentTitle}}
            </a>
        </li>
    </ul>

    <!-- Tab content -->
    <div class="tab-content rounded-3">
        <!-- tab-pane -->
        <div class="tab-pane fade " id="tab{{li.ItemId}}" role="tabpanel" attr.aria-label="tabs-tab{{li.ItemId}}"
            [ngClass]="activeTab.toLocaleLowerCase() === li.ItemFields?.Title?.toLocaleLowerCase() || (activeTab === '' && i == 0) ? 'show active':''"
            *ngFor="let li of CenterTabs; let i = index">
            <!-- content -->
            <app-about-center
                *ngIf="li.ItemFields?.Title?.toLocaleLowerCase() === 'about' && li.ItemFields?.HasCustomContent"
                [centerId]="centerId" [center]="center"></app-about-center>

            <app-center-leader
                *ngIf="li.ItemFields?.Title?.toLocaleLowerCase() === 'ceo' && li.ItemFields?.HasCustomContent"
                [centerId]="centerId" [cenetrLeader]="centerLeader"></app-center-leader>

            <app-center-services [centerId]="centerId" [cenetrservices]="cenetrservices"
                [cenetrservicesCategory]="cenetrservicesCategory" [serviceCengerIcon]="centerIconUrl"
                [ServiceCategoryTag]="activeSection"
                (dynamicUrl)="getChildData($event)"
                *ngIf="li.ItemFields?.Title?.toLocaleLowerCase() === 'services' && li.ItemFields?.HasCustomContent"></app-center-services>

            <app-center-service-catalog [centerName]="centerName" 
                *ngIf="li.ItemFields?.Title?.toLocaleLowerCase() === 'catalog' && li.ItemFields?.HasCustomContent"></app-center-service-catalog>

            <app-accreditationresult [centerId]="centerId"
                *ngIf="li.ItemFields?.Title?.toLocaleLowerCase() === 'accreditationsresults' && li.ItemFields?.HasCustomContent"></app-accreditationresult>

            <div class="row justify-content-md-start justify-content-center mb-2" *ngIf="!li.ItemFields?.HasSubContent">
                <div class="col-12 text-primary order-list" style="text-align: justify;"
                    [innerHTML]="li.ItemFields?.PublicContentDetails" *ngIf="!li.ItemFields?.HasCustomContent"> </div>
            </div>
            <!-- end content  -->
        </div>
        <app-shareicons [dynamicUrl]="_dynamicUrl"></app-shareicons>
    </div>
</section>