import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import { LightGallery } from 'lightgallery/lightgallery';
import { LightGallerySettings } from 'lightgallery/lg-settings';

@Component({
  selector: 'app-sef',
  templateUrl: './sef.component.html',
  styleUrls: ['./sef.component.css']
})
export class SEFComponent implements OnInit {
  content ='';
  _lang = '';
  private lightGallery!: LightGallery;
  private needRefresh = false;
  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }
  title = 'angular-demo';
  settings = {
    counter: false,
    plugins: [lgZoom]
  };
  items = [
    {
      id: '1',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A01.jpg',
      thumb:
        'assets/media/img/sef-img/A01.jpg',
    },
    {
      id: '2',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A02.jpg',
      thumb:
        'assets/media/img/sef-img/A02.jpg',
    },
    {
      id: '3',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A03.jpg',
      thumb:
        'assets/media/img/sef-img/A03.jpg',
    },
    {
      id: '4',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A04.jpg',
      thumb:
        'assets/media/img/sef-img/A04.jpg',
    },
    {
      id: '5',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A05.jpg',
      thumb:
        'assets/media/img/sef-img/A05.jpg',
    },
    {
      id: '6',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A06.jpg',
      thumb:
        'assets/media/img/sef-img/A06.jpg',
    },
    {
      id: '7',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A07.jpg',
      thumb:
        'assets/media/img/sef-img/A07.jpg',
    },
    {
      id: '8',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A08.jpg',
      thumb:
        'assets/media/img/sef-img/A08.jpg',
    },
    {
      id: '9',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A09.jpg',
      thumb:
        'assets/media/img/sef-img/A09.jpg',
    },
    {
      id: '11',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A011.jpg',
      thumb:
        'assets/media/img/sef-img/A011.jpg',
    },
    {
      id: '12',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A012.jpg',
      thumb:
        'assets/media/img/sef-img/A012.jpg',
    },
    {
      id: '13',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A013.jpg',
      thumb:
        'assets/media/img/sef-img/A013.jpg',
    },
    {
      id: '14',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A014.jpg',
      thumb:
        'assets/media/img/sef-img/A014.jpg',
    },
    {
      id: '15',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A015.jpg',
      thumb:
        'assets/media/img/sef-img/A015.jpg',
    },
    {
      id: '16',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A016.jpg',
      thumb:
        'assets/media/img/sef-img/A016.jpg',
    },
    {
      id: '17',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A017.jpg',
      thumb:
        'assets/media/img/sef-img/A017.jpg',
    },
    {
      id: '18',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A018.jpg',
      thumb:
        'assets/media/img/sef-img/A018.jpg',
    },
    {
      id: '19',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A019.jpg',
      thumb:
        'assets/media/img/sef-img/A019.jpg',
    },
    {
      id: '20',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A020.jpg',
      thumb:
        'assets/media/img/sef-img/A020.jpg',
    },
    {
      id: '21',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A021.jpg',
      thumb:
        'assets/media/img/sef-img/A021.jpg',
    },
    {
      id: '22',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A022.jpg',
      thumb:
        'assets/media/img/sef-img/A022.jpg',
    },
    {
      id: '23',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A023.jpg',
      thumb:
        'assets/media/img/sef-img/A023.jpg',
    },
    {
      id: '24',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A024.jpg',
      thumb:
        'assets/media/img/sef-img/A024.jpg',
    },
    {
      id: '25',
      size: '1000-666',
      src:
        'assets/media/img/sef-img/A025.jpg',
      thumb:
        'assets/media/img/sef-img/A025.jpg',
    }
  ];
  onInit = (detail:any): void => {
    this.lightGallery = detail.instance;
  };
  constructor(private apiServices : ApiService, private actRoute: ActivatedRoute,  private apiResources: ResourcesService, private loading: LoadingService) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  ngOnInit(): void {
  }

}
