import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ServicesResponse } from 'src/app/models/ServicesResponse';
import { Service } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { RouteApiService } from 'src/app/services/route-api.service';
import { ServiceStructureTagsList } from 'src/app/models/ServiceStructureTagsList';
import { CenterList } from 'src/app/models/CenterList';
import { environment } from 'src/environments/environment';
import { ListResponse } from 'src/app/models/ListResponse';
import { Title } from '@angular/platform-browser';
import { ResourcesService } from 'src/app/services/resources.service';
import { LoadingService } from 'src/app/services/loading.service';


enum SERVICESECTOR {
  Personal = 1,
  Business = 2
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})


export class ProductsComponent implements OnInit {
  public p: number = 1;
  showCount = 12;
  searchText: any;
  more: string = '';
  PageTitle: string = '';
  home: string = '';
  all: string = '';
  Individuals: string = '';
  Institutions: string = '';
  Centers: string = '';
  ServiceType: string = '';
  Category: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  tags: ServiceStructureTagsList[] = [];
  tagsNumber: number[] = [];
  tagsStaus: boolean[] = [];
  selectedTags: string[] = [];
  centerStatus: boolean[] = [];
  selectedCenter: number[] = [];
  response: Service[] = [];
  responseService: Service[] = [];
  selectedResponse: Service[] = [];
  centers: CenterList[] = [];
  tab = 'single';
  filterAll = true;
  sectorAll = false;
  tagsAll = true;
  subTitle = '';
  centerId = -1;
  servicesof: string;
  EtecFullName = 'هيئة تقويم التعليم والتدريب';
  filter = { qiyas: true, Ncaaa: true, Ncsee: true, Masar: true, NCMAA: true, etec: true,  nacademy: true};
  sectorFilter = { personal: true, business: true };
  Products: Service[] = this.response;
  _lang = '';
  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiRersources.clearResource();
  }

  constructor(private apiServices: ApiService, private apiRoute: RouteApiService, private router: Router, private actRoute: ActivatedRoute
    , private Title: Title, private apiRersources: ResourcesService, private loading: LoadingService) {

    this.actRoute.params.subscribe(async params => {

      if (!localStorage.getItem('lang')) {
        this._lang = 'ar';
      }
      else {
        this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
      }
      
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }

      this.tab = params["id"] == null ? '' : params["id"]
      if (this.tab === 'indviduals') {
        this.sectorFilter.business = false;
        this.sectorFilter.personal = true;
      }
      else if (this.tab === 'corporate') {
        this.sectorFilter.personal = false;
        this.sectorFilter.business = true;
      }
      else {
        this.sectorAll = true
        this.sectorFilter.personal = true;
        this.sectorFilter.business = true;
      }

      this.apiServices.getList('centers').pipe(takeUntil(this.destroy$)).subscribe(
        (data) => {
          this.centers = data; //.filter(
          //(x: CenterList) => x.ItemId != 1);
          //console.log(this.centers);
          this.centers.forEach(element => {
            this.centerStatus.push(false);
          });
          this.apiServices.getListTopN('Services', 100, false, 'ServiceSectorId', this.sectorFilter.personal ? SERVICESECTOR.Personal : SERVICESECTOR.Business).pipe(takeUntil(this.destroy$)).subscribe(
            (data: ServicesResponse[]) => {
              data.forEach(element => {
                var obj: Service = {
                  GUID: element.ItemFields.GUID,
                  ServiceCode: element.ItemFields?.ServiceCode,
                  serviceTitle: element.ItemFields.Title,
                  serviceId: element.ItemId,
                  serviceName: element.ItemFields.ServiceName,
                  ServiceCenterId: element.ItemFields.ServiceCenterId?.LookupId,
                  ServiceCenterName: this.centers.find(elm => elm.ItemId == element.ItemFields?.ServiceCenterId?.LookupId)?.ItemFields.CenterShortName,
                  ServiceCenterIcon: this.centers.find(elm => elm.ItemId == element.ItemFields?.ServiceCenterId?.LookupId)?.ItemFields.CenterLogoIcon?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
                  ServiceDescription: element.ItemFields?.ServiceDescription?.replace(/<[^>]*>/g, ''),
                  serviceIconUrl: './assets/img/icon/exam/GAT.svg',
                  serviceLevel: 0,
                  serviceVisitCount: element.ItemFields.VisitingCount ? element.ItemFields.VisitingCount : 0,
                  serviceDetailsFileUrl: element.ItemFields?.ServiceDescription,
                  serviceKeyword: '',
                  serviceRegistrationLink: data[0]?.ItemFields?.DetailsButtonLink?.Url,
                  serviceRegistrationTitle: data[0]?.ItemFields?.DetailsButtonLabel,
                  serviceSectorId: element.ItemFields.ServiceSectorId.LookupId,
                  serviceTagId: element.ItemFields.ServiceTagId?.LookupValue,
                  isActive: element.ItemFields.IsActive1,
                  ShowDetailsButton: element.ItemFields.ShowDetailsButton
                };
                if(!element.ItemFields?.IsHiddenFromServiceList){
                  this.responseService.push(obj);
                }
                   
              });
              this.filterChange();
            });
        }
      );



    });
  }

  /** Custom methods */
  filterChange() {
    this.tagsNumber = [];
    this.selectedTags = [];
    this.selectedCenter = [];
    this.response = [];
    this.tagsStaus.forEach((element, index) => {
      if (element === true) {
        this.selectedTags.push(this.tags[index].ItemFields.ServiceTagName);

      }
      this.tagsNumber.push(0);
    });

    this.filterAll = this.centerId == -1;
    if (this.sectorFilter.personal) {
      this.getServiceByType(SERVICESECTOR.Personal);
    }
    if (this.sectorFilter.business) {
      this.getServiceByType(SERVICESECTOR.Business);
    }

    if (this.sectorFilter.business && this.sectorFilter.personal) {
      this.sectorAll = true;
    }
    else {
      this.sectorAll = false;
    }


    // this.centerStatus.forEach((element) => {
    //   if(!element){
    //     this.filterAll = false;
    //   }
    // });

    this.tagsAll = true;
    this.tagsStaus.forEach((element) => {
      if (!element) {
        this.tagsAll = false;
      }
    });
  }
  getCenter() {
    this.apiServices.getList('centers').pipe(takeUntil(this.destroy$)).subscribe(
      (data) => {
        this.centers = data.filter(
          (x: CenterList) => x.ItemId != 1);

        this.centers.forEach(element => {
          this.centerStatus.push(false);
        });
        this.filterChange();
      }
    );

  }

  route(product: Service) {
    this.apiRoute.setService(product);
    return "/service/" + product.ServiceCode
  }

  putVisitNo(itemId: string) {
    this.apiServices.putVisitingCount('Services', itemId).subscribe();
  }

  navigateUrl(product: Service) {

    //this.router.navigate(['mediacenter'])
    this.putVisitNo(product.serviceId.toString());
    this.router.navigateByUrl('/service/' + product.ServiceCode);

  }

  getStructureTags() {
    this.apiServices.getList('ServiceStructureTags').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceStructureTagsList[]) => {
        this.tags = data;
        data.forEach(element => {
          this.tagsStaus.push(true);
          this.selectedTags.push(element.ItemFields.ServiceTagName);
          this.tagsNumber.push(0);
        });
      }
    );
  }

  private getServiceByType(serviceSectorId: number) {
    //console.log(serviceSectorId);
    this.tagsStaus.forEach((element, index) => {
      if (element === true) {
        this.selectedTags.push(this.tags[index].ItemFields.ServiceTagName);
      }
    });


    //this.responseService.forEach(val => this.response.push(Object.assign({}, val)));

    this.responseService.forEach(obj => {
      if (!this.filterAll && this.centerId == (obj.ServiceCenterId)) {
        this.response.push(obj);
      }
      if (this.filterAll) {
        this.response.push(obj);
      }
    });

    //console.log(this.response);
    //Sort Array by visiting count
    this.response = this.response.sort((a, b) => b.serviceVisitCount - a.serviceVisitCount);
    //
    // this.response = this.response.filter(
    //   x =>
    //     (x.ServiceCenterId === 2 && this.filter.qiyas) ||
    //     (x.ServiceCenterId === 3 && this.filter.Ncaaa) ||
    //     (x.ServiceCenterId === 5 && this.filter.Ncsee) ||
    //     (x.ServiceCenterId === 4 && this.filter.Masar) ||
    //     (x.ServiceCenterId === 6 && this.filter.NCMAA) ||
    //     (x.ServiceCenterId === 7 && this.filter.nacademy) ||
    //     (x.ServiceCenterId === 1 && this.filter.etec)
    // );


    this.response.forEach(element => {
      this.centers.forEach((e, index) => {
        if (e.ItemId === element.ServiceCenterId) {
          this.centerStatus[index] = true;
        }
      });
    });

    //number count 
    this.response.forEach(element => {
      var index = this.tags.findIndex(x => x.ItemFields.ServiceTagName === element.serviceTagId!);
      if (index != -1) {
        this.tagsNumber[index]++;
      }
    });

    this.response = this.response.filter(el => {
      //console.log('hi' + this.selectedTags.indexOf(el.serviceTagId!));
      return this.selectedTags.indexOf(el.serviceTagId!) != -1;
    });
    this.showCount = this.response.length > 12 ? 12 : this.response.length;
  }

  changeCategry() {
    this.sectorFilter.personal = this.sectorAll;
    this.sectorFilter.business = this.sectorAll;
    this.filterChange();
  }

  allCategoryToggle() {
    if (this.sectorFilter.business && this.sectorFilter.personal) {
      this.sectorAll = true;
    }
    else {
      this.sectorAll = false;
    }
  }

  changeCenter() {
    this.filterAll = true;
    this.centerStatus.forEach((element, index) => {
      if (this.centerStatus[index]) {
        this.centerStatus[index] = this.filterAll;
      }

    });
    this.centerId = -1;

    this.filterChange();
  }

  changeTage() {
    this.tagsStaus.forEach((element, index) => {
      this.tagsStaus[index] = this.tagsAll;
    });
    this.filterChange();
  }

  /** Component events  */
  async ngOnInit() {

    this.getStructureTags();
    this.more = await this.apiRersources.getByKey("more");
    this.home = await this.apiRersources.getByKey("home");
    this.all = await this.apiRersources.getByKey("all");
    this.servicesof = await this.apiRersources.getByKey("servicesof");

    this.Individuals = await this.apiRersources.getByKey("Individuals");
    this.Institutions = await this.apiRersources.getByKey("Institutions");
    this.Centers = await this.apiRersources.getByKey("Centers");
    this.ServiceType = await this.apiRersources.getByKey("ServiceType");
    this.Category = await this.apiRersources.getByKey("Category");

    this.apiServices.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ListResponse[]) => {
        data.forEach(element => {
          if (this.tab === 'indviduals') {
            if (element.ItemFields.Title.toLowerCase() === 'single services') {

              this.PageTitle = element.ItemFields.MenuName;

              this.Title.setTitle(this.EtecFullName + " - " + this.PageTitle);
            }
          }
          if (this.tab === 'corporate') {
            if (element.ItemFields.Title.toLowerCase() === 'corporate services') {

              this.PageTitle = element.ItemFields.MenuName;

              this.Title.setTitle(this.EtecFullName + " - " + this.PageTitle);
            }
          }

        });
      }
    );
  }
}
