import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Subject, takeUntil } from 'rxjs';
import { Identityguidelinelist } from 'src/app/models/identityguidelinelist';
import { Identityguideline } from 'src/app/models/ResponseModel';
import { environment } from 'src/environments/environment';
import { SafeUrl } from '@angular/platform-browser';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-identityguideline',
  templateUrl: './identityguideline.component.html',
  styleUrls: ['./identityguideline.component.css']
})

export class IdentityguidelineComponent implements OnInit {
  logodownload : string ='';
  etecidguide: string ='';
  Identityguidelines: Identityguideline[] = [] ;
  LinkguidePDF : SafeUrl | undefined ='';
  ImageLinkguidePDF: SafeUrl | undefined ='' ;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private apiServices : ApiService, private  apiResources: ResourcesService) { }

/** Custom methods */
  getMediaCenterCategory(){
    this.apiServices.getListTopN('MediaCenterFiles',0,false,'MediaCenterCategory','دليل الهوية').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Identityguidelinelist[]) => {
       data.forEach(element => {
          var obj: Identityguideline = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            MediaCenterCategory:  element.ItemFields.MediaCenterCategory.LookupValue,
            MCFileName : element.ItemFields.MCFileName,
            MCFileLink: element.ItemFields.MCFileLink?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            MCFileThumbnails: element.ItemFields.MCFileThumbnails?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            MCFilesDetails : element.ItemFields.MCFilesDetails,
            isActive: element.ItemFields.IsActive1,
          };

          if(obj.MCFileName == 'AuthorityIdentityGuideimg'){
            this.ImageLinkguidePDF = obj.MCFileThumbnails;
          }
          if(obj.MCFileName == 'AuthorityIdentityGuide'){
            this.LinkguidePDF = obj.MCFileLink;
          }
          if(obj.MCFileName != 'AuthorityIdentityGuideimg' || obj.MCFileName != 'AuthorityIdentityGuideimg' )
          {
            this.Identityguidelines.push(obj);
          }
          
       });
 
      }
    );
  }

 /** Component events  */
  async ngOnInit(){

    this.logodownload = await this.apiResources.getByKey("logodownload");
    this.etecidguide = await this.apiResources.getByKey("etecidguide");
  
    this.getMediaCenterCategory();
    
  }

}
