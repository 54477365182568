import { Component, OnInit } from '@angular/core';
import { map, Subject, takeUntil } from 'rxjs';
import { NewsList } from 'src/app/models/NewsList';
import { ApiService } from 'src/app/services/api.service';
import {  News } from 'src/app/models/ResponseModel';

import { environment } from 'src/environments/environment';
 
import { Title } from '@angular/platform-browser';
import { ResourcesService } from 'src/app/services/resources.service';
import { LoadingService } from 'src/app/services/loading.service';
 
 

@Component({
  selector: 'app-allnews',
  templateUrl: './allnews.component.html',
  styleUrls: ['./allnews.component.css']
})

export class AllnewsComponent implements OnInit {

  allNews: News[] = [];
  newsSearch: News[] = [];
  showCount = 6;
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText: any = '';
  selectedValue: string;
  more: string = '';
  searchtxt: string = '';
  ascalph: string = '';
  descalph: string = '';
  ascdatealph: string = '';
  datedescalph: string = '';
  orderby: string = '';
  EtecFullName: string = '';
  servicesof:string = '';
  Total : number;
  LastID : number;
  IsAsc:boolean =true;
  _lang = '';

  constructor(private apiServices: ApiService, private Title: Title
    , private apiResources: ResourcesService
    ,private loading: LoadingService) { 
      if (!localStorage.getItem('lang')) {
        this._lang = 'ar';
      }
      else {
        this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
      }
    }

  /**Unused functions */
  updateTitle(title: string) {
    this.Title.setTitle(this.EtecFullName + " - " + title);
  }

  /** Custom methods */
  cloneObj(data: NewsList): News {
    this.LastID = data.ItemId;
    return ({
      newsId: data.ItemId,
      newsDate: data.ItemFields.NewsDate,
      newsTitle: data.ItemFields.NewsTitle,
      newsDetails: this.cutString(data.ItemFields.NewsDetails?.replace(/<[^>]*>/g, '')),
      newsUrl: data.ItemFields?.NewsFile?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
      NewsImage: data.ItemFields?.NewsImage?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
      NewsImageThumbnail: data.ItemFields?.NewsImageThumbnail?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
      NewsCode: data.ItemFields.NewsCode,
      isActive: data.ItemFields.IsActive1,
      NewsKeyword: ''
    } as News)
  }

  GetListMaxID(listname : string,firsttime:boolean,IsAsc:boolean)
  {
      this.apiServices.GetListMaxID('News').subscribe(data => {
        this.Total = data.TotalCount;
      
       
      this.getNews(data.Result,firsttime,IsAsc);
      });  
  }
  
  getNews(id:number,firsttime:boolean,IsAsc:boolean) {
    if(firsttime){
      this.allNews.splice(0,  this.allNews.length);
    }
    
    this.apiServices.getListTopN('news',6,false,'',null,id).pipe(takeUntil(this.destroy$), map((res: NewsList[]) => res.map(re => {
     
      return this.cloneObj(re)
    }))).subscribe(data => {
      this.allNews.push(...data);
      this.newsSearch = this.allNews;
      if(IsAsc){
        this.allNews.sort((a: any, b: any) => {
          return <any>new Date(b.newsDate) - <any>new Date(a.newsDate);
        });
      }else{
        this.allNews.sort((a: any, b: any) => {
          return <any>new Date(a.newsDate) - <any>new Date(b.newsDate);
        });
      }
    })
     
  }

getNews2(id:number,firsttime:boolean,IsAsc:boolean){
  if(firsttime){
    this.allNews.splice(0,  this.allNews.length);
  }

  this.apiServices.getListTopN('News',6,false,'',null,id).pipe(takeUntil(this.destroy$)).subscribe(
    (data: NewsList[]) => {
      data.forEach(element => {
        var obj: News = {
          newsId: element.ItemId,
          newsDate: element.ItemFields.NewsDate,
          newsTitle: element.ItemFields.NewsTitle,
          newsDetails: this.cutString(element.ItemFields.NewsDetails),
          newsUrl: element.ItemFields?.NewsFile?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          NewsImage: element.ItemFields?.NewsImage?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          NewsImageThumbnail: element.ItemFields?.NewsImageThumbnail?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          NewsCode: element.ItemFields.NewsCode,
          isActive: element.ItemFields.IsActive1,
          NewsKeyword: ''
        };
      
          this.LastID = obj.newsId;
          this.allNews.push(obj);
    
      });
      if(IsAsc){
      this.allNews.sort((a: any, b: any) => {
        return <any>new Date(b.newsDate) - <any>new Date(a.newsDate);
      });
    }else{
      this.allNews.sort((a: any, b: any) => {
        return <any>new Date(a.newsDate) - <any>new Date(b.newsDate);
      });
    }
    }
  );
}
  async fillResources() {
    this.more = await this.apiResources.getByKey("more");
    this.ascalph = await this.apiResources.getByKey("ascalph");
    this.descalph = await this.apiResources.getByKey("descalph");
    this.ascdatealph = await this.apiResources.getByKey("ascdatealph");
    this.datedescalph = await this.apiResources.getByKey("datedescalph");
    this.orderby = await this.apiResources.getByKey("orderby");
    this.searchtxt = await this.apiResources.getByKey("search");
    this.servicesof = await this.apiResources.getByKey("servicesof");
    

  }

  cutString(str: string) {
    var output = ' ';
    var data = str.split(' ');
    var i = 0;
    for (var i = 0; i < 25; i++) {

      output = output.concat(data[i], ' ');
    };
    return output;
  }



  showItems(SelectValue: any) {

    var optionSelected = SelectValue.target.value.split('-');

    if(optionSelected[1] != "true"){

      this.getNews(6,true,false);
      this.IsAsc = false;
    }if(optionSelected[1] == "true" ){

       
       this.GetListMaxID("",true,true);
       this.IsAsc = true;

    }
   
    //Get selected news
   /* this.apiServices.getListOrder('TempNews1', 0, optionSelected[1], optionSelected[0]).pipe(takeUntil(this.destroy$), map((res: NewsList[]) => res.map(re => {
      return this.cloneObj(re)
    }))).subscribe(data => {
      this.allNews = data;
      this.newsSearch = this.allNews;
      this.allNews.sort((a: any, b: any) => {
        return <any>new Date(b.newsDate) - <any>new Date(a.newsDate);
      });
    })*/
  }

  search() {
    this.showCount = 6;
    this.allNews = this.newsSearch;
    this.allNews = this.allNews.filter(x => x.newsTitle?.toUpperCase().includes(this.searchText.toUpperCase()));
  }

  /** Component events  */
  ngOnInit(): void {
     this.GetListMaxID('TempNews1',true,true);
    //this.getNews();
    this.fillResources();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

}
