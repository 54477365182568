<!--begin::vote-->
<section class="container my-5 mt-2">
    <div class="row justify-content-center">
        <div class="col-lg-10 col-12">
            <div class="card p-3 border-0 shadow-primary" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-offset="200">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="row">
                        <div
                            class="col-xl-4 col-12 d-flex flex-column align-items-center justify-content-center pt-5 pb-xl-5 pb-0">
                            <img src="assets/media/icons/vote-icon.svg" class="position-absolute zindex-1 mt-n4"
                                width="100" />
                            <h3 class="h3 mb-0 text-primary fw-semibold text-center" data-aos="fade-up" data-aos-duration="1200">
                                {{opinionpoll}}
                            </h3>
                            <p class="fs-sm fw-normal text-primary mt-3 mb-0" style="text-align: justify;" data-aos="fade-up" data-aos-duration="1500">
                                {{pollList?.ItemFields?.PollQuestions}}
                            </p>
                        </div>
                        <div class="col-xl-4 col-12 d-flex flex-column align-items-center py-5">
                            <div class=" w-100" *ngIf="ShowSurvayOptions">
                                <p *ngIf="submitted && f['answer'].errors" class="text-danger" i18n>{{selectananswer}}
                                </p>
                                <div class="form-check my-3" *ngFor="let option of pollList?.ItemFields?.PollChoices">
                                    <input class="form-check-input shadow-sm border-primary" type="radio" name="answer"
                                        value="{{option.LookupId}}" formControlName="answer"
                                        [ngClass]="{ 'is-invalid': submitted && f['answer'].errors }">
                                    <label class="form-check-label text-primary fs-sm"
                                        for="answers">{{option.LookupValue}}</label>
                                </div>
                            </div>
                            <div class=" w-100" *ngIf="ShowSurvayResult">
                                <div class="w-100 d-flex flex-column" *ngFor="let answer of Answers; let i = index">
                                    <div class="row my-2 justify-content-center align-items-center">
                                        <div class="col-3 px-1 text-center">
                                            <p class="text-primary fs-sm mb-0">{{answer.answer}} </p>
                                        </div>
                                        <div class="col-6 px-1">
                                            <div class="progress {{bgprogress[i]}}">
                                                <div class="progress-bar {{bgcolors[i]}}" role="progressbar"
                                                    [ngStyle]="{'width': answer.result+'%'}  " aria-valuenow="25"
                                                    aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-3 px-1">
                                            <p class="text-center fs-sm fw-semibold mb-0 {{colors[i]}}">
                                                {{answer.result}}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-12 offset-xl-1 d-flex flex-xl-column flex-row align-items-center justify-content-center pb-5 pt-xl-5 pt-0">
                            <button class="btn btn-primary w-xl-75 w-100 p-2 me-xl-0 me-3 fs-sm border-1" style="width:9rem;"
                                *ngIf="!isDone && !isshow" data-aos="flip-up" data-aos-duration="2200">{{vote}}</button>
                            <button class="btn btn-primary w-xl-75 w-100 p-2 fs-sm border-1" style="width:9rem;"
                                (click)="initialPoll()" *ngIf="isshow" data-aos="flip-up" data-aos-duration="2200">{{back}}</button>
                            <button class="btn btn-outline-secondary w-xl-75 w-100 p-2 mt-xl-3 fs-sm border-1" style="width:9rem;"
                                (click)="showResult()" data-aos="flip-up" data-aos-duration="2200"  *ngIf="!isDone && !isshow">{{voteresult}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<!-- <div class="card bg-white h-100 border-0 shadow rounded-5 mx-2 survay">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="card-header border-0">
            <h4 class="h4 my-3 text-primary text-center" i18n>
                <i class="bx bx-help-circle me-2 mb-0 h3"></i>
                {{opinionpoll}}
            </h4>
            <p class="fs-sm fw-semibold text-primary mb-0 text-center">
                {{pollList?.ItemFields?.PollQuestions}}
            </p>
        </div>
        <div class="card-body d-flex flex-column align-items-center">
            <div class=" w-100" *ngIf="ShowSurvayOptions">
                <p *ngIf="submitted && f['answer'].errors" class="text-danger" i18n>{{selectananswer}} </p>
                <div class="form-check my-3" *ngFor="let option of pollList?.ItemFields?.PollChoices">
                    <input class="form-check-input shadow-sm border-primary" type="radio" name="answer"
                        value="{{option.LookupId}}" formControlName="answer"
                        [ngClass]="{ 'is-invalid': submitted && f['answer'].errors }">
                    <label class="form-check-label text-primary fs-sm" for="answers">{{option.LookupValue}}</label>
                </div>
            </div>
            <div class=" w-100" *ngIf="ShowSurvayResult">
                <div class="w-100 d-flex flex-column" *ngFor="let answer of Answers; let i = index">
                    <div class="row my-2 justify-content-center align-items-center">
                        <div class="col-3 px-1 text-center">
                            <p class="text-primary fs-sm mb-0">{{answer.answer}} </p>
                        </div>
                        <div class="col-6 px-1">
                            <div class="progress {{bgprogress[i]}}">
                                <div class="progress-bar {{bgcolors[i]}}" role="progressbar"
                                    [ngStyle]="{'width': answer.result+'%'}  " aria-valuenow="25" aria-valuemin="0"
                                    aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-3 px-1">
                            <p class="text-center fs-sm fw-semibold mb-0 {{colors[i]}}">{{answer.result}}%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer py-3 border-0 rounded-5 d-flex align-items-center justify-content-around flex-wrap">
            <button class="btn btn-primary fs-sm border-2" style="min-width: 45%;" *ngIf="!isDone && !isshow">{{vote}}</button>
            <button class="btn btn-primary fs-sm border-2" style="min-width: 45%;" (click)="initialPoll()" *ngIf="isshow">{{back}}</button>
            <button class="btn btn-outline-secondary fs-sm" style="min-width: 45%;"(click)="showResult()">{{voteresult}}</button>
        </div>
    </form>
</div> -->