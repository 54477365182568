<!--begin::breadcrumb-->
<section class="bg-primary">
    <div class="container">
        <div class="row">
            <div class="col mt-4 mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" class="fs-sm fw-normal">الرئيسية</a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page">المنتجات والخدمات</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>
<!--begin::carousel-->
<!-- <app-carousel></app-carousel> -->
<!--begin::reports-->
<div class="container">
    <div class="row">
        <div class="col-12 my-5">
            <div class="card p-3 mb-5 border-1 shadow-sm">
                <div class="card-body">
                    <div class="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-4 justify-content-start">
                        <!-- reports cards -->
                        <div class="col">
                            <div class="card card-hover-primary shadow-sm border-0 h-100">
                                <div class="card-body p-5 text-center">
                                    <table>
                                        <tr>
                                            <td>
                                                <span class="AccredItationinstitutional-icon">
                                                    <img src="./assets/img/icon/NCAAE/AccredItationinstitutional.svg"
                                                        width="55" class="px-2" />
                                                </span>
                                            </td>
                                            <td>
                                                <h4 class="text-primary d-inline">

                                                    البحوث والتقارير
                                                </h4>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                                <div class="card-footer bg-primary text-white text-center">
                                    <hr />
                                    المزيد
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover-primary shadow-sm border-0 h-100">
                                <div class="card-body p-5 text-center">
                                    <table>
                                        <tr>
                                            <td>
                                                <span class="AccredItationinstitutional-icon">
                                                    <img src="./assets/img/icon/NCAAE/AccredItationinstitutional.svg"
                                                        width="55" class="px-2" />
                                                </span>
                                            </td>
                                            <td>
                                                <h4 class="text-primary d-inline">

                                                    فعاليات علمية
                                                </h4>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                                <div class="card-footer bg-primary text-white text-center">
                                    <hr />
                                    المزيد
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card card-hover-primary shadow-sm border-0 h-100">
                                <div class="card-body p-5 text-center">
                                    <table>
                                        <tr>
                                            <td>
                                                <span class="AccredItationinstitutional-icon">
                                                    <img src="./assets/img/icon/NCAAE/AccredItationinstitutional.svg"
                                                        width="55" class="px-2" />
                                                </span>
                                            </td>
                                            <td>
                                                <h4 class="text-primary d-inline">

                                                    البيانات المفتوحة </h4>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                                <div class="card-footer bg-primary text-white text-center">
                                    <hr />
                                    المزيد
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover-primary shadow-sm border-0 h-100">
                                <div class="card-body p-5 text-center">
                                    <table>
                                        <tr>
                                            <td>
                                                <span class="AccredItationinstitutional-icon">
                                                    <img src="./assets/img/icon/NCAAE/AccredItationinstitutional.svg"
                                                        width="55" class="px-2" />
                                                </span>
                                            </td>
                                            <td>
                                                <h4 class="text-primary d-inline">

                                                    منصة بيانات تقويم التعليم والتدريب </h4>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                                <div class="card-footer bg-primary text-white text-center">
                                    <hr />
                                    المزيد
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card card-hover-primary shadow-sm border-0 h-100">
                                <div class="card-body p-5 text-center">
                                    <table>
                                        <tr>
                                            <td>
                                                <span class="AccredItationinstitutional-icon">
                                                    <img src="./assets/img/icon/NCAAE/AccredItationinstitutional.svg"
                                                        width="55" class="px-2" />
                                                </span>
                                            </td>
                                            <td>
                                                <h4 class="text-primary d-inline">
                                                    مؤشر ترتيب
                                                </h4>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                                <div class="card-footer bg-primary text-white text-center">
                                    <hr />
                                    المزيد
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--begin::share-->
                    <app-shareicons></app-shareicons>

                </div>

            </div>

        </div>
    </div>
</div>