import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ListResponse } from 'src/app/models/ListResponse';
import { OpenDataCategoryList } from 'src/app/models/openDataCategoryList';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-open-data-all',
  templateUrl: './open-data-all.component.html',
  styleUrls: ['./open-data-all.component.css']
})
export class OpenDataAllComponent implements OnInit {

  _lang = '';
  more = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  data : OpenDataCategoryList [] = [];
  home = '';
  PageTitle = '';

  constructor(private actRoute: ActivatedRoute, private service: ApiService,private Title: Title,
     private apiResources: ResourcesService, private loading: LoadingService, private router: Router) {
    this.actRoute.params.subscribe(async params => {
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }
    });
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }
  

  /** Custom methods */
  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  getSiteMenu() {

    this.service.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ListResponse[]) => {
        data.forEach(element => {
          if (element.ItemFields.Title.toLowerCase() === 'opendata') {

            this.PageTitle = element.ItemFields.MenuName;
            this.Title.setTitle(  this.PageTitle);
          }

        });
      }
    );
  }
  
  getOpenDataCategory() {
    this.service.getList('OpenDataCategories').pipe(takeUntil(this.destroy$)).subscribe(
      (data: OpenDataCategoryList[]) => {
      this.data = data;
       
      });
  }

  navigateUrl(product: OpenDataCategoryList){
    this.router.navigateByUrl('/opendata/' + product.ItemFields?.Title);
  }
  async ngOnInit(): Promise<void> {
    this.more = await this.apiResources.getByKey('more');
    this.home = await this.apiResources.getByKey("home");
    
    this.getOpenDataCategory() ;
    this.getSiteMenu();
  }
}
