<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/ContactUs.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="h1 text-white fw-semibold m-0 position-relative zindex-5" [innerText]="PageTitle">
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page" [innerText]="PageTitle">
                            11 </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<!--begin::contact us-->
<div class="container">
    <div class="row">
        <!-- form -->
        <!-- <div class="col-xxl-8">
            <div class="mb-2 d-flex w-100">
                <article class="card position-relative p-0 h-100 w-100 border-0">
                    <div class="card-header w-100 border-0 p-0 m-0 mb-3">
                        <div class="d-flex flex-row align-items-center mb-2 bg-primary p-3 rounded-5">
                            <i class="bx bx-phone-call text-warning fs-3 me-2"></i>
                            <h3 class="fs-lg text-white m-0">نموذج الإتصال</h3>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="input-group mb-3 position-relative">
                            <label class="form-label fs-sm fw-semibold w-100" for="name">النوع</label>
                            <select class="form-select select" aria-label=" select ">
                                <option selected disabled>- اختر -</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>


                        <div class="input-group mb-3 position-relative">
                            <label class="form-label fs-sm fw-semibold w-100" for="name">الاسم</label>
                            <div class="position-relative w-100">
                                <input class="form-control ps-5" id="name" type="text" placeholder="الاسم" name="name">
                                <i
                                    class="bx bx-user fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                            </div>
                        </div>
                        <div class="input-group mb-3 position-relative">
                            <label class="form-label fs-sm fw-semibold w-100" for="email">البريد الإلكتروني</label>
                            <div class="position-relative w-100">
                                <input class="form-control ps-5" id="email" type="email" placeholder="البريد الإلكتروني"
                                    style="direction: rtl;">
                                <i
                                    class="bx bx-envelope fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                            </div>
                        </div>
                        <div class="input-group mb-3 position-relative">
                            <label class="form-label fs-sm fw-semibold w-100" for="textarea-input">نص الرسالة</label>
                            <textarea class="form-control fs-md" id="textarea-input" rows="4"></textarea>
                        </div>
                        <p class="fs-sm fw-normal text-primary overflow-hidden mb-0">

                        </p>
                    </div>
                </article>
            </div>
                <div class="d-flex justify-content-center w-100 mb-4">
                    <button class="btn btn-primary fs-sm border-2 w-lg-25 w-sm-50 w-100 ms-2">إرسال </button>
                    </div>
        </div> -->
        <!-- contact -->
         <!-- <div class="offset-xxl-1 col-xxl-3">
            <div class="row row-cols-xl-1 row-cols-lg-2 row-cols-md-2 row-cols-1"> -->
                <div class="col-12">
                    <div class="row row-cols-xl-4 row-cols-lg-2 row-cols-md-2 row-cols-1">
                <div class="col mb-3 d-flex">
                    <article class="card position-relative py-3 pb-0 border-1 shadow-primary pt-0 h-100 w-100"
                        style="border-radius: 0.938rem 0.938rem 0.5rem 0.5rem !important;">
                        <div class="btn btn-secondary p-3 w-100 pe-none rounded-5 d-flex flex-row">
                            <p class="text-white m-0 fs-lg">رقم التواصل</p>
                        </div>
                        <div class="card-body m-3 p-0 d-flex flex-column justify-content-start align-items-start">
                            <a href="tel:{{unfiedNumber}}"
                            class="fs-sm fw-normal text-primary overflow-hidden mb-2 text-decoration-none"
                            [innerText]="unfiedNumber"> </a>
                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-2">
                                توفر الخدمة: 
                                من يوم الأحد إلى الخميس - 8:00 ص إلى 6:00م 
                                
                            </p>
                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-0">
                                مدة الاستجابة:
                                فوري.                                
                            </p>
                        </div>
                    </article>
                </div>
                <div class="col mb-3 d-flex">
                    <article class="card position-relative py-3 pb-0 border-1 shadow-primary pt-0 h-100 w-100"
                        style="border-radius: 0.938rem 0.938rem 0.5rem 0.5rem !important;">
                        <div class="btn btn-secondary p-3 w-100 pe-none rounded-5 d-flex flex-row">
                            <p class="text-white m-0 fs-lg">منصة الدعم الموحدة</p>
                        </div>
                        <div class="card-body m-3 p-0 d-flex flex-column justify-content-start align-items-start">
                            <a href="{{mailAdderess}}" class="fs-sm fw-normal text-primary overflow-hidden mb-2 text-decoration-none"
                            [innerText]="mailAdderess" target="_blank"> </a>

                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-2">
                                توفر الخدمة: 
                                7/24                                
                            </p>
                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-0">
                                مدة الاستجابة: 
                                يومي عمل.                                                              
                            </p>
                        </div>
                    </article>
                </div>
                <div class="col mb-3 d-flex">
                    <article class="card position-relative py-3 pb-0 border-1 shadow-primary pt-0 h-100 w-100"
                        style="border-radius: 0.938rem 0.938rem 0.5rem 0.5rem !important;">
                        <div class="btn btn-secondary p-3 w-100 pe-none rounded-5 d-flex flex-row">
                            <p class="text-white m-0 fs-lg">البريد الإلكتروني</p>
                        </div>
                        <div class="card-body m-3 p-0 d-flex flex-column justify-content-start align-items-start">
                            <a mailto="cs@etec.gov.sa" class="fs-sm fw-normal text-primary overflow-hidden mb-2 text-decoration-none"
                             target="_blank" style="direction: ltr;">
                              cs@etec.gov.sa
                            </a>

                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-2">
                                توفر الخدمة: 
                                7/24                                
                            </p>
                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-0">
                                مدة الاستجابة: 
                                يومي عمل.                                                              
                            </p>
                        </div>
                    </article>
                </div>
                <div class="col mb-3 d-flex">
                    <article class="card position-relative py-3 pb-0 border-1 shadow-primary pt-0 h-100 w-100"
                        style="border-radius: 0.938rem 0.938rem 0.5rem 0.5rem !important;">
                        <div class="btn btn-secondary p-3 w-100 pe-none rounded-5 d-flex flex-row">
                            <p class="text-white m-0 fs-lg">منصة 

                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/></svg>

                            </p>
                        </div>
                        <div class="card-body m-3 p-0 d-flex flex-column justify-content-start align-items-start">
                            <a href="https://x.com/eteccare?s=11&t=cF2CXp2nEtfok1zNUv_i_g" class="fs-sm fw-normal text-primary overflow-hidden mb-2 text-decoration-none"
                             target="_blank" style="direction: ltr;">@eteccare</a>

                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-2">
                                توفر الخدمة: 
                                7/24                                
                            </p>
                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-0">
                                مدة الاستجابة:
                                6 ساعات عمل.
                            </p>
                        </div>
                    </article>
                </div>
            </div>
         </div>
    </div>




    <!-- text -->
    <!-- <div class="row justify-content-md-start justify-content-center mb-4">
        <div class="col-12">
            <div class="d-flex justify-content-center mb-3">
                <div class="px-3 py-0">
                    <span>
                        <img width="75" class="" src="assets/img/icon/contactUs-icon.svg" />
                    </span>
                </div>
                <div class="px-3 border-start border-primary d-flex align-items-center">
                    <h3 class="text-primary mb-0" [innerText]="looktoserveyou"></h3>
                </div>

            </div>
        </div>
    </div> -->
    <!-- map -->
    <!-- <div class="row mb-2">
        <div class="col-12" data-aos="fade-up" data-aos-duration="2000">
            <iframe class="d-block h-100"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1811.9614459369507!2d46.59609973224652!3d24.72952734952678!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1b6ba6fc41f7%3A0xb065eeeb470df366!2z2YfZitim2Kkg2KrZgtmI2YrZhSDYp9mE2KrYudmE2YrZhSDZiNin2YTYqtiv2LHZitioIEVkdWNhdGlvbiAmVHJhaW5pbmcgRXZhbHVhdGlvbiBjb21taXNzaW9u!5e0!3m2!1sen!2sua!4v1659437270435!5m2!1sen!2sua"
                style="border: 0; min-height: 350px;border-radius: 1.375rem;" allowfullscreen=""
                loading="lazy"></iframe>
        </div>
    </div> -->
    <!-- data -->
    <!-- <div class="row row-cols-1 row-cols-lg-2 g-4 text-center d-flex align-items-center my-4 py-3">
        <div class="col d-flex justify-content-start align-items-center" data-aos="fade-up" data-aos-duration="1500">
            <i class="bx bx-phone text-secondary fs-2"></i>
            <a href="tel:{{unfiedNumber}}"
                class="ps-3 fs-lg fw-normal text-primary mb-0 text-start text-decoration-none"
                [innerText]="unfiedNumber"> </a>
        </div>
        <div class="col d-flex justify-content-start align-items-center" data-aos="fade-up" data-aos-duration="1800">
            <i class="bx bx-link text-secondary fs-2"></i>
            <a href="{{mailAdderess}}" class="ps-3 fs-lg fw-normal text-primary mb-0 text-start text-decoration-none"
                [innerText]="mailAdderess" target="_blank">
            </a>
        </div>
        <div class="col d-flex justify-content-start align-items-center" data-aos="fade-up" data-aos-duration="2000">
            <i class="bx bx-stopwatch text-secondary fs-2"></i>
            <span class="ps-3 fs-base fw-normal text-primary mb-0 mb-n1 text-start" [innerText]="workinghours">

            </span>
        </div>
        <div class="col d-flex justify-content-start align-items-center" data-aos="fade-up" data-aos-duration="2200">
            <i class="bx bx-map text-secondary fs-2"></i>
            <a href="https://goo.gl/maps/22s6CgxfVu44e2Kc7"
                class="ps-3 fs-base fw-normal text-primary mb-0 mb-n1 text-start text-decoration-none"
                [innerText]="locationName"> </a>
        </div>
        <div class="col d-flex justify-content-start align-items-xl-center align-items-start" data-aos="fade-up" data-aos-duration="2200">
            <i class="bx bx-mobile-alt text-secondary fs-2"></i>
            <div class="d-flex flex-xl-row flex-column align-items-xl-center ms-2">
                <p class="ps-1 fs-base fw-normal text-primary mb-0 mb-n1 text-start text-decoration-none" [innerText]="BeneficiarySupport"></p>
                <div class="ms-xl-2 mt-xl-0 mt-3">
                    <a href="https://apps.apple.com/tt/app/%D8%AF%D8%B9%D9%85-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D9%81%D9%8A%D8%AF%D9%8A%D9%86/id1535379996" target="_blank">
                        <img src="assets/media/img/app-store.svg" alt="app store" style="height: 35px !important;">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.qiyas&hl=ar" target="_blank" class="ms-2">
                        <img src="assets/media/img/google-play.svg" alt="google play" style="height: 35px !important;">
                    </a>
                </div>

            </div>
        </div>
    </div> -->
    <!--begin::share-->
    <app-shareicons></app-shareicons>
</div>

<script type="text/javascript">

    $(document).ready(function () {
        $('.responsive').slick({
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });
</script>