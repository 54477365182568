import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Service } from '../models/ResponseModel';

@Injectable({
  providedIn: 'root'
})
export class RouteApiService {

  mediaCenterActive: string;
  service: Service;
  constructor(@Inject(DOCUMENT) private document:any) { }

  getMediaCenter() {
    return this.mediaCenterActive;
  }

  setMediaCenter(mediaCenterActiveTab: string ) {
    this.mediaCenterActive = mediaCenterActiveTab;
  }

  getService() {
    return this.service;
  }

  setService(service: Service) {
    this.service = service;
  }

  setCss(element:string, attribute:string, value:string) {
   // console.log('The value is :'+ value);
    this.document.querySelector(element).style[attribute] = value;
  }
}
