<!--begin::products-->

<div class="row d-flex justify-content-center mb-3">
    <div class="col-12 mb-3">
        <h5 class="fs-lg text-primary fw-normal mb-2"> {{accreditationCategory}}</h5>
        <div class="d-flex flex-row flex-wrap justify-content-start w-100">
            <div class="me-2 mb-2" *ngIf="HaveInstitutionsItems">
                <input type="radio" class="btn-check" checked value="{{Institutions}}" name="options"
                    id="option-institutional" (change)="onChangeCategory($event)" autocomplete="off">
                <label class="btn btn-outline-primary fs-sm border-1" for="option-institutional"
                    style="width: 7.5rem;">{{Institutions}}</label>
            </div>
            <div class="me-2 mb-2" *ngIf="HaveProgramsItems">
                <input type="radio" class="btn-check" value="{{programs}}" name="options" id="option-program"
                    (change)="onChangeCategory($event)" autocomplete="off">

                <label class="btn btn-outline-primary fs-sm border-1" for="option-program"
                    style="width: 7.5rem;">{{programs}}</label>


            </div>
        </div>
    </div>
    <div class="col-12 d-flex flex-lg-row flex-column align-items-center justify-content-start">
        <div class="form-floating mb-3 me-lg-3 w-lg-auto w-100">

            <app-combobox [list]="Organazation" [Title]="LabelTitle" [outSideIndex]="outsideIndex"
                (Item)="onChangeOrganazation($event)"></app-combobox>

        </div>
        <div class="form-floating mb-3 me-lg-3 w-lg-auto w-100">
            <app-combobox [list]="TypeAccrediation" [Title]="LabelTitle2" [outSideIndex]="outsideIndex"
                (Item)="onChangeTypeAccrediation($event)"></app-combobox>
        </div>
    </div>
</div>

<div *ngIf="selectedTabinstitution">
    <div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
        <div class="col d-flex justify-content-center"
            *ngFor="let item of SortItemsinstitution(viewItemsinstitution) | slice:0:showCount">

            <div class="card d-flex align-items-center border-0 h-100 w-100 shadow-primary">
                <div class="card-header h5 bg-primary text-center my-0 py-3 w-100 border-0 rounded-5">
                    <a href="{{item.Link}}" class="fs-sm text-white" target="_blank" *ngIf="item.Link">
                        {{item.OrganizationName}} </a>
                    <p *ngIf="!item.Link" class="fs-sm text-white">
                        {{item.OrganizationName}}
                    </p>
                </div>
                <div class="card-body card-striped text-center p-4 px-4 w-100">
                    <div class="d-flex align-items-center justify-content-between rounded-3 p-3 py-2">
                        <p href="#" class="text-primary d-flex align-items-center m-0 me-3 fs-sm">
                            {{OrganizationType}}
                        </p>
                        <p class="text-primary d-flex align-items-center m-0 fs-sm fw-normal">
                            {{item.OrgTypeName}}
                        </p>
                    </div>

                    <div class="d-flex align-items-center justify-content-between rounded-3 p-3 py-2 mt-2">
                        <p href="#" class="text-primary d-flex align-items-center m-0 me-3 fs-sm">
                            {{DateAccreditation}}
                        </p>
                        <p class="text-primary d-flex align-items-center m-0  fs-sm fw-normal">
                            {{item.AccFrom | date:"MM YYYY"}} - {{item.AccTo| date:"MM YYYY"}}

                        </p>
                    </div>

                    <div class="d-flex align-items-center justify-content-between rounded-3 p-3 py-2 mt-2"
                        [ngClass]="(item.AccTypeName ===full )?'full':( (item.AccTypeName ===expired) ? 'expire' : 'conditional' )">
                        <p href="#" class="text-primary d-flex align-items-center m-0 me-3 fs-sm">
                            {{Typeofaccreditation}}
                        </p>
                        <p class="text-primary d-flex align-items-center m-0  fs-sm fw-normal">
                            {{item.AccTypeName}}
                        </p>
                    </div>

                </div>
            </div>
        </div>



    </div>
    <div class="row">
        <div class="col d-flex justify-content-center mt-4 mb-2">
            <p class="m-0 text-primary fw-semibold fs-sm">{{showCount<
                    viewItemsinstitution.length?showCount:viewItemsinstitution?.length }} {{servicesof}}
                    {{viewItemsinstitution?.length}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-center mb-2">

            <input type="button" value="{{more}}" (click)="showCount= showCount + 6"
                *ngIf="showCount < viewItemsinstitution.length"
                class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
        </div>
    </div>
</div>
<div *ngIf="selectedTabPrograms">
    <div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
        <div class="col d-flex justify-content-center"
            *ngFor="let item of SortItemsprograms(viewItemsprograms) | slice:0:showCount ">
            <div class="card d-flex align-items-center border-0 h-100 w-100 shadow-primary">
                <div class="card-header h5 bg-primary text-white text-center my-0 py-3 w-100 border-0 rounded-5">
                    <a href="{{item.Link}}" class="fs-sm text-white" target="_blank" *ngIf="item.Link">
                        {{item.OrganizationName}} </a>
                    <p *ngIf="!item.Link" class="fs-sm text-white">
                        {{item.OrganizationName}}
                    </p>
                </div>
                <div class="card-body card-striped text-center p-4 px-4 w-100">
                    <div class="d-flex align-items-center justify-content-between rounded-3 p-3 py-2 fw-normal">
                        <p href="#" class="text-primary d-flex align-items-center m-0 me-3 fs-sm">
                            {{ProgramName}}
                        </p>
                        <p class="text-primary d-flex align-items-center m-0 fs-sm fw-normal">
                            {{item.OrganizationProgramName}}
                        </p>
                    </div>

                    <div class="d-flex align-items-center justify-content-between rounded-3 p-3 py-2 mt-2 fw-normal">
                        <p href="#" class="text-primary d-flex align-items-center m-0 me-3 fs-sm">
                            {{DegreeName}}
                        </p>
                        <p class="text-primary d-flex align-items-center m-0 fs-sm fw-normal">
                            {{item.OrganizationDegreeProgramName}}
                        </p>
                    </div>

                    <div class="d-flex align-items-center justify-content-between rounded-3 p-3 py-2 mt-2 fw-normal">
                        <p href="#" class="text-primary d-flex align-items-center m-0 me-3 fs-sm">
                            {{DateAccreditation}}
                        </p>
                        <p class="text-primary d-flex align-items-center m-0  fs-sm fw-normal">
                            {{item.AccFrom | date:"MM YYYY"}} - {{item.AccTo| date:"MM YYYY"}}

                        </p>
                    </div>

                    <div class="d-flex align-items-center justify-content-between rounded-3 p-3 py-2 mt-2 fw-normal">
                        <p href="#" class="text-primary d-flex align-items-center m-0 me-3 fs-sm">
                            {{OrganizationType}}
                        </p>
                        <p class="text-primary d-flex align-items-center m-0 fs-sm fw-normal">
                            {{item.OrgTypeName}}
                        </p>
                    </div>

                    <div class="d-flex align-items-center justify-content-between rounded-3 p-3 py-2 mt-2 fw-normal"
                        [ngClass]="(item.AccTypeName === full)?'full':( (item.AccTypeName === expired ) ? 'expire' : 'conditional' )">
                        <p href="#" class="text-primary d-flex align-items-center m-0 me-3 fs-sm">
                            {{Typeofaccreditation}}
                        </p>
                        <p class="text-primary d-flex align-items-center m-0 fs-sm fw-normal">
                            {{item.AccTypeName}}
                        </p>
                    </div>


                </div>
            </div>
        </div>

        
    </div>

    <div class="row">
        <div class="col d-flex justify-content-center mt-4 mb-2">
            <p class="m-0 text-primary fw-semibold fs-sm">{{showCount<
                    viewItemsprograms.length?showCount:viewItemsprograms.length }} {{servicesof}}
                    {{viewItemsprograms.length}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-center mb-2">

            <input type="button" value="{{more}}" (click)="showCount= showCount + 6"
                *ngIf="showCount < viewItemsprograms.length"
                class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
        </div>
    </div>
</div>

<!-- <app-shareicons></app-shareicons> -->