<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
        <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item fs-sm fw-normal"><a href="/home" [innerText]="home"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal"><a href="/mediacenter" [innerText]="PageTitle"> </a></li>
                        <li class="breadcrumb-item fs-sm fw-normal"><a href="/mediacenter/news" [innerText]="news"> </a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page">{{ newsDetails?.newsTitle }}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>
<!--begin::news-->
<div class="container" *ngIf="newsDetails">
    <!-- date & print -->
    <div class="row mb-3">
        <div class="col d-flex align-items-center justify-content-between">
            <span class="fs-sm fw-normal text-primary">
                <i class="bx bx-calendar fs-sm me-2"></i>{{ newsDetails?.newsDate |
                date:'yyyy-MM-dd'}}
            </span>
            <button href="javascript:void(0);" class="btn btn-outline-primary btn-icon border-0 ms-1 bg-infoLight" type="button"
                style="width: 3rem;height: 2.5rem;" printSectionId="dvToPrint"
                printTitle="{{ newsDetails?.newsTitle }}" [useExistingCss]="true"
                [printStyle]="{body:{'text-align':'right'}}" ngxPrint>
                <i class="bx bx-printer fs-4"></i>
            </button>
        </div>
    </div>
    <!-- News content -->
    <div class="row justify-content-md-start justify-content-center mb-4">
        <div class="col-12 d-flex flex-column justify-content-center pt-2 align-items-md-start align-items-center text-md-start text-center"
            id="dvToPrint">
            <!-- news title -->
            <h1 class="text-primary h3 mb-2">
                {{ newsDetails?.newsTitle }}
            </h1>
            <span class="bg-secondary mt-1 mb-5" style="height: 0.35rem;width: 3.5rem;"></span>
            <!-- news img -->
            <div class="mb-5 w-100 d-flex justify-content-center">
                <img src="{{newsDetails.NewsImage}}" class="rounded-5 shadow-primary img-fluid w-100" style="max-width: 400px;" alt="News Image">
                <!-- <div class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
                    style="height: 300px;border-radius: 1rem;"
                    [ngStyle]="{'background-image': 'url(' +'\'' +newsDetails?.NewsImage +'\'' +')'}">
                </div> -->
            </div>
            <!-- news content -->
            <p class="fs-sm lh-lg text-primary mb-4" style="text-align: justify;" [innerHTML]="newsDetails?.newsDetails">
            </p>
        </div>
    </div>
    <!--begin::share-->
    <app-shareicons></app-shareicons>
</div>
<!-- related news -->
<div class="container my-5" *ngIf="relatedNews.length>0">
    <div class="row justify-content-center">
        <h2 class="h2 text-center pb-4 mb-1 mt-2 mb-lg-3" [innerText]="relatednews"> </h2>
    </div>
    <div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
        <div class="col" *ngFor="let item of relatedNews| slice:0:3">
            <article class="card h-100 border-0 shadow rounded-5 mx-2">
                <div class="card-header my-3 pb-0 bg-white border-0 rounded-5 overflow-hidden"
                    style="min-height: 250px;height: 250px;">
                    <div class="position-relative">
                        <img src="{{item.NewsImageThumbnail}}" class="card-img-top rounded-9" alt="Image">
                    </div>
                </div>
                <div class="card-body pb-0 d-flex flex-column justify-content-center align-items-start">
                    <h4 class="h5 mb-3 overflow-hidden"
                        style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow:ellipsis;">
                        <!-- <a [routerLink]="['/news', item.NewsCode]" href="javascript:void(0);"
                            class="text-decoration-none text-primary lh-base">
                            {{item.newsTitle}}
                        </a> -->
                        <a href="{{_lang+'/news/'+item.NewsCode}}"
                        class="text-decoration-none text-primary lh-base">
                        {{item.newsTitle}}
                    </a>
                    </h4>
                    <p class="fs-xs fw-normal text-primary mb-0" style="text-align: justify;"
                        [innerHTML]="item.newsDetails+'...'">
                    </p>
                </div>
                <div class="card-footer py-3 border-0 rounded-5 d-flex align-items-center justify-content-between">
                    <span class="fs-sm fw-normal text-primary">
                        <i class="bx bx-calendar fs-md me-2"></i>{{item.newsDate | date: 'yyyy/MM/dd'}}
                    </span>
                    <!-- <a href="javascript:void(0);" class="btn btn-secondary fs-sm border-0"
                        [routerLink]="['/news', item.NewsCode]">{{more}}
                    </a> -->
                    <a href="{{_lang+'/news/'+item.NewsCode}}"  class="btn btn-secondary fs-sm border-0" >{{more}}
                    </a>
                </div>
            </article>
        </div>
    </div>
</div>