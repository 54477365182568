<div class="row justify-content-center">
    <div class="col-12 mb-5">
        <div class="input-group position-relative">
            <input type="text" class="form-control form-control-lg ps-5 fs-sm" placeholder="{{searchtxt}}"
                [(ngModel)]="searchText" (keypress)="search()" (keyup)="search()" (keydown)="search()">
            <i
                class="bx bx-search fs-sm text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>

        </div>
    </div>
</div>
<!-- Data 2 -->

<div class="row row-cols-lg-2 row-cols-1 g-4">
    <div class="col" *ngFor="let item of Publications|  slice:0:showCount; let i = index">
        <article class="card border-0 shadow-primary h-100">
            <div class="row g-0 justify-content-center align-items-center">
                <div class="col-xl-4 col-lg-5 col-12  ">
                    <div *ngIf="item.MCFileThumbnails"
                        class="position-relative bg-position-center bg-repeat-0 bg-size-cover m-3  "
                        style=" min-height: 13rem;border-radius: 0.7rem;"
                        [ngStyle]="{'background-image': 'url(' +'\'' +item.MCFileThumbnails?.replace(imptempPublicUrl,imgPublicUrl) +'\'' +')'}">
                    </div>
                    <div *ngIf="!item.MCFileThumbnails"
                        class="d-flex justify-content-center align-items-center m-3 bg-primary"
                        style=" min-height: 13rem;border-radius: 0.7rem;">
                        <i
                            class="bx bx-image bx-flip-horizontal text-white display-5 h-100"></i>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-7 col-12">
                    <div class="px-0 pt-sm-0 pb-0 m-3 d-flex flex-column justify-content-center h-100">
                                                <h3 class="fs-lg text-primary" [innerHTML]="item.MCFileName"></h3>
                                                <p class="mb-2 text-primary" [innerHTML]="item.MCFilesDetails" *ngIf="item.MCFilesDetails">
                                                </p>
                                                <div class="d-flex flex-column flex-lg-row justify-content-between text-primary mt-3">
                                                    <div class="d-flex flex-column flex-lg-row">
                                                        <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-lg-0 mb-2 d-flex justify-content-between"
                                                            target="_blank" *ngIf="item.MCFileLink" href="{{item.MCFileLink?.replace(imptempPublicUrl,imgPublicUrl)}}"
                                                            href="{{item.MCFileLink?.replace(imptempPublicUrl,imgPublicUrl)}}">
                                                            <span class="d-inline me-3">{{reportdownload}}</span>
                                                            <span class="bg-warning rounded-3 btn btn-icon">
                                                                <i class="bx bx-show-alt  fs-4"></i>
                                                            </span>
                                                        </a>
                                                
                                                        <!-- <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-lg-0 mb-2 d-flex justify-content-between"
                                                                                    target="_blank"
                                                                                    *ngIf="element.ItemFields?.RRLink && element.ItemFields?.IsRequiredPersonalInformation"
                                                                                    href="personalinfo/{{element.ItemFields.GUID}}/research">
                                                                                    <span class="d-inline me-3"> {{reportdownload}}</span>
                                                                                    <span class="bg-warning rounded-3 btn btn-icon">
                                                                                        <i class="bx bx-search  fs-4"></i>
                                                                                    </span>
                                                                                </a> -->
                                                
                                                        <!-- <a *ngIf="element.ItemFields?.CitationDetails"
                                                                                    (click)="open(content, element.ItemFields?.CitationDetails,' اقتباس التقرير')"
                                                                                    target="_blank"
                                                                                    class="btn btn-outline-primary px-2 py-1 mb-lg-0 mb-2 d-flex justify-content-between">
                                                                                    <span class="d-inline me-3"> {{reportquotes}}</span>
                                                                                    <span class="bg-warning rounded-3 btn btn-icon">
                                                                                        <i class="bx bxs-quote-alt-left fs-4"></i>
                                                                                    </span>
                                                                                </a> -->
                                                        <!-- <a *ngIf="element.ItemFields?.RRDetails"
                                                                                    (click)="open(content, element.ItemFields?.RRDetails,details)" target="_blank"
                                                                                    class="btn btn-outline-primary px-2 py-1 mb-lg-0 mb-2 d-flex justify-content-between">
                                                                                    <span class="d-inline me-3"> {{details}}</span>
                                                                                    <span class="bg-warning rounded-3 btn btn-icon">
                                                                                        <i class="bx bxs-file fs-4"></i>
                                                                                    </span>
                                                                                </a> -->
                                                
                                                    </div>
                                                    <!-- <div class="d-flex align-items-center h-100" *ngIf="element.ItemFields?.RRYear">
                                                                                <i class="bx bx-calendar fs-lg me-1"></i>
                                                                                <span class="fs-sm" [innerHTML]="element.ItemFields?.RRYear?.LookupValue"></span>
                                                                            </div> -->
                                                </div>
                    </div>
                    <!-- <div
                        class="card-body px-0 pt-sm-0 pb-0 m-3 d-flex flex-column justify-content-center align-items-lg-start align-items-center">

                    </div> -->
                </div>
            </div>
        </article>
    </div>
</div>






<!-- Data -->
<!-- <div class="row">
    <div class="col-12" *ngFor="let item of Publications|  slice:0:showCount; let i = index"> -->

        <!-- odd  -->
        <!-- <div *ngIf="i%2 == 0" class="card border-0 bg-primary shadow-sm overflow-hidden mb-3 p-3">
            <div class="row g-0 justify-content-between">
                <div *ngIf="item.MCFileThumbnails"
                    class="col-xl-2 col-lg-2 col-md-4 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 mb-lg-0 mb-3 d-block"
                    [ngStyle]="{'background-image': 'url(' +'\'' +item.MCFileThumbnails?.replace(imptempPublicUrl,imgPublicUrl) +'\'' +')'}"
                    style="min-height: 12rem;">
                </div>
                <div class="col-xl-8 col-lg-7 col-md-8 col-12">
                    <div class="pb-sm-2 px-md-4 px-sm-0 py-0 d-flex flex-column justify-content-center h-100">
                        <h3 class="h5 text-white" [innerHTML]="item.MCFileName">
                        </h3>
                        <p class="mb-4 text-white" [innerHTML]="item.MCFilesDetails" *ngIf="item.MCFilesDetails">
                        </p>

                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-center flex-column h-100">

                        <a class="text-start w-100 text-decoration-none py-4 px-3 rounded-3 position-relative bg-white text-primary mb-3 h6 mb-0"
                            style="padding-top: 15px !important;padding-bottom: 15px !important;" target="_blank"
                            *ngIf="item.MCFileLink" href="{{item.MCFileLink?.replace(imptempPublicUrl,imgPublicUrl)}}">
                            {{reportdownload}}
                            <div class="d-flex justify-content-center align-items-center bg-warning p-2 h-100 position-absolute top-50 end-0 translate-middle-y rounded-3"
                                style="width:55px;">
                                <i class="bx bx-cloud-download h2 text-white mb-0"></i>
                            </div>
                        </a>

                    </div>

                </div>
            </div>
        </div> -->
        <!-- end odd -->
        <!-- even  -->
        <!-- <div *ngIf="i%2 > 0" class="card border-0 bg-white shadow-primary overflow-hidden mb-3 p-3">
            <div class="row g-0 justify-content-between">
                <div *ngIf="item.MCFileThumbnails"
                    class="col-xl-2 col-lg-2 col-md-4 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 mb-lg-0 mb-3 d-block"
                    [ngStyle]="{'background-image': 'url(' +'\'' +item.MCFileThumbnails?.replace(imptempPublicUrl,imgPublicUrl) +'\'' +')'}"
                    style="min-height: 12rem;">
                </div>
                <div class="col-xl-8 col-lg-7 col-md-8 col-12">
                    <div class="pb-sm-2 px-md-4 px-sm-0 py-0 d-flex flex-column justify-content-center h-100">
                        <h3 class="h5 text-primary" [innerHTML]="item.MCFileName">
                        </h3>
                        <p class="mb-4 text-primary" [innerHTML]="item.MCFilesDetails" *ngIf="item.MCFilesDetails">
                        </p>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-center flex-column h-100">

                        <a class="text-start w-100 text-decoration-none py-4 px-3 rounded-3 position-relative bg-primary text-white mb-3 h6 mb-0"
                            style="padding-top: 15px !important;padding-bottom: 15px !important;" target="_blank"
                            *ngIf="item.MCFileLink" href="{{item.MCFileLink?.replace(imptempPublicUrl,imgPublicUrl)}}">
                            {{reportdownload}}
                            <div class="d-flex justify-content-center align-items-center bg-warning p-2 h-100 position-absolute top-50 end-0 translate-middle-y rounded-3"
                                style="width:55px;">
                                <i class="bx bx-cloud-download h2 text-white mb-0"></i>
                            </div>
                        </a>

                    </div>

                </div>
            </div>
        </div> -->
        <!-- end even -->


        <!-- <div class="card border-0 bg-primary shadow-sm overflow-hidden mb-3 p-3"
            style="border:1px solid var(--primaryColor) !important">
            <div class="row g-0">
</div> 
                <div class="col-xl-10 col-lg-9 col-12">
                    <div class="px-sm-4 py-0 pb-lg-0 pb-3 d-flex flex-column justify-content-center h-100">
                        <h3 class="h6 text-white mb-0">
                            {{item.MCFileName}}
                        </h3>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-center flex-column h-100">
                        <a class="text-start w-100 text-decoration-none py-4 px-3 rounded-3 position-relative bg-white text-primary h6 mb-0"
                            style="padding-top: 15px !important;padding-bottom: 15px !important;"
                            href="{{item.MCFileLink?.replace(imptempPublicUrl,imgPublicUrl)}}"
                            target="_blank">
                            حمل التقرير
                            <div class="d-flex justify-content-center align-items-center bg-warning p-2 h-100 position-absolute top-50 end-0 translate-middle-y rounded-3"
                                style="width:55px;">
                                <i class="bx bx-cloud-download h2 text-white mb-0"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div> -->
    <!-- </div>
</div> -->
<div class="row">
    <div class="col d-flex justify-content-center mt-4 mb-2">
        <p class="m-0 text-primary fw-semibold fs-sm">{{showCount< Publications.length?showCount:Publications.length
                }} {{servicesof}} {{Publications.length}}</p>
    </div>
</div>
<div class="row">
    <div class="col d-flex justify-content-center mb-2">

        <input type="button" value="{{more}}" (click)="showCount= showCount + 6" *ngIf="showCount < Publications.length"
            class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
            style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
    </div>
</div>