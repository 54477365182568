import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EServiceGuidList } from '../models/EServiceGuidList';
import { FAQList } from '../models/FAQList';
import { Service, ServiceGoal, ServiceContent, ServiceNote, ServiceAudience, ServiceSteps, ServicePrerequirements, ServiceDocuments } from '../models/ResponseModel';
import { ServiceMedialinksList } from '../models/ServiceMedialinksList';
import { ServiceStructureTagsResponse } from '../models/ServiceStructureTagsResponse';
import { ServiceTargetedSectorResponse } from '../models/ServiceTargetedSectorResponse';
import { ServicesTabs } from '../models/ServicesDto';
import { TestTimeTableList } from '../models/TestTimeTableList';
import { ApiService } from '../services/api.service';
import { ServiceAudnicesResponse } from '../models/ServiceAudnicesResponse';
import { ServiceContentResponse } from '../models/ServiceContentResponse';
import { ServiceDocumentsResponse } from '../models/ServiceDocumentsResponse';
import { ServiceGoalsResponse } from '../models/ServiceGoalsResponse';
import { ServiceNotesResponse } from '../models/ServiceNotesResponse';
import { ServicePrerequirementsResponse } from '../models/ServicePrerequirementsResponse';
import { ServiceStepsResponse } from '../models/ServiceStepsResponse';
import { Router } from '@angular/router';
import { ServicesResponse } from '../models/ServicesResponse';
import { ResourcesService } from '../services/resources.service';

@Component({
  selector: 'app-custom-service',
  templateUrl: './custom-service.component.html',
  styleUrls: ['./custom-service.component.css']
})
export class CustomServiceComponent implements OnInit {
  productId: string | null;
  Product: Service;
  Goals: ServiceGoal[] = [];
  GoalsToShow: ServiceGoal[] = [];
  Content: ServiceContent[] = [];
  Notes: ServiceNote[] = [];
  Audnices: ServiceAudience[] = [];
  Steps: ServiceSteps[] = [];
  Prerequirements: ServicePrerequirements[] = [];
  ServiceDoument: ServiceDocuments[] = [];
  ServiceDoumentResponse: ServiceDocuments[] = [];
  TestTimeTable: TestTimeTableList[] = [];
  ServiceTargetedSector: ServiceTargetedSectorResponse[] = [];
  ServiceStructureTag: ServiceStructureTagsResponse[] = [];
  ServiceVideo: ServiceMedialinksList[] = [];
  ServiceVideoResponse: ServiceMedialinksList[] = [];
  serviceAllTabs: ServicesTabs[]= [];
  centerCode: string;
  centerName: string;
  currentUrl: string;
  htmlString = '<a href="">Hello gowtham</a>';
  servicegoal = '';
  serviceaudience = '';
  servicesteps = '';
  servicedocuments = '';
  serviceprerequirments = '';
  serviceName = '';
  videos = '';
  timetable = '';
  faq = '';
  notes = '';
  close = '';
  PageTitle: string = '';
  home: string = '';
  EtecFullName = '';
  infoAboutService = '';
  searchText: any = '';
  searchText2: any = '';
  ServiceTargetedSectorsTitle = '';
  faqList: FAQList[] = [];
  tagImage: string;
  imptempPublicUrl = environment.imptempPublicUrl;
  imgPublicUrl = environment.imgPublicUrl;
  serviceSectorURL = '';
  url: SafeUrl | undefined;
  videoTitle: any;
  show: string = '';
  goalsActive = '';
  audnicesActive = '';
  serviceTargetedSectorActive = '';
  stepsActive = '';
  contentActive = '';
  notesActive = '';
  prerequirementsActive = '';
  serviceDoumentActive = '';
  serviceVideoActive = '';
  testTimeTableActive = '';
  faqListActive = '';
  relatedServiceGuidActive = '';

  goalsShow = false;
  audnicesShow = false;
  serviceTargetedSectorShow = false;
  stepsShow = false;
  contentShow = false;
  notesShow = false;
  prerequirementsShow = false;
  serviceDoumentShow = false;
  serviceVideoShow = false;
  testTimeTableShow = false;
  faqListShow = false;

  searchtxt = '';
  Category = '';
  all = '';
  more = '';
  relatedServiceGuide = '';
  showTabs = false;
  hasCategory = false;
  videoHasCategory = false;
  videoTagsAll = true;
  showLoading = false;
  videoTags: string[] = [];
  videoSelectedTags: string[] = [];
  videoTagsStatus: boolean[] = [];

  tagsAll = true;
  tags: string[] = [];
  selectedTags: string[] = [];
  tagsStatus: boolean[] = [];
  tagsNumber: number[] = [];
  ServiceGuid: EServiceGuidList[] = [];
  servicesof='';
  showCount =12;
  showVdCount =12;
  tabUrl = '';
  _lang = '';
  _location = '';
  _currentUrl = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  
  @Input() ServiceId : string;
  constructor(private apiServices: ApiService,private router: Router,
     public _sanitizer: DomSanitizer,private apiResources: ResourcesService) { }

  async ngOnInit(): Promise<void> {
    this.servicegoal = await this.apiResources.getByKey("servicegoal");
    this.serviceaudience = await this.apiResources.getByKey("serviceaudience");
    this.servicesteps = await this.apiResources.getByKey("servicesteps");
    this.servicedocuments = await this.apiResources.getByKey("servicedocuments");
    this.serviceprerequirments = await this.apiResources.getByKey("serviceprerequirments");
    this.home = await this.apiResources.getByKey("home");
    this.EtecFullName = await this.apiResources.getByKey("EtecFullName");
    this.infoAboutService = await this.apiResources.getByKey("InfoAboutService");
    this.ServiceTargetedSectorsTitle = await this.apiResources.getByKey("ServiceTargetedSectors");
    this.show = await this.apiResources.getByKey("show");
    this.videos = await this.apiResources.getByKey("videos");
    this.timetable = await this.apiResources.getByKey("timetable");
    this.faq = await this.apiResources.getByKey("faq");
    this.notes = await this.apiResources.getByKey("notes");
    this.close = await this.apiResources.getByKey("close");
    this.searchtxt = await this.apiResources.getByKey("search");
    this.Category = await this.apiResources.getByKey("Category");
    this.all = await this.apiResources.getByKey('all');
    this.more = await this.apiResources.getByKey('more');
    this.relatedServiceGuide = await this.apiResources.getByKey('RelatedServiceGuide');
    this.servicesof = await this.apiResources.getByKey("servicesof");
  }

  ngOnChanges(){
    console.log('Servcie' + this.ServiceId);
    this.productId = this.ServiceId;
    this.apiServices.getListTopN('Services', 1, true, "ServiceCode", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServicesResponse[]) => {
        if(data.length > 0 ){
          var obj: Service = {
            serviceTitle: data[0]?.ItemFields.Title,
            serviceId: data[0]?.ItemId,
            serviceName: data[0]?.ItemFields.ServiceName,
            ServiceCenterId: data[0]?.ItemFields.ServiceCenterId?.LookupId,
            ServiceCenterName: data[0]?.ItemFields.ServiceCenterId?.LookupValue,
            ServiceDescription: data[0]?.ItemFields.ServiceDescription!?.replace(/<[^>]*>/g, ''),
            serviceIconUrl: './assets/img/icon/exam/GAT.svg',
            serviceLevel: 0,
            serviceVisitCount: 0,
            serviceDetailsFileUrl: data[0]?.ItemFields.ServiceCategoryId_x003a_ServiceC?.LookupValue,
            serviceKeyword: data[0]?.ItemFields.ServiceKeyword,
            serviceSector: data[0]?.ItemFields.ServiceSectorId?.LookupValue,
            serviceSectorId: data[0]?.ItemFields.ServiceSectorId?.LookupId,
            ServiceCategory: data[0]?.ItemFields.ServiceCategoryId?.LookupValue,
            ServiceCategoryId: data[0]?.ItemFields.ServiceCategoryId?.LookupId,
            serviceRegistrationLink: data[0]?.ItemFields?.DetailsButtonLink?.Url,
            serviceRegistrationTitle: data[0]?.ItemFields?.DetailsButtonLabel,
            isActive: data[0]?.ItemFields.IsActive1,
            showServiceRegistration: data[0]?.ItemFields?.ShowDetailsButton,
            ShowDetailsButton: data[0]?.ItemFields?.ShowDetailsButton,
            RelatedEServiceGuide: data[0]?.ItemFields?.RelatedEServiceGuide!
          }

          this.getServiceStructureTags(data[0]?.ItemFields?.ServiceTagId?.LookupId);
          this.Product = obj;
          this.getServiceTabs(data[0].ItemId);
        }
       
      });
   
  }

  route(url: string) {
    this.router.navigateByUrl(this._lang +'/service/'+ this.productId+ '/'+ url);
  }

  getServiceTabs(serviceId: number){
    this.clearActiveTabs();
    this.apiServices
        .checkService(serviceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: ServicesTabs[]) => {
            this.serviceAllTabs = data.filter( m => m.IsExist );
            this.goalsShow = data.filter( m => m.IsExist  && m.ListName == 'ServiceGoals').length > 0;
            this.audnicesShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceAudiences').length > 0;
            this.serviceTargetedSectorShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceTargetedSectors').length > 0;
            this.stepsShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceSteps').length > 0;
            this.contentShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceContent').length > 0;
            this.notesShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceNotes').length > 0;
            this.prerequirementsShow =  data.filter( m => m.IsExist  && m.ListName == 'ServicePrerequirements').length > 0;
            this.serviceDoumentShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceDocuments').length > 0;
            this.serviceVideoShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceMediaLinks').length > 0;
            this.testTimeTableShow =  data.filter( m => m.IsExist  && m.ListName == 'TestTimeTable').length > 0;
            this.faqListShow =  data.filter( m => m.IsExist  && m.ListName == 'FAQ').length > 0;
            this.getActiveTabByDefault(data);
        });
  }

  getServiceStructureTags(tagId: number) {
    this.apiServices.getListById('ServiceStructureTags', tagId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceStructureTagsResponse) => {
        this.tagImage = data?.ItemFields?.TagImage?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl);
      });
  }

  clearActiveTabs(){
    this.goalsActive =  '';
    this.contentActive =  '';
    this.notesActive =  '';
    this.audnicesActive =  '';
    this.serviceTargetedSectorActive =  '';
    this.stepsActive =   '';
    this.prerequirementsActive = '';
    this.serviceDoumentActive =  '';
    this.testTimeTableActive = '';
    this.faqListActive =  '';
    this.serviceVideoActive = '';
    this.relatedServiceGuidActive = '';
    this.showTabs = true;
  }

  getActiveTabByDefault(data: ServicesTabs[]){

    if(data.length > 0){
      var first = data.filter(m => m.IsExist)[0];
      if(first.ListName == 'ServiceGoals' && first.IsExist){
        this.goalsActive = 'active';
        this.getServiceGoals();
      }else if (first.ListName == 'ServiceAudiences' && first.IsExist){
        this.audnicesActive = 'active';
        this.getServiceAudnices();
      }else if (first.ListName == 'ServiceTargetedSectors' && first.IsExist){
        this.serviceTargetedSectorActive = 'active';
        this.getServiceTargetedSector();
      }else if (first.ListName == 'ServiceSteps'  && first.IsExist){
        this.stepsActive = 'active';
        this.getServiceSteps();
      }else if  (first.ListName == 'ServiceContent' && first.IsExist){
        this.contentActive = 'active';
        this.getServiceContent();
      }else if  (first.ListName == 'ServiceNotes' && first.IsExist){
        this.notesActive = 'active';
        this.getServiceNotes();
      }else if (first.ListName == 'ServicePrerequirements' && first.IsExist){
        this.prerequirementsActive = 'active'; 
        this.getServicePrerequirements();
      }else if (first.ListName == 'ServiceDocuments' && first.IsExist){
        this.serviceDoumentActive = 'active';
        this.getServiceDoument();
      }else if (first.ListName == 'ServiceMediaLinks' && first.IsExist){
        this.serviceVideoActive = 'active';
        this.getVideos();
      }else if (first.ListName == 'TestTimeTable' && first.IsExist){
        this.testTimeTableActive = 'active';
        this.getTestTimetable();
      }else if (first.ListName == 'FAQ' && first.IsExist){
        this.faqListActive = 'active';
        this.getFaq();
      }
    }
  }

  
  getServiceGoals() {
    this._currentUrl = `${location.origin }/${this._lang}/service/${this.productId}/servicegoal'`;
    if(this.Goals.length == 0){
      this.apiServices.getListTopN('ServiceGoals', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
        (data: ServiceGoalsResponse[]) => {
          data.forEach(element => {
            var obj: ServiceGoal = {
              ServiceGoalNumber: element.ItemFields.ServiceGoalNumber,
              ServiceGoalName: element.ItemFields.ServiceGoalName
            };
  
            this.Goals.push(obj);
          });
          //this.getServiceContent();
        });
    }
  }

  getServiceContent() {
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/infoAboutService';
    if(this.Content.length == 0){
      this.apiServices.getListTopN('ServiceContent', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
        (data: ServiceContentResponse[]) => {
          data.forEach(element => {
            var obj: ServiceContent = {
              ServiceContentTitle: element.ItemFields.ServiceContentTitle,
              ServiceContentDetailes: element.ItemFields.ServiceContentDetails,
              ServiceContentIcon: element.ItemFields.ServiceContentIcon!
            };
  
            this.Content.push(obj);
          });
         // this.getServiceNotes();
        });
    }
  }

  getServiceNotes() {
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/notes';
    if(this.Notes.length == 0 ){
    this.apiServices.getListTopN('ServiceNotes', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceNotesResponse[]) => {

        data.forEach(element => {

          var obj: ServiceNote = {
            serviceNoteDetailes: element?.ItemFields?.ServiceNoteDetails?.replace(new RegExp('&#58;', 'gi'), ':').replace(new RegExp(environment.imptempPublicUrl, 'gi'), environment.imgPublicUrl),
            serviceNoteTitle: element.ItemFields.ServiceNoteTitle
          };

          this.Notes.push(obj);
        });
        //this.getServiceAudnices();
      });
    }
  }

  getServiceAudnices() {
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/serviceaudience';
    if(this.Audnices.length == 0){
    this.apiServices.getListTopN('ServiceAudiences', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceAudnicesResponse[]) => {
        data.forEach(element => {
          var obj: ServiceAudience = {
            serviceAudienceId: element.ItemFields.ServiceAudienceId,
            serviceAudienceName: element.ItemFields.ServiceAudienceName

          };

          this.Audnices.push(obj);
        });
       // this.getServiceSteps();
      });
    }
  }

  getServiceSteps() {
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/servicesteps';
    if(this.Steps.length == 0){
    this.apiServices.getListTopN('ServiceSteps', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceStepsResponse[]) => {
        data.forEach(element => {
          var obj: ServiceSteps = {
            ServiceStepsDetails: element.ItemFields.ServiceStepsDetails
          };

          this.Steps.push(obj);
        });
       // this.getServicePrerequirements();
      });
    }
  }

  getServicePrerequirements() {
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/serviceprerequirments';
    if(this.Prerequirements.length == 0){
    this.apiServices.getListTopN('ServicePrerequirements', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServicePrerequirementsResponse[]) => {
        data.forEach(element => {
          var obj: ServicePrerequirements = {
            ServicePrerequirementsDetails: element.ItemFields.ServicePrerequirementsDetails
          };

          this.Prerequirements.push(obj);
        });
       // this.getServiceDoument();
      });
    }
  }

  getServiceDoument() {
    //this.ServiceDoumentResponse = [];
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/servicedocuments';
    if(this.ServiceDoument.length == 0){
      this.ServiceDoument = [];
    this.apiServices.getListTopN('ServiceDocuments', 100, true, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceDocumentsResponse[]) => {

        data.forEach(element => {
          var obj: ServiceDocuments = {
            DocumentName: element.ItemFields.DocumentName_x0020_,
            ServiceDocument: element.ItemFields.ServiceDocument?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            ServiceDocumentWord: element.ItemFields.ServiceDocumentWord?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            ServiceDocumentCategory: element.ItemFields?.DocumentCategory?.LookupValue,
            ServiceDocumentOrder: element.ItemFields?.DocumentOrder
          };
          this.ServiceDoument.push(obj);

          if (obj.ServiceDocumentCategory) {
            if (obj.ServiceDocumentCategory != '') {
              this.hasCategory = true;
              if (this.tags.indexOf(obj.ServiceDocumentCategory) == -1) {
                this.tags.push(obj.ServiceDocumentCategory);
                this.tagsStatus.push(true);
              }
            }
          }
        });
        //this.ServiceDoument = this.ServiceDoument.sort((a, b) => a.ServiceDocumentOrder! - b.ServiceDocumentOrder!);
        this.ServiceDoumentResponse = [... this.ServiceDoument].sort((a, b) => a.ServiceDocumentOrder! - b.ServiceDocumentOrder!);
        //this.getTestTimetable();
      });
    }
  }

  getTestTimetable() {
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/timetable';
    if(this.TestTimeTable.length == 0){
    this.apiServices.getListTopN('TestTimeTable', 100, true, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: TestTimeTableList[]) => {
        this.TestTimeTable = data;
        //this.getServiceTargetedSector();
      });
    }
  }

  getServiceTargetedSector() {
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/servicetargetedsectors';
    if(this.ServiceTargetedSector.length == 0){
    this.apiServices.getListTopN('ServiceTargetedSectors', 100, true, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceTargetedSectorResponse[]) => {
        this.ServiceTargetedSector = data;
       // this.getFaq();
      });
    }
  }

  getFaq() {

    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/faq';
    if(this.faqList.length == 0){
    this.apiServices.getListOrder('faq', 0, true, 'OrderFAQ').pipe(takeUntil(this.destroy$)).subscribe((data: FAQList[]) => {
      this.faqList = data.filter((x) => x.ItemFields.ServiceId?.LookupId == this.Product.serviceId);
      this.faqList.forEach(element => {
        element.ItemFields.FAQQuestion = element.ItemFields.FAQQuestion?.replace(/<[^>]*>/g, '');
        element.ItemFields.FAQAnswer = element.ItemFields.FAQAnswer;
      });
      //this.getVideos();
    });
  }
  }

  getVideos() {
    
    this._currentUrl = location.origin+this._lang +'/service/'+ this.productId+ '/servicedocuments';
    if(this.ServiceVideo.length == 0){
    this.apiServices.getListTopN('ServiceMediaLinks', 100, true, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceMedialinksList[]) => {
        this.ServiceVideo = data;
        this.ServiceVideo = this.ServiceVideo.sort((a, b) => a.ItemFields?.ServiceMediaOrder! - b.ItemFields?.ServiceMediaOrder!);
        this.ServiceVideoResponse = [... this.ServiceVideo];
        data.forEach(element => {
          if (element.ItemFields.ServiceMediaCategory) {
            if (element.ItemFields.ServiceMediaCategory?.LookupValue != '') {
              this.videoHasCategory = true;
              if (this.videoTags.indexOf(element.ItemFields.ServiceMediaCategory?.LookupValue) == -1) {
                this.videoTags.push(element.ItemFields.ServiceMediaCategory?.LookupValue);
                this.videoTagsStatus.push(true);
              }
            }
          }
        });
      });
    }
  }

  open(item: ServiceMedialinksList) {
    this.showLoading = true;
    this.videoTitle = item.ItemFields.MediaLinkName;
    this.url = this.getVideo(item.ItemFields.MediaLink.Url);
  }

  getVideo(url: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url.replace(this.imptempPublicUrl, this.imgPublicUrl) + '?rel=0');
  }
  putVisitNo(itemId: string) {
    this.apiServices.putVisitingCount('EServiceGuide', itemId).subscribe();
  }

  navigateUrl(product: EServiceGuidList) {

    this.putVisitNo(product.ItemId.toString());
    this.router.navigateByUrl('/servicecatalogdetails/' + product.ItemFields?.Title);

  }
  
  changeTageVideo() {
    this.videoTagsStatus.forEach((element, index) => {
      this.videoTagsStatus[index] = this.videoTagsAll;
    });
    this.filterChangeVideo();
  }

  filterChangeVideo() {
    this.videoSelectedTags = [];

    this.videoTagsStatus.forEach((element, index) => {
      if (element === true) {
        this.videoSelectedTags.push(this.videoTags[index]);
      }
      //this.tagsNumber.push(0);
    });

    this.videoTagsAll = true;
    this.videoTagsStatus.forEach((element) => {
      if (!element) {
        this.videoTagsAll = false;
      }
    });


    this.getVideoTagsResult();
  }

  getVideoTagsResult() {

    if (!this.videoTagsAll && this.videoHasCategory) {
      this.ServiceVideoResponse = this.ServiceVideo.filter(x => this.videoSelectedTags.indexOf(x.ItemFields.ServiceMediaCategory?.LookupValue!) != -1);
    } else {
      this.ServiceVideoResponse = [... this.ServiceVideo];
    }

    if (this.searchText2 != '') {
      if (!this.videoTagsAll && this.videoHasCategory) {
        this.ServiceVideoResponse = this.ServiceVideo.filter(x => this.videoSelectedTags.indexOf(x.ItemFields.ServiceMediaCategory?.LookupValue!) != -1);
      }
      this.ServiceVideoResponse = this.ServiceVideoResponse.filter(x => x.ItemFields.MediaLinkName?.toUpperCase().includes(this.searchText2.toUpperCase())
      );
    }
  }

  changeTage() {
    this.tagsStatus.forEach((element, index) => {
      this.tagsStatus[index] = this.tagsAll;
    });
    this.filterChange();
  }

  filterChange() {
    this.selectedTags = [];

    this.tagsStatus.forEach((element, index) => {
      if (element === true) {
        this.selectedTags.push(this.tags[index]);
      }
      //this.tagsNumber.push(0);
    });

    this.tagsAll = true;
    this.tagsStatus.forEach((element) => {
      if (!element) {
        this.tagsAll = false;
      }
    });


    this.getTagsResult();
  }

  getTagsResult() {

    if (!this.tagsAll && this.hasCategory) {
      //this.ServiceDoumentResponse = this.ServiceDoument.filter(x => this.selectedTags.indexOf(x.ServiceDocumentCategory!) != -1 || !x.ServiceDocumentCategory);
      this.ServiceDoumentResponse = this.ServiceDoument.filter(x => this.selectedTags.indexOf(x.ServiceDocumentCategory!) != -1);

    } else {
      this.ServiceDoumentResponse = [... this.ServiceDoument];
    }

    if (this.searchText != '') {
      if (!this.tagsAll && this.hasCategory) {
        this.ServiceDoumentResponse = this.ServiceDoument.filter(x => this.selectedTags.indexOf(x.ServiceDocumentCategory!) != -1);
      }
      this.ServiceDoumentResponse = this.ServiceDoumentResponse.filter(x => x.DocumentName?.toUpperCase().includes(this.searchText.toUpperCase()));
    }
  }

  getThumbnail(url: any, size: string): string {
    var video, results;

    video = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
    // video   = (results === null) ? url : results[1];

    if (size === 'small') {
      return 'https://img.youtube.com/vi/' + video + '/2.jpg';
    }
    return 'https://img.youtube.com/vi/' + video + '/0.jpg';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();

  }
}
