import { Component, Input, OnInit } from '@angular/core';
import { CenterLeader, Service } from 'src/app/models/ResponseModel';
 

@Component({
  selector: 'app-center-leader',
  templateUrl: './center-leader.component.html',
  styleUrls: ['./center-leader.component.css']
})

export class CenterLeaderComponent {
  @Input() centerId!: number;
  @Input() cenetrLeader!: CenterLeader;
  isFound : boolean = false;

  constructor() { 
  }

}
