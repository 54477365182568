<article class="col-12 my-lg-4 my-3 mt-0" *ngIf="Audnices.length>0">
    <div class="card serviceaudience border-0 bg-primary shadow-sm overflow-hidden p-5 rounded-5">
        <div class="row g-4 justify-content-center align-items-center">
            <div class="col-lg-3 col-12 ">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <img src="assets/img/icon/PGATE-Target.svg" width="120" alt="Target Icon"
                        class="mb-2">
                    <div class="pt-3">
                        <h3 class="h3 mb-0 text-white fw-semibold text-center"
                            [innerText]="serviceaudience">
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-12">
                <div class="row row-cols-lg-2 row-cols-md-2 row-cols-1 g-4 justify-content-between">
                    <div class="col d-flex justify-content-center" *ngFor="let item of Audnices">
                        <div class="card border-0 w-100 rounded-5">
                            <div
                                class="card-body text-center p-4 px-4 w-100 d-flex justify-content-center align-items-center">
                                <p class="fs-sm text-primary m-0">
                                    {{item.serviceAudienceName}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>