<div w3-include-html="header.html"></div>
<!--begin::breadcrumb-->
<section class="bg-primary">
    <div class="container">
        <div class="row">
            <div class="col mt-4 mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
                        <li class="breadcrumb-item"><a href="#">المراكز</a></li>
                        <li class="breadcrumb-item"><a href="#">اعتماد</a></li>
                        <li class="breadcrumb-item"><a href="#">المنتجات</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            {{Product.serviceName}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<div class="container marketing">
    <div class="row featurette">
        <div class="col-md-7">
            <div class="vcenter-item h-100">
                <div class="row">
                    <div class="col-lg-12">
                        <h2 class="featurette-heading text-primary">
                            {{Product.serviceName}}
                        </h2>
                    </div>
                    <div class="col-lg-12 mt-5">
                        <p class="lead text-primary">
                            {{Product.ServiceDescription}}
                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div>
                            <button class="btn btn-primary d-inline">متطلبات الاعتماد </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <img class="featurette-image img-fluid mx-auto p-5" src="assets/img/icon/PGATE-Exam.svg">
        </div>
    </div>

    <div class="row  my-5">
        <div class="col-lg-12">
            <div class="card border-2 border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="d-flex flex-row">
                                <div class="w-50">
                                    <img class="featurette-image img-fluid mx-auto p-5"
                                        src="assets/img/icon/PGATE-Search.svg">
                                </div>
                                <div class="w-50">
                                    <div class="vcenter-item h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="featurette-heading text-primary"> أهداف </h2>
                                            </div>
                                            <div class="col-12">
                                                <h2 class="featurette-heading text-primary">

                                                    الاعتماد
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="d-flex flex-row  mt-5 mb-3">
                                <div class="w-50">
                                    <div class="d-flex flex-row" *ngFor="let item of Goals">
                                        <div>
                                            <span
                                                class="list-badge-circle badge bg-primary rounded-circle">{{item.ServiceGoalNumber}}</span>
                                        </div>
                                        <div>
                                            <h5 class="text-primary">

                                                {{item.ServiceGoalName}}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row my-5">
        <div class="col-lg-12">
            <div class="card bg-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="d-flex flex-column">
                                <div class="w-100 text-center">
                                    <img width="300" class="featurette-image img-fluid mx-auto p-5"
                                        src="assets/img/icon/PGATE-Target.svg">
                                </div>
                                <div class="w-100 text-center">
                                    <div class="col-12">
                                        <h2 class="featurette-heading text-white"> خطوات </h2>
                                    </div>
                                    <div class="col-12">
                                        <h2 class="featurette-heading text-white"> الاعتماد </h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">

                            <div class="row mt-5">
                                <div class="col-lg-4 my-2" *ngFor="let item of Steps">
                                    <div class="card h-100">
                                        <div class="card-body text-center p-lg-5">
                                            {{item.ServiceStepsDetails}}
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-4 my-2">
                                    <div class="card h-100">
                                        <div class="card-body text-center p-lg-5">
                                            التحقق من تأهل البرنامج الأكاديمي للاعتماد
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 my-2">
                                    <div class="card h-100">
                                        <div class="card-body text-center p-lg-5">
                                            تشكيل الفريق وتحديد موعد الزيارة
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 my-2">
                                    <div class="card h-100">
                                        <div class="card-body text-center p-lg-5">
                                            الزيارة الميدانية وإعداد تقرير المراجعة
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 my-2">
                                    <div class="card h-100">
                                        <div class="card-body text-center p-lg-5">
                                            صدور قرار الاعتماد

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 my-2">
                                    <div class="card h-100">
                                        <div class="card-body text-center p-lg-5">
                                            المتابعة الدورية للبرامج المعتمدة
                                        </div>
                                    </div>
                                </div> -->

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row  my-5">
        <div class="col-lg-12">
            <div class="card border-2 border-primary">
                <div class="card-body  p-lg-5">
                    <div class="row">
                        <div class="col-lg-12 text-center mt-2">
                            <h1 class="text-primary">
                                متطلبات الاعتماد
                            </h1>
                        </div>
                        <div class="col-lg-12 text-center mt-5">
                            <p class="text-primary">
                                {{Prerequirements.ServicePrerequirementsDetails}}
                            </p>
                        </div>

                        <div class="col-lg-12 text-center mt-5">
                            <a href="#" class="btn btn-infoBook">
                                متطلبات التأهل للتقدم بطلب الاعتماد

                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row  my-5">
        <div class="col-lg-12">
            <div class="card border-2 border-primary">
                <div class="card-body  p-lg-5">
                    <div class="row">
                        <div class="col-lg-12 text-center mt-2">
                            <h1 class="text-primary">
                                النماذج والمعايير
                            </h1>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-4">
                                <div class="card mx-5 h-100 dawonload-icon-card">
                                    <div class="card-body bg-primary text-white text-center py-5">
                                        <h3 class="fw-normal">
                                            نماذج الاعتماد
                                            البرامجي-الدراسات العليا
                                        </h3>

                                    </div>
                                    <img class="dawonload-icon" src="assets/img/icon/dawonload-icon.svg" />

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card mx-5 h-100 dawonload-icon-card">
                                    <div class="card-body bg-primary text-white text-center py-5">
                                        <h3 class="fw-normal">
                                            معايير الاعتماد لبرامج الدراسات العليا
                                        </h3>

                                    </div>
                                    <img class="dawonload-icon" src="assets/img/icon/dawonload-icon.svg" />

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card mx-5 h-100 dawonload-icon-card">
                                    <div class="card-body bg-primary text-white text-center py-5">
                                        <h3 class="fw-normal">
                                            وثيقة كفايات خريجي كليات الطب بالمملكة
                                        </h3>

                                    </div>
                                    <img class="dawonload-icon" src="assets/img/icon/dawonload-icon.svg" />

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>




</div>




<script type="text/javascript">

    $(document).ready(function () {
        $('.responsive').slick({
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });
</script>