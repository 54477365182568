import { Component, OnInit } from '@angular/core';
//import { MatSelectChange } from '@angular/material/select';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Infographiclist } from 'src/app/models/infographiclist';
 
import { Infographic } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';
// import lightGallery from 'lightgallery';
// import lgZoom from 'lightgallery/plugins/zoom';
// import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import { BeforeSlideDetail } from 'lightgallery/lg-events';

@Component({
  selector: 'app-infographic',
  templateUrl: './infographic.component.html',
  styleUrls: ['./infographic.component.css']
})
export class InfographicComponent implements OnInit {

  title = 'appBootstrap';
  closeResult: string;

  Infographics: Infographic[] = [] ;
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText:any = '';
  selectedValue: string;
  divid='1';
  InfographicsSearch: Infographic[] = [] ;
  more : string = '';
  showCount = 6;
  ascalph: string = '';
  descalph: string = '';
  ascdatealph: string = '';
  datedescalph: string = '';
  orderby: string = '';
  servicesof: string = '';
  show : string = '';
  searchtxt :  string = '';

  // name = "Angular ";
  // settings = {
  //   counter: false,
  //   plugins: [lgZoom]
  // };

  // onBeforeSlide = (detail: BeforeSlideDetail): void => {
  //   const { index, prevIndex } = detail;
  //   console.log(index, prevIndex);
  // };

    // start settings
    settings = {
      // counter: false,
      //actualSize: true,
      // infiniteZoom :true,
      // // height: '100%',
      // // width: '100%',
      // zoomFromOrigin: true,
      // zoomPosition: 'center',
      // scale:10,

      //loop: true,
      fourceAutoply: false,
      autoplay: false,
      thumbnail: false,
      speed: 400,

    //  showZoomInOutIcons : true,
      scale: 1,
      keypress: true,
      rotateLeft: false,
      flipHorizontal: false,
      flipVertical: false,
      mobileSettings: { controls: true},
      plugins: [lgFullscreen, lgZoom],
      licenseKey: 'D4194FDD-48924833-A54AECA3-D6F8E646',
    };
    
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
      const { index, prevIndex } = detail;
      console.log(index, prevIndex);
    };

  constructor(private apiServices: ApiService,private modalService: NgbModal, private apiResources: ResourcesService) {}

  /** Custom methods */
  open(content :any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {

      return 'by pressing ESC';

    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {

      return 'by clicking on a backdrop';

    } else {

      return  `with: ${reason}`;
    }

  }

  getMediaCenterFile(){

    this.apiServices.getListTopN('MediaCenterFiles',0,false,'MediaCenterCategory','الإنفوقرافيك').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Infographiclist[]) => {
       data.forEach(element => {
          var obj: Infographic = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            MediaCenterCategory:  element.ItemFields.MediaCenterCategory.LookupValue,
            MCFileName : element.ItemFields.MCFileName,
            MCFileLink: element.ItemFields.MCFileLink?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            MCFileThumbnails: element.ItemFields.MCFileThumbnails?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            MCFilesDetails : element.ItemFields.MCFilesDetails,
            isActive: element.ItemFields.IsActive1,
            MediaOrder: element.ItemFields?.MediaOrder
          };

          this.Infographics.push(obj);
       });
       this.InfographicsSearch = this.Infographics;
        this.Infographics.sort((a, b) => {
         // return <any>new Date(b.newsDate) - <any>new Date(a.newsDate);
         return b.MediaOrder - a.MediaOrder;
        });
      }
    );
  }

  showItems(SelectValue : any){

    var optionSelected = SelectValue.target.value.split('-');
   this.Infographics.splice(0,  this.Infographics.length);

    this.apiServices.getListOrder('MediaCenterFiles',0,optionSelected[1],optionSelected[0]).pipe(takeUntil(this.destroy$)).subscribe(
      (data: Infographiclist[]) => {
       data.forEach(element => {
          var obj: Infographic = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            MediaCenterCategory:  element.ItemFields.MediaCenterCategory.LookupValue,
            MCFileName : element.ItemFields.MCFileName,
            MCFileLink: element.ItemFields.MCFileLink?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            MCFileThumbnails: element.ItemFields.MCFileThumbnails?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            MCFilesDetails : element.ItemFields.MCFilesDetails,
            isActive: element.ItemFields.IsActive1,
            MediaOrder: element.ItemFields?.MediaOrder
          };
           
          this.Infographics.push(obj);
       });
       this.InfographicsSearch = this.Infographics;
      }
    );
  }

  // changeRatio(event: MatSelectChange) {
  //   var optionSelected = event.value.split('-');
  //  this.Infographics.splice(0,  this.Infographics.length);
  //   this.apiServices.getListTopN('MediaCenterFiles',0,optionSelected[1],optionSelected[0],'').pipe(takeUntil(this.destroy$)).subscribe(
  //     (data: Infographiclist[]) => {
  //      data.forEach(element => {
  //         var obj: Infographic = {
  //           ID: element.ItemFields.ID,
  //           Title: element.ItemFields.Title,
  //           MediaCenterCategory:  element.ItemFields.MediaCenterCategory.LookupValue,
  //           MCFileName : element.ItemFields.MCFileName,
  //           MCFileLink: element.ItemFields.MCFileLink?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
  //           MCFileThumbnails: element.ItemFields.MCFileThumbnails?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
  //           MCFilesDetails : element.ItemFields.MCFilesDetails,
  //           isActive: element.ItemFields.IsActive1,
  //         };
  //         this.Infographics.push(obj);
  //      });
  //      this.InfographicsSearch = this.Infographics;
  //     }
   
  //   );
  // }

  search(){
    this.showCount = 5;
    this.Infographics = this.InfographicsSearch;
    this.Infographics = this.Infographics.filter(x => x.MCFileName?.toUpperCase().includes(this.searchText.toUpperCase()));
  }

  /** Component events  */
  async ngOnInit() {
    
    this.getMediaCenterFile();

    this.more = await this.apiResources.getByKey("more");
    this.ascalph = await this.apiResources.getByKey("ascalph");
    this.descalph = await this.apiResources.getByKey("descalph");
    this.ascdatealph = await this.apiResources.getByKey("ascdatealph");
    this.datedescalph = await this.apiResources.getByKey("datedescalph");
    this.orderby = await this.apiResources.getByKey("orderby");
    this.servicesof = await this.apiResources.getByKey("servicesof");
    this.show = await this.apiResources.getByKey("show");
    this.searchtxt = await this.apiResources.getByKey("search");
 
  }

}
