import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-theme-menu',
  templateUrl: './theme-menu.component.html',
  styleUrls: ['./theme-menu.component.css']
})
export class ThemeMenuComponent implements OnInit {

  @Input() isEvent: boolean
  @Input() eventTheme: boolean
  @Input() eventName: string
  @Output() dark = new EventEmitter<number>()
  @Output() event = new EventEmitter<number>()

  constructor() { }

  ngOnInit(): void {
  } 

  ChangeToDark(){
    this.dark.emit();
  }

  ChangeToEvent(){
    this.event.emit();
  }


}
