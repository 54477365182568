<div class="row d-flex justify-content-center my-5 mt-3">
    <div class="col">
        <p class="fs-sm text-primary lh-base mb-0" style="text-align: justify;" [innerText]="center?.centerDescription">
        </p>
    </div>
</div>
<div  *ngFor="let item of CenterBDetailTypeList">
    <app-center-details [businssType]="item.ItemId" [centerId]="centerId" [typeName]="item.ItemFields.TypeDescription"></app-center-details>
</div>

<app-shareicons></app-shareicons>
