<main>
    <nav class="bg-primary" aria-label="breadcrumb">

        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
                <li class="breadcrumb-item"><a href="#">البيانات والتقارير  </a></li>
                <li class="breadcrumb-item"><a href="#">فعاليات علمية</a></li>

            </ol>
        </div>
    
    </nav>
    <div>
        <app-carousel></app-carousel>
      
        <div class="container ">
            <div class="tab-nav">
                <ul class="nav tab justify-content-center large-screen-tab">
                    <li class="nav-item ">
                        <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase()  === 'workshops'? 'active':''"  routerLink="/workshops/workshops"  id="home-tab" data-bs-toggle="tab" data-bs-target="#home" role="tab"
                            aria-controls="home" aria-selected="true">
                            <span class="tab-span">
                                <span class="tab-icon">
                                    <img width="50" src="./assets/img/icon/Report/Report_1.svg" />
                                </span>
                                <span class="tab-text">
                                    المحاضرات وورش العمل

                                    &nbsp;
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " [ngClass]="activeTab.toLocaleLowerCase()  === 'conferences'? 'active':''"  routerLink="/workshops/conferences" href="#" id="vision-tab" data-bs-toggle="tab" data-bs-target="#vision" role="tab"
                            aria-controls="vision" aria-selected="false">
                            <span class="tab-span">
                                <span class="tab-icon">
                                    <img width="50" src="./assets/img/icon/Report/Report_2.svg" />
                                </span>
            
                                <span class="tab-text">
                                    المؤتمرات
                                </span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            
            
            <div class="container ">
                <div class="card card-tab shadow-sm border-0">
                    <div class="card-body card-body-padding">
                        <div class="row small-screen-tab">
                            <div class="col-lg-12">
                                <div class="list-group mb-3 ">
                                    <a class="list-group-item list-group-item-action active p-3"  [ngClass]="activeTab.toLocaleLowerCase()  === 'workshops'? 'active':''"  routerLink="/workshops/workshops" id="home-tab" data-bs-toggle="tab"
                                        data-bs-target="#home" role="tab" aria-controls="home" aria-selected="true">
                                        <img width="30" src="./assets/img/icon/Report/Report_3.svg" />
                                        المحاضرات وورش العمل
                                    </a>
                                    <a class="list-group-item list-group-item-action  p-3 " [ngClass]="activeTab.toLocaleLowerCase()  === 'conferences'? 'active':''"  routerLink="/workshops/conferences" href="#" id="vision-tab"
                                        data-bs-toggle="tab" data-bs-target="#vision" role="tab" aria-controls="vision"
                                        aria-selected="false">
                                        <img width="30" src="assets/img/icon/about/mail.svg" />
                                        المؤتمرات
                                    </a>
                                </div>
                            </div>
                        </div>
           
                        <div class="tab-content tab-content-card" id="myTabContent">
                            <div class="tab-pane fade " [ngClass]="activeTab.toLocaleLowerCase()  === 'workshops'? 'active show':''" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="report-table">
                                <div class="table-item row">
                                    <div class="col-lg-2">
                                        <span>
                                            م
                                        </span>
                                    </div>
                                    <div class="col-lg-8">
                                        <span>
                                            ​عنوان المحاضرة​​​

                                        </span>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <span>
                                            الرابط
                                        </span>
                                    </div>
                                </div>
                                <div class="table-item row" *ngFor="let element of reasearchList; let i = index">
                                    <div class="col-lg-2">
                                        <span>
                                            {{ i }}
                                        </span>
                                    </div>
                                    <div class="col-lg-8">
                                        <span>
                                            {{element.ItemFields.RRName}​

                                        </span>
                                    </div>
                               
                                    <div class="col-lg-2">
                                        <a _ngcontent-wvc-c182="" href="{{element.ItemFields.RRLink?.Url}}" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                    </div>
                                </div>
                              
                                <!-- <div class="table-item row">
                                    <div class="col-lg-2">
                                        <span>
                                            2
                                        </span>
                                    </div>
                                    <div class="col-lg-8">
                                        <span>
                                            تأثير دراسات التقويم الدولية في السياسات وممارسات تحسين جودة التعليم ​

                                        </span>
                                    </div>
                               
                                    <div class="col-lg-2">
                                        <a _ngcontent-wvc-c182="" href="#" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                    </div>
                                </div>
                                <div class="table-item row">
                                    <div class="col-lg-2">
                                        <span>
                                            3
                                        </span>
                                    </div>
                                    <div class="col-lg-8">
                                        <span>
                                            تأثير دراسات التقويم الدولية في السياسات وممارسات تحسين جودة التعليم ​

                                        </span>
                                    </div>
                               
                                    <div class="col-lg-2">
                                        <a _ngcontent-wvc-c182="" href="#" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                    </div>
                                </div>

                                <div class="table-item row">
                                    <div class="col-lg-2">
                                        <span>
                                            4
                                        </span>
                                    </div>
                                    <div class="col-lg-8">
                                        <span>
                                            تأثير دراسات التقويم الدولية في السياسات وممارسات تحسين جودة التعليم ​

                                        </span>
                                    </div>
                               
                                    <div class="col-lg-2">
                                        <a _ngcontent-wvc-c182="" href="#" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="row mt-5">
                                <div class="col-lg-12 text-center">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-center">
    
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                            <li class="page-item"><a class="page-link" href="#">...</a></li>
                                            <li class="page-item"><a class="page-link" href="#">6</a></li>
                                            <li class="page-item"><a class="page-link" href="#">7</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> -->
                            </div>
                            <div class="tab-pane fade" [ngClass]="activeTab.toLocaleLowerCase()  === 'conferences'? 'active show':''" id="vision" role="tabpanel" aria-labelledby="vision-tab">
                                <div class="report-table">
                                    <div class="table-item row" >
                                        <div class="col-lg-2">
                                            <span>
                                                م
                                            </span>
                                        </div>
                                        <div class="col-lg-5">
                                            <span>
                                                ​عنوان المؤتمر​​​
    
                                            </span>
                                        </div>
                                        <div class="col-lg-3">
                                            <span>
                                                ​​​المقد​م
    
                                            </span>
                                        </div>
                                        <div class="col-lg-2">
                                            <span>
                                                الرابط
                                            </span>
                                        </div>
                                    </div>
                                    <div class="table-item row" *ngFor="let element of reasearchList; let i = index">
                                        <div class="col-lg-2">
                                            <span>
                                                {{i}}
                                            </span>
                                        </div>
                                        <div class="col-lg-5">
                                            <span>
                                                {{ element.ItemFields?.RRName}
                                            </span>
                                        </div>
                                        <div class="col-lg-3">
                                            <span>
                                                معالي.د. حسام زمان
                                                <br>
    إندرياس شلايشر​​
    
    
                                            </span>
                                        </div>
                                        <div class="col-lg-2">
                                            <a _ngcontent-wvc-c182="" href="{{element.ItemFields.RRLink?.Url}}" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                        </div>
                                    </div>
                                    <!-- <div class="table-item row">
                                        <div class="col-lg-2">
                                            <span>
                                                2
                                            </span>
                                        </div>
                                        <div class="col-lg-5">
                                            <span>
                                                التدشين وحلقة النقاش الافتراضية لاتفاقية الشراكة بين هيئة تقويم التعليم والتدريب ومنظمة التعاون الاقتصادي والتنمية لدعم الهيئة في تقويم التعليم وتحسين استعمال البيانات وتكامل مكونات التقويم والقياس.​
                                            </span>
                                        </div>
                                        <div class="col-lg-3">
                                            <span>
                                                معالي.د. حسام زمان
                                                <br>
    إندرياس شلايشر​​
    
    
                                            </span>
                                        </div>
                                        <div class="col-lg-2">
                                            <a _ngcontent-wvc-c182="" href="#" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                        </div>
                                    </div>
                                    <div class="table-item row">
                                        <div class="col-lg-2">
                                            <span>
                                                3
                                            </span>
                                        </div>
                                        <div class="col-lg-5">
                                            <span>
                                                التدشين وحلقة النقاش الافتراضية لاتفاقية الشراكة بين هيئة تقويم التعليم والتدريب ومنظمة التعاون الاقتصادي والتنمية لدعم الهيئة في تقويم التعليم وتحسين استعمال البيانات وتكامل مكونات التقويم والقياس.​
                                            </span>
                                        </div>
                                        <div class="col-lg-3">
                                            <span>
                                                معالي.د. حسام زمان
                                                <br>
    إندرياس شلايشر​​
    
    
                                            </span>
                                        </div>
                                        <div class="col-lg-2">
                                            <a _ngcontent-wvc-c182="" href="#" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                        </div>
                                    </div>
                                    <div class="table-item row">
                                        <div class="col-lg-2">
                                            <span>
                                                4
                                            </span>
                                        </div>
                                        <div class="col-lg-5">
                                            <span>
                                                التدشين وحلقة النقاش الافتراضية لاتفاقية الشراكة بين هيئة تقويم التعليم والتدريب ومنظمة التعاون الاقتصادي والتنمية لدعم الهيئة في تقويم التعليم وتحسين استعمال البيانات وتكامل مكونات التقويم والقياس.​
                                            </span>
                                        </div>
                                        <div class="col-lg-3">
                                            <span>
                                                معالي.د. حسام زمان
                                                <br>
    إندرياس شلايشر​​
    
    
                                            </span>
                                        </div>
                                        <div class="col-lg-2">
                                            <a _ngcontent-wvc-c182="" href="#" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                        </div>
                                    </div>
                                    <div class="table-item row">
                                        <div class="col-lg-2">
                                            <span>
                                                5
                                            </span>
                                        </div>
                                        <div class="col-lg-5">
                                            <span>
                                                التدشين وحلقة النقاش الافتراضية لاتفاقية الشراكة بين هيئة تقويم التعليم والتدريب ومنظمة التعاون الاقتصادي والتنمية لدعم الهيئة في تقويم التعليم وتحسين استعمال البيانات وتكامل مكونات التقويم والقياس.​
                                            </span>
                                        </div>
                                        <div class="col-lg-3">
                                            <span>
                                                معالي.د. حسام زمان
                                                <br>
    إندرياس شلايشر​​
    
    
                                            </span>
                                        </div>
                                        <div class="col-lg-2">
                                            <a _ngcontent-wvc-c182="" href="#" class="report-action"><img _ngcontent-wvc-c182="" src="assets/img/icon/report-view-icon.png"></a>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- <div class="row mt-5">
                                    <div class="col-lg-12 text-center">
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination justify-content-center">
        
                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                <li class="page-item"><a class="page-link" href="#">...</a></li>
                                                <li class="page-item"><a class="page-link" href="#">6</a></li>
                                                <li class="page-item"><a class="page-link" href="#">7</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> -->
                               
                            </div>
                        
                           
                        </div>
                          <app-shareicons></app-shareicons>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</main>