import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CenterLeaderList } from 'src/app/models/CenterLeaderList';
import { CenterList } from 'src/app/models/CenterList';
import { EServiceGuidList } from 'src/app/models/EServiceGuidList';
import { Center, CenterLeader, PublicContents, Service } from 'src/app/models/ResponseModel';
import { ServicesResponse } from 'src/app/models/ServicesResponse';
import { Publiccontentslist } from 'src/app/models/publiccontentslist';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-center',
  templateUrl: './dynamic-center.component.html',
  styleUrls: ['./dynamic-center.component.css']
})
export class DynamicCenterComponent implements OnInit {

  center: Center;
  centerName: string = '';
  centerId: number;
  PageTitle: string = '';
  home: string = '';
  services: string = '';
  aboutcenter: string = '';
  ceo: string = '';
  serviceGuid: string = '';
  centerCode: string = '';
  activeTab: string = '';
  CenterTabs: Publiccontentslist[] = [];
  centerLeader: CenterLeader;
  cenetrservices: Service[] = [];
  cenetrservicesCategory: Service[] = [];
  catalogCount = 0;
  activeSection = '';
  centerIconUrl = '';
  _lang = '';
  _dynamicUrl = location.href;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private centerService: ApiService, private apiResources: ResourcesService, private actRoute: ActivatedRoute, private loading: LoadingService) {
    this.actRoute.params.subscribe(async params => {
      this.centerCode = params["id"] == null ? 'QIYAS' : params["id"];
      this.activeTab = params["tab"] == null ? '' : params["tab"];
      this.activeSection = params["type"] == null ? '' : params["type"];

      this.getCenter(this.centerCode);
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }

    });

    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }


  getChildData(data: string){
    this._dynamicUrl = data;
  }
  async ngOnInit() {
    this.home = await this.apiResources.getByKey("home");
    this.services = await this.apiResources.getByKey("services");
    this.aboutcenter = await this.apiResources.getByKey("aboutcenter");
    this.ceo = await this.apiResources.getByKey("ceo");
    this.serviceGuid = await this.apiResources.getByKey("ServiceGuide");
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

  getCenter(centerCode: string) {
    this.centerService.GetListsItemsFilterd('centers', 1, false, 'CenterCode', centerCode).pipe(takeUntil(this.destroy$)).subscribe(
      (data: CenterList[]) => {
        var element = data[0];
        var obj: Center = {
          centerId: element.ItemId,
          centerName: element.ItemFields.CenterName,
          centerDescription: element.ItemFields.CenterDescription?.toString(),
          centerVision: element.ItemFields.CenterVision?.toString(),
          centerVisionIcon: '',
          centerMission: element.ItemFields.CenterMission?.toString(),
          centerMissionIcon: '',
          centerLogo: element.ItemFields.CenterLogo?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          CenterLogoIcon: element.ItemFields.CenterLogoIcon?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          CenterMainImageCover: element.ItemFields.CenterMainImageCover?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
          centerOrgnizationalStructureUrl: '',
          centerMainImageCoverUrl: '',
          centerParenetId: -1,
          isActive: element.ItemFields.IsActive1,
          CenterMoreDetails: ''
        };

        this.centerId = obj.centerId;
        this.PageTitle = obj.centerName;
        this.centerName = obj.centerName;
        this.center = obj;
        this.centerIconUrl = element.ItemFields.CenterLogoIcon?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl);
        this.getCenterTabs(obj.centerId);
        this.getLeaders(obj.centerId);
        this.getServiceCenter(obj.centerId);
        this.getServiceGuid(obj.centerName);
      }
    );
  }

  getCenterTabs(centerId: number) {
    this.centerService.GetListsItemsFilterd('PublicContents', 0, false, 'CenterName.LookupId', centerId.toString())
      .pipe(takeUntil(this.destroy$)).subscribe(
        (data: Publiccontentslist[]) => {
          this.CenterTabs = data.sort((a, b) => a.ItemFields.ContentOrder! - b.ItemFields.ContentOrder!);

        }
      );
  }

  private getLeaders(centerId: number) {
    this.centerService.GetListsItemsFilterd('centersleader', 0, false, 'CenterId.LookupId', centerId.toString()).pipe(takeUntil(this.destroy$)).subscribe(
      (data: CenterLeaderList[]) => {
        data.forEach(element => {

          var obj: CenterLeader = {
            centerLeaderId: element.ItemId,
            centerLeaderPosition: element.ItemFields.CentersLeaderPositionId?.LookupValue,
            centerLeaderPrefixId: element.ItemFields.CentersLeaderPrefixId?.LookupValue == null ? '' : element.ItemFields.CentersLeaderPrefixId?.LookupValue,
            centerLeaderName: element.ItemFields.CentersLeaderName,
            centerLeaderPortrait: element.ItemFields?.CentersLeaderPortrait.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            centerLeaderCurriculumVite: element.ItemFields.CentersLeaderCurriculumVitae,
            Sequence: element.ItemFields.Sequence,
            isActive: element.ItemFields.IsActive1,

          };
          this.centerLeader = obj;
        });
      }
    );
  }

  private getServiceCenter(centerId: number) {
    this.cenetrservices = [];
    this.centerService.getListsItemsFilterdOrderd('Services', 0, false, 'VisitingCount', 'ServiceCenterId', centerId, 'Eq', '', 'Eq', '', '').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServicesResponse[]) => {
        data.forEach(element => {
          var obj: Service = {
            GUID: element.ItemFields.GUID,
            serviceId: element.ItemFields.ID,
            ServiceCode: element.ItemFields?.ServiceCode,
            serviceName: element.ItemFields.ServiceName,
            ServiceDescription: element.ItemFields.ServiceDescription.replace(/<[^>]*>/g, ''),
            serviceIconUrl: './assets/img/icon/NCAAE/AccredItationinstitutional.svg',
            ServiceCenterId: element.ItemFields?.ServiceCenterId?.LookupId,
            serviceLevel: 0,
            serviceVisitCount: 0,
            serviceDetailsFileUrl: '',
            serviceKeyword: '',
            ServiceCategory: element.ItemFields?.ServiceCategoryId?.LookupValue!,
            ServiceCategoryId: element.ItemFields?.ServiceCategoryId?.LookupId!,
            isActive: false,
            serviceTitle: element.ItemFields?.ServiceCategoryId_x003a_ServiceC?.LookupValue!,
            ShowDetailsButton: element.ItemFields.ShowDetailsButton
          };
          this.cenetrservices.push(obj);
        });
  
        this.cenetrservicesCategory = [...new Map(this.cenetrservices.map(item => [item.ServiceCategoryId, item])).values()];
      });

  }

  getServiceGuid(centerName: string) {
    this.centerService
      .getListTopN('EServiceGuide', 1, true, "CenterName", centerName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: EServiceGuidList[]) => {
        this.catalogCount = data.length;

      });
  }
}
