<!-- Top fixed header -->
<nav class="navbar navbar-expand-xl p-0 navbar-light bg-primary position-relative top-0 d-xl-flex d-none tools-navbar"
    style="z-index:9999">
    <div class="container position-relative zindex-5">
        <div class="navbar-brand bg-danger text-white p-3 fs-sm fw-normal" *ngIf="!isProducation && demo">{{demo}}</div>
        <div class="navbar-brand text-white p-3 fs-sm fw-normal" *ngIf="isProducation">&nbsp;</div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown ms-1">
                    <!-- <a class="nav-link p-2 text-white fs-sm fw-normal" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        {{settings}}
                        <i class="bx bx-cog ms-2"></i>
                    </a> -->
                    <a *ngIf="_lang =='ar' || _lang == null" href="#" class="nav-link p-2 text-white fs-sm fw-normal"
                        href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                        lang="ar">
                        {{settings}}
                        <i class="bx bx-cog ms-2"></i>
                    </a>
                    <a *ngIf="_lang =='en' " href="#" class="nav-link p-2 text-white fs-sm fw-normal" href="#"
                        id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" lang="en">
                        {{settings}}
                        <i class="bx bx-cog ms-2"></i>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngIf="isEvent && !this.eventTheme"><a class="dropdown-item fs-sm fw-normal"
                                style="cursor: pointer;" (click)="ChangeToEvent()"><i
                                    class="bx bxs-palette text-center fs-base me-2" style="width:25px;"></i>
                                {{eventName}}</a></li>
                        <li *ngIf="isEvent && this.eventTheme"><a class="dropdown-item fs-sm fw-normal"
                                style="cursor: pointer;" (click)="ChangeToEvent()"><i
                                    class="bx bxs-palette text-center fs-base me-2" style="width:25px;"></i>الوضع
                                الافتراضي</a></li>
                        <li><a class="dropdown-item fs-sm fw-normal" style="cursor: pointer;" (click)="Change()"><i
                                    class="bx bx-show-alt text-center fs-base me-2"
                                    style="width:25px;"></i>{{darkMoode}}</a></li>
                        <li><a class="dropdown-item fs-sm fw-normal" style="cursor: pointer;"
                                (click)="increaseFont()"><span class="d-inline-block fs-base me-2"
                                    style="width:25px;">+A</span>تكبير الخط </a></li>
                        <li><a class="dropdown-item fs-sm fw-normal" style="cursor: pointer;"
                                (click)="normalFont()"><span class="d-inline-block fs-base text-center me-2"
                                    style="width:25px;">A̲</span>الوضع الطبيعي</a></li>
                        <li><a class="dropdown-item fs-sm fw-normal" style="cursor: pointer;"
                                (click)="decreaseFont()"><span class="d-inline-block fs-base me-2"
                                    style="width:20px;">-A</span> تصغير الخط</a></li>
                    </ul>
                </li>
                <li class="nav-item ms-1">
                    <a *ngIf="_lang =='ar' || _lang == null" href="{{url}}"
                        class="nav-link p-2 text-white fs-sm fw-normal" (click)="changeLang('en')" lang="en">
                        English
                        <i class="bx bx-world ms-2"></i>
                    </a>
                    <a *ngIf="_lang =='en' " href="{{url}}" class="nav-link p-2 text-white fs-sm fw-normal"
                        (click)="changeLang('ar')" lang="ar">
                        عربي
                        <i class="bx bx-world ms-2"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- Home header -->
<header class="header navbar navbar-light navbar-expand-xl position-absolute header-top is-home" [ngClass]="headerClass"
    *ngIf="isHome">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary zindex-4" style="opacity: 0.50;"></span>
    <div class="container-xxl px-3 position-relative zindex-5">
        <button type="button" class="navbar-toggler order-1" data-bs-toggle="offcanvas" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand d-none d-sm-block me-lg-0 me-0 order-lg-1 order-2" href="{{_lang+'/home'}}">
            <img src="assets/media/logos/etec-logoW.svg" alt="ETEC LOGO" width="235" height="35" loading="lazy">
        </a>
        <a class="navbar-brand d-sm-none me-lg-2 me-0 order-lg-1 order-2" href="{{_lang+'/home'}}">
            <img src="assets/media/logos/etec-logoW.svg" alt="ETEC LOGO" width="185" height="35" loading="lazy">
        </a>
        <div id="navbarNav" class="offcanvas offcanvas-start order-lg-2 order-1"
            aria-labelledby="offcanvasScrollingLabel" data-bs-scroll="true" data-bs-backdrop="false">
            <div class="offcanvas-header bg-primary justify-content-between" style="padding:0.75rem  0.75rem;">
                <button type="button" class="btn-close text-white fs-xl" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
                <h5 class="offcanvas-title text-white fw-semibold mx-4" id="offcanvasWithBackdropLabel">
                    <a href="{{_lang+'/home'}}">
                        <img src="assets/media/logos/etec-logoW.svg" alt="ETEC LOGO" width="185" height="35"
                            loading="lazy">
                    </a>
                </h5>
                <div class="d-flex justify-content-between">
                    <!-- dark mode icon on mobile menu -->
                    <!-- <button class="btn btn-icon btn-outline-light d-flex flex-column justify-content-center fs-xs"
                        data-bs-dismiss="offcanvas" aria-label="Close" (click)="Change()">
                        <i class="bx bx-show-alt text-center fs-base" style="width:25px;"></i>
                    </button> -->
                    <a *ngIf="_lang =='ar' || _lang == null" href="{{url}}"
                        class="btn btn-icon btn-outline-light d-flex flex-column justify-content-center fs-xs ms-2"
                        (click)="changeLang('en')" style="width: 4rem;">
                        <!-- <i class="bx bx-world"></i> -->
                        English
                    </a>
                    <a *ngIf="_lang =='en' " href="{{url}}"
                        class="btn btn-icon btn-outline-light d-flex flex-column  justify-content-center fs-xs ms-2"
                        (click)="changeLang('ar')" style="width: 4rem;">
                        <!-- <i class="bx bx-world"></i> -->
                        عربي
                    </a>
                </div>


            </div>
            <div class="offcanvas-body py-xl-4 px-xl-0 p-2 ">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0" *ngIf="response.length> 0">
                    <ng-container *ngFor="let item of response">
                        <ng-container *ngIf="!item.parentMenuId">
                            <li class="nav-item dropdown me-xl-1 me-0" *ngIf="ifHasChild(item)">
                                <a href="javascript:void(0);" class="nav-link dropdown-toggle p-2 px-3"
                                    data-bs-toggle="dropdown" aria-current="page">{{item.menuName}}</a>
                                <ul class="dropdown-menu border-0">
                                    <ng-container *ngFor="let subItem of response ">
                                        <ng-container *ngIf="item.menuId === subItem.parentMenuId">
                                            <li data-bs-toggle="offcanvas" data-bs-target="#navbarNav"
                                                aria-controls="navbarNav"
                                                (click)="putVisitNo(subItem.menuId.toString())">
                                                <a href="{{_lang+'/'+subItem.menULink}}"
                                                    class="dropdown-item rounded-3 "
                                                    *ngIf="subItem.menULink?.toString()?.indexOf('http') == -1">
                                                    <span class="border-3 border-start border-secondary me-2"></span>{{
                                                    subItem.menuName}}</a>

                                                <a href="{{subItem.menULink}}" class="dropdown-item rounded-3 "
                                                    *ngIf="subItem.menULink?.toString()?.indexOf('http') != -1">
                                                    <span class="border-3 border-start border-secondary me-2"></span>{{
                                                    subItem.menuName}}</a>
                                            </li>

                                        </ng-container>
                                    </ng-container>
                                </ul>
                            </li>
                            <li class="nav-item" data-bs-toggle="offcanvas" data-bs-target="#navbarNav"
                                aria-controls="navbarNav" *ngIf="!ifHasChild(item)">
                                <a href="{{_lang+'/'+item.menULink}}" class="nav-link p-2 px-3"
                                    *ngIf="item.menULink?.toString()?.indexOf('http') == -1">{{
                                    item.menuName}}</a>
                                <a href="{{item.menULink}}" class="nav-link p-2 px-3"
                                    *ngIf="item.menULink?.toString()?.indexOf('http') != -1">{{
                                    item.menuName}}</a>
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </div>
            <div class="offcanvas-footer bg-primary p-2 d-flex flex-column">
                <div class="d-flex justify-content-center align-items-center mb-3">

                    <!-- <button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close"
                        (click)="Change()">
                        <i class="bx bx-show-alt text-center fs-base" style="width:25px;"></i>
                        التباين
                    </button>
                    <button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close"
                        *ngIf="isEvent && !this.eventTheme" (click)="ChangeToEvent()">
                        <i class="bx bxs-palette text-center fs-base" style="width:25px;"></i>
                        {{eventName}}
                    </button>
                    <button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close"
                        *ngIf="isEvent && this.eventTheme" (click)="ChangeToEvent()">
                        <i class="bx bxs-palette text-center fs-base" style="width:25px;"></i>
                        الوضع الافتراضي
                    </button> -->
                    <app-theme-menu [isEvent]="isEvent" [eventName]="eventName" [eventTheme]="eventTheme"
                    (dark)="Change()" (event)="ChangeToEvent()"></app-theme-menu>

                    <!-- <a href="https://www.facebook.com/ETECKSA" class="btn btn-outline-light btn-icon me-1 rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-facebook"></i></a>
                    <a href="https://www.twitter.com/EtecKsa" class="btn btn-outline-light btn-icon me-1 rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-twitter"></i></a>
                    <a href="https://www.youtube.com/etecksa" class="btn btn-outline-light btn-icon me-1 rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-youtube"></i></a>
                    <a href="https://www.instagram.com/etecksa/" class="btn btn-outline-light btn-icon me-1 rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-instagram-alt"></i></a>
                    <a href="https://ae.linkedin.com/company/etec-ksa" class="btn btn-outline-light btn-icon rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-linkedin"></i></a> -->
                </div>
                <div class="text-center">
                    <p class="fs-xs text-white m-0 mb-2"> {{CopyRights}}</p>
                </div>
            </div>
        </div>
        <div class="navbar-toolbar d-flex align-items-center order-3">
            <div class="d-block d-xl-none px-0">
                <span class="bg-danger text-white p-2 fs-xs rounded-3" *ngIf="!isProducation && demo">{{demo}}</span>
                <span class="text-white p-2 fs-xs rounded-3" *ngIf="isProducation && demo">&nbsp;</span>
            </div>
        </div>
    </div>
</header>
<!-- Internal header -->
<header class="header navbar navbar-light bg-white navbar-expand-xl position-relative" style="z-index:999;"
    [ngClass]="headerClass" *ngIf="!isHome">
    <div class="container-xxl px-3 position-relative zindex-5">
        <button type="button" class="navbar-toggler order-1" data-bs-toggle="offcanvas" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand d-none d-sm-block me-lg-0 me-0 order-lg-1 order-2" href="{{_lang+'/home'}}">
            <!-- <img src="https://cdn.etec.gov.sa/etec-logo.svg" alt="ETEC LOGO" width="235" height="35" loading="lazy"> -->
            <img src="assets/media/logos/etec-logo.svg" alt="ETEC LOGO" width="235" height="35" loading="lazy">
        </a>
        <a class="navbar-brand d-sm-none me-lg-2 me-0 order-lg-1 order-2" href="{{_lang+'/home'}}">
            <!-- <img src="https://cdn.etec.gov.sa/etec-logo.svg" alt="ETEC LOGO" width="235" height="35" loading="lazy"> -->
            <img src="assets/media/logos/etec-logo.svg" alt="ETEC LOGO" width="185" height="35" loading="lazy">
        </a>
        <div id="navbarNav" class="offcanvas offcanvas-start order-lg-2 order-1"
            aria-labelledby="offcanvasScrollingLabel" data-bs-scroll="true" data-bs-backdrop="false">
            <div class="offcanvas-header bg-primary justify-content-between" style="padding:1.625rem  0.75rem;">
                <button type="button" class="btn-close text-white fs-xl" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
                <h5 class="offcanvas-title text-white fw-semibold mx-4" id="offcanvasWithBackdropLabel">
                    <a href="{{_lang+'/home'}}">
                        <img src="assets/media/logos/etec-logoW.svg" alt="ETEC LOGO" width="185" loading="lazy">
                    </a>
                </h5>
                <div class="d-flex justify-content-between">
                    <!-- dark mode icon on mobile menu -->
                    <!-- <button class="btn btn-icon btn-outline-light d-flex flex-column justify-content-center fs-xs"
                        data-bs-dismiss="offcanvas" aria-label="Close" (click)="Change()">
                        <i class="bx bx-show-alt text-center fs-base" style="width:25px;"></i>
                    </button> -->
                    <a *ngIf="_lang =='ar' || _lang == null" href="{{url}}"
                        class="btn btn-icon btn-outline-light d-flex flex-column justify-content-center fs-xs ms-2"
                        (click)="changeLang('en')" style="width: 4rem;">
                        <!-- <i class="bx bx-world"></i> -->
                        English
                    </a>
                    <a *ngIf="_lang =='en' " href="{{url}}"
                        class="btn btn-icon btn-outline-light d-flex flex-column  justify-content-center fs-xs  ms-2"
                        (click)="changeLang('ar')" style="width: 4rem;">
                        <!-- <i class="bx bx-world"></i> -->
                        عربي
                    </a>
                </div>
            </div>
            <div class="offcanvas-body py-xl-4 px-xl-0 p-2 ">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0" *ngIf="response.length> 0">
                    <ng-container *ngFor="let item of response">
                        <ng-container *ngIf="!item.parentMenuId">
                            <li class="nav-item dropdown me-xl-1 me-0" *ngIf="ifHasChild(item)">
                                <a href="javascript:void(0);" class="nav-link dropdown-toggle p-2 px-3"
                                    data-bs-toggle="dropdown" aria-current="page">{{item.menuName}}</a>
                                <ul class="dropdown-menu border-0">
                                    <ng-container *ngFor="let subItem of response ">
                                        <ng-container *ngIf="item.menuId === subItem.parentMenuId">
                                            <li data-bs-toggle="offcanvas" data-bs-target="#navbarNav"
                                                aria-controls="navbarNav"
                                                (click)="putVisitNo(subItem.menuId.toString())">
                                                <a href="{{_lang+'/'+subItem.menULink}}"
                                                    class="dropdown-item rounded-3 "
                                                    *ngIf="subItem.menULink?.toString()?.indexOf('http') == -1">
                                                    <span class="border-3 border-start border-secondary me-2"></span>{{
                                                    subItem.menuName}}</a>
                                                <a href="{{subItem.menULink}}" class="dropdown-item rounded-3 "
                                                    *ngIf="subItem.menULink?.toString()?.indexOf('http') != -1">
                                                    <span class="border-3 border-start border-secondary me-2"></span>{{
                                                    subItem.menuName}}</a>
                                            </li>

                                        </ng-container>
                                    </ng-container>
                                </ul>
                            </li>
                            <li class="nav-item" data-bs-toggle="offcanvas" data-bs-target="#navbarNav"
                                aria-controls="navbarNav" *ngIf="!ifHasChild(item)">
                                <a href="{{_lang+'/'+item.menULink}}" class="nav-link p-2 px-3"
                                    *ngIf="item.menULink?.toString()?.indexOf('http') == -1">{{
                                    item.menuName}}</a>
                                <a href="{{_lang+'/'+item.menULink}}" class="nav-link p-2 px-3"
                                    *ngIf="item.menULink?.toString()?.indexOf('http') != -1">{{
                                    item.menuName}}</a>
                            </li>
                        </ng-container>
                    </ng-container>
                </ul>
            </div>
            <div class="offcanvas-footer bg-primary p-2 d-flex flex-column">
                <div class="d-flex justify-content-center align-items-center mb-3">

                    <!-- <button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close"
                        (click)="Change()">
                        <i class="bx bx-show-alt text-center fs-base" style="width:25px;"></i>
                        التباين
                    </button>
                    <button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close"
                        *ngIf="isEvent && !this.eventTheme" (click)="ChangeToEvent()">
                        <i class="bx bxs-palette text-center fs-base" style="width:25px;"></i>
                        {{eventName}}
                    </button>
                    <button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close"
                        *ngIf="isEvent && this.eventTheme" (click)="ChangeToEvent()">
                        <i class="bx bxs-palette text-center fs-base" style="width:25px;"></i>
                        الوضع الافتراضي
                    </button> -->

                    <app-theme-menu [isEvent]="isEvent" [eventName]="eventName" [eventTheme]="eventTheme"
                        (dark)="Change()" (event)="ChangeToEvent()"></app-theme-menu>

                    <!-- <a href="https://www.facebook.com/ETECKSA" class="btn btn-outline-light btn-icon me-1 rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-facebook"></i></a>
                    <a href="https://www.twitter.com/EtecKsa" class="btn btn-outline-light btn-icon me-1 rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-twitter"></i></a>
                    <a href="https://www.youtube.com/etecksa" class="btn btn-outline-light btn-icon me-1 rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-youtube"></i></a>
                    <a href="https://www.instagram.com/etecksa/" class="btn btn-outline-light btn-icon me-1 rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-instagram-alt"></i></a>
                    <a href="https://ae.linkedin.com/company/etec-ksa" class="btn btn-outline-light btn-icon rounded-3"
                        type="button" style="width: 1.8rem;height: 1.8rem;" target="_blank"><i
                            class="bx bxl-linkedin"></i></a> -->
                </div>
                <div class="text-center">
                    <p class="fs-xs text-white m-0 mb-2"> {{CopyRights}}</p>
                </div>
            </div>
        </div>
        <div class="navbar-toolbar d-flex align-items-center order-3">
            <div class="d-block d-xl-none px-0">
                <span class="bg-danger text-white p-2 fs-xs rounded-3" *ngIf="!isProducation && demo">{{demo}}</span>
                <span class="text-white p-2 fs-xs rounded-3" *ngIf="isProducation && demo">&nbsp;</span>
            </div>
        </div>
    </div>
</header>
<!--begin::header Search-->
<div id="searchCollapse" class="collapse order-lg-2 position-fixed w-100 bg-light searchCollapse" style="z-index:9999;">
    <div class="container py-3 pt-lg-2 pb-lg-4">
        <div class="position-relative">
            <input type="search" class="form-control form-control-lg ps-5" placeholder="{{search}}...">
            <i class="bx bx-search fs-5 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3"></i>
        </div>
    </div>
</div>