import { Component, Input, OnInit } from '@angular/core';
import { ServiceTargetedSectorResponse } from 'src/app/models/ServiceTargetedSectorResponse';

@Component({
  selector: 'app-targeted-sector',
  templateUrl: './targeted-sector.component.html',
  styleUrls: ['./targeted-sector.component.css']
})
export class TargetedSectorComponent implements OnInit {

  @Input() ServiceTargetedSector: ServiceTargetedSectorResponse[] = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
