import { Component, Input, OnInit } from '@angular/core';
import { ServiceContent } from 'src/app/models/ResponseModel';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  @Input() Content: ServiceContent[] = []
  @Input() infoAboutService: string
  
  constructor() { }

  ngOnInit(): void {
  }

}
