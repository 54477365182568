import { Injectable } from '@angular/core';
import { ResourcesList } from '../models/ResourcesList';
import { firstValueFrom } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class ResourcesService {

  public _resources: ResourcesList[] = [];

  constructor(private apiServices: ApiService) { }

  async getByKey(resourceKey: string): Promise<string> {
    
    let searchResult = 'not set';

    if (!this.getWithExpiry()) {
      this.apiServices.logInAsync();
      const arr: ResourcesList[] = await firstValueFrom(this.apiServices.getList('Resources'));
      this._resources = arr;
      this.setWithExpiry(JSON.stringify(arr));  
    }
    else {
      const resourceStorage = localStorage.getItem('resources');
      this._resources = JSON.parse(resourceStorage!);
    }

    this._resources.forEach(element => {
      if (element.ItemFields.ResourceKey?.toLowerCase() == resourceKey.toLowerCase()) {
        searchResult = element.ItemFields.ResourceValue;
      }
    });

    return searchResult;
  }


  setWithExpiry(authResult: string) {
    const now = new Date();

    const item = {
      value: 'token',
      expiry: now.getTime() + 100000
    }

    localStorage.setItem('resources', authResult);
    localStorage.setItem('resources_expire_at', JSON.stringify(item));
  }

  getWithExpiry() {
    const itemStr = localStorage.getItem('resources_expire_at');
    if (!itemStr) {
      return false;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem('resources_expire_at');
      localStorage.removeItem('resources');

      return false;
    }

    return true;
  }

  clearResource(){
    localStorage.removeItem('resources_expire_at');
    localStorage.removeItem('resources');
  }

}