<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="{{_lang}}/home" [innerText]="home"></a></li>
                        <li class="breadcrumb-item active" aria-current="page" [innerText]="privacypolicy"></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<!--begin::card-->
<div class="container">
    <div class="row justify-content-md-start justify-content-center mb-2" *ngIf="!hasSubContent">
        <div class="col-12 text-primary" style="text-align: justify;" [innerHTML]="content"> </div>
    </div>
    <!-- Dynamic Nav tabs -->
    <app-dynamicnav [chilContentList]="chilContentList" [hasSubContent]="hasSubContent" [activeId]="activeId"
        *ngIf="hasSubContent && !hasCustomContent"></app-dynamicnav>

        <app-custom-service      [ServiceId]="_routeParameter"  *ngIf="hasSubContent && hasCustomContent" ></app-custom-service>
    <!-- Dynamic Document     -->
    <!-- <app-dynamic-document *ngIf="hasCustomContent && customDocumentList.length > 0"
                 [ContentTitle]="privacypolicy" [ContentDocuments]="customDocumentList"></app-dynamic-document> -->
    <!--begin::share-->
    <app-shareicons></app-shareicons>
</div>