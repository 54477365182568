import { Component, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-scroll-totop',
  templateUrl: './scroll-totop.component.html',
})
export class ScrollTotopComponent implements OnInit {
  constructor() {}
  ngOnInit() {
    fromEvent(window, 'scroll').pipe()
    .subscribe((_e: Event) => {
      this.scrollY = window.scrollY ;
    });
  }

  scrollY =0;
  isTop = window.scrollY === 0;

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

}
