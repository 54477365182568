<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item">
                            <a href="/home" class="fs-sm fw-normal">{{home}}</a>
                        </li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page">
                            {{serviceGuid}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>
<!--begin::services-->
<section class="container">
    <div class="row justify-content-center">
        <!-- title -->
        <div class="col-12 mb-4 d-flex flex-row align-items-center">
            <i class="bx bx-desktop text-primary fs-2 me-3"></i>
            <h3 class="fs-lg text-primary m-0" [innerHTML]="serviceGuid"></h3>
        </div>
        <!-- search bar -->
        <div class="col-12 mb-4">
            <div class="input-group position-relative">
                <input type="text" class="form-control form-control-lg py-3 ps-5" placeholder='{{searchtxt}}'
                    [(ngModel)]="searchText" (keypress)="search()" (keyup)="search()" (keydown)="search()">
                <i
                    class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
            </div>
        </div>
        <!-- filter -->
        <div class="col-12 my-1">
            <h5 class="fs-lg text-primary fw-semibold mb-2 d-none">{{Category}}</h5>
            <div class="d-flex flex-row flex-nowrap overflow-auto justify-content-start w-100">
                <div class="me-2 mb-2 nav nav-tabs-alt d-block position-relative products-checkbox">
                    <input type="checkbox" class="btn-check products-category" name="options2" id="option-all-tags"
                        autocomplete="off" [(ngModel)]="tagsAll" (ngModelChange)="changeTage()">
                    <label class="nav-link fs-sm" for="option-all-tags">{{all}}</label>
                </div>
                <div class="me-2 mb-2 nav nav-tabs-alt  d-block position-relative products-checkbox"
                    *ngFor="let element of tags; let i = index">
                    <input type="checkbox" class="btn-check products-category" name="options2" id="chbox{{i}}"
                        autocomplete="off" [(ngModel)]="tagsStaus[i]" (ngModelChange)="filterChange()">
                    <label class="nav-link fs-sm justify-content-center" for="chbox{{i}}">{{element.ItemFields?.ServiceTagName}} ({{tagsNumber[i]}})</label>
                </div>
            </div>
        </div>
        <!-- // -->
    </div>
    <!-- services cards -->
    <div class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 g-4 mt-3">
        <!--  -->
        <div class="col d-flex" *ngFor="let service of getSorted(responseService)| slice:0:showCount |filter:searchText">
            <article class="card position-relative py-4 border-1 shadow-primary h-100 w-100">
                <!-- <span class="btn btn-light pe-none rounded-0 position-absolute top-0 start-0 pb-2 ms-3"
                    style="border-radius: 0px 0px 15px 15px !important;">
                   {{service.ItemFields?.EServiceOrder }}
                </span>  -->
                <!-- <div class="card-header border-0 d-flex flex-column position-relative p-0 mt-5 mb-3 mx-3">-->
                <div class="card-header border-0 d-flex flex-column position-relative p-0 my-3 mx-3">
                    <div class="d-flex flex-row align-items-center mb-2" *ngIf="service.ItemFields.ServiceGuidIcon">
                        <img _ngcontent-cdf-c85="" style="width: 30px !important;" alt="img" class="img-fluid me-3" src="{{service.ItemFields.ServiceGuidIcon}}">
                        <span class="fs-sm text-primary" >{{service.ItemFields?.ServiceStructureTag?.LookupValue}}</span>
                    </div>
                    <h3 class="fs-lg text-primary m-0">{{service.ItemFields?.ServiceGuideTitle}}</h3>
                </div>
                <div class="card-body pt-0 pb-0 p-3">
                    <p class="fs-sm fw-normal text-primary overflow-hidden mb-0"
                        style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;"
                        [innerText]="service.ItemFields?.ServiceGuideDescription">
                    </p>
                </div>
                <div
                    class="card-footer p-0 m-0 mt-4 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                    <button class="btn btn-outline-primary p-2 fs-sm border-1" style="width: 7.5rem;"
                        (click)="navigateUrl(service)">{{more}}
                    </button>
                </div>
            </article>
        </div>
    </div>
    
    <div class="row">
        <div class="col d-flex justify-content-center mt-4 mb-2">
            <p class="m-0 text-primary fw-semibold fs-sm">{{showCount< response.length?showCount:response.length }}
                    {{servicesof}} {{response.length}}</p>
        </div>
    </div>
    <!-- show more -->
    <div class="row">
        <div class="col d-flex justify-content-center mb-2">

            <input type="button" value=" {{more}}" (click)="showCount= showCount + 8"
                *ngIf="showCount < response.length" class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
        </div>
    </div>
    <!-- // -->
</section>