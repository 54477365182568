import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Publicationslist } from 'src/app/models/publicationslist';
 
import { Publications } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publicationsandreleases',
  templateUrl: './publicationsandreleases.component.html',
  styleUrls: ['./publicationsandreleases.component.css']
})
export class PublicationsandreleasesComponent implements OnInit {

  showCount = 6;
  Publications: Publications[] = [] ;
  PublicationsSearch: Publications[] = [] ;
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText:any = '';
  selectedValue: string;
  more : string = '';
  reportdownload : string = '';
  reportsearch: string = '';
  servicesof: string = '';
  imptempPublicUrl = environment.imptempPublicUrl;
  imgPublicUrl = environment.imgPublicUrl;
  searchtxt: string = '';

  constructor(private apiServices: ApiService, private apiResources:ResourcesService) { }

  /** Custom methods */
  getMediaFiles(){
    this.apiServices.getListTopN('MediaCenterFiles',0,false,'MediaCenterCategory','النشرات والإصدارات').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Publicationslist[]) => {
        data.forEach(element => {
          var obj: Publications = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            MediaCenterCategory:  element.ItemFields.MediaCenterCategory.LookupValue,
            MCFileName : element.ItemFields.MCFileName,
            MCFileLink: element.ItemFields.MCFileLink?.Url,
            MCFileThumbnails: element.ItemFields.MCFileThumbnails?.Url,
            MCFilesDetails : element.ItemFields.MCFilesDetails,
            isActive: element.ItemFields.IsActive1,
            MediaOrder: element.ItemFields?.MediaOrder,
          };
          this.Publications.push(obj);
       });

       this.PublicationsSearch = this.Publications;

       this.Publications.sort((a: any, b: any) => {
        return b.MediaOrder - a.MediaOrder;
      });
      }
    );
  }

  search(){
    this.showCount = 5;
    this.Publications = this.PublicationsSearch;
    this.Publications = this.Publications.filter(x => x.MCFileName?.toUpperCase().includes(this.searchText.toUpperCase()));
  }

  /** Custom methods */
  async ngOnInit() {
    this.more = await this.apiResources.getByKey("more");
    this.reportdownload = await this.apiResources.getByKey("reportdownload");
    this.reportsearch = await this.apiResources.getByKey("reportsearch");
    this.servicesof = await this.apiResources.getByKey("servicesof");
    this.searchtxt = await this.apiResources.getByKey("search");

    this.getMediaFiles();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
     // Unsubscribe from the subject
    this.destroy$.unsubscribe();
   }

}
