import { Component, Input, OnInit } from '@angular/core';
import { ServiceNote } from 'src/app/models/ResponseModel';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

  @Input() Notes: ServiceNote[] = []

  constructor() { }

  ngOnInit(): void {
  }

}
