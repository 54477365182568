<article class="col-12 my-lg-4 my-3 mt-0" *ngIf="Prerequirements.length >0">
    <div class="card border-1 bg-white shadow-primary overflow-hidden rounded-5 mb-4 p-4 pb-5 px-5">
        <div class="row">
            <div class="col">
                <h3 class="h3 text-center mt-4 fw-semibold" [innerText]="serviceprerequirments"> </h3>
                <p class="text-primary fs-sm fw-normal" style="text-align: justify;">
                    <!-- {{Prerequirements?.ServicePrerequirementsDetails}} -->
                </p>
                <div class="row justify-content-start mt-4" *ngIf="Prerequirements?.length === 1">
                    <div class="col">
                        <p class="text-primary align-middle mb-0 fs-sm lh-base"
                            style="text-align: justify;"
                            [innerText]="Prerequirements[0].ServicePrerequirementsDetails"></p>
                    </div>
                </div>
                <div class="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-5 justify-content-start my-4"
                    *ngIf="Prerequirements.length > 1">
                    <div class="col" *ngFor="let item of Prerequirements ; index as i">

                        <div class="d-flex flex-row align-items-center">
                            <div class="me-2">
                                <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center"
                                    style="height: 2rem; width: 2rem;border-radius: 50%;">{{i+1}}
                                </div>
                            </div>
                            <p class="d-inline-block align-middle text-primary m-0 lh-base"
                                [innerText]="item.ServicePrerequirementsDetails">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>