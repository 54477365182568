import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EServiceGuidList } from 'src/app/models/EServiceGuidList';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { Title } from '@angular/platform-browser';
import { InputModality } from '@angular/cdk/a11y';
import { EServiceGuideChannelList } from 'src/app/models/EServiceGuideChannelList';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-servicecatalogdetails',
  templateUrl: './servicecatalogdetails.component.html',
  styleUrls: ['./servicecatalogdetails.component.css']
})
export class ServicecatalogdetailsComponent implements OnInit {
  home: string = '';
  Guid: string;
  serviceGuideDescription: string = '';
  serviceGuideRequirements: string = '';
  serviceGuideSupportChannel: string = '';
  serviceGuideSteps: string = '';
  serviceGuideTargetedGroup: string = '';
  serviceGuideAvailabilityTime: string = '';
  serviceGuideAlternativeChannels: string = '';
  serviceGuid: string = '';
  jumpToService: string = '';
  serviceGuideCompletionTime: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  response: EServiceGuidList;
  channel$: EServiceGuideChannelList[];
  defaultLink: string = '';

  constructor(private actRoute: ActivatedRoute, private apiServices: ApiService
    , private apiRersources: ResourcesService, private router: Router
    , private Title: Title,
    private loading: LoadingService) {

  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiRersources.clearResource();
  }

  async ngOnInit(): Promise<void> {
    this.home = await this.apiRersources.getByKey('home');
    this.serviceGuideDescription = await this.apiRersources.getByKey("serviceGuideDescription");
    this.serviceGuideRequirements = await this.apiRersources.getByKey("serviceGuideRequirements");
    this.serviceGuideSupportChannel = await this.apiRersources.getByKey("ServiceGuideChannel");
    this.serviceGuideSteps = await this.apiRersources.getByKey("ServiceGuideSteps");
    this.serviceGuideTargetedGroup = await this.apiRersources.getByKey("ServiceGuideTargetedGroup");
    this.serviceGuideAvailabilityTime = await this.apiRersources.getByKey("ServiceGuideAvailabilityTime");
    this.serviceGuideAlternativeChannels = await this.apiRersources.getByKey("ServiceGuideAlternativeChannels");
    this.serviceGuid = await this.apiRersources.getByKey("ServiceGuide");
    this.serviceGuideCompletionTime = await this.apiRersources.getByKey("ServiceGuideCompletionTime");
    this.jumpToService = await this.apiRersources.getByKey("JumpToService");

    this.actRoute.params.subscribe(async params => {
      this.Guid = params["id"];
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }

      this.getCatalog();
    });

    this.getServiceChannel();
  }

  getCatalog() {

    this.apiServices
      .getListTopN('EServiceGuide', 1, true, 'Title', this.Guid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: EServiceGuidList[]) => {
        if (data.length === 0) {
          this.router.navigateByUrl('/notfound')
        }

        this.response = data[0];
        this.Title.setTitle(data[0]?.ItemFields?.ServiceGuideTitle);
      });
  }

  getServiceChannel() {
    return this.apiServices
      .getList('EServiceGuideChannel')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => this.channel$ = data);
  }

  getChannelById(id: number) {
    return this.channel$.filter(m => m.ItemId == id)[0].ItemFields;
  }
}
