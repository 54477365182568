<!--begin::products-->
<div class="container">
    <!-- products cards -->
    <div class="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-4 products-cards"
        *ngIf="data.length>0">
        <div class="col d-flex" *ngFor="let item of data ">
            <article class="card py-4 border-1 shadow-primary h-100 w-100">
                <!-- <div
                    class="card-header border-0 px-2 d-flex justify-content-center align-items-center position-relative mb-4">
                    <div class="position-absolute rounded-circle bg-infoLight"
                        style="width: 75px;height: 70px;z-index: 99;">
                    </div>
                    
                </div> -->
                <div class="card-body mx-3 mb-4 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h4 class="fs-lg fw-semibold mb-0 text-primary  text-center overflow-hidden" placement="bottom"
                        style="text-align: justify;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;cursor: context-menu;">
                        {{item.ItemFields?.Title}}
                    </h4>

                    <!-- <ng-template #tipContent>
                        <div [innerHTML]="item.ItemFields?.Description"></div>
                    </ng-template> -->

                    <p class="fs-sm fw-normal text-primary overflow-hidden mt-3 mb-0" placement="bottom"
                        style="display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;"
                        [innerHTML]="item.ItemFields?.Description"></p>
                </div>
                <div
                    class="card-footer p-0 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                    <button class="btn btn-outline-primary p-2 fs-sm border-1" style="width:7.5rem;"
                        (click)="navigateUrl(item);">{{more}}
                    </button>
                </div>
            </article>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="card bg-infoLight border-0 shadow-none h-100 w-100 p-2 d-flex flex-row justify-content-center align-items-center">
                <p class="me-3 mb-0 text-primary">هل استفدت من مجموعة البيانات البيانات المقدمة؟</p>
                <a href="https://survey.etec.sa/s/0a250d18?isPre=True" target="_blank" class="btn btn-secondary p-2 px-3 fs-sm">
                    شاركنا رأيك
            </a>
            </div>
        </div>
    </div>
</div>