<div class="row d-flex justify-content-center mt-5" *ngIf="ResponsbilityETEC.length > 0">
    <div class="col">
        <div class="d-flex align-items-center">
            <div class="text-nowrap me-3">
                <h2 class="fs-lg fw-semibold d-inline-block align-middle text-primary mb-0" [innerText]="typeName"></h2>
            </div>
        </div>
    </div>
</div>
<div class="row justify-content-start my-5 mt-0" *ngIf="ResponsbilityETEC?.length === 1">
    <div class="col">
        <p class="mt-2 fs-sm" [innerHTML]="ResponsbilityETEC[0].ItemFields.BusinessDetail"></p>
    </div>
</div>
<div class="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-4 justify-content-start mb-5 mt-0"
    *ngIf="ResponsbilityETEC.length > 1">
    <div class="col" *ngFor="let item of ResponsbilityETEC ; index as i">
        <div class="d-flex flex-row align-items-start">
            <div class="me-2">
                <div class="d-flex align-items-center justify-content-center bg-secondary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                    style="height: 2rem; width: 2rem;">{{i+1}}</div>
            </div>
            <p class="d-inline-block align-middle text-primary m-0 fs-sm lh-lg" [innerHTML]="item.ItemFields.BusinessDetail">
            </p>
        </div>
    </div>
</div>