<!--begin::Products-->
<section class="container my-5" style="min-height: 55vh;">
    <div class="row justify-content-center" data-aos="fade-up" data-aos-duration="1000">
        <h3 *ngIf="ourService" class="h3 text-center fw-semibold pb-4 mb-2 mb-lg-4" [innerText]="ourService"> </h3>
    </div>
    <!-- <section class="container my-5"> -->
    <!-- Nav tabs -->
    <ul class="nav nav-tabs content-tabs flex-nowrap justify-content-md-center justify-content-start overflow-auto mb-0 pb-5" role="tablist" *ngIf="personalServices.length">
        <li class="nav-item mb-0" role="presentation" >
            <button
                class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center active"
                id="firstTab-tab" data-bs-toggle="tab" data-bs-target="#firstTab" type="button" role="tab"
                aria-controls="firstTab" aria-selected="true"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important" >
                <i class="bx bx-group me-2 fs-2"></i>
                {{Sector1}}
            </button>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation" >
            <button class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="secoundTab-tab" data-bs-toggle="tab" data-bs-target="#secoundTab" type="button" role="tab"
                aria-controls="secoundTab" aria-selected="false"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bx-buildings me-2 fs-2"></i>
                {{Sector2}}
            </button>
        </li>
    </ul>
    <!-- Tab panes -->
    <div class="tab-content productsonhome rounded-3" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-offset="200">
        <!-- frst tab -->
        <div class="tab-pane fade show active" id="firstTab" role="tabpanel" aria-labelledby="firstTab-tab">
            <div class="position-relative">
                <!-- Slider controls (Prev / next) -->
                <!-- <button type="button" id="card-prev"
                    class="btn btn-prev bg-primary btn-sm shadow-none p-1 border-1 position-absolute top-50 start-0 translate-middle-y d-none">
                    <i class="bx bx-chevron-right"></i>
                </button>
                <button type="button" id="card-next"
                    class="btn btn-next bg-primary btn-sm shadow-none p-1 border-1 position-absolute top-50 end-0 translate-middle-y d-none">
                    <i class="bx bx-chevron-left"></i>
                </button> -->
                <div class="row mx-lg-5" *ngIf="personalServices.length" >
                    <swiper [config]='configServices' class="swiper-item pt-2 pb-5 mx-n0" >
                        
                        <ng-template  *ngFor="let item of personalServices  " swiperSlide>
                            <!-- Item -->
                            <article class="card py-4 border-0 shadow-primary h-100 mx-2">
                                <div class="px-2 py-3 my-3 mt-0 border-0 d-flex justify-content-between align-items-center bg-infoLight">
                                    <h5 class="fs-sm mb-0 text-primary ms-2">
                                        <img src="{{item.ServiceCenterIcon}}" class="pe-1" alt="center logo"  loading="lazy" width="35" height="24">
                                        {{item.ServiceCenterName}}
                                    </h5>
                                </div>
                                <div class="card-body mx-3 my-4 p-0 d-flex align-items-center justify-content-center">
                                    <h4 class="fs-lg mb-0 text-primary text-center">
                                        {{item.serviceName}}
                                    </h4>
                                </div>
                                <div *ngIf="item.ShowDetailsButton"
                                    class="card-footer p-0 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                                    <button class="btn btn-outline-primary p-2 fs-sm border-1" style="width:7.5rem;"
                                        (click)="navigateUrl(item);" data-aos="flip-up" data-aos-duration="1500">
                                        {{more}}
                                    </button>
                                </div>
                            </article>
                        </ng-template>
                    </swiper>
                </div>
            </div>
            <!-- btn -->
            <div class="row d-flex justify-content-center mt-5" data-aos="flip-up" data-aos-duration="1000">
                <a href="{{_lang+ '/services/indviduals'}}" class="btn btn-outline-primary p-2 fs-sm border-1 fw-semibold"
                    style="width: 8rem;">
                    {{more}}
                </a>
            </div>
        </div>
        <!-- secound tab -->
        <div class="tab-pane fade" id="secoundTab" role="tabpanel" aria-labelledby="secoundTab-tab">
            <div class="position-relative">
                <!-- Slider controls (Prev / next) -->
                <button type="button" id="card-prev"
                    class="btn btn-prev bg-primary btn-sm shadow-none p-1 border-1 position-absolute top-50 start-0 translate-middle-y d-none">
                    <i class="bx bx-chevron-right"></i>
                </button>
                <button type="button" id="card-next"
                    class="btn btn-next bg-primary btn-sm shadow-none p-1 border-1 position-absolute top-50 end-0 translate-middle-y d-none">
                    <i class="bx bx-chevron-left"></i>
                </button>
                <div class="row mx-lg-5" *ngIf="businessServices.length">
                    <swiper [config]='configServices' class="swiper-item pt-2 pb-5 mx-n0" dir="rtl">
                        <ng-template *ngFor="let item of businessServices  " swiperSlide>
                            <!-- Item -->
                            <article class="card py-4 border-0 shadow-primary h-100 mx-2">
                                <div class="px-2 py-3 my-3 mt-0 border-0 d-flex justify-content-between align-items-center bg-infoLight">
                                    <h5 class="fs-sm mb-0 text-primary ms-2">
                                        <img src="{{item.ServiceCenterIcon}}" class="pe-1"  loading="lazy" alt="qiyas logo" width="35" height="24">
                                        {{item.ServiceCenterName}}
                                    </h5>
                                </div>
                                <div class="card-body mx-3 my-4 p-0 d-flex align-items-center justify-content-center">
                                    <h4 class="fs-lg mb-0 text-primary text-center">
                                        {{item.serviceName}}
                                    </h4>
                                </div>
                                <div *ngIf="item.ShowDetailsButton"
                                    class="card-footer p-0 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                                    <button class="btn btn-outline-primary p-2 fs-sm border-1" style="width:7.5rem;"
                                        (click)="navigateUrl(item);">
                                        {{more}}
                                    </button>
                                </div>
                            </article>
                        </ng-template>
                    </swiper>
                </div>
            </div>
            <!-- btn -->
            <div class="row d-flex justify-content-center mt-5" data-aos="zoom-out" data-aos-duration="1000">
                <a href="{{_lang+'/services/corporate'}}" class="btn btn-outline-primary p-2 fs-sm border-2 fw-semibold"
                    style="width: 8rem;">
                    {{more}}
                </a>
            </div>
        </div>

    </div>

</section>

<!--begin::tabs-->
<!-- <section class="position-relative py-5">
    <div class="container">
        <div class="main-tabs-wrapper">
            <ul class="nav nav-tabs nav-hero main-tabs justify-content-center" role="tablist">
                <li class="nav-item m-0">
                    <a class="nav-link rounded-3 p-4 active" href="#individual" id="tabs-individual"
                        data-bs-toggle="pill" role="tab" aria-controls="individual" aria-selected="true">
                        <i class="bx bx-user-circle me-2 display-6"></i>
                        {{Sector1}}
                    </a>
                </li>
                <li class="nav-item m-0">
                    <a class="nav-link rounded-3 p-4" href="#business" id="tabs-business" data-bs-toggle="pill"
                        role="tab" aria-controls="business" aria-selected="false">
                        <i class="bx bx-buildings  me-2 display-6"></i>
                        {{Sector2}}
                    </a>
                </li>
            </ul>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="tab-pane fade active show" id="preview6" role="tabpanel">
                    <div class="tab-content card p-3 border-0 shadow-primary">
                        <div class="tab-pane fade card-body active show" id="individual" role="tabpanel"
                            aria-labelledby="tabs-individual">
                            <div class="row d-flex justify-content-center mb-3">
                                <div class="col-lg-10 col-12">
                                    <div class="position-relative">
                                        <i
                                            class="bx bx-search fs-3 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 mt-n2"></i>
                                        <form class="example-form ">

                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <mat-label class="ps-5">{{search}} ..</mat-label>
                                                <input type="text" placeholder="   {{search}} .." matInput
                                                    [formControl]="myControlP" [matAutocomplete]="auto" class="ps-5">
                                                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                                    (optionSelected)='displayFn($event.option.value)'>
                                                    <mat-option *ngFor="let option of filteredOptionsP | async"
                                                        [value]="option.GUID">
                                                        <a href="service/{{option.GUID}}"> <img src="{{option.iconUrl}}"
                                                                alt="ETEC icon" class="mb-3"
                                                                style="width: 1rem;height: 1rem;" />
                                                            {{option.serviceName}}</a>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="row  row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 g-3 g-sm-4">
                                <div class="col d-flex"
                                    *ngFor="let item of personalServices | slice:0:4 |filter:searchText ">
                                    <div class="card border-0 shadow-primary w-100">
                                        <div class="py-3 px-2 my-3 mx-3 bg-white border-0 rounded-5 d-flex justify-content-between align-items-center rounded-3"
                                            style="background-color:#F5F4F8 !important;">
                                            <h5 class="h6 mb-0 text-primary">
                                                <img src="{{item.ServiceCenterIcon}}" class="pe-1" alt="qiyas logo"
                                                    width="35">
                                                {{item.ServiceCenterName}}
                                            </h5>
                                            <div *ngIf="item.serviceRegistrationLink">
                                                <span class="badge bg-warning shadow-primary p-2"
                                                    *ngIf="item.serviceRegistrationTitle == 'ينتهي قريباً'">
                                                    {{item.serviceRegistrationTitle}}
                                                </span>
                                                <span class="badge bg-success shadow-primary p-2"
                                                    *ngIf="item.serviceRegistrationTitle == 'متاح التسجيل'">
                                                    {{item.serviceRegistrationTitle}}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="card-body m-3 my-2 p-0 d-flex align-items-center justify-content-center">
                                            <h4 class="h5 mb-0 text-primary text-center">
                                                {{item.serviceName}}
                                            </h4>
                                        </div>
                                        <div
                                            class="card-footer py-3 px-3 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-lg-end justify-content-center">
                                            <button class="btn btn-primary fs-sm border-2" style="width:6rem;"
                                                (click)="navigateUrl(item);">{{more}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->


<!-- old -->
<!-- <div class="row row-cols-xl-6 row-cols-lg-4 row-cols-md-2 row-cols-1 g-4">
                                <div class="col" *ngFor="let item of personalServices | slice:0:6 |filter:searchText ">
                                    <div class="card card-hover card-hover-primary border-0 py-2 shadow-primary"
                                        style="height: 165px;max-height: 165px;min-width: 165px;cursor:pointer;"
                                        (click)="navigateUrl(item);">
                                        <div *ngIf="item.serviceRegistrationLink">
                                            <span
                                                class="badge bg-warning shadow-primary p-2 position-absolute top-0 start-50 translate-middle"
                                                *ngIf="item.serviceRegistrationTitle == 'ينتهي قريباً'">{{item.serviceRegistrationTitle}}</span>
                                            <span
                                                class="badge bg-success shadow-primary p-2 position-absolute top-0 start-50 translate-middle"
                                                *ngIf="item.serviceRegistrationTitle == 'متاح التسجيل'">{{item.serviceRegistrationTitle}}</span>
                                        </div>
                                        <div
                                            class="card-body text-center  d-flex flex-column justify-content-center align-items-center px-3">
                                            <img src="{{item.serviceIconUrl}}" alt="ETEC icon" class="mb-3"
                                                style="width: 3rem;height: 3rem;" />
                                            <p class="card-text text-primary fw-semibold fs-sm">{{item.serviceName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
<!-- old -->
<!-- <div class="row d-flex justify-content-center mt-5">
                                <a href="/services/single" class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                                    style="height:45px;width:125px;background-color: var(--disable) !important;color: var(--primaryColor)!important;">
                                    {{more}}
                                </a>
                            </div>
                        </div>
                        <div class="tab-pane fade card-body" id="business" role="tabpanel"
                            aria-labelledby="pills-business">
                            <div class="row d-flex justify-content-center mb-3">
                                <div class="col-lg-10 col-12">
                                    <div class="position-relative">
                                        <i
                                            class="bx bx-search fs-3 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 mt-n2"></i>
                                        <form class="example-form">
                                            <mat-form-field class="example-full-width" appearance="fill">
                                                <mat-label class="ps-5"> {{search}} ..</mat-label>
                                                <input type="text" placeholder="{{search}} .." matInput
                                                    [formControl]="myControlB" [matAutocomplete]="auto1" class="ps-5">
                                                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                                                    (optionSelected)='displayFn($event.option.value)'>
                                                    <mat-option *ngFor="let option of filteredOptionsB | async"
                                                        [value]="option.GUID">
                                                        <a href="service/{{option.GUID}}"><img src="{{option.iconUrl}}"
                                                                alt="ETEC icon" class="mb-3"
                                                                style="width: 1rem;height: 1rem;" />{{option.serviceName}}</a>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 g-3 g-sm-4">
                                <div class="col d-flex"
                                    *ngFor="let item of businessServices | slice:0:4  | filter:searchText ">
                                    <div class="card border-0 shadow-primary w-100">
                                        <div class="py-3 px-2 my-3 mx-3 bg-white border-0 rounded-5 d-flex justify-content-between align-items-center rounded-3"
                                            style="background-color:#F5F4F8 !important;">
                                            <h5 class="h6 mb-0 text-primary">
                                                <img src="{{item.ServiceCenterIcon}}" class="pe-1" alt="qiyas logo"
                                                    width="35">
                                                {{item.ServiceCenterName }}
                                            </h5>
                                            <div *ngIf="item.serviceRegistrationLink">
                                                <span class="badge bg-warning shadow-primary p-2"
                                                    *ngIf="item.serviceRegistrationTitle == 'ينتهي قريباً'">
                                                    {{item.serviceRegistrationTitle}}
                                                </span>
                                                <span class="badge bg-success shadow-primary p-2"
                                                    *ngIf="item.serviceRegistrationTitle == 'متاح التسجيل'">
                                                    {{item.serviceRegistrationTitle}}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="card-body m-3 my-2 p-0 d-flex align-items-center justify-content-center">
                                            <h4 class="h5 mb-0 text-primary text-center">
                                                {{item.serviceName}}
                                            </h4>
                                        </div>
                                        <div
                                            class="card-footer py-3 px-3 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-lg-end justify-content-center">
                                            <button class="btn btn-primary fs-sm border-2" style="width:6rem;"
                                                (click)="navigateUrl(item);">{{more}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

<!-- <div class="row row-cols-xl-6 row-cols-lg-4 row-cols-md-2 row-cols-1 g-4">
                                <div class="col"
                                    *ngFor="let item of businessServices | slice:0:6  | filter:searchText ">
                                    <div class="card card-hover card-hover-primary border-0 py-2 shadow-primary"
                                        style="height: 165px;max-height: 165px;min-width: 165px;cursor:pointer;"
                                        (click)="navigateUrl(item);">
                                        <div class="card-hint" *ngIf="item.serviceRegistrationLink">
                                            <span class="badge bg-warning"
                                                *ngIf="item.serviceRegistrationTitle == 'ينتهي قريباً'">{{item.serviceRegistrationTitle}}</span>
                                            <span class="badge bg-success"
                                                *ngIf="item.serviceRegistrationTitle == 'متاح التسجيل'">{{item.serviceRegistrationTitle}}</span>
                                        </div>
                                        <div
                                            class="card-body text-center d-flex flex-column justify-content-center align-items-center px-3">
                                            <img src="{{item.serviceIconUrl}}" alt="ETEC icon" class=" mb-3"
                                                style="width: 3rem;height: 3rem;" />
                                            <p class="card-text text-primary fw-semibold fs-sm">{{item.serviceName}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

<!-- <div class="row d-flex justify-content-center mt-5">
                                <a [routerLink]="['/services/corporate']"
                                    class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                                    style="height:45px;width:125px;background-color: var(--disable) !important;color: var(--primaryColor)!important;">{{more}}
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->