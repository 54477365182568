<div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
    <div class="col" *ngFor="let item of relatedNews" >
        <article class="card h-100 border-0 shadow rounded-5 mx-2">
            <div class="card-header py-3 pb-0 bg-white border-0 rounded-5 overflow-hidden" style="min-height: 250px;height: 250px;">
                <div class="position-relative">
                    <img src="{{item.newsUrl}}" class="card-img-top rounded-9" alt="Image">
                </div>
            </div>
            <div class="card-body pb-0 d-flex flex-column justify-content-center align-items-start">
                <h4 class="h5 mb-3 overflow-hidden" style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                    <a [routerLink]="['/news', item.newsId]" href="javascript:void(0);"
                        class="text-decoration-none text-primary">
                        {{item.newsTitle}}
                    </a>
                </h4>
                <p class="fs-xs fw-normal text-primary mb-0" style="text-align: justify;" [innerHTML]="item.newsDetails+'...'">
                </p>
            </div>
            <div class="card-footer bg-white py-3 border-0 rounded-5 d-flex align-items-center justify-content-between">
                <span class="fs-sm fw-normal text-primary">
                    <i class="bx bx-calendar fs-md me-2"></i>{{item.newsDate | date: 'yyyy/MM/dd'}}
                </span>
                <a href="javascript:void(0);" class="btn btn-secondary fs-sm border-0"
                    [routerLink]="['/news', item.newsId]"> {{more}}
                     </a>
            </div>
        </article>
    </div>
</div>
