<!-- Search bar -->
<div class="row mb-5 justify-content-center">
    <div class="col-12 d-flex flex-md-row flex-column">
        <div class="input-group position-relative w-lg-75 w-md-75 w-100">
            <input type="text" class="form-control form-control-lg ps-5" placeholder='{{searchtxt}}'
                [(ngModel)]="searchText" (keypress)="search()" (keyup)="search()" (keydown)="search()">

            <i class="bx bx-search fs-sm text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
       
        </div>
        <div class="form-floating ms-md-2 ms-0 mt-md-0 mt-2 w-lg-25 w-md-25 w-100">
            <select class="form-select" id="fl-select" style="background-color: var(--disable);" (change)="showItems($event)">
                <option  value="NewsDate-true" [innerText]="datedescalph">   </option>
                <option  value="NewsDate-false" [innerText]="ascdatealph">  </option>
            </select>
            <label for="fl-select" class="text-primary opacity-100"><i class="bx bx-sort"></i> {{orderby}}</label>
        </div>
    </div>
</div>

<div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
    <div class="col d-flex" *ngFor="let item of allNews ">
        <article class="card w-100 h-100 border-0 shadow rounded-5">
            <div class="card-header my-3 pb-0 bg-white border-0 rounded-5 overflow-hidden"
                style="min-height: 250px;height: 250px;">
                <div class="position-relative">
                    <img src="{{item.NewsImageThumbnail}}" class="card-img-top rounded-9" alt="Image">
                </div>
            </div>
            <div class="card-body pb-0 d-flex flex-column justify-content-center align-items-start">
                <h4 class="fs-lg mb-3 overflow-hidden"
                    style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                    <a   href="{{_lang +'/news/'+ item.NewsCode}}" 
                        class="text-decoration-none text-primary lh-base">
                      {{item.newsTitle}}
                    </a>
                </h4>
                <p class="fs-sm fw-normal text-primary mb-0" style="text-align: justify;"
                    [innerHTML]="item.newsDetails+'...'">
                </p>
            </div>
            <div class="card-footer py-3 border-0 rounded-5 d-flex align-items-center justify-content-between">
                <span class="fs-sm fw-normal text-primary">
                    <i class="bx bx-calendar fs-sm me-2"></i>{{item.newsDate | date: 'yyyy/MM/dd'}}
                </span>
                <a style="width: 6rem;" class="btn btn-secondary p-2 fs-sm border-0"
                    href="{{_lang +'/news/'+ item.NewsCode}}" [innerText]="more">
                    </a>
                    <!-- <a href="javascript:void(0);" style="width: 6rem;" class="btn btn-secondary p-2 fs-sm border-0"
                    [routerLink]="['/news', item.NewsCode]" [innerText]="more">
                    </a>     -->
            </div>
        </article>
    </div>
</div>
<div class="row">
    <div class="col d-flex justify-content-center mt-4 mb-2">
        <p class="m-0 text-primary fw-semibold fs-sm">{{allNews.length }}  {{servicesof}}
                {{Total}}</p>
    </div>
</div>
<div class="row">
    <div class="col d-flex justify-content-center mb-2">

        <input  type="button" value="{{more}}" (click)="getNews(LastID =LastID - 6,false,IsAsc) " *ngIf="allNews.length < Total && IsAsc"
            class="btn btn-light shadow-none border-0 fs-sm fw-semibold" style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
    
            <input  type="button" value="{{more}}" (click)="getNews(LastID =LastID + 6,false,IsAsc) " *ngIf="allNews.length < Total && !IsAsc"
            class="btn btn-light shadow-none border-0 fs-sm fw-semibold" style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
        </div>
</div>