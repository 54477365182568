<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item">
                            <a href="/home" class="fs-sm fw-normal">{{home}}</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="/serviceguidecatalog" class="fs-sm fw-normal" [innerHTML]="serviceGuid"></a>
                        </li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page">
                            {{response.ItemFields?.ServiceGuideTitle}}
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<!--servicecatalogdetails-->
<section class="container mt-5" *ngIf="response">
    <div class="row mb-5">
        <!-- title -->
        <div class="col-xxl-8 col-12">
            <div class="d-flex flex-row align-items-center p-xxl-3 px-xxl-4 p-4 rounded-5" style="background-color: #F3F3FF !important;">
                <i class="bx bx-file text-primary fs-2 me-3"></i>
                <h3 class="fs-lg text-primary m-0">{{response.ItemFields?.ServiceGuideTitle}}</h3>
            </div>
        </div>
        <!-- button service -->
        <div class="offset-xxl-1 col-xxl-3 col-12 d-flex justify-content-center" *ngIf="response?.ItemFields?.DefaultServiceLink?.LookupId  ">
            <a href="{{getChannelById(response?.ItemFields?.DefaultServiceLink?.LookupId!)?.ChannelLink?.Url}}" target="_blank" class="btn btn-info rounded-5 fs-lg w-xxl-100 h-100 mt-xxl-0 mt-3">
                {{jumpToService}}
                <i class="bx bx-arrow-back ms-2"></i>
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-xxl-8">
            <div class="row">
                <!-- وصف الخدمة -->
                <div class="col-12 mb-5 d-flex">
                    <article class="card position-relative p-0 h-100 w-100 border-0">
                        <div class="card-header w-100 border-0 p-0 m-0 mb-3">
                            <div class="d-flex flex-row align-items-center mb-2 bg-primary p-3 rounded-5">
                                <i class="bx bx-info-circle text-warning fs-3 me-2"></i>
                                <h3 class="fs-lg text-white m-0" [innerText]="serviceGuideDescription"></h3>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-0"
                                [innerHTML]="response.ItemFields?.ServiceGuideDescription">

                            </p>
                        </div>
                    </article>
                </div>
                <!-- متطلبات تقديم الخدمة -->
                <div class="col-12 mb-5 d-flex">
                    <article class="card position-relative p-0 h-100 w-100 border-0">
                        <div class="card-header w-100 border-0 p-0 m-0 mb-3">
                            <div class="d-flex flex-row align-items-center mb-2 bg-primary p-3 rounded-5">
                                <i class="bx bx-list-ul text-warning fs-3 me-2"></i>
                                <h3 class="fs-lg text-white m-0" [innerText]="serviceGuideRequirements"></h3>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <p class="fs-sm fw-normal text-primary overflow-hidden mb-0"
                                [innerHTML]="response.ItemFields?.ServiceGuideRequirements">

                            </p>
                        </div>
                    </article>
                </div>
                <!-- قنوات دعم الخدمة -->
                <div class="col-12 mb-5 d-flex">
                    <article class="card position-relative p-0 h-100 w-100 border-0">
                        <div class="card-header w-100 border-0 p-0 m-0 mb-3">
                            <div class="d-flex flex-row align-items-center mb-2 bg-primary p-3 rounded-5">
                                <i class="bx bx-desktop text-warning fs-3 me-2"></i>
                                <h3 class="fs-lg text-white m-0" [innerText]="serviceGuideSupportChannel"></h3>
                            </div>
                        </div>
                        <div class="card-body p-0" >
                            <ul class="fs-sm text-primary" *ngIf="channel$">
                                <li *ngFor="let channel of response.ItemFields?.ServiceGuideChannel"><span *ngIf="!getChannelById(channel.LookupId)?.ChannelLink">
                                    {{getChannelById(channel.LookupId).ChannelName }}</span>
                                <a *ngIf="getChannelById(channel.LookupId).ChannelLink" href="{{getChannelById(channel.LookupId)?.ChannelLink?.Url}}"
                                    target="_blank">
                                    {{getChannelById(channel.LookupId)?.ChannelName }}</a></li>
                            </ul>
                        </div>
                    </article>
                </div>
                <!-- خطوات تنفيذ الخدمة -->
                <div class="col-12 mb-5 d-flex">
                    <article class="card position-relative p-0 h-100 w-100 border-0">
                        <div class="card-header w-100 border-0 p-0 m-0 mb-3">
                            <div class="d-flex flex-row align-items-center mb-2 bg-primary p-3 rounded-5">
                                <i class="bx bx-list-check text-warning fs-3 me-2"></i>
                                <h3 class="fs-lg text-white m-0" [innerText]="serviceGuideSteps"></h3>
                            </div>
                        </div>
                        <div class="card-body fs-sm text-primary p-0"
                            [innerHTML]="response.ItemFields?.ServiceGuideSteps">
                        </div>
                    </article>
                </div>


            </div>
        </div>
        <div class="offset-xxl-1 col-xxl-3">
            <div class="row row-cols-xl-1 row-cols-lg-2 row-cols-md-2 row-cols-1">
                <!-- الفئة المستهدفة -->
                <div class="col-12 mb-5 d-flex">
                    <article class="card position-relative py-3 pb-0 border-1 shadow-primary pt-0 h-100 w-100"
                        style="border-radius: 0.938rem 0.938rem 0.5rem 0.5rem !important;">
                        <div class="btn btn-secondary p-3 w-100 pe-none rounded-5 d-flex flex-row">
                            <i class="bx bx-user fs-3 me-2"></i>
                            <p class="text-white m-0 fs-lg" [innerText]="serviceGuideTargetedGroup"></p>
                        </div>
                        <div class="card-body m-3 p-0 d-flex justify-content-center align-items-center">
                            <p class="fs-sm fw-normal text-center text-primary overflow-hidden mb-0"
                                [innerHTML]="response.ItemFields?.ServiceTargetedSector?.LookupValue">
                            </p>
                        </div>
                    </article>
                </div>
                <!-- وقت التوفر -->
                <div class="col-12 mb-5 d-flex">
                    <article class="card position-relative py-3 pb-0 border-1 shadow-primary pt-0 h-100 w-100"
                        style="border-radius: 0.938rem 0.938rem 0.5rem 0.5rem !important;">
                        <div class="btn btn-secondary p-3 w-100 pe-none rounded-5 d-flex flex-row">
                            <i class="bx bx-time-five fs-3 me-2"></i>
                            <p class="text-white m-0 fs-lg" [innerText]="serviceGuideAvailabilityTime"></p>
                        </div>
                        <div class="card-body m-3 p-0 d-flex justify-content-center align-items-center">
                            <p class="fs-sm fw-normal text-center text-primary overflow-hidden mb-0"
                                [innerHTML]="response.ItemFields?.ServiceAvailability?.LookupValue">
                            </p>
                        </div>
                    </article>
                </div>
                <!-- وقت الإنجاز -->
                <div class="col-12 mb-5 d-flex">
                    <article class="card position-relative py-3 pb-0 border-1 shadow-primary pt-0 h-100 w-100"
                        style="border-radius: 0.938rem 0.938rem 0.5rem 0.5rem !important;">
                        <div class="btn btn-secondary p-3 w-100 pe-none rounded-5 d-flex flex-row">
                            <i class="bx bx-calendar-event fs-3 me-2"></i>
                            <p class="text-white m-0 fs-lg" [innerText]="serviceGuideCompletionTime"></p>
                        </div>
                        <div class="card-body m-3 p-0 d-flex justify-content-center align-items-center">
                            <p class="fs-sm fw-normal text-center text-primary overflow-hidden mb-0"
                                [innerHTML]="response.ItemFields?.ServiceAccomplishment?.LookupValue">
                            </p>
                        </div>
                    </article>
                </div>
                <!-- قنوات بديلة للدعم -->
                <div class="col-12 mb-5 d-flex">
                    <article class="card position-relative py-3 pb-0 border-1 shadow-primary pt-0 h-100 w-100"
                        style="border-radius: 0.938rem 0.938rem 0.5rem 0.5rem !important;">
                        <div class="btn btn-secondary p-3 w-100 pe-none rounded-5 d-flex flex-row">
                            <i class="bx bx-desktop fs-3 me-2"></i>
                            <p class="text-white m-0 fs-lg" [innerText]="serviceGuideAlternativeChannels"></p>
                        </div>
                        <div class="card-body m-3 p-0 d-flex flex-column justify-content-center align-items-start">


                            <ul class="fs-sm text-primary" *ngIf="channel$">
                                <li *ngFor="let channel of response.ItemFields?.ServiceGuideAlternativeChannel"><span
                                        *ngIf="!getChannelById(channel.LookupId)?.ChannelLink">
                                        {{getChannelById(channel.LookupId).ChannelName }}</span>
                                    <a *ngIf="getChannelById(channel.LookupId)?.ChannelLink"
                                        href="{{getChannelById(channel.LookupId)?.ChannelLink?.Url}}" target="_blank">
                                        {{getChannelById(channel.LookupId)?.ChannelName }}</a>
                                </li>
                            </ul>
                        </div>

                    </article>
                </div>

            </div>
        </div>
    </div>
</section>