import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CenterList } from 'src/app/models/CenterList';
import { Service, ServiceGoal, ServiceContent, ServiceNote, ServiceAudience, ServicePrerequirements, ServiceSteps, ServiceDocuments } from 'src/app/models/ResponseModel';
import { ServiceAudnicesResponse } from 'src/app/models/ServiceAudnicesResponse';
import { ServiceContentResponse } from 'src/app/models/ServiceContentResponse';
import { ServiceDocumentsResponse } from 'src/app/models/ServiceDocumentsResponse';
import { ServiceGoalsResponse } from 'src/app/models/ServiceGoalsResponse';
import { ServiceNotesResponse } from 'src/app/models/ServiceNotesResponse';
import { ServicePrerequirementsResponse } from 'src/app/models/ServicePrerequirementsResponse';
import { ServiceCenterIdOrServiceSectorIdOrServiceTagIdOrServiceCategoryIdOrSortBehaviorOrSMTotalSizeOrSMTotalFileCount, ServicesResponse } from 'src/app/models/ServicesResponse';
import { ServiceStepsResponse } from 'src/app/models/ServiceStepsResponse';
import { TestTimeTableList } from 'src/app/models/TestTimeTableList';
import { ApiService } from 'src/app/services/api.service';
import { RouteApiService } from 'src/app/services/route-api.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { ListResponse } from 'src/app/models/ListResponse';
import { FAQList } from 'src/app/models/FAQList';
import { ResourcesService } from 'src/app/services/resources.service';
import { SwiperOptions, Pagination, Swiper, Autoplay, Navigation } from 'swiper';
import { ServiceTargetedSectorResponse } from 'src/app/models/ServiceTargetedSectorResponse';
import { ServiceStructureTagsResponse } from 'src/app/models/ServiceStructureTagsResponse';
import { ServiceMedialinksList } from 'src/app/models/ServiceMedialinksList';
import { EServiceGuidList } from 'src/app/models/EServiceGuidList';
import { LoadingService } from 'src/app/services/loading.service';
import { ServicesTabs } from 'src/app/models/ServicesDto';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {
  configServiceDoument: SwiperOptions = {
    slidesPerView: 1,
    loop: false,
    spaceBetween: 50,
    speed: 2000,
    // navigation: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
    },
    breakpoints: {
      '560': {
        slidesPerView: 1,
        slidesPerGroup: 1
      },
      '768': {
        slidesPerView: 2,
        slidesPerGroup: 2

      },
      '1000': {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      '1200': {
        slidesPerView: 4,
        slidesPerGroup: 4
      }
    }
  };

  destroy$: Subject<boolean> = new Subject<boolean>();

  productId: string | null;
  Product: Service;
  Goals: ServiceGoal[] = [];
  GoalsToShow: ServiceGoal[] = [];
  Content: ServiceContent[] = [];
  Notes: ServiceNote[] = [];
  Audnices: ServiceAudience[] = [];
  Steps: ServiceSteps[] = [];
  Prerequirements: ServicePrerequirements[] = [];
  ServiceDoument: ServiceDocuments[] = [];
  ServiceDoumentResponse: ServiceDocuments[] = [];
  TestTimeTable: TestTimeTableList[] = [];
  ServiceTargetedSector: ServiceTargetedSectorResponse[] = [];
  ServiceStructureTag: ServiceStructureTagsResponse[] = [];
  ServiceVideo: ServiceMedialinksList[] = [];
  ServiceVideoResponse: ServiceMedialinksList[] = [];
  serviceAllTabs: ServicesTabs[]= [];
  centerCode: string;
  centerName: string;
  currentUrl: string;
  htmlString = '<a href="">Hello gowtham</a>';
  servicegoal = '';
  serviceaudience = '';
  servicesteps = '';
  servicedocuments = '';
  serviceprerequirments = '';
  serviceName = '';
  videos = '';
  timetable = '';
  faq = '';
  notes = '';
  close = '';
  PageTitle: string = '';
  home: string = '';
  EtecFullName = '';
  infoAboutService = '';
  searchText: any = '';
  searchText2: any = '';
  ServiceTargetedSectorsTitle = '';
  faqList: FAQList[] = [];
  tagImage: string;
  imptempPublicUrl = environment.imptempPublicUrl;
  imgPublicUrl = environment.imgPublicUrl;
  serviceSectorURL = '';
  url: SafeUrl | undefined;
  videoTitle: any;
  show: string = '';
  goalsActive = '';
  audnicesActive = '';
  serviceTargetedSectorActive = '';
  stepsActive = '';
  contentActive = '';
  notesActive = '';
  prerequirementsActive = '';
  serviceDoumentActive = '';
  serviceVideoActive = '';
  testTimeTableActive = '';
  faqListActive = '';
  relatedServiceGuidActive = '';

  goalsShow = false;
  audnicesShow = false;
  serviceTargetedSectorShow = false;
  stepsShow = false;
  contentShow = false;
  notesShow = false;
  prerequirementsShow = false;
  serviceDoumentShow = false;
  serviceVideoShow = false;
  testTimeTableShow = false;
  faqListShow = false;

  searchtxt = '';
  Category = '';
  all = '';
  more = '';
  relatedServiceGuide = '';
  showTabs = false;
  hasCategory = false;
  videoHasCategory = false;
  videoTagsAll = true;
  showLoading = false;
  videoTags: string[] = [];
  videoSelectedTags: string[] = [];
  videoTagsStatus: boolean[] = [];

  tagsAll = true;
  tags: string[] = [];
  selectedTags: string[] = [];
  tagsStatus: boolean[] = [];
  tagsNumber: number[] = [];
  ServiceGuid: EServiceGuidList[] = [];
  servicesof='';
  showCount =12;
  showVdCount =12;
  tabUrl = '';
  _lang = '';
  _location = '';
  _currentUrl = '';

  constructor(private actRoute: ActivatedRoute, private apiServices: ApiService, private apiRoute: RouteApiService, private router: Router
    , private Title: Title, private apiResources: ResourcesService, public _sanitizer: DomSanitizer, private loading: LoadingService) {
    this.url = this._sanitizer.bypassSecurityTrustResourceUrl('');
    this.currentUrl = window.location.href;
    Swiper.use([Pagination]);
    Swiper.use([Autoplay]);
    Swiper.use([Navigation]);
    
    this._location = this.router.url    ;
    
     
    this.actRoute.params.subscribe(async params => {
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }

      this.tabUrl = params["tab"] ? params["tab"] : '';

      if (!localStorage.getItem('lang')) {
        this._lang = 'ar';
      }
      else {
        this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
      }
    });
  }

  /** Custom methods */
  updateTitle(title: string) {
    this.Title.setTitle(title);
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  route(url: string) {
    this.router.navigateByUrl(this._lang +'/service/'+ this.productId+ '/'+ url);
  }

  getService() {

    this.apiServices.getListTopN('Services', 1, true, "ServiceCode", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServicesResponse[]) => {
        if (data.length === 0  ) {
          this.router.navigateByUrl('/notfound')
        }
        if (data[0]?.ItemFields?.IsHiddenFromServiceList) {
          this.router.navigateByUrl('/notfound')
        }
        var obj: Service = {
          serviceTitle: data[0]?.ItemFields.Title,
          serviceId: data[0]?.ItemId,
          serviceName: data[0]?.ItemFields.ServiceName,
          ServiceCenterId: data[0]?.ItemFields.ServiceCenterId?.LookupId,
          ServiceCenterName: data[0]?.ItemFields.ServiceCenterId?.LookupValue,
          ServiceDescription: data[0]?.ItemFields.ServiceDescription!?.replace(/<[^>]*>/g, ''),
          serviceIconUrl: './assets/img/icon/exam/GAT.svg',
          serviceLevel: 0,
          serviceVisitCount: 0,
          serviceDetailsFileUrl: data[0]?.ItemFields.ServiceCategoryId_x003a_ServiceC?.LookupValue,
          serviceKeyword: data[0]?.ItemFields.ServiceKeyword,
          serviceSector: data[0]?.ItemFields.ServiceSectorId?.LookupValue,
          serviceSectorId: data[0]?.ItemFields.ServiceSectorId?.LookupId,
          ServiceCategory: data[0]?.ItemFields.ServiceCategoryId?.LookupValue,
          ServiceCategoryId: data[0]?.ItemFields.ServiceCategoryId?.LookupId,
          serviceRegistrationLink: data[0]?.ItemFields?.DetailsButtonLink?.Url,
          serviceRegistrationTitle: data[0]?.ItemFields?.DetailsButtonLabel,
          isActive: data[0]?.ItemFields.IsActive1,
          showServiceRegistration: data[0]?.ItemFields?.ShowDetailsButton,
          ShowDetailsButton: data[0]?.ItemFields?.ShowDetailsButton,
          RelatedEServiceGuide: data[0]?.ItemFields?.RelatedEServiceGuide!
        }

        this.Product = obj;
        this.serviceName = obj.serviceName?.toString() == null ? '1' : obj.serviceName?.toString();
        this.serviceSectorURL = obj.serviceSectorId?.toString() == '1' ? '/'+this._lang+'/services/indviduals' : '/'+this._lang+'/services/corporate';
        //get all service's tabs and activate first one or url one
        this.getServiceTabs(obj.serviceId);
        //get related service guid
        this.getRelatedEServiceGuid(obj.RelatedEServiceGuide!);
        this.updateTitle(obj.ServiceCenterName + " - " + this.serviceName.toString());
        this.getCenterCode(data[0]?.ItemFields.ServiceCenterId?.LookupId);
        //get service big icon
        this.getServiceStructureTags(data[0]?.ItemFields?.ServiceTagId?.LookupId);
      }
    );

  }

  getServiceTabs(serviceId: number){
    this.clearActiveTabs();
    this.apiServices
        .checkService(serviceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: ServicesTabs[]) => {
            this.serviceAllTabs = data.filter( m => m.IsExist );
            this.goalsShow = data.filter( m => m.IsExist  && m.ListName == 'ServiceGoals').length > 0;
            this.audnicesShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceAudiences').length > 0;
            this.serviceTargetedSectorShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceTargetedSectors').length > 0;
            this.stepsShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceSteps').length > 0;
            this.contentShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceContent').length > 0;
            this.notesShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceNotes').length > 0;
            this.prerequirementsShow =  data.filter( m => m.IsExist  && m.ListName == 'ServicePrerequirements').length > 0;
            this.serviceDoumentShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceDocuments').length > 0;
            this.serviceVideoShow =  data.filter( m => m.IsExist  && m.ListName == 'ServiceMediaLinks').length > 0;
            this.testTimeTableShow =  data.filter( m => m.IsExist  && m.ListName == 'TestTimeTable').length > 0;
            this.faqListShow =  data.filter( m => m.IsExist  && m.ListName == 'FAQ').length > 0;
           if(this.tabUrl == ''){
              this.getActiveTabByDefault(data);
           }else{
            this.getActiveTabFromUrl();
           }
        });
  }

  getActiveTabByDefault(data: ServicesTabs[]){

    if(data.length > 0){
      var first = data.filter(m => m.IsExist)[0];
      if(first.ListName == 'ServiceGoals' && first.IsExist){
        this.goalsActive = 'active';
        this.getServiceGoals();
      }else if (first.ListName == 'ServiceAudiences' && first.IsExist){
        this.audnicesActive = 'active';
        this.getServiceAudnices();
      }else if (first.ListName == 'ServiceTargetedSectors' && first.IsExist){
        this.serviceTargetedSectorActive = 'active';
        this.getServiceTargetedSector();
      }else if (first.ListName == 'ServiceSteps'  && first.IsExist){
        this.stepsActive = 'active';
        this.getServiceSteps();
      }else if  (first.ListName == 'ServiceContent' && first.IsExist){
        this.contentActive = 'active';
        this.getServiceContent();
      }else if  (first.ListName == 'ServiceNotes' && first.IsExist){
        this.notesActive = 'active';
        this.getServiceNotes();
      }else if (first.ListName == 'ServicePrerequirements' && first.IsExist){
        this.prerequirementsActive = 'active'; 
        this.getServicePrerequirements();
      }else if (first.ListName == 'ServiceDocuments' && first.IsExist){
        this.serviceDoumentActive = 'active';
        this.getServiceDoument();
      }else if (first.ListName == 'ServiceMediaLinks' && first.IsExist){
        this.serviceVideoActive = 'active';
        this.getVideos();
      }else if (first.ListName == 'TestTimeTable' && first.IsExist){
        this.testTimeTableActive = 'active';
        this.getTestTimetable();
      }else if (first.ListName == 'FAQ' && first.IsExist){
        this.faqListActive = 'active';
        this.getFaq();
      }
    }
  }

  clearActiveTabs(){
    this.goalsActive =  '';
    this.contentActive =  '';
    this.notesActive =  '';
    this.audnicesActive =  '';
    this.serviceTargetedSectorActive =  '';
    this.stepsActive =   '';
    this.prerequirementsActive = '';
    this.serviceDoumentActive =  '';
    this.testTimeTableActive = '';
    this.faqListActive =  '';
    this.serviceVideoActive = '';
    this.relatedServiceGuidActive = '';
    this.showTabs = true;
  }

  changeUrl(url: string){
    window.history.pushState("object or string", "Title", `/${url}`);
  }

  getRelatedEServiceGuid(arr: ServiceCenterIdOrServiceSectorIdOrServiceTagIdOrServiceCategoryIdOrSortBehaviorOrSMTotalSizeOrSMTotalFileCount[]) {
    if (arr) {
      arr.forEach(element => {
        this.apiServices
          .getListTopN('EServiceGuide', 1, true, 'ID', element.LookupId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data: EServiceGuidList[]) => {
            if (data.length > 0) {
              this.ServiceGuid.push(data[0]);
            }
          });

      });
    }
  }

  async getCenterCode(id: number) {
    this.apiServices.getListById('centers', id).subscribe(
      (res: CenterList) => {
        this.centerCode = res.ItemFields.CenterCode;
        this.centerName = res.ItemFields.CenterName;
       // this.getServiceGoals();

      }
    )
  }

  getServiceGoals() {
    this.changeUrl(`${this._lang}/service/${this.productId}/servicegoal`);
    this._currentUrl = `${location.origin }/${this._lang}/service/${this.productId}/servicegoal'`;
    if(this.Goals.length == 0){
      this.apiServices.getListTopN('ServiceGoals', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
        (data: ServiceGoalsResponse[]) => {
          data.forEach(element => {
            var obj: ServiceGoal = {
              ServiceGoalNumber: element.ItemFields.ServiceGoalNumber,
              ServiceGoalName: element.ItemFields.ServiceGoalName
            };
  
            this.Goals.push(obj);
          });
          //this.getServiceContent();
        });
    }
  }

  getServiceContent() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/infoAboutService');
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/infoAboutService';
    if(this.Content.length == 0){
      this.apiServices.getListTopN('ServiceContent', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
        (data: ServiceContentResponse[]) => {
          data.forEach(element => {
            var obj: ServiceContent = {
              ServiceContentTitle: element.ItemFields.ServiceContentTitle,
              ServiceContentDetailes: element.ItemFields.ServiceContentDetails,
              ServiceContentIcon: element.ItemFields.ServiceContentIcon!
            };
  
            this.Content.push(obj);
          });
         // this.getServiceNotes();
        });
    }
  }

  getServiceNotes() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/notes');
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/notes';
    if(this.Notes.length == 0 ){
    this.apiServices.getListTopN('ServiceNotes', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceNotesResponse[]) => {

        data.forEach(element => {

          var obj: ServiceNote = {
            serviceNoteDetailes: element?.ItemFields?.ServiceNoteDetails?.replace(new RegExp('&#58;', 'gi'), ':').replace(new RegExp(environment.imptempPublicUrl, 'gi'), environment.imgPublicUrl),
            serviceNoteTitle: element.ItemFields.ServiceNoteTitle
          };

          this.Notes.push(obj);
        });
        //this.getServiceAudnices();
      });
    }
  }

  getServiceAudnices() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/serviceaudience');
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/serviceaudience';
    if(this.Audnices.length == 0){
    this.apiServices.getListTopN('ServiceAudiences', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceAudnicesResponse[]) => {
        data.forEach(element => {
          var obj: ServiceAudience = {
            serviceAudienceId: element.ItemFields.ServiceAudienceId,
            serviceAudienceName: element.ItemFields.ServiceAudienceName

          };

          this.Audnices.push(obj);
        });
       // this.getServiceSteps();
      });
    }
  }

  getServiceSteps() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/servicesteps');
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/servicesteps';
    if(this.Steps.length == 0){
    this.apiServices.getListTopN('ServiceSteps', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceStepsResponse[]) => {
        data.forEach(element => {
          var obj: ServiceSteps = {
            ServiceStepsDetails: element.ItemFields.ServiceStepsDetails
          };

          this.Steps.push(obj);
        });
       // this.getServicePrerequirements();
      });
    }
  }

  getServicePrerequirements() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/serviceprerequirments');
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/serviceprerequirments';
    if(this.Prerequirements.length == 0){
    this.apiServices.getListTopN('ServicePrerequirements', 100, false, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServicePrerequirementsResponse[]) => {
        data.forEach(element => {
          var obj: ServicePrerequirements = {
            ServicePrerequirementsDetails: element.ItemFields.ServicePrerequirementsDetails
          };

          this.Prerequirements.push(obj);
        });
       // this.getServiceDoument();
      });
    }
  }

  getServiceDoument() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/servicedocuments');
    
    //this.ServiceDoumentResponse = [];
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/servicedocuments';
    if(this.ServiceDoument.length == 0){
      this.ServiceDoument = [];
    this.apiServices.getListTopN('ServiceDocuments', 100, true, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceDocumentsResponse[]) => {

        data.forEach(element => {
          var obj: ServiceDocuments = {
            DocumentName: element.ItemFields.DocumentName_x0020_,
            ServiceDocument: element.ItemFields.ServiceDocument?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            ServiceDocumentWord: element.ItemFields.ServiceDocumentWord?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            ServiceDocumentCategory: element.ItemFields?.DocumentCategory?.LookupValue,
            ServiceDocumentOrder: element.ItemFields?.DocumentOrder
          };
          this.ServiceDoument.push(obj);

          if (obj.ServiceDocumentCategory) {
            if (obj.ServiceDocumentCategory != '') {
              this.hasCategory = true;
              if (this.tags.indexOf(obj.ServiceDocumentCategory) == -1) {
                this.tags.push(obj.ServiceDocumentCategory);
                this.tagsStatus.push(true);
              }
            }
          }
        });
        //this.ServiceDoument = this.ServiceDoument.sort((a, b) => a.ServiceDocumentOrder! - b.ServiceDocumentOrder!);
        this.ServiceDoumentResponse = [... this.ServiceDoument].sort((a, b) => a.ServiceDocumentOrder! - b.ServiceDocumentOrder!);
        //this.getTestTimetable();
      });
    }
  }

  getTestTimetable() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/timetable');
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/timetable';
    if(this.TestTimeTable.length == 0){
    this.apiServices.getListTopN('TestTimeTable', 100, true, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: TestTimeTableList[]) => {
        this.TestTimeTable = data;
        //this.getServiceTargetedSector();
      });
    }
  }

  getServiceTargetedSector() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/servicetargetedsectors');
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/servicetargetedsectors';
    if(this.ServiceTargetedSector.length == 0){
    this.apiServices.getListTopN('ServiceTargetedSectors', 100, true, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceTargetedSectorResponse[]) => {
        this.ServiceTargetedSector = data;
       // this.getFaq();
      });
    }
  }

  getFaq() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/faq');
    this._currentUrl = location.origin+'/'+this._lang +'/service/'+ this.productId+ '/faq';
    if(this.faqList.length == 0){
    this.apiServices.getListOrder('faq', 0, true, 'OrderFAQ').pipe(takeUntil(this.destroy$)).subscribe((data: FAQList[]) => {
      this.faqList = data.filter((x) => x.ItemFields.ServiceId?.LookupId == this.Product.serviceId);
      this.faqList.forEach(element => {
        element.ItemFields.FAQQuestion = element.ItemFields.FAQQuestion?.replace(/<[^>]*>/g, '');
        element.ItemFields.FAQAnswer = element.ItemFields.FAQAnswer;
      });
      //this.getVideos();
    });
  }
  }

  getVideos() {
    this.changeUrl(this._lang +'/service/'+ this.productId+ '/servicedocuments');
    this._currentUrl = location.origin+this._lang +'/service/'+ this.productId+ '/servicedocuments';
    if(this.ServiceVideo.length == 0){
    this.apiServices.getListTopN('ServiceMediaLinks', 100, true, "ServiceId", this.Product.serviceId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceMedialinksList[]) => {
        this.ServiceVideo = data;
        this.ServiceVideo = this.ServiceVideo.sort((a, b) => a.ItemFields?.ServiceMediaOrder! - b.ItemFields?.ServiceMediaOrder!);
        this.ServiceVideoResponse = [... this.ServiceVideo];
        data.forEach(element => {
          if (element.ItemFields.ServiceMediaCategory) {
            if (element.ItemFields.ServiceMediaCategory?.LookupValue != '') {
              this.videoHasCategory = true;
              if (this.videoTags.indexOf(element.ItemFields.ServiceMediaCategory?.LookupValue) == -1) {
                this.videoTags.push(element.ItemFields.ServiceMediaCategory?.LookupValue);
                this.videoTagsStatus.push(true);
              }
            }
          }
        });
      });
    }
  }

  getServiceStructureTags(tagId: number) {
    this.apiServices.getListById('ServiceStructureTags', tagId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceStructureTagsResponse) => {
        this.tagImage = data?.ItemFields?.TagImage?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl);
      });
  }

  // cateogry(categoryitems: any[]): any[] {
  //   let sortedcategoryitems = categoryitems.sort((a, b) => (a.ServiceGoalNumber < b.ServiceGoalNumber) ? -1 : ((b.ServiceGoalNumber > a.ServiceGoalNumber) ? 1 : 0));
  //   return sortedcategoryitems;
  // }

  @ViewChild("table") MyProp: ElementRef;

  scrollToElement(): void {
    this.MyProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  getThumbnail(url: any, size: string): string {
    var video, results;

    video = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
    // video   = (results === null) ? url : results[1];

    if (size === 'small') {
      return 'https://img.youtube.com/vi/' + video + '/2.jpg';
    }
    return 'https://img.youtube.com/vi/' + video + '/0.jpg';
  }

  getVideo(url: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url.replace(this.imptempPublicUrl, this.imgPublicUrl) + '?rel=0');
  }

  open(item: ServiceMedialinksList) {
    this.showLoading = true;
    this.videoTitle = item.ItemFields.MediaLinkName;
    this.url = this.getVideo(item.ItemFields.MediaLink.Url);
  }

  stopVideo() {
    this.url = this._sanitizer.bypassSecurityTrustResourceUrl('');
  }

  onLoad() {
    this.showLoading = false;
  }

  getActiveTab() {
    this.goalsActive = (this.Goals.length > 0) ? 'active' : '';

    this.contentActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length == 0
      && this.Content.length > 0) ? 'active' : '';


    this.notesActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length == 0
      && this.Content.length == 0
      && this.Notes.length > 0) ? 'active' : '';

    this.audnicesActive = (this.Goals.length == 0
      && this.Audnices.length > 0) ? 'active' : '';

    this.serviceTargetedSectorActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length > 0) ? 'active' : '';


    this.stepsActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length > 0) ? 'active' : '';


    this.prerequirementsActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length == 0
      && this.Content.length == 0
      && this.Notes.length == 0
      && this.Prerequirements.length > 0) ? 'active' : '';


    this.serviceDoumentActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length == 0
      && this.Content.length == 0
      && this.Notes.length == 0
      && this.Prerequirements.length == 0
      && this.servicedocuments.length > 0) ? 'active' : '';



    this.testTimeTableActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length == 0
      && this.Content.length == 0
      && this.Notes.length == 0
      && this.Prerequirements.length == 0
      && this.servicedocuments.length == 0
      && this.ServiceVideo.length == 0
      && this.TestTimeTable.length > 0) ? 'active' : '';


    this.faqListActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length == 0
      && this.Content.length == 0
      && this.Notes.length == 0
      && this.Prerequirements.length == 0
      && this.servicedocuments.length == 0
      && this.ServiceVideo.length == 0
      && this.TestTimeTable.length == 0
      && this.faqList.length > 0) ? 'active' : '';


    this.serviceVideoActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length == 0
      && this.Content.length == 0
      && this.Notes.length == 0
      && this.Prerequirements.length == 0
      && this.servicedocuments.length == 0
      && this.ServiceVideo.length > 0) ? 'active' : '';


    this.relatedServiceGuidActive = (this.Goals.length == 0
      && this.Audnices.length == 0
      && this.ServiceTargetedSector.length == 0
      && this.Steps.length == 0
      && this.Content.length == 0
      && this.Notes.length == 0
      && this.Prerequirements.length == 0
      && this.servicedocuments.length == 0
      && this.ServiceVideo.length == 0
      && this.TestTimeTable.length == 0
      && this.faqList.length == 0
      && this.relatedServiceGuide.length > 0) ? 'active' : '';


    this.showTabs = true;

  }

  getActiveTabFromUrl() {
    this.goalsActive = (this.tabUrl == 'servicegoal') ? 'active' : '';
    this.contentActive = this.tabUrl == 'infoAboutService' ? 'active' : '';
    this.notesActive = this.tabUrl == 'notes' ? 'active' : '';
    this.audnicesActive = this.tabUrl == 'serviceaudience' ? 'active' : '';
    this.serviceTargetedSectorActive = this.tabUrl == 'servicetargetedsectors' ? 'active' : '';
    this.stepsActive = this.tabUrl == 'servicesteps' ? 'active' : '';
    this.prerequirementsActive = this.tabUrl == 'serviceprerequirments' ? 'active' : '';
    this.serviceDoumentActive = this.tabUrl == 'servicedocuments' ? 'active' : '';
    this.testTimeTableActive = this.tabUrl == 'timetable' ? 'active' : '';
    this.faqListActive = this.tabUrl == 'faq' ? 'active' : '';
    this.serviceVideoActive = this.tabUrl == 'videos' ? 'active' : '';
    this.relatedServiceGuidActive = this.tabUrl == 'relatedserviceguide' ? 'active' : '';
    this.showTabs = true;

    if( this.goalsActive == 'active'){
      this.getServiceGoals();
    }else if ( this.audnicesActive == 'active'){
      this.getServiceAudnices();
    }else if (this.serviceTargetedSectorActive == 'active'){
      this.getServiceTargetedSector();
    }else if (this.stepsActive == 'active'){
      this.getServiceSteps();
    }else if  (this.contentActive == 'active'){
      this.getServiceContent();
    }else if  (this.notesActive == 'active'){
      this.getServiceNotes();
    }else if (this.prerequirementsActive == 'active'){
      this.getServicePrerequirements();
    }else if (this.serviceDoumentActive == 'active'){
      this.getServiceDoument();
    }else if (this.serviceVideoActive == 'active'){
      this.getVideos();
    }else if (this.testTimeTableActive == 'active'){
      this.getTestTimetable();
    }else if (this.faqListActive == 'active'){
      this.getFaq();
    }
  }

  changeTage() {
    this.tagsStatus.forEach((element, index) => {
      this.tagsStatus[index] = this.tagsAll;
    });
    this.filterChange();
  }

  filterChange() {
    this.selectedTags = [];

    this.tagsStatus.forEach((element, index) => {
      if (element === true) {
        this.selectedTags.push(this.tags[index]);
      }
      //this.tagsNumber.push(0);
    });

    this.tagsAll = true;
    this.tagsStatus.forEach((element) => {
      if (!element) {
        this.tagsAll = false;
      }
    });


    this.getTagsResult();
  }

  getTagsResult() {

    if (!this.tagsAll && this.hasCategory) {
      //this.ServiceDoumentResponse = this.ServiceDoument.filter(x => this.selectedTags.indexOf(x.ServiceDocumentCategory!) != -1 || !x.ServiceDocumentCategory);
      this.ServiceDoumentResponse = this.ServiceDoument.filter(x => this.selectedTags.indexOf(x.ServiceDocumentCategory!) != -1);

    } else {
      this.ServiceDoumentResponse = [... this.ServiceDoument];
    }

    if (this.searchText != '') {
      if (!this.tagsAll && this.hasCategory) {
        this.ServiceDoumentResponse = this.ServiceDoument.filter(x => this.selectedTags.indexOf(x.ServiceDocumentCategory!) != -1);
      }
      this.ServiceDoumentResponse = this.ServiceDoumentResponse.filter(x => x.DocumentName?.toUpperCase().includes(this.searchText.toUpperCase()));
    }
  }

  changeTageVideo() {
    this.videoTagsStatus.forEach((element, index) => {
      this.videoTagsStatus[index] = this.videoTagsAll;
    });
    this.filterChangeVideo();
  }

  filterChangeVideo() {
    this.videoSelectedTags = [];

    this.videoTagsStatus.forEach((element, index) => {
      if (element === true) {
        this.videoSelectedTags.push(this.videoTags[index]);
      }
      //this.tagsNumber.push(0);
    });

    this.videoTagsAll = true;
    this.videoTagsStatus.forEach((element) => {
      if (!element) {
        this.videoTagsAll = false;
      }
    });


    this.getVideoTagsResult();
  }

  getVideoTagsResult() {

    if (!this.videoTagsAll && this.videoHasCategory) {
      this.ServiceVideoResponse = this.ServiceVideo.filter(x => this.videoSelectedTags.indexOf(x.ItemFields.ServiceMediaCategory?.LookupValue!) != -1);
    } else {
      this.ServiceVideoResponse = [... this.ServiceVideo];
    }

    if (this.searchText2 != '') {
      if (!this.videoTagsAll && this.videoHasCategory) {
        this.ServiceVideoResponse = this.ServiceVideo.filter(x => this.videoSelectedTags.indexOf(x.ItemFields.ServiceMediaCategory?.LookupValue!) != -1);
      }
      this.ServiceVideoResponse = this.ServiceVideoResponse.filter(x => x.ItemFields.MediaLinkName?.toUpperCase().includes(this.searchText2.toUpperCase())
      );
    }
  }


  /** Component events  */
  async ngOnInit() {
    this.actRoute.params.subscribe(async params => {

      this.productId = params["id"];
      this.tabUrl = params["tab"] ? params["tab"] : '';
      this.getService();

      this.servicegoal = await this.apiResources.getByKey("servicegoal");
      this.serviceaudience = await this.apiResources.getByKey("serviceaudience");
      this.servicesteps = await this.apiResources.getByKey("servicesteps");
      this.servicedocuments = await this.apiResources.getByKey("servicedocuments");
      this.serviceprerequirments = await this.apiResources.getByKey("serviceprerequirments");
      this.home = await this.apiResources.getByKey("home");
      this.EtecFullName = await this.apiResources.getByKey("EtecFullName");
      this.infoAboutService = await this.apiResources.getByKey("InfoAboutService");
      this.ServiceTargetedSectorsTitle = await this.apiResources.getByKey("ServiceTargetedSectors");
      this.show = await this.apiResources.getByKey("show");
      this.videos = await this.apiResources.getByKey("videos");
      this.timetable = await this.apiResources.getByKey("timetable");
      this.faq = await this.apiResources.getByKey("faq");
      this.notes = await this.apiResources.getByKey("notes");
      this.close = await this.apiResources.getByKey("close");
      this.searchtxt = await this.apiResources.getByKey("search");
      this.Category = await this.apiResources.getByKey("Category");
      this.all = await this.apiResources.getByKey('all');
      this.more = await this.apiResources.getByKey('more');
      this.relatedServiceGuide = await this.apiResources.getByKey('RelatedServiceGuide');
      this.servicesof = await this.apiResources.getByKey("servicesof");
      this.apiServices.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
        (data: ListResponse[]) => {
          data.forEach(element => {
            if (element.ItemFields.Title.toLowerCase() === 'services') {
              this.PageTitle = element.ItemFields.MenuName;
            }

          });
        }
      );
    }
    );
  }

  putVisitNo(itemId: string) {
    this.apiServices.putVisitingCount('EServiceGuide', itemId).subscribe();
  }

  navigateUrl(product: EServiceGuidList) {

    this.putVisitNo(product.ItemId.toString());
    this.router.navigateByUrl('/servicecatalogdetails/' + product.ItemFields?.Title);

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();

  }
}
