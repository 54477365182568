<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/Services2.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="h1 text-white fw-semibold m-0 position-relative zindex-5" [innerText]="PageTitle">
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
        <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="{{_lang}}/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page" [innerText]="PageTitle"> </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>
<!--begin::products-->
<div class="container">
    <!-- filter section -->
    <div class="row d-flex justify-content-center mb-4 ">
        <div class="col-12 my-1">
            <h5 class="fs-lg text-primary fw-semibold mb-2">{{Centers}}</h5>
            <div class="d-flex flex-row flex-nowrap overflow-auto justify-content-start w-100">
                <div class="me-2 mb-2 d-block position-relative">
                    <input type="checkbox" class="btn-check" name="options1" id="option-all-centers" autocomplete="off"
                        [(ngModel)]="filterAll" (ngModelChange)="changeCenter()">
                    <label class="btn label-check fs-sm border-1" for="option-all-centers"
                        style="min-width: 7.5rem;">{{all}}</label>
                </div>
                <div *ngFor="let element of centers; let i = index">
                    <div class="me-2 mb-2 d-block position-relative" *ngIf="centerStatus[i]">
                        <input type="radio" class="btn-check" name="options1" id="chbox_{{i}}"
                            [value]="centers[i]?.ItemId" [(ngModel)]="centerId" (ngModelChange)="filterChange()">
                        <label class="btn label-check fs-sm border-1" for="chbox_{{i}}"
                            style="min-width: 7.5rem;">{{element.ItemFields?.CenterShortName}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 my-1">
            <h5 class="fs-lg text-primary fw-semibold mb-2 d-none">{{Category}}</h5>
            <div class="d-flex flex-row flex-nowrap overflow-auto justify-content-start w-100">
                <div class="me-2 mb-2 nav nav-tabs-alt d-block position-relative products-checkbox">
                    <input type="checkbox" class="btn-check products-category" name="options2" id="option-all-tags"
                        autocomplete="off" [(ngModel)]="tagsAll" (ngModelChange)="changeTage()">
                    <label class="nav-link fs-sm"  for="option-all-tags">{{all}}</label>
                </div>
                <div class="me-2 mb-2 nav nav-tabs-alt d-block position-relative products-checkbox" *ngFor="let element of tags; let i = index">
                    <input type="checkbox" class="btn-check products-category" name="options2" id="chbox{{i}}"
                        autocomplete="off" [(ngModel)]="tagsStaus[i]" (ngModelChange)="filterChange()">
                    <label class="nav-link fs-sm justify-content-center" for="chbox{{i}}">{{element.ItemFields?.ServiceTagName}} ({{tagsNumber[i]}})</label>
                </div>
            </div>
        </div>
    </div>

    <!-- products cards -->
    <div class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-4 products-cards" *ngIf="response.length>0">
        <div class="col d-flex" *ngFor="let item of response | slice:0:showCount |filter:searchText "
            (ngModelChange)="filterChange()">
            <article class="card py-4 border-1 shadow-primary h-100 w-100">
                <div
                    class="card-header border-0 px-2 d-flex justify-content-center align-items-center position-relative mb-4">
                    <div class="position-absolute rounded-circle bg-infoLight"
                        style="width: 75px;height: 70px;z-index: 99;">
                    </div>
                    <img src="{{item.ServiceCenterIcon}}" alt="Center-logo" class="position-relative"
                        style="z-index: 99;" width="45">
                </div>
                <div class="card-body mx-3 mb-4 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h4 class="fs-lg fw-semibold mb-0 text-primary  text-center overflow-hidden" placement="bottom"
                        ngbTooltip="{{item.serviceName}}"
                        style="text-align: justify;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;cursor: context-menu;">
                        {{item.serviceName}}
                    </h4>

                    <ng-template #tipContent>
                        <div [innerHTML]="item.serviceDetailsFileUrl"></div>
                    </ng-template>

                    <p class="fs-sm fw-normal text-primary overflow-hidden mt-3 mb-0" placement="bottom"
                        [ngbTooltip]="tipContent"
                        style="display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;"
                        [innerHTML]="item.ServiceDescription"></p>
                </div>
                <div *ngIf="item.ShowDetailsButton"
                    class="card-footer p-0 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                    <button class="btn btn-outline-primary p-2 fs-sm border-1" style="width:7.5rem;"
                        [routerLink]="route(item)" (click)="navigateUrl(item);">{{more}}
                    </button>
                </div>
            </article>
        </div>
    </div>
    <!-- counter -->
    <div class="row">
        <div class="col d-flex justify-content-center mt-4 mb-2">
            <p class="m-0 text-primary fw-semibold fs-sm">{{showCount< response.length?showCount:response.length }}
                    {{servicesof}} {{response.length}}</p>
        </div>
    </div>
    <!-- show more -->
    <div class="row">
        <div class="col d-flex justify-content-center mb-2">

            <input type="button" value=" {{more}}" (click)="showCount= showCount + 12"
                *ngIf="showCount < response.length" class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
        </div>
    </div>
    <!--begin::share-->
    <app-shareicons></app-shareicons>
</div>