import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { CenterBusinessDetailsList } from 'src/app/models/CenterBusinessDetailsList';
import { CenterBusinessDetailTypeList } from 'src/app/models/CenterBusinessDetailTypeList';
import { Center, CenterLeader } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-about-center',
  templateUrl: './about-center.component.html',
  styleUrls: ['./about-center.component.css']
})
export class AboutCenterComponent implements OnInit {
  @Input() centerId!: number;
  @Input() center: Center;
  @Input() cenetrLeader!: CenterLeader;
  isFound : boolean = false;

  ResponsbilityETEC: CenterBusinessDetailsList[] =[];
  CenterBDetailTypeList : CenterBusinessDetailTypeList[] =[];
  dummy:  CenterBusinessDetailTypeList[] =[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  PageTitle : string = '';


 
  constructor(private centerService: ApiService,private Title : Title) {
    this.getCenterBusinessDetailTypes();  
  }

 /** Custom methods */
  getCenterBusinessDetailTypes(){
    this.centerService.getList('CenterBusinessDetailTypes').pipe(takeUntil(this.destroy$)).subscribe(
      (data: CenterBusinessDetailTypeList[]) => {
        this.CenterBDetailTypeList = data.sort((a,b) => a.ItemFields.TypeOrder - b.ItemFields.TypeOrder);
        //console.log(this.CenterBDetailTypeList);
      }
    ); 
  }

  /** Component events  */
  ngOnInit(): void {
  }
  
  ngOnChanges() { 
    this.Title.setTitle(this.center?.centerName);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
     // Unsubscribe from the subject
    this.destroy$.unsubscribe();
   }
}
