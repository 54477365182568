<!-- Search bar -->
<div class="row mb-5 justify-content-center">
    <div class="col-12 d-flex flex-md-row flex-column">
        <!-- <div class="input-group position-relative w-lg-75 w-md-75 w-100"> -->
        <div class="input-group position-relative w-100">
            <input type="text" class="form-control form-control-lg ps-5" placeholder="{{searchtxt}}"
                [(ngModel)]="searchText" (keypress)="search()" (keyup)="search()" (keydown)="search()">

            <i
                class="bx bx-search fs-sm text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>

        </div>
        <div class="form-floating ms-md-2 ms-0 mt-md-0 mt-2 w-lg-25 w-md-25 w-100 d-none">
            <select class="form-select" id="fl-select" style="background-color: var(--disable);">
                <!-- <option value="0" selected>اختر</option> -->

                <option value="NewsTitle-false" [innerText]="ascalph"> </option>
                <option value="NewsTitle-true" [innerText]="descalph"> </option>
                <option value="NewsDate-false" [innerText]="ascdatealph"> </option>
                <option value="NewsDate-true" [innerText]="datedescalph"> </option>
            </select>
            <label for="fl-select" class="text-primary opacity-100"><i class="bx bx-sort"></i> {{orderby}}</label>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title fs-sm" id="exampleModalLabel" [innerText]="videoTitle"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="stopVideo()"></button>
            </div>
            <div class="modal-body">
                <!-- youtube iframe -->
                <div class="mask" *ngIf="showLoading">
                    <app-spinner></app-spinner>
                </div>
                <iframe (load)="onLoad()" id="video2" class="rounded-3 yvideo" width="100%" height="400" [src]="url"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- end youtube iframe -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary fs-sm" data-bs-dismiss="modal"
                    (click)="stopVideo()">اغلاق</button>
            </div>
        </div>
    </div>
</div>

<!-- Video library -->
<div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
    <div class="col d-flex" *ngFor="let item of MediaCenterVideos | slice:0:showCount ">
        <article class="card w-100 h-100 border-0 shadow rounded-5">
            <div class="card-header my-3 pb-0 bg-white border-0 rounded-5 overflow-hidden"
                style="min-height: 250px;height: 250px;">
                <div class="position-relative">
                    <span class="position-absolute top-0 start-0 w-100 h-100 bg-dark rounded-3 opacity-25"></span>
                    <div
                        class="d-flex align-items-center justify-content-center position-absolute top-0 start-0 w-100 h-100 zindex-2">
                        <!-- img btn modal -->
                        <button type="button" class="btn btn-video btn-icon bg-primary stretched-link"
                            data-bs-toggle="modal" data-bs-target="#exampleModal" data-backdrop="static"
                            data-keyboard="false" (click)="open(item)">
                            <i class="bx bx-play text-white"></i>
                        </button>
                        <!-- end img btn modal -->
                    </div>
                    <!-- youtube thumbnail -->
                    <img src="{{item.MCFileThumbnails}}" class="card-img-top rounded-9" alt="Image">
                    <!-- end youtube thumbnail -->
                </div>
            </div>
            <div class="card-body d-flex flex-column justify-content-center align-items-start pb-0">
                <h4 class="fs-lg mb-3 overflow-hidden"
                    style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;"
                    [innerText]="item.MCFileName">

                </h4>
            </div>
            <div class="card-footer py-3 border-0 rounded-5 d-flex align-items-center justify-content-end">
                <button type="button" style="width: 6rem;" class="btn btn-secondary p-2 fs-sm border-0"
                    data-bs-toggle="modal" [innerText]="show" data-bs-target="#exampleModal" data-backdrop="static"
                    data-keyboard="false" (click)="open(item)">

                </button>
            </div>
        </article>
    </div>
</div>

<div class="row">
    <div class="col d-flex justify-content-center mt-4 mb-2">
        <p class="m-0 text-primary fw-semibold fs-sm">{{showCount<
                MediaCenterVideos.length?showCount:MediaCenterVideos.length }} {{servicesof}}
                {{MediaCenterVideos.length}}</p>
    </div>
</div>
<div class="row">
    <div class="col d-flex justify-content-center mb-2">

        <input type="button" value="{{more}}" (click)="showCount= showCount + 6"
            *ngIf="showCount < MediaCenterVideos.length" class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
            style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
    </div>
</div>