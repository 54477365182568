<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/ResearchReports.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="text-white h1 fw-semibold m-0 position-relative zindex-5" [innerText]="PageTitle">
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
        <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="{{_lang}}/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page" [innerText]="PageTitle">11 </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>
<!--begin::tabs-->
<section class="container">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs content-tabs flex-nowrap justify-content-md-center justify-content-start overflow-auto mb-0 pb-4 pt-0"
        role="tablist">
        <li class="nav-item mb-0" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab1" data-bs-toggle="tab" data-bs-target="#report" role="tab" aria-controls="tab1"
                aria-selected="true" routerLink="/data/report"
                [ngClass]="activeTab.toLocaleLowerCase()  === 'report'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bxs-report me-2 fs-2"></i>
                {{Reports}}
            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab2" data-bs-toggle="tab" data-bs-target="#special" role="tab" aria-controls="tab2"
                aria-selected="true" routerLink="/data/special"
                [ngClass]="activeTab.toLocaleLowerCase() === 'special'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bx-line-chart  me-2 fs-2"></i>
                {{TechnicalReports}}
            </a>
        </li>
        <li class="nav-item mb-0 ms-lg-4" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab3" data-bs-toggle="tab" data-bs-target="#research" role="tab" aria-controls="tab3"
                aria-selected="true" routerLink="/data/research"
                [ngClass]="activeTab.toLocaleLowerCase() === 'research'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bxs-book-open me-2 fs-2"></i>
                {{PublishedReports}}
            </a>
        </li>
        <!-- <li class="nav-item mb-0 ms-lg-4" role="presentation">
            <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
                id="tabs-tab4" data-bs-toggle="tab" data-bs-target="#research-reward" role="tab" aria-controls="tab4"
                aria-selected="true" routerLink="/data/research-reward"
                [ngClass]="activeTab.toLocaleLowerCase() === 'research-reward'? 'active':''"
                style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important">
                <i class="bx bxs-book-open me-2 fs-2"></i>
                {{researchreward}}
            </a>
        </li> -->
    </ul>
    <!-- Tab content -->
    <div class="tab-content rounded-3">
        <!-- tab-pane -->
        <div class="tab-pane fade" id="report" role="tabpanel" aria-labelledby="tabs-tab1"
            [ngClass]="activeTab.toLocaleLowerCase() === 'report'? 'show active':''">
            <!-- start tab content -->
            <!-- search bar -->
            <div class="row justify-content-center">
                <div class="col-12 mb-5">
                    <div class="input-group position-relative">
                        <input type="text" class="form-control form-control-lg py-3 ps-5"
                            placeholder="{{reportsearch}}.." [(ngModel)]="searchText"
                            (keypress)="search()" (keyup)="search()" (keydown)="search()">
                        <i
                            class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                    </div>
                </div>
            </div>

            <div class="row row-cols-xxl-2 row-cols-1 g-4">
                <div class="col"
                    *ngFor="let element of reasearchList |slice:0:showCount1">
                    <div class="card h-100 border-1 bg-white shadow-sm overflow-hidden mb-3 p-3">
                        <div class="row g-0">
                            <!-- img -->
                            <div *ngIf="element.ItemFields?.ImageThumbnails?.Url"
                                class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 bg-primary"
                                style="    min-height: 12rem;/*background-size: 150px !important;*/"
                                [ngStyle]="{'background-image': 'url(' +'\'' +element.ItemFields?.ImageThumbnails?.Url?.replace(imptempPublicUrl,imgPublicUrl) +'\'' +')'}">
                            </div>
                            <div *ngIf="!element.ItemFields?.ImageThumbnails?.Url"
                                class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 "
                                style="    min-height: 12rem;background-size: 150px !important;"
                                [ngStyle]="{'background-image': 'url(assets/img/data/reports.png)'}">
                                <!-- <i
                                    class="bx bx-image bx-flip-horizontal text-white display-3 d-flex h-100 justify-content-center align-items-center"></i> -->
                            </div>
                            <!-- content -->
                            <div class="col-xxl-9 col-lg-9 col-md-12 col-12">
                                <div class="px-0 px-lg-4 py-0 d-flex flex-column justify-content-center h-100">
                                    <h3 class="fs-lg text-primary" [innerHTML]="element.ItemFields.RRName"></h3>
                                    <p class="mb-2 fs-sm text-primary" [innerHTML]="element.ItemFields?.PublishedLocation">
                                    </p>
                                    <div *ngIf="element.ItemFields?.RRWriter"
                                        class="d-flex align-items-start text-primary flex-column flex-lg-row">
                                        <div *ngFor="let item of element.ItemFields?.RRWriter">
                                            <i class="bx bx-user fs-lg me-1"></i>
                                            <span class="fs-sm me-3" [innerHTML]="item?.LookupValue "> </span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex flex-column flex-lg-row justify-content-between text-primary mt-3">
                                        <div class="d-flex flex-column flex-wrap flex-lg-row">
                                            <!-- <a
                                                class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-lg-0 mb-2 d-flex justify-content-between">
                                                <span class="d-inline me-3 fs-sm">عرض التقرير</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bx-search fs-4"></i>
                                                </span>
                                            </a> -->
                                            <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-2 d-flex justify-content-between"
                                                target="_blank"
                                                *ngIf="element.ItemFields?.RRLink && !element.ItemFields?.IsRequiredPersonalInformation"
                                                href="{{element.ItemFields?.RRLink?.Url?.replace(imptempPublicUrl,imgPublicUrl)}}">
                                                <span class="d-inline me-3 fs-sm"> {{reportdownload}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bx-show-alt  fs-4"></i>
                                                </span>
                                            </a>

                                            <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-2 d-flex justify-content-between"
                                                target="_blank"
                                                *ngIf="element.ItemFields?.RRLink && element.ItemFields?.IsRequiredPersonalInformation"
                                                href="personalinfo/{{element.ItemFields.GUID}}/research">
                                                <span class="d-inline me-3 fs-sm"> {{reportdownload}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bx-show-alt  fs-4"></i>
                                                </span>
                                            </a>

                                            <a *ngIf="element.ItemFields?.CitationDetails"
                                                (click)="open(content, element.ItemFields.CitationDetails,' اقتباس التقرير')"
                                                target="_blank"
                                                class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-2 d-flex justify-content-between">
                                                <span class="d-inline me-3 fs-sm"> {{reportquotes}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bxs-quote-alt-left fs-4"></i>
                                                </span>
                                            </a>
                                            <a *ngIf="element.ItemFields?.RRDetails"
                                                (click)="open(content, element.ItemFields.RRDetails!,details)"
                                                target="_blank"
                                                class="btn btn-outline-primary px-2 py-1 mb-2 d-flex justify-content-between">
                                                <span class="d-inline me-3 fs-sm"> {{details}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bxs-file fs-4"></i>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center h-100" *ngIf="element.ItemFields?.RRYear">
                                            <i class="bx bx-calendar fs-lg me-1"></i>
                                            <span class="fs-sm"
                                                [innerHTML]="element.ItemFields?.RRYear?.LookupValue"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- counter -->
            <div class="row">
                <div class="col d-flex justify-content-center mt-4 mb-2">
                    <p class="m-0 text-primary fw-semibold fs-sm">{{showCount1<
                            reasearchList.length?showCount1:reasearchList.length}}    {{servicesof}}  {{reasearchList.length}}</p>
                </div>
            </div>
            <!-- show more -->
            <div class="row">
                <div class="col d-flex justify-content-center mb-2">

                    <input type="button" value=" {{more}}" (click)="showCount1= showCount1 + 5"
                        *ngIf="showCount1 < reasearchList.length"
                        class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                        style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
                </div>
            </div>
            <!-- end tab content -->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade " id="special" role="tabpanel" aria-labelledby="tabs-tab2"
            [ngClass]="activeTab.toLocaleLowerCase() === 'special'? 'show active':''">
            <!-- start tab content -->
            <div class="row justify-content-center g-2 mb-5">
                <div class="col-lg-8 col-md-12">
                    <div class="input-group position-relative">
                        <input type="text" class="form-control form-control-lg py-3 ps-5"
                            placeholder="{{reportsearch}}.." [(ngModel)]="searchText" 
                            (keypress)="search2()" (keyup)="search2()" (keydown)="search2()">
                        <i
                            class="bx bx-search fs-sm text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <div class="form-floating mt-md-0 mt-2 w-100">
                        <select class="form-select" id="fl-select" style="background-color: var(--disable);"
                            (change)="onChangeAuthor($event)">
                            <option value="0" selected>{{all}}</option>
                            <option *ngFor="let item of Authors" value="{{item.NameAR}}">{{item.NameAR}}</option>
                        </select>
                        <label for="fl-select" class="text-primary opacity-100"> {{select}}</label>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <div class="form-floating mt-md-0 mt-2 w-100">
                        <select class="form-select" id="fl-select" style="background-color: var(--disable);"
                            (change)="onChangeYear($event)">
                            <option value="0" selected>{{all}}</option>
                            <option *ngFor="let item of Years" value="{{item.Year | date: 'yyyy'}}">{{item.Year |
                                date: 'yyyy'}}</option>
                        </select>
                        <label for="fl-select" class="text-primary opacity-100"> {{select}}</label>
                    </div>
                </div>
                <!-- <div class="col-12 mb-5">
                    <div class="input-group mt-3 position-relative">
                        <input type="text" class="form-control form-control-lg ps-5" placeholder="{{reportsearch}}   .."
                            [(ngModel)]="searchText">
                        <i
                            class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>

                        <div class="form-floating ms-md-2 ms-0 mt-md-0 mt-2 w-lg-25 w-md-25 w-100">
                            <select class="form-select" id="fl-select" style="background-color: var(--disable);"
                                (change)="onChangeAuthor($event)">
                                <option value="0" selected>{{all}}</option>
                                <option *ngFor="let item of Authors" value="{{item.NameAR}}">{{item.NameAR}}</option>
                            </select>
                            <label for="fl-select" class="text-primary opacity-100"> {{select}}</label>
                        </div>

                        <div class="form-floating ms-md-2 ms-0 mt-md-0 mt-2 w-lg-25 w-md-25 w-100">
                            <select class="form-select" id="fl-select" style="background-color: var(--disable);"
                                (change)="onChangeYear($event)">
                                <option value="0" selected>{{all}}</option>
                                <option *ngFor="let item of Years" value="{{item.Year | date: 'yyyy'}}">{{item.Year |
                                    date: 'yyyy'}}</option>
                            </select>
                            <label for="fl-select" class="text-primary opacity-100"> {{select}}</label>
                        </div>

                    </div>
                </div> -->
            </div>
            <div class="row row-cols-xxl-2 row-cols-1 g-4">
                <div class="col"
                    *ngFor="let element of reasearchList2 |slice:0:showCount2 ">
                    <div class="card h-100 border-1 bg-white shadow-sm overflow-hidden mb-3 p-3">
                        <div class="row g-0">
                            <!-- img -->
                            <div *ngIf="element.ItemFields?.ImageThumbnails?.Url"
                                class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 bg-primary"
                                 style="    min-height: 12rem;background-size: 150px !important;"
                                [ngStyle]="{'background-image': 'url(' +'\'' +element.ItemFields?.ImageThumbnails?.Url?.replace(imptempPublicUrl,imgPublicUrl) +'\'' +')'}">
                            </div>
                            <div *ngIf="!element.ItemFields?.ImageThumbnails?.Url"
                                class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 "
                                 style="    min-height: 12rem;background-size: 150px !important;"
                                [ngStyle]="{'background-image': 'url(assets/img/data/technicalreports.png)'}">
                                <!-- <i
                                    class="bx bx-image bx-flip-horizontal text-white display-3 d-flex h-100 justify-content-center align-items-center"></i> -->
                            </div>
                            <!-- content -->
                            <div class="col-xxl-9 col-lg-9 col-md-12 col-12">
                                <div class="px-0 px-lg-4 py-0 d-flex flex-column justify-content-center h-100">
                                    <h3 class="fs-lg text-primary" [innerHTML]="element.ItemFields.RRName"></h3>
                                    <p class="mb-2 text-primary" [innerHTML]="element.ItemFields?.PublishedLocation">
                                    </p>
                                    <div *ngIf="element.ItemFields?.RRWriter"
                                        class="d-flex align-items-start text-primary flex-column flex-lg-row">
                                        <div *ngFor="let item of element.ItemFields?.RRWriter">
                                            <i class="bx bx-user fs-lg me-1"></i>
                                            <span class="fs-sm me-3" [innerHTML]="item?.LookupValue "> </span>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex flex-column flex-lg-row justify-content-between text-primary mt-3">
                                        <div class="d-flex flex-column flex-wrap flex-lg-row">
                                            <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-2 d-flex justify-content-between"
                                                target="_blank"
                                                *ngIf="element.ItemFields?.RRLink && !element.ItemFields?.IsRequiredPersonalInformation"
                                                href="{{element.ItemFields?.RRLink?.Url?.replace(imptempPublicUrl,imgPublicUrl)}}">
                                                <span class="d-inline me-3 fs-sm"> {{reportdownload}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bx-show-alt  fs-4"></i>
                                                </span>
                                            </a>

                                            <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-2 d-flex justify-content-between"
                                                target="_blank"
                                                *ngIf="element.ItemFields?.RRLink && element.ItemFields?.IsRequiredPersonalInformation"
                                                href="personalinfo/{{element.ItemFields.GUID}}/research">
                                                <span class="d-inline me-3 fs-sm"> {{reportdownload}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bx-show-alt  fs-4"></i>
                                                </span>
                                            </a>

                                            <a *ngIf="element.ItemFields?.CitationDetails"
                                                (click)="open(content, element.ItemFields.CitationDetails,' اقتباس التقرير')"
                                                target="_blank"
                                                class="btn btn-outline-primary px-2 py-1 mb-2 d-flex justify-content-between">
                                                <span class="d-inline me-3 fs-sm"> {{reportquotes}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bxs-quote-alt-left fs-4"></i>
                                                </span>
                                            </a>
                                            <a *ngIf="element.ItemFields?.RRDetails"
                                                (click)="open(content, element.ItemFields.RRDetails!,details)"
                                                target="_blank"
                                                class="btn btn-outline-primary px-2 py-1 mb-2 d-flex justify-content-between">
                                                <span class="d-inline me-3 fs-sm"> {{details}}</span>
                                                <span class="bg-warning rounded-3 btn btn-icon">
                                                    <i class="bx bxs-file fs-4"></i>
                                                </span>
                                            </a>

                                        </div>
                                        <div class="d-flex align-items-center h-100" *ngIf="element.ItemFields?.RRYear">
                                            <i class="bx bx-calendar fs-lg me-1"></i>
                                            <span class="fs-sm"
                                                [innerHTML]="element.ItemFields?.RRYear?.LookupValue"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end tab content -->
            <!-- counter -->
            <div class="row">
                <div class="col d-flex justify-content-center mt-4 mb-2">
                    <p class="m-0 text-primary fw-semibold fs-base">{{showCount2<
                            reasearchList2.length?showCount2:reasearchList2.length}} تقرير من
                            {{reasearchList2.length}}</p>
                </div>
            </div>
            <!-- show more -->
            <div class="row">
                <div class="col d-flex justify-content-center mb-2">

                    <input type="button" value=" {{more}}" (click)="showCount2= showCount2 + 5"
                        *ngIf="showCount2 < reasearchList2.length"
                        class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                        style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
                </div>
            </div>
            <!--begin::share-->
        </div>
        <!-- tab-pane -->
        <div class="tab-pane fade " id="research" role="tabpanel" aria-labelledby="tabs-tab3"
            [ngClass]="activeTab.toLocaleLowerCase() === 'research'? 'show active':''">
            <!-- start tab content -->
            <div class="row justify-content-center g-2 mb-5">
                <div class="col-lg-8 col-md-12">
                    <div class="input-group position-relative">
                        <input type="text" class="form-control form-control-lg py-3 ps-5"
                            placeholder="{{reportsearch}}.." [(ngModel)]="searchText" 
                            (keypress)="search()" (keyup)="search3()" (keydown)="search3()">
                        <i
                            class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <div class="form-floating mt-md-0 mt-2 w-100">
                        <select class="form-select" id="fl-select" style="background-color: var(--disable);"
                            (change)="onChangeAuthor($event)">
                            <option value="0" selected>{{all}}</option>
                            <option *ngFor="let item of Authors" value="{{item.NameAR}}">{{item.NameAR}}</option>
                        </select>
                        <label for="fl-select" class="text-primary opacity-100"> {{select}}</label>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12">
                    <div class="form-floating mt-md-0 mt-2 w-100">
                        <select class="form-select" id="fl-select" style="background-color: var(--disable);"
                            (change)="onChangeYear($event)">
                            <option value="0" selected>{{all}}</option>
                            <option *ngFor="let item of Years" value="{{item.Year | date: 'yyyy'}}">{{item.Year |
                                date: 'yyyy'}}</option>
                        </select>
                        <label for="fl-select" class="text-primary opacity-100"> {{select}}</label>
                    </div>
                </div>
            </div>
            <!-- data -->
            <div class="row row-cols-xxl-2 row-cols-1 g-4" *ngIf="reasearchList3">
                    <div class="col" *ngFor="let element of reasearchList3|slice:0:showCount3 ">
                        <div class="card h-100 d-flex justify-content-center border-1 bg-white shadow-sm overflow-hidden mb-3 p-3">
                            <div class="row g-0">
                                <!-- img -->
                                <div *ngIf="element.ItemFields?.ImageThumbnails?.Url"
                                    class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 bg-primary"
                                     style="    min-height: 12rem;background-size: 150px !important;"
                                    [ngStyle]="{'background-image': 'url(' +'\'' +element.ItemFields?.ImageThumbnails?.Url?.replace(imptempPublicUrl,imgPublicUrl) +'\'' +')'}">
                                </div>
                                <div *ngIf="!element.ItemFields?.ImageThumbnails?.Url"
                                    class="col-xxl-3 col-lg-3 col-md-12 col-12 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 d-block mb-2 mb-lg-0 "
                                     style="    min-height: 12rem;background-size: 150px !important;" [ngStyle]="{'background-image': 'url(assets/img/data/publishedreports.png)'}">
                                    <!-- <i
                                        class="bx bx-image bx-flip-horizontal text-white display-3 d-flex h-100 justify-content-center align-items-center"></i> -->
                                </div>
                                <!-- content -->
                                <div class="col-xxl-9 col-lg-9 col-md-12 col-12">
                                    <div class="px-0 px-lg-4 py-0 d-flex flex-column justify-content-center h-100">
                                        <h3 class="fs-lg text-primary" [innerHTML]="element.ItemFields.RRName"></h3>
                                        <p class="mb-2 text-primary" [innerHTML]="element.ItemFields?.PublishedLocation">
                                        </p>
                                        <div *ngIf="element.ItemFields?.RRWriter"
                                            class="d-flex align-items-start text-primary flex-column flex-lg-row">
                                            <div *ngFor="let item of element.ItemFields?.RRWriter">
                                                <i class="bx bx-user fs-lg me-1"></i>
                                                <span class="fs-sm me-3" [innerHTML]="item?.LookupValue "> </span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column flex-lg-row justify-content-between text-primary mt-3">
                                            <div class="d-flex flex-column flex-wrap flex-lg-row">
                                                <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-2 d-flex justify-content-between"
                                                    target="_blank"
                                                    *ngIf="element.ItemFields?.RRLink && !element.ItemFields?.IsRequiredPersonalInformation"
                                                    href="{{element.ItemFields?.RRLink?.Url?.replace(imptempPublicUrl,imgPublicUrl)}}">
                                                    <span class="d-inline me-3 fs-sm"> {{reportdownload}}</span>
                                                    <span class="bg-warning rounded-3 btn btn-icon">
                                                        <i class="bx bx-show-alt  fs-4"></i>
                                                    </span>
                                                </a>
                    
                                                <a class="btn btn-outline-primary px-2 py-1 me-0 me-lg-3 mb-2 d-flex justify-content-between"
                                                    target="_blank"
                                                    *ngIf="element.ItemFields?.RRLink && element.ItemFields?.IsRequiredPersonalInformation"
                                                    href="personalinfo/{{element.ItemFields.GUID}}/research">
                                                    <span class="d-inline me-3 fs-sm"> {{reportdownload}}</span>
                                                    <span class="bg-warning rounded-3 btn btn-icon">
                                                        <i class="bx bx-show-alt  fs-4"></i>
                                                    </span>
                                                </a>
                    
                                                <a *ngIf="element.ItemFields?.CitationDetails"
                                                    (click)="open(content, element.ItemFields.CitationDetails,' اقتباس التقرير')"
                                                    target="_blank"
                                                    class="btn btn-outline-primary px-2 py-1 mb-2 d-flex justify-content-between">
                                                    <span class="d-inline me-3 fs-sm"> {{reportquotes}}</span>
                                                    <span class="bg-warning rounded-3 btn btn-icon">
                                                        <i class="bx bxs-quote-alt-left fs-4"></i>
                                                    </span>
                                                </a>
                                                <a *ngIf="element.ItemFields?.RRDetails"
                                                    (click)="open(content, element.ItemFields?.RRDetails!,details)" target="_blank"
                                                    class="btn btn-outline-primary px-2 py-1 mb-2 d-flex justify-content-between">
                                                    <span class="d-inline me-3 fs-sm"> {{details}}</span>
                                                    <span class="bg-warning rounded-3 btn btn-icon">
                                                        <i class="bx bxs-file fs-4"></i>
                                                    </span>
                                                </a>
                    
                                            </div>
                                            <div class="d-flex align-items-center h-100" *ngIf="element.ItemFields?.RRYear">
                                                <div class="mb-2" *ngIf="element.ItemFields?.RRYear">
                                                    <i class="bx bx-calendar fs-lg me-1"></i>
                                                    <span class="fs-sm" [innerHTML]="element.ItemFields?.RRYear?.LookupValue"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                            </div>
                        </div>
                    </div>
                <div *ngIf="reasearchList3.length === 0">
                    <p [innerText]="nocontent"></p>
                </div>
            </div>
            <!-- end tab content -->
            <!-- counter -->
            <div class="row">
                <div class="col d-flex justify-content-center mt-4 mb-2">
                    <p class="m-0 text-primary fw-semibold fs-base">{{showCount3<
                            reasearchList3.length?showCount3:reasearchList3.length}} تقرير من
                            {{reasearchList3.length}}</p>
                </div>
            </div>
            <!-- show more -->
            <div class="row">
                <div class="col d-flex justify-content-center mb-2">

                    <input type="button" value=" {{more}}" (click)="showCount3= showCount3 + 5"
                        *ngIf="showCount3 < reasearchList3.length"
                        class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                        style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
                </div>
            </div>
        </div>
        <!-- tab-pane -->
        <!-- <div class="tab-pane fade " id="research-reward" role="tabpanel" aria-labelledby="tabs-tab4"
        [ngClass]="activeTab.toLocaleLowerCase() === 'research-reward'? 'show active':''">
         <app-custom-service [ServiceId]="'research-reward'"></app-custom-service>
        </div> -->
    </div>
</section>


<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" [innerHTML]="modalTitle"> </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p [innerHTML]="modalData"></p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" aria-label="Close" (click)="d('Cross click')">اغلاق</button>
    </div>
</ng-template>