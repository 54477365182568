import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
//import { MdbCarouselComponent } from 'mdb-angular-ui-kit/carousel';
import { Subject, takeUntil } from 'rxjs';
import { Carousel } from 'src/app/models/Carousel';
import { CarouselList } from 'src/app/models/CarouselList';
import { Configurationlist } from 'src/app/models/configurationlist';

import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';
import  { SwiperOptions, Pagination, EffectFade, Swiper, Autoplay } from 'swiper';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})

export class CarouselComponent implements OnInit {
  //@ViewChild('carousel') carousel!: MdbCarouselComponent;
  configCarousel: SwiperOptions = {
    spaceBetween: 30,
    effect: "fade",
    speed: 1000,
    autoplay: { delay: 2500 },
    loop: true,
    pagination: { clickable: true },
    // scrollbar: { draggable: true },
  };

  carousels: Carousel[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  title: string;
  titleDetails: string;
  url: string;
  defaultTheme = '';
  isEvent = false;

  constructor(private carouselService: ApiService, private apiResources: ResourcesService) { }

  /** Custom methods */
  getCarosal() {

    this.carouselService.getListOrder('homesliders', 20, true, 'HomeSliderSequence').pipe(takeUntil(this.destroy$)).subscribe(
      (data: CarouselList[]) => {
        let i: number;
        i = 0
        let isActive: string = '';
        data.forEach(element => {
          if (i == 0) {
            isActive = 'active'
          }else{
            isActive = ''
          }

          var arr = element.ItemFields?.HomeSliderTitle.split('\n');
          if (arr.length > 1) {
            var obj: Carousel = {
              head: arr[0],
              title: arr[1],
              Message: element.ItemFields?.HomeSliderDetails,
              image: element.ItemFields?.HomeSliderBackground?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
              link: element.ItemFields?.HomeSliderLink?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl)!,
              active: isActive,
            };
          }
          else {
            var obj: Carousel = {
              head: undefined,
              title: element.ItemFields?.HomeSliderTitle,
              Message: element.ItemFields?.HomeSliderDetails,
              image: element.ItemFields?.HomeSliderBackground?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
              link: element.ItemFields?.HomeSliderLink?.Url.replace(environment.imptempPublicUrl, environment.imgPublicUrl)!,
              active: isActive,
            };
          }

          obj.image = obj?.image?.replace(environment.imgLocalUrl, environment.imgBaseUrl);
          this.carousels.push(obj);
          //console.log(obj);

          i++;
        });
      }
    );
  }

  getConfig() {
    this.carouselService.getList('Configurations').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Configurationlist[]) => {
        this.defaultTheme = data.filter(m => m.ItemFields?.ConfigurationName == 'DefaultTheme')[0].ItemFields?.ConfigurationValue!;
        this.isEvent = data.filter(m => m.ItemFields?.ConfigurationName== 'IsEvent')[0].ItemFields?.ConfigurationValue! == '1' ? true : false;

        // if(localStorage.getItem('defaultTheme')){
        //     this.defaultTheme = localStorage.getItem('defaultTheme')?.toString()!;
        // }
       
        if (localStorage.getItem('defaultTheme') === null) {
          this.defaultTheme =  this.defaultTheme ? this.defaultTheme : '';
        } else {
          this.defaultTheme = localStorage.getItem('defaultTheme')?.toString()!;
        }
        console.log('hi'+this.defaultTheme);
      }
    );
  }

  /** Component events  */
  ngAfterViewInit(): void {
  }

  async ngOnInit() {
  
    await this.carouselService.logInAsync();
    this.getCarosal();
    this.getConfig();

    this.url = await this.apiResources.getByKey("bannervedio");
    Swiper.use([Pagination]);
    Swiper.use([EffectFade]);
    Swiper.use([Autoplay]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

}
