import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { Subject, takeUntil } from 'rxjs';
import { ListResponse } from 'src/app/models/ListResponse';

import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {
  // private map: google.maps.Map
  captcha: string;
  email: string;
  firstName: string;
  subject: string;
  message: string;
  submitted: boolean;
  looktoserveyou = '';
  unfiedNumber = '';
  mailAdderess = '';
  locationName = '';
  BeneficiarySupport = '';
  home = '';
  PageTitle: string = '';
  workinghours = '';
  EtecFullName = 'هيئة تقويم التعليم والتدريب';
  destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(private apiServices: ApiService, public Title: Title, private apiResources: ResourcesService
    , private loading: LoadingService
    , private actRoute: ActivatedRoute) {

    this.actRoute.params.subscribe(async params => {
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }
    });
    this.captcha = "";
    this.submitted = false;

    this.updateTitle();
  }

  /** Custom methods */

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }
  updateTitle() {

    this.apiServices.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ListResponse[]) => {
        data.forEach(element => {
          if (element.ItemFields.Title.toLowerCase() === 'contactus') {
            this.PageTitle = element.ItemFields.MenuName;

          }
          this.Title.setTitle(this.EtecFullName + " - " + this.PageTitle);
        });
      }
    );
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  send() {
    this.submitted = true;
  }

  /** Component events  */
  async ngOnInit() {

    this.unfiedNumber = await this.apiResources.getByKey("unfiedNumber");
    this.mailAdderess = await this.apiResources.getByKey("mailadderess");
    this.locationName = await this.apiResources.getByKey("locationname");
    this.BeneficiarySupport = await this.apiResources.getByKey("BeneficiarySupport");
    this.home = await this.apiResources.getByKey("home");
    this.looktoserveyou = await this.apiResources.getByKey("looktoserveyou");
    this.EtecFullName = await this.apiResources.getByKey("etecfullname");
    this.workinghours = await this.apiResources.getByKey("workinghours");

    /* let loader = new Loader({
       apiKey: "AIzaSyDV1Vrxmr6xtiC0wrQ9sc7u8fGz2gO34A8"
     });
 
     loader.load().then(() => {
 
       const location = { lat: 24.728995, lng: 46.597157 }
 
       const marker = new google.maps.Marker({
         position: location,
         map: this.map,
       });
     })*/
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}