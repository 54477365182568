import { Component, LOCALE_ID, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ApiService } from './services/api.service';
import { LoadingService } from './services/loading.service';
import { Subject, takeUntil } from 'rxjs';
import { Metadatalist } from './models/metadatalist';
import { MetaData, Resources } from './models/ResponseModel';
import { Meta } from '@angular/platform-browser';
import { ResourcesService } from './services/resources.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import * as AOS from 'aos';
import { Spinkit } from 'ng-http-loader';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { Configurationlist } from './models/configurationlist';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent {

  title = 'EtecPortal';
  Tag = '';
  Value = '';
  language = 'none';
  public loading = false;
  logo = 'https://beta.etec.gov.sa/assets/media/logos/etec-OI.svg';
  MetaData: MetaData[] = [];
  Resources: Resources[] = [];
  loading$ = this.loader.loading$;
  typeSelected: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  defaultTheme = 'Default';
  isEvent = false;
  public spinkit = Spinkit;
  public spinnerComponent = SpinnerComponent;

  constructor(private loader: LoadingService, @Inject(DOCUMENT) private document: Document, @Inject(LOCALE_ID) protected localeId: string, private resources: ResourcesService
    , private changeDetector: ChangeDetectorRef, private actRoute: ActivatedRoute, private apiServices: ApiService, private metaTagService: Meta, private router: Router, private scroll: ViewportScroller) {
  }

  /** Custom methods */
  prepareDocument() {

    let _lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';

    var linkRtl = '<link rel="stylesheet" href="https://cdn.etec.gov.sa/theme-rtl.css" id="rtl"/>';
    var linkLtr = '<link rel="stylesheet" href="https://cdn.etec.gov.sa/theme-ltr.css" id="ltr" />';

    if (_lang == 'en') {

      var a = document.getElementById('rtl');
      a?.parentNode?.removeChild(a);
      document.body.dir = "ltr";
    }
    else {
      var a = document.getElementById('ltr');
      a?.parentNode?.removeChild(a);
      document.body.dir = "rtl";
    }


    if (localStorage.getItem('defaultTheme') === null) {
      document.body.setAttribute("data-event", this.defaultTheme);
    } else {
      document.body.setAttribute("data-event", localStorage.getItem('defaultTheme')!);
    }



    let _darkMode = localStorage.getItem('darkTheme')?.toString();
    document.body.setAttribute("data-theme", _darkMode!);

    // if (!this.isEvent) {
    //   let _eventMode = localStorage.getItem('eventTheme')?.toString();
    //   document.body.setAttribute("data-theme", _eventMode!);
    // }


    let font = localStorage.getItem('fontsize')?.toString();

    var root = document.getElementsByTagName('html')[0];
    root.setAttribute('class', font!);

  }

  getConfig() {
    this.apiServices.getList('Configurations').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Configurationlist[]) => {
        if(data .length > 0 ){
          this.defaultTheme = data.filter(m => m.ItemFields?.ConfigurationName == 'DefaultTheme')[0].ItemFields?.ConfigurationValue!;
          this.isEvent = data.filter(m => m.ItemFields?.ConfigurationName == 'IsEvent')[0].ItemFields?.ConfigurationValue! == '1' ? true : false;
        }
        this.prepareDocument();
      }
    );
  }


  getMetaData() {
    this.apiServices.getList('Metadata').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Metadatalist[]) => {
        data.forEach(element => {
          var obj: MetaData = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            Tag: element.ItemFields.Tag,
            Value: element.ItemFields.Value,
            IsSocial: element.ItemFields.IsSocial
          };

          if (obj.IsSocial) {
            this.metaTagService.addTag({ property: obj.Tag, content: obj.Value });
          }
          else {
            this.metaTagService.addTag({ name: obj.Tag, content: obj.Value });
          }
        });
      }
    );
  }

  /** Component events  */
  async ngOnInit() {
    this.getConfig();
    await this.apiServices.logInAsync();
    await this.getMetaData();
    AOS.init();
  }

  onActivate() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'auto'
        });
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 500);

  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
