<div class="container">
    <div class="row row-cols-lg-2 row-cols-1 g-5">
        <div class="col d-flex" style="min-height:12.5rem;" *ngFor="let item of Identityguidelines">
            <article class="card w-100 h-100 border-0 shadow rounded-5 position-relative">
                <div class="card-body d-flex justify-content-center align-items-center">
                    <img src="{{item.MCFileThumbnails}}" width="300" alt="Image" dawonlad>
                </div>
                <a class="text-start w-100 text-decoration-none py-4 px-3 rounded-3 bg-primary text-white mb-3 h6 mb-0 position-absolute top-100 start-50 translate-middle"
                    style="padding-top: 15px !important;padding-bottom: 15px !important;max-width: 13rem;"
                    target="_blank" href="{{item.MCFileLink}}">
                    {{logodownload}}
                    <div class="d-flex justify-content-center align-items-center bg-warning p-2 h-100 position-absolute top-50 end-0 translate-middle-y rounded-3"
                        style="width:55px;">
                        <i class="bx bx-cloud-download h2 text-white mb-0"></i>
                    </div>
                </a>
            </article>
        </div>
    </div>
    <div class="row mt-5 pt-3">
        <div class="col-12 d-flex" style="min-height:12.5rem;" *ngIf="!ImageLinkguidePDF">
            <div
                class="d-flex flex-lg-row flex-column align-items-center justify-content-lg-between bg-primary card w-100 position-relative p-4">
                <div class="ps-md-3">
                    <h3 class="h4 text-white">
                        {{etecidguide}}
                    </h3>
                    <a class="d-flex text-start text-decoration-none rounded-3 position-relative bg-white text-primary h6 py-4 px-3 mt-4 mb-0"
                        style="padding-top: 15px !important;padding-bottom: 15px !important;max-width: 13rem;"
                        target="_blank" href="{{LinkguidePDF}}">
                        {{logodownload}}
                        <div class="d-flex justify-content-center align-items-center bg-warning p-2 h-100 position-absolute top-50 end-0 translate-middle-y rounded-3"
                            style="width:55px;">
                            <i class="bx bx-cloud-download h2 text-white mb-0"></i>
                        </div>
                    </a>
                </div>
                <div class="guide-manual-img">
                    <img src="{{ImageLinkguidePDF}}" width="400" alt="Digital Marketing"
                        class="me-md-4 mb-md-0 mb-3">
                </div>
            </div>
        </div>
    </div>
</div>