import { Component, Input, OnInit } from '@angular/core';
import { DynamicDocumentList } from 'src/app/models/DynamicDocumentList';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';
import { BlockLike } from 'typescript';

@Component({
  selector: 'app-dynamic-document',
  templateUrl: './dynamic-document.component.html',
  styleUrls: ['./dynamic-document.component.css']
})
export class DynamicDocumentComponent implements OnInit {
  @Input() ContentTitle: string
  // @Input() ContentDocuments: DynamicDocumentList[]
  @Input() ItemId: number
  _ContentDocuments: DynamicDocumentList[] = []
  _ContentDocumentsResponse: DynamicDocumentList[] = []
  tags: string[] = [];
  tagsStatus: boolean[] = [];
  selectedTags: string[] = [];

  searchtxt = '';
  searchText = '';
  Category = '';
  all = '';
  more = '';
  tagsAll = true;
  showCount = 12;
  imptempPublicUrl = environment.imptempPublicUrl;
  imgPublicUrl=  environment.imgPublicUrl;
  constructor(private apiResources: ResourcesService, private apiServices: ApiService) { }

  async ngOnInit(): Promise<void> {

    this.searchtxt = await this.apiResources.getByKey("search");
    this.Category = await this.apiResources.getByKey("Category");
    this.all = await this.apiResources.getByKey('all');
    this.more = await this.apiResources.getByKey('more');
  }

  ngOnChanges() {
    this.apiServices.GetListsItemsFilterd('PublicContentDocuments', 0, false, 'ContentTitle.LookupId', this.ItemId.toString())
      .subscribe(
        (data: DynamicDocumentList[]) => {
          this._ContentDocuments = data;
          this._ContentDocumentsResponse = data;
          this._ContentDocuments.forEach(element => {
            if (element.ItemFields?.DocumentCategory && this.tags.indexOf(element.ItemFields?.DocumentCategory?.LookupValue) == -1) {
              this.tags.push(element.ItemFields?.DocumentCategory?.LookupValue)
              this.tagsStatus.push(true)
            }
          });
        }
      );


  }


  getTagsResult() {
    if (!this.tagsAll) {
      this._ContentDocumentsResponse = this._ContentDocuments.filter(x => this.selectedTags.indexOf(x.ItemFields?.DocumentCategory?.LookupValue!) != -1);

    } else {
      this._ContentDocumentsResponse = [... this._ContentDocuments];
    }

    if (this.searchText != '') {
      if (!this.tagsAll) {
        this._ContentDocumentsResponse = this._ContentDocuments.filter(x => this.selectedTags.indexOf(x.ItemFields?.DocumentCategory?.LookupValue!) != -1);
      }
      this._ContentDocumentsResponse = this._ContentDocuments.filter(x => x.ItemFields?.DocumentName?.toUpperCase().includes(this.searchText.toUpperCase()));
    }
    console.log(this.tags)
  }

  changeTage() {
    this.tagsStatus.forEach((element, index) => {
      this.tagsStatus[index] = this.tagsAll;
    });
    this.filterChange();
  }

  filterChange() {
    this.selectedTags = [];

    this.tagsStatus.forEach((element, index) => {
      if (element === true) {
        this.selectedTags.push(this.tags[index]);
      }
      //this.tagsNumber.push(0);
    });

    this.tagsAll = true;
    this.tagsStatus.forEach((element) => {
      if (!element) {
        this.tagsAll = false;
      }
    });

    console.log(this.tags);
    this.getTagsResult();
  }
}
