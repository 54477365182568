import { Component, OnInit, ViewChild } from '@angular/core';
import domtoimage from 'dom-to-image';
import { Observable, Observer } from 'rxjs';


@Component({
  selector: 'app-greatings',
  templateUrl: './greatings.component.html',
  styleUrls: ['./greatings.component.css']
})
export class GreatingsComponent implements OnInit {
  @ViewChild('container') container: { nativeElement: Node; }; 
  imageUrl: string = '';
  title = "أ";
  name = "";
  base64Image: any;
  ctx: CanvasRenderingContext2D | null;

  constructor() { }

  ngOnInit(): void {
    
  }

  downloadImage() {
    setTimeout(() => domtoimage.toSvg(this.container.nativeElement)
    .then( (dataUrl) => {
        var img = new Image();
        img.src = dataUrl;
       // document.body.appendChild(img);
       this.imageUrl = dataUrl;

       let imageUrl =
       dataUrl;

       this.getBase64ImageFromURL(imageUrl).subscribe((base64data: string) => {
       
        this.base64Image = "data:image/jpg;base64," + base64data;
        // save image to disk
        var link = document.createElement("a");
  
        document.body.appendChild(link); // for Firefox
  
        link.setAttribute("href", this.base64Image);
        link.setAttribute("download", "mrHankey.jpg");
        link.click();
      });
    })
    .catch(function (error) {
        console.error('oops, something went wrong!', error);
    }), 500);
    
  

   
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    this.ctx = canvas.getContext("2d");
    this.ctx?.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}
