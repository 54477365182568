<div class="row">
    <div class="col">
        <div  class="d-flex justify-content-center align-items-center">
            <select name="title" [(ngModel)]="title">
                <option value="أ">أ</option>
                <option value="م">م</option>
                <option value="د">د</option>
            </select>

            <input type="text" [(ngModel)]="name" />
            <button (click)="downloadImage()" class="btn btn-outline-primary">تنزيل</button>
        </div>
    </div>
</div>


<div class="container-fluid" #container>
<div class="row">
    <div class="col">
        <div class="d-flex justify-content-center align-items-center">
           
            <div class="bg-repeat-0 bg-position-center bg-size-cover" style="background-image: url('assets/img/greating.png');width: 414px;height: 750px;">
                <div class="position-relative text-center" style="top:525px">
                    <p class="m-0" style="font-family:'DINNext' !important;color: green;">{{title}}</p>
                </div>
                <div class="position-relative text-center" style="top:575px">
                    <p class="m-0" style="font-family:'DINNext' !important;color: red;">{{name}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div>
    <img src="{{imageUrl}}"/>
</div>






