import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ResourcesService } from 'src/app/services/resources.service';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

@Component({
  selector: 'app-shareicons',
  templateUrl: './shareicons.component.html',
  styleUrls: ['./shareicons.component.css'],
  standalone:true,
  imports:[CommonModule,ShareButtonsModule]
})
export class ShareiconsComponent implements OnInit {

  public currentUrl : string ;
  @Input() dynamicUrl: string ;
  etec ='';
  share ='';

  constructor(private apiResources: ResourcesService) {this.currentUrl =  window.location.href; }

 /** Component events  */
  async ngOnInit() {
    this.etec = await this.apiResources.getByKey("EtecFullName");
    this.share = await this.apiResources.getByKey("share");
  }

}
