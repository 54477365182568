import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { EServiceGuidList } from 'src/app/models/EServiceGuidList';
import { ServiceStructureTagsResponse } from 'src/app/models/ServiceStructureTagsResponse';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-center-service-catalog',
  templateUrl: './center-service-catalog.component.html',
  styleUrls: ['./center-service-catalog.component.css'],
})
export class CenterServiceCatalogComponent implements OnInit {
  searchtxt: string = '';
  searchText: string = '';
  more: string = '';
  @Input() centerName: string;
  centerNameStr: string;
  serviceGuid : string = '';
  showCount = 12;
  servicesof = '';
  responseService: EServiceGuidList[];
  response: EServiceGuidList[];
  tags: ServiceStructureTagsResponse[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private apiServices: ApiService,
    private apiRersources: ResourcesService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.more = await this.apiRersources.getByKey('more');
    this.serviceGuid  =  await this.apiRersources.getByKey("ServiceGuide");
    this.servicesof = await this.apiRersources.getByKey("servicesof");
  }

  getServiceGuid(centerName: string) {
    this.apiServices
      .getListTopN('EServiceGuide', 0, true, "CenterName", centerName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: EServiceGuidList[]) => {
        this.responseService = data.sort((a, b) => (a.ItemFields?.EServiceOrder - b.ItemFields?.EServiceOrder));
        this.response = [... this.responseService];
        this.getStructureTags();
      });
  }

  search() {
    this.response = this.responseService.filter(x => x.ItemFields?.ServiceGuideTitle?.toUpperCase().includes(this.searchText.toUpperCase()))
  }


  putVisitNo(itemId: string) {
    this.apiServices.putVisitingCount('EServiceGuide', itemId).subscribe();
  }

  navigateUrl(product: EServiceGuidList) {
  
    this.putVisitNo(product.ItemId.toString());
    this.router.navigateByUrl('/servicecatalogdetails/' + product.ItemFields?.Title);

  }

  getStructureTags() {
    this.apiServices
      .getList('ServiceStructureTags')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ServiceStructureTagsResponse[]) => {
        this.tags = data;
       
        this.responseService.forEach(element => {
          element.ItemFields.ServiceGuidIcon = this.tags.filter(m => m.ItemId == element.ItemFields.ServiceStructureTag.LookupId)[0].ItemFields.TagImage?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl);
        });
       
      });
  }
  
  ngOnChanges() {
    this.centerNameStr = this.centerName;

    this.getServiceGuid(this.centerNameStr);
  }
}
