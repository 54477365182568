<!--begin::footer-->
<footer class="footer position-relative bg-infoLight border-top border-light py-4 mt-5">
    <div class="container pt-xl-4">
      <div class="row pb-3">
        <!-- important links -->
        <div class="col-xl-3 col-sm-6 col-xs-12">
          <p class="text-primary fw-semibold fs-lg d-flex" data-aos="flip-up" data-aos-duration="800">{{importantLinks}}</p>
      <app-importantlinks></app-importantlinks>
        </div>
        <!-- centers -->
        <div class="col-xl-3 col-sm-6 col-xs-12 mt-xl-0 mt-sm-0 mt-5">
          <p class="text-primary fw-semibold fs-lg d-flex" data-aos="flip-up" data-aos-duration="800">{{Centers}}</p>
          <div class="d-lg-block" style="" data-aos="fade-up" data-aos-duration="1200">
            <ul class="nav flex-column justify-content-between pb-lg-1 mb-lg-3">
              <li *ngFor="let element of centers" class="nav-item w-xl-75 w-100">
                <a href="{{_lang}}/{{element.ItemFields?.CenterCode?.toLowerCase()}}" class="nav-link d-inline-block px-0 pt-1 pb-2 w-100 fs-sm">{{element.ItemFields?.CenterShortName}}</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- services -->
        <div class="col-xl-3 col-sm-6 col-xs-12 mt-xl-0 mt-5">
          <p class="text-primary fw-semibold fs-lg d-flex" data-aos="flip-up" data-aos-duration="800">{{services}}</p>
          <div class="d-lg-block" style="" data-aos="fade-up" data-aos-duration="1200">
            <ul class="nav flex-column justify-content-between pb-lg-1 mb-lg-3">
              <li class="nav-item w-xl-75 w-100"><a href="{{_lang}}/services/indviduals" class="nav-link d-inline-block px-0 pt-1 pb-2 w-100 fs-sm"> {{ServicesforIndividuals}}</a></li>
              <li class="nav-item w-xl-75 w-100"><a href="{{_lang}}/services/corporate" class="nav-link d-inline-block px-0 pt-1 pb-2 w-100 fs-sm"> {{ServicesforBusinesses}}</a></li>
              <li class="nav-item w-xl-75 w-100"><a href="{{_lang}}/serviceguidecatalog" class="nav-link d-inline-block px-0 pt-1 pb-2 w-100 fs-sm"> {{serviceGuid}}</a></li>
            </ul>
          </div>
        </div>
        <!-- social -->
        <div class="col-xl-3 col-sm-6 col-xs-12 mt-xl-0 mt-5">
          <p class="text-primary fw-semibold fs-lg d-flex" data-aos="flip-up" data-aos-duration="800"> {{FollowUson}}  </p>
          <div data-aos="flip-up" data-aos-duration="1200">
            <a href="https://www.facebook.com/ETECKSA" class="btn btn-outline-primary btn-icon border-1" style="width: 2rem;height: 2rem;"><i class="bx bxl-facebook"></i></a>
            <a href="https://www.twitter.com/EtecKsa" class="btn btn-outline-primary btn-icon ms-2 border-1" style="width: 2rem;height: 2rem;">
              <!-- <i class="bx bxl-twitter"></i> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/></svg>
            </a>
            <a href="https://www.youtube.com/etecksa" class="btn btn-outline-primary btn-icon ms-2 border-1" style="width: 2rem;height: 2rem;"><i class="bx bxl-youtube"></i></a>
            <a href="https://www.instagram.com/etecksa?igsh=MmVlMjlkMTBhMg==" class="btn btn-outline-primary btn-icon ms-2 border-1" style="width: 2rem;height: 2rem;"><i class="bx bxl-instagram-alt"></i></a>
            <a href="https://ae.linkedin.com/company/etec-ksa" class="btn btn-outline-primary btn-icon ms-2 border-1" style="width: 2rem;height: 2rem;"><i class="bx bxl-linkedin"></i></a>
          </div>
          <form class="needs-validation mt-5" *ngIf="!isDone">
            <label for="subscr-email" class="form-label text-primary fw-semibold fs-lg" data-aos="flipl-up" data-aos-duration="800">{{NewsLitter}}</label>
            <div class="input-group mt-3" data-aos="flip-up" data-aos-duration="1500">
              <input class="form-control ps-5 form-control rounded-start  fs-sm" type="email" id="email" placeholder="{{emailtxt}}" required="" [(ngModel)]="email" required="{{requiredfield}}" name="email" autocomplete="one-time-code" required [dir]="_lang =='ar'?'rtl':'ltr'">
              <i class="bx bx-envelope fs-xl text-primary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
              <div class="invalid-tooltip position-absolute top-100 start-0">{{invalidemail}}</div>
              <button type="submit" class="btn btn-sm btn-primary ms-2 rounded-3 px-3  fs-sm" 
              (click)="sendSubscribtion()">{{SubscribeText}}</button>
            </div>
          </form>
        </div>
      </div>

    </div>

      <app-scroll-totop></app-scroll-totop>

  </footer>
  <div class="footer bg-white border-0 py-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-12 d-flex justify-content-center justify-content-lg-start align-items-center">
          <p class="fs-sm text-primary m-0 text-center">{{CopyRights.replace("{0}", curYear.toString())}}</p>
        </div>
      </div>
    </div>
  </div>
<!-- Offcanvas Cookie -->
<div class="offcanvas cookie show offcanvas-bottom bg-secondary border-0" tabindex="-1" id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel" data-bs-scroll="true" data-bs-backdrop="false"
    style="opacity: 93%;" *ngIf="!isAgree">

    <div class="offcanvas-body p-3" >
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <h5 *ngIf="cookiedisclaimer" class="text-primary fs-lg" [innerHtml]='cookiedisclaimer?.replace("{0}","<br/>")?.replace("{1}","")'> </h5>
                    <a class="text-decoration-underline text-primary mb-lg-0 mb-2 d-block fs-sm" target="_blank"
                        href="./page/privacy" [innerText]="privacypolicy"></a>
                </div>
                <div class="col-lg-6 col-12 d-flex justify-content-lg-end justify-content-center align-items-center">
                    <button type="button" class="btn btn-light btn-md" data-bs-dismiss="offcanvas" aria-label="Close"
                        (click)="agree()" [innerText]="ok" style="width:6rem;"> </button>
                </div>
            </div>
        </div>
    </div>
</div>























<!-- 
<footer class="footer bg-primary border-top border-light py-4 mt-5">
    <div class="container pt-lg-4">
        <div class="row pb-3">
            <div class="col-xl-4 col-lg-4 col-md-6">
                <p class="fw-semibold fs-sm text-secondary p-0 me-0 mb-3 mb-lg-4 ">
                    {{ETEC}}
                </p>
                <p class="fs-sm text-light opacity-70 pb-lg-3 mb-4" style="text-align: justify;">
                    {{EtecFooterDescription}}
                </p>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-6 offset-xl-1 offset-lg-1 offset-md-0 pt-4 pt-md-1 pt-lg-0">
                <div id="footer-links" class="row">
                    <div class="col-xl-6 col-lg-4">
                        <app-importantlinks [title]="ImportantLinks"></app-importantlinks>
                    </div>
                    <div class="col-xl-6 col-lg-8 pt-2 pt-lg-0" *ngIf="!isDone">
                        <form class="needs-validation">
                            <label for="subscr-email"
                                class="form-label text-secondary fw-semibold fs-sm">{{NewLitters}}</label>
                            <div class="input-group mt-3">
                                <input type="email" id="email" placeholder="{{emailtxt}}" required=""
                                    style="direction: rtl;" [(ngModel)]="email" required="{{requiredfield}}" name="email" autocomplete="one-time-code" required class="form-control ps-5 form-control rounded-start fs-sm">

                                <i
                                    class="bx bx-envelope fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>

                                <div class="invalid-tooltip position-absolute top-100 start-0"> {{invalidemail}}

                                </div>
                                <button type="submit" class="btn btn-sm btn-secondary ms-2 rounded-3 px-3 fs-sm"
                                    (click)="sendSubscribtion()">{{SubscribeText}}</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-12 d-flex justify-content-center justify-content-lg-start align-items-center">
                <p class="fs-sm text-white m-0 fs-sm">{{CopyRights.replace("{0}", curYear.toString())}}</p>
            </div>
            <div
                class="col-lg-7 col-12 d-flex justify-content-lg-end justify-content-center align-items-center mt-lg-0 mt-3">
                <a href="https://www.facebook.com/ETECKSA" class="btn btn-outline-light btn-icon me-1"
                    style="width: 2rem;height: 2rem;"><i class="bx bxl-facebook"></i></a>

                <a href="https://www.twitter.com/EtecKsa" class="btn btn-outline-light btn-icon me-1"
                    style="width: 2rem;height: 2rem;"><i class="bx bxl-twitter"></i></a>

                <a href="https://www.youtube.com/etecksa" class="btn btn-outline-light btn-icon me-1"
                    style="width: 2rem;height: 2rem;"><i class="bx bxl-youtube"></i></a>

                <a href="javascript:void(0);" class="btn btn-outline-light btn-icon me-1"
                    style="width: 2rem;height: 2rem;"><i class="bx bxl-instagram-alt"></i></a>

                <a href="https://ae.linkedin.com/company/etec-ksa" class="btn btn-outline-light btn-icon"
                    style="width: 2rem;height: 2rem;"><i class="bx bxl-linkedin"></i></a>
            </div>
        </div>
    </div>
</footer> -->

