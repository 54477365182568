import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-researcher-list',
  templateUrl: './researcher-list.component.html',
  styleUrls: ['./researcher-list.component.css']
})
export class ResearcherListComponent implements OnInit {

  @Input() educationList: any[];
  @Output() educationListChange = new EventEmitter<any[]>();

  localEducationList : any[];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.localEducationList = this.educationList;
    this.educationListChange.emit(this.educationList);
  }

  removeResearcher(nationalId: string , guid: string){
    this.http
    .post(`${environment.tarteebUrl}RemoveResearch?nationalId=${nationalId}&guid=${guid}`, {
      reportProgress: true,
      observe: "events"
    })
    .pipe(
      map((event: any) => {
        this.localEducationList = [...  this.localEducationList .filter(item => item.nationalId != nationalId  )];
        //this.educationList  = this.localEducationList;
        this.educationListChange.emit(this.localEducationList);

        console.log( this.localEducationList)
      }),

      catchError((err: any) => {
        alert(err.message);
        return throwError(err.message);
      })
    )
    .toPromise();

  }
}
