import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from '../services/api.service';
import { PublicContents } from '../models/ResponseModel';
import { Publiccontentslist } from '../models/publiccontentslist';
import { ResourcesService } from '../services/resources.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { DynamicDocumentList } from '../models/DynamicDocumentList';
import { ServicesResponse } from '../models/ServicesResponse';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  PageTitle = '';
  privacypolicy = '';
  home = '';
  content = '';
  hasSubContent = false;
  hasCustomContent = false;
  activeId = 0;
  _lang = '';
  isService = false;
  _routeParameter = '';
  _route = '';
  chilContentList: Publiccontentslist[] = [];
  customDocumentList: DynamicDocumentList [] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private actRoute: ActivatedRoute, private apiServices: ApiService
      , private apiResources: ResourcesService
      ,private router: Router,
    private loading: LoadingService) {
    this.actRoute.params.subscribe(async params => {
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }
    });

    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  async ngOnInit() {
    this.actRoute.params.subscribe(async params => {

      if(params["id"] && params["id"] === 'service-details' && !params["route"]){
        this.router.navigateByUrl('/notfound')
      }
      this.apiServices.getListWithTwoConditionsTopN('PublicContents', 1, false,  "ContentCode", params["id"], "RouteParameter",  params["route"]?params["route"] : '', "Eq",  params["route"]? "And":"", "Eq")
      .pipe(takeUntil(this.destroy$)).subscribe(
        (data: Publiccontentslist[]) => {
          data.forEach(element => {
            var obj: PublicContents = {
              ID: element.ItemFields.ID,
              Title: element.ItemFields.Title,
              PublicContentTitle: element.ItemFields.PublicContentTitle,
              PublicContentDetails: element.ItemFields.PublicContentDetails,
              HasSubContent: element.ItemFields.HasSubContent,
              HasCustomContent:  element.ItemFields.HasCustomContent,
              RouteParameter: element.ItemFields?.RouteParameter,
              ContentCode: element.ItemFields?.ContentCode,
            };
            this.privacypolicy = obj.PublicContentTitle;
          
            //show page content if has no child
            if (!obj.HasSubContent && !obj.HasCustomContent) {
              this.content = obj.PublicContentDetails;
              this.privacypolicy = obj.PublicContentTitle;
            }
            else if (!obj.HasSubContent && obj.HasCustomContent){
              this.hasCustomContent = true;
              this.apiServices.GetListsItemsFilterd('PublicContentDocuments', 0, false, 'ContentTitle.LookupValue', obj.PublicContentTitle)
              .subscribe(
                (data: DynamicDocumentList[]) => {
                  this.customDocumentList = data ;
                }
              );
            }
            //else if (obj.HasSubContent && obj.HasCustomContent){
              else if (obj.RouteParameter ){
              this.hasSubContent = true;
              this.hasCustomContent = true;
              this.apiServices.GetListsItemsFilterd('services', 0, false, 'ServiceCode', obj.RouteParameter!)
              .subscribe(
                (data: ServicesResponse[]) => {
                  if(data.length > 0 ){
                    this.isService = true;
                    this._routeParameter =  obj.RouteParameter!;
                  }
                }
              );
            }
            //build child view while it has childs
            else {
              this.hasSubContent = true;
              this.apiServices.GetListsItemsFilterd('PublicContents', 0, false, 'ParentContent.LookupValue', obj.Title)
                .subscribe(
                  (data: Publiccontentslist[]) => {
                    this.chilContentList = data.sort((a, b) => a.ItemFields?.ContentOrder! - b.ItemFields?.ContentOrder!)

                    this.activeId = data[0].ItemId;
                  }
                );
            }
          });

          if(data.length == 0){
            this.router.navigateByUrl('/notfound')
            console.log('from here')
          }
        }
      );

    });

    //this.privacypolicy = await this.apiResources.getByKey("privacypolicy");
    this.home = await this.apiResources.getByKey("home");
  }
}
