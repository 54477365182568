<main>
    <div>
        <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">

            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="bd-placeholder-img" src="./assets/img/BG/tras-bg.svg" />
                </div>
            </div>
        </div>
        <div class="container ">
            <div class="tab-nav">
                <ul class="nav tab justify-content-center">
                    <li class="nav-item ">
                        <a class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" role="tab"
                            aria-controls="home" aria-selected="true">
                            <span class="tab-span">
                                <span class="tab-icon">
                                    <svg id="Group_3939" data-name="Group 3939" xmlns="http://www.w3.org/2000/svg"
                                        width="65" height="65" viewBox="0 0 65 65">
                                        <path id="Path_3451" data-name="Path 3451"
                                            d="M469.579,122.984a12.759,12.759,0,0,0-12.5,12.98h6.353a6.144,6.144,0,1,1,12.287,0c0,1.971-1.525,3.4-3.863,5.363q-1.144.93-2.2,1.977a10.922,10.922,0,0,0-3.263,7.171v2.2h6.353V150.59a4.562,4.562,0,0,1,1.4-2.615c.541-.533,1.111-1.037,1.7-1.512,2.475-2.082,6.219-5.226,6.219-10.5a12.753,12.753,0,0,0-12.493-12.98m-3.176,32.99h6.353v6.6H466.4Z"
                                            transform="translate(-437.079 -110.276)" fill="#fff" />
                                        <path id="Path_3452" data-name="Path 3452"
                                            d="M482,117.984a32.5,32.5,0,1,0,32.5,32.5,32.5,32.5,0,0,0-32.5-32.5m0,58.5a26,26,0,1,1,26-26,26,26,0,0,1-26,26"
                                            transform="translate(-449.499 -117.984)" fill="#fff" />
                                    </svg>
                                </span>
                                <span class="tab-text" i18n="@@aboutCenter">
                                    {{aboutcenter}}
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" role="tab"
                            aria-controls="profile" aria-selected="false">
                            <span class="tab-span">
                                <span class="tab-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="42.386" height="52.985"
                                        viewBox="0 0 42.386 52.985">
                                        <path id="Path_3481" data-name="Path 3481"
                                            d="M517.649,289.582a13.822,13.822,0,0,0,13.507,14.129q.311.008.623,0a13.819,13.819,0,0,0,14.127-13.5c.005-.208.005-.415,0-.625a13.819,13.819,0,0,0-13.5-14.127c-.207-.005-.415-.005-.623,0a13.821,13.821,0,0,0-14.13,13.5q-.008.312,0,.623m21.193,0a6.757,6.757,0,0,1-6.433,7.066q-.315.015-.63,0a6.758,6.758,0,0,1-7.066-6.433c-.01-.21-.01-.423,0-.633a6.757,6.757,0,0,1,6.433-7.063c.21-.01.423-.01.633,0a6.758,6.758,0,0,1,7.064,6.433q.014.315,0,.63M517.649,324.9a10.61,10.61,0,0,1,10.6-10.6h7.064a10.61,10.61,0,0,1,10.6,10.6v3.532h7.066V324.9a17.683,17.683,0,0,0-17.661-17.661h-7.064A17.681,17.681,0,0,0,510.585,324.9v3.532h7.064Z"
                                            transform="translate(-510.585 -275.451)" fill="#fff" />
                                    </svg>
                                </span>
                                <span class="tab-text" i18n="@@CEO">
                                    {{ceo}}
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" role="tab"
                            aria-controls="contact" aria-selected="false">
                            <span class="tab-span">
                                <span class="tab-icon">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="50.082" height="50.082"
                                        viewBox="0 0 50.082 50.082">
                                        <path id="Path_3430" data-name="Path 3430"
                                            d="M4.108,25.24H20.8a2.783,2.783,0,0,0,2.782-2.782V5.763A2.783,2.783,0,0,0,20.8,2.981H4.108A2.783,2.783,0,0,0,1.326,5.763V22.457A2.783,2.783,0,0,0,4.108,25.24M6.891,8.546H18.02V19.675H6.891ZM48.626,2.981H31.932a2.783,2.783,0,0,0-2.782,2.782V22.457a2.783,2.783,0,0,0,2.782,2.782H48.626a2.783,2.783,0,0,0,2.782-2.782V5.763a2.783,2.783,0,0,0-2.782-2.782M45.843,19.675H34.714V8.546H45.843ZM20.8,53.063a2.783,2.783,0,0,0,2.782-2.782V33.587A2.783,2.783,0,0,0,20.8,30.8H4.108a2.783,2.783,0,0,0-2.782,2.782V50.281a2.783,2.783,0,0,0,2.782,2.782ZM6.891,36.369H18.02V47.5H6.891Zm36.17-2.782H37.5v5.565H31.932v5.565H37.5v5.565h5.565V44.716h5.565V39.151H43.061Z"
                                            transform="translate(-1.326 -2.981)" fill="#fff" />
                                    </svg>
                                </span>
                                <span class="tab-text">
                                    المنتجات
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="accrediation-tab" data-bs-toggle="tab" data-bs-target="#accrediation"
                            role="tab" aria-controls="contact" aria-selected="false">
                            <span class="tab-span">
                                <span class="tab-icon">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="50.082" height="50.082"
                                        viewBox="0 0 50.082 50.082">
                                        <path id="Path_3430" data-name="Path 3430"
                                            d="M4.108,25.24H20.8a2.783,2.783,0,0,0,2.782-2.782V5.763A2.783,2.783,0,0,0,20.8,2.981H4.108A2.783,2.783,0,0,0,1.326,5.763V22.457A2.783,2.783,0,0,0,4.108,25.24M6.891,8.546H18.02V19.675H6.891ZM48.626,2.981H31.932a2.783,2.783,0,0,0-2.782,2.782V22.457a2.783,2.783,0,0,0,2.782,2.782H48.626a2.783,2.783,0,0,0,2.782-2.782V5.763a2.783,2.783,0,0,0-2.782-2.782M45.843,19.675H34.714V8.546H45.843ZM20.8,53.063a2.783,2.783,0,0,0,2.782-2.782V33.587A2.783,2.783,0,0,0,20.8,30.8H4.108a2.783,2.783,0,0,0-2.782,2.782V50.281a2.783,2.783,0,0,0,2.782,2.782ZM6.891,36.369H18.02V47.5H6.891Zm36.17-2.782H37.5v5.565H31.932v5.565H37.5v5.565h5.565V44.716h5.565V39.151H43.061Z"
                                            transform="translate(-1.326 -2.981)" fill="#fff" />
                                    </svg>
                                </span>
                                <span class="tab-text">
                                    الاعتمادات والتراخيص
                                </span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <app-about-center [centerId]="centerId" [center]="center"></app-about-center>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <app-center-leader [centerId]="centerId" [cenetrLeader]="centerLeader"></app-center-leader>
                </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <app-center-services [centerId]="centerId"></app-center-services>
                </div>
                <div class="tab-pane fade" id="accrediation" role="tabpanel" aria-labelledby="accrediation-tab">
                    <app-accreditationresult></app-accreditationresult>
                </div>
            </div>
            <!-- FOOTER -->
        </div>
    </div>
</main>