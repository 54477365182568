import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, map, Subject, takeUntil } from 'rxjs';
import { ListResponse } from 'src/app/models/ListResponse';
import { MenuResponse } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { RouteApiService } from 'src/app/services/route-api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Configurationlist } from 'src/app/models/configurationlist';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  CopyRights: string = '';
  response: MenuResponse[] = [];
  betadisclaimer: string = '';
  search: string = '';
  headerClass = '';
  isHome = false;
  url = '';
  _lang = '';
  settings = '';
  darkMoode = '';
  demo = '';
  defaultTheme = 'Default';
  isEvent = false;
  isProducation = true;
  eventName= '';
  constructor(private apiServices: ApiService
    , private router: Router, private apiRouter: RouteApiService
    , private apiResources: ResourcesService, private loading: LoadingService) {
    //set language link
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }


    //Control scroll position 
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
      .subscribe((_e: Event) => {
        this.headerClass = window.scrollY > 50 ? 'navbar-stuck' : ' ';
      });

    //Chekc if this home page
    this.router.events.subscribe(_value => {
      this.isHome = window.location.href.toLowerCase().endsWith('home');
      this.url = this._lang == 'ar' ? window.location.href.toLowerCase().replace('/ar/', '/en/') : window.location.href.toLowerCase().replace('/en/', '/ar/');
    });
  }

  test: string[];
  /** Custom methods */
  getMenu() {

    this.apiServices.getList('sitemenu').pipe(map((res: ListResponse[]) => res.map(re => {
      return (
        {
          menuId: re.ItemId, menuName: re.ItemFields.MenuName,
          menULink: re.ItemFields.MenuLink?.Url.replace('http://baseurl.com/', '').toLocaleLowerCase(),
          menuSubLink: re.ItemFields.MenuLink?.Url.replace('http://baseurl.com/', '').toLocaleLowerCase(),
          menuVisitTotal: '0',
          Sequence: re.ItemFields.Sequence,
          isActive: re.ItemFields.IsActive1,
          parentMenuId: re.ItemFields.ParentMenuId?.LookupId
        } as MenuResponse)
    }))).subscribe({
      next: data => {
        this.response = data;
        this.response.sort((a, b) => a.Sequence - b.Sequence)
      },
      error: er => {
        this.apiServices.logIn();
      }
    })
  }

  getConfig() {
    this.apiServices.getList('Configurations').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Configurationlist[]) => {
        this.defaultTheme = data.filter(m => m.ItemFields?.ConfigurationName == 'DefaultTheme')[0].ItemFields?.ConfigurationValue!;
        this.isEvent = data.filter(m => m.ItemFields?.ConfigurationName== 'IsEvent')[0].ItemFields?.ConfigurationValue! == '1' ? true : false;
        this.isProducation = data.filter(m => m.ItemFields?.ConfigurationName== 'IsProduction')[0].ItemFields?.ConfigurationValue! == '1' ? true : false;
        this.eventName = data.filter(m => m.ItemFields?.ConfigurationName== 'EventName')[0].ItemFields?.ConfigurationValue!;
        if(localStorage.getItem('defaultTheme') === null){
          this.eventTheme =  this.defaultTheme === ''  ? false: true ;
        } else{
          this.eventTheme = localStorage.getItem('defaultTheme') ==='event' ? true : false;
        }
      }
    );
  }

  putVisitNo(itemId: string) {
    this.apiServices.putVisitingCount('SiteMenu', itemId).subscribe();
  }

  ifHasChild(menuItem: MenuResponse) {
    return this.response.find(element => element.parentMenuId == menuItem.menuId);
  }

  navigateUrl(routeUrl: string) {
    this.apiRouter.setMediaCenter(routeUrl);

    this.router.navigateByUrl('/mediacenter')

  }

  toggle(size: string) {

    this.apiRouter.setCss('html', 'font-size', size);
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  darkTheme = (localStorage.getItem('darkTheme')) == "dark" ? true : false;
  eventTheme =  localStorage.getItem('defaultTheme') === null ? true :   false;

  Change() {
    this.darkTheme = !this.darkTheme;
    localStorage.setItem('darkTheme', this.darkTheme ? "dark" : "");
    document.body.setAttribute("data-theme", this.darkTheme ? "dark" : "");
  }

  ChangeToEvent() {
    this.eventTheme = !this.eventTheme;
    localStorage.setItem('eventTheme', this.eventTheme ? "event" : "");
    localStorage.setItem('defaultTheme', this.eventTheme ? "event" : "");
    document.body.setAttribute("data-event", this.eventTheme ? "event" : "");

    window.location.reload();
  }

  increaseFont() {
    var root = document.getElementsByTagName('html')[0];
    localStorage.setItem('fontsize', 'increaseFont');
    root.setAttribute('class', 'increaseFont');
  }

  normalFont() {
    var root = document.getElementsByTagName('html')[0];
    localStorage.setItem('fontsize', '');
    root.setAttribute('class', '');
  }

  decreaseFont() {
    var root = document.getElementsByTagName('html')[0];
    localStorage.setItem('fontsize', 'decreaseFont');
    root.setAttribute('class', 'decreaseFont');
  }
  /** Component events  */
  async ngOnInit(): Promise<void> {
    // await this.apiServices.logInAsync();
    this.getMenu();
    this.getConfig();
    this.settings = await this.apiResources.getByKey("settings");
    this.darkMoode = await this.apiResources.getByKey("darkmode");
    this.demo = await this.apiResources.getByKey("Demo");
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
