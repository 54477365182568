import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';

import { CookieService } from 'ngx-cookie-service';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { ActivePollAnswerList } from 'src/app/models/ActivePollAnswerList';

import { PollAnswersList } from 'src/app/models/PollAnswersList';
import { PollList } from 'src/app/models/PoolsList';

import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
@Component({
  selector: 'app-survay',
  templateUrl: './survay.component.html',
  styleUrls: ['./survay.component.css']
})
export class SurvayComponent implements OnInit {
  bgcolors = ["bg-success", "bg-warning", "bg-info", "bg-danger"];
  colors = ["text-success", "text-warning", "text-info", "text-danger"];
  bgprogress = ["pbg-success", "pbg-warning", "pbg-info", "pbg-danger"];
  Answers = [{ "answer": "", "result": 0 }];
  pollList: PollList;
  pollItemId = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isDone = false;
  opinionpoll = '';
  selectananswer = '';
  voteresult = '';
  vote = '';
  back = 'رجوع';
  isshow = false;
  isLoad = false;
  ShowSurvayResult = false;
  ShowSurvayOptions = true;
  form: UntypedFormGroup = new UntypedFormGroup({
    answer: new UntypedFormControl('')
  });
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder, private apiServices: ApiService, private cookieService: CookieService, private apiResources: ResourcesService) {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {
        if (window.scrollY > 1750 && !this.isLoad) {
          this.isLoad = true;
          this.apiServices.getList('polls').pipe(takeUntil(this.destroy$)).subscribe(
            (data) => {
              this.pollList = data[0];
              this.pollItemId = this.pollList.ItemId;
              this.isDone = this.getCookie();
              if (this.isDone) {
                this.showResult();
              }
            });
          this.form = this.formBuilder.group(
            {
              answer: ['', Validators.required]
            },
            {

            }
          );
          
        }
      });
  }
 
 /** Custom methods */
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  initialPoll() {
    this.submitted = false;
    this.ShowSurvayOptions = true;
    this.ShowSurvayResult = false;
    this.isshow = false;

  }

  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.apiServices.putPollData(this.pollItemId, this.form.value.answer).subscribe(
      () => {
        this.getResult(this.pollItemId)
        this.ShowSurvayResult = true;
        this.ShowSurvayOptions = false;

        this.setCookie();
        this.isDone = true;
        this.submitted = true;
      }
    );


  }

  setCookie() {
    this.cookieService.set('poll', 'true', 7);
  }

  getCookie() {
    return this.cookieService.get('poll') ? true : false;
  }

  getResult(pollid: number) {
    this.Answers = [];
    this.pollList?.ItemFields?.PollChoices?.forEach(element => {
      this.Answers.push({ "answer": element.LookupValue, "result": 0 });
    });

    // this.apiServices.getList('pollanswers').pipe(takeUntil(this.destroy$)).subscribe(
    //   (data: PollAnswersList[]) => {
    //     data.forEach(element => {
    //       this.Answers.forEach(answerElement => {
    //         if (element.ItemFields?.PollChoiceId) {
    //           if (answerElement.answer === element.ItemFields?.PollChoiceId?.LookupValue) {
    //             answerElement.result = answerElement.result + 1;
    //           }
    //         }
    //       });
    //     });

    //     this.Answers.sort((a, b) => b.result - a.result);
    //   }
    // );

    this.apiServices.GetActivePollAnswer().pipe(takeUntil(this.destroy$)).subscribe(
      (data: ActivePollAnswerList) => {
       
        this.Answers.forEach(answerElement => {

          answerElement.result = Math.round( Number(data?.PollAnswers?.filter(x=> x.PollChoiceName == answerElement.answer)[0]?.PollChoiceIdPercentFormatted) * 100);
        });

        //this.Answers.sort((a, b) => b.result - a.result);
      }
    );
  }

  showResult() {
    this.getResult(this.pollItemId);
   
    this.isDone = this.getCookie();
    this.submitted = false;
    this.ShowSurvayOptions = false;
    this.ShowSurvayResult = true;
    if (!this.isDone) {
      this.isshow = true;
      this.back = 'رجوع';
    }
  }

 /** Component events  */
  async ngOnInit(): Promise<void> {

    this.vote =await  this.apiResources.getByKey("vote");  
    this.voteresult = await  this.apiResources.getByKey("voteresult");
    this.selectananswer = await  this.apiResources.getByKey("selectananswer");
    this.opinionpoll =await  this.apiResources.getByKey("opinionpoll");

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}