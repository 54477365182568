import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Subject, takeUntil } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { CenterList } from 'src/app/models/CenterList';

import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})


export class FooterComponent implements OnInit {

  emailForm: FormGroup;
  myusername: string = '';
  emailRegex =
    '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  destroy$: Subject<boolean> = new Subject<boolean>();
  CopyRights: string = '';
  EtecFooterDescription: string = '';
  ETEC: string = '';
  NewsLitter: string = '';
  ImportantLinks: string = '';
  requiredfield: string = '';
  invalidemail: string = '';
  email: string = '';
  SubscribeText: string = '';
  cookiedisclaimer: string = '';
  privacypolicy: string = '';
  Centers: string = '';
  importantLinks: string = '';
  services: string = '';
  ok: string = '';
  isDone = false;
  isAgree = false;
  emailtxt = '';
  Center = '';
  FollowUson = '';
  ServicesforIndividuals = '';
  ServicesforBusinesses = '';
  serviceGuid = '';
  _lang = '';
  currentDate = new Date();
  curYear = this.currentDate.getFullYear();
  centers: CenterList[] = [];

  constructor(private apiService: ApiService, private apiResources: ResourcesService, private notificationService: NotificationService,
    private cookieService: CookieService, private _appService: AppComponent) {

    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }

    this.isDone = this.getCookie();
    this.isAgree = this.getCookiefooter();
  }

  /** Custom methods */
  get validator() {
    return true;
  }

  async ngOnInit() {
    await this.apiService.logInAsync();
    this.ETEC = await this.apiResources.getByKey("etecfullname");
    this.cookiedisclaimer = await this.apiResources.getByKey("cookiedisclaimer");
    this.SubscribeText = await this.apiResources.getByKey("subscribetext");
    this.invalidemail = await this.apiResources.getByKey("importantlinks");
    this.ImportantLinks = await this.apiResources.getByKey("importantlinks");
    this.EtecFooterDescription = await this.apiResources.getByKey("etecfooterdescription");
    this.privacypolicy = await this.apiResources.getByKey("privacypolicy");
    this.emailtxt = await this.apiResources.getByKey("email");
    this.ok = await this.apiResources.getByKey("ok");
    this.CopyRights = await this.apiResources.getByKey("CopyRights");
    this.NewsLitter = await this.apiResources.getByKey("NewsLetter");
    this.Centers = await this.apiResources.getByKey("Centers");
    this.importantLinks = await this.apiResources.getByKey("importantLinks");
    this.services = await this.apiResources.getByKey("services");
    this.Center = await this.apiResources.getByKey("Center");
    this.FollowUson = await this.apiResources.getByKey("FollowUson");
    this.ServicesforIndividuals = await this.apiResources.getByKey("ServicesforIndividuals");
    this.ServicesforBusinesses = await this.apiResources.getByKey("ServicesforBusinesses");
    this.serviceGuid = await this.apiResources.getByKey("ServiceGuide");


    this.getCenter();
  }

  setCookie() {
    this.cookieService.set('subscribe', 'true');

  }

  getCookie() {
    return this.cookieService.get('subscribe') ? true : false;
  }

  setCookiefooter() {
    this.cookieService.set('Agree', 'true', 365);

  }

  getCookiefooter() {
    return this.cookieService.get('Agree') ? true : false;
  }

  agree() {
    this.setCookiefooter();
    this.isAgree = true;
  }

  sendSubscribtion() {
    if (!this.email) {
      this.notificationService.showError('', 'الرجاء إدخال الإيميل');
      return;
    }

    let pattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
    let valid = pattern.test(this.email);
    if (!valid) {
      this.notificationService.showError('', 'الرجاء إدخال إيميل صحيح');
      return;
    }

    this.apiService.subscribe(this.email).subscribe(
      () => {
        this.email = '';
        this.notificationService.showSuccess('', 'تم إرسال طلب الإشتراك بنجاح');
        this.setCookie();
        this.isDone = true;
      }
    );

  }

  getCenter() {
    this.apiService.getList('centers').pipe(takeUntil(this.destroy$)).subscribe(
      (data) => {
        this.centers = data.filter(
          (x: CenterList) => x.ItemId != 1);
        this.centers.sort((a, b) => a.ItemFields.Order - b.ItemFields.Order);
      }
    );
  }

  /** Component events  */
  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

}
