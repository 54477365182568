import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
 
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private Title: Title, private loader: LoadingService, private actRoute: ActivatedRoute  
    , private apiResources: ResourcesService, private loading: LoadingService, private router: Router ) { 
    this.actRoute.params.subscribe(async params =>
      {
         var isRoute =  localStorage.getItem('lang') != params["language"] && params["language"];
         await this.changeLang(params["language"]);

         if(isRoute){
          window.location.reload();
         }
      });
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  /** Component events  */
  ngOnInit(): void {
    this.Title.setTitle("هيئة تقويم التعليم والتدريب");
  }

}
