<!-- hero -->
<section class="container overflow-hidden  mt-4 pt-4 mb-4 pb-4">
    <div class="d-flex align-items-center rounded-5 position-relative overflow-hidden py-5 px-3 px-sm-4 px-xl-5 mx-md-n3 mx-lg-auto hero-mh bg-primary"
        style="background: radial-gradient(circle, rgba(105,128,188,1) 0%, rgba(61,46,122,1) 55%);">
        <div class="position-absolute bottom-0 start-50 translate-middle-x h-100">
            <img src="assets/media/img/hero-natforum.png" alt="hero img" style="min-width: 1200px;">
        </div>
    </div>
    <div class="position-relative mt-n4 rounded-5 w-100 d-flex justify-content-center p-3 py-4"
        style="background-color: #3994C8;">
        <img src="assets/media/logos/etec-ywlogo.svg" alt="etec logo" width="150" class="me-xl-5 me-4">
        <img src="assets/media/logos/moe-wlogo.svg" alt="moe logo" width="90">
    </div>
</section>
<div *ngIf="_lang.toString() == 'ar'">
    <!-- title -->
    <section class="container overflow-hidden mt-4 text-center">
        <p class="text-center fs-3 text-primary mb-4 fw-bold">
            الملتقى الوطني للتميز المدرسي (تميز 2024)
        </p>
        <p class="text-center fs-6 mb-4 p-3 rounded-pill d-inline-flex text-center "
            style="color: #3994C8;background-color: #F4FAFE;border: 1px solid #D4EDFF;">
            11 ربيع الثاني 1446هـ الموافق 14 أكتوبر 2024
        </p>
        <p class="text-center text-xl-start text-primary fs-6 mb-3">
            تنظم هيئة تقويم التعليم والتدريب بالتعاون مع وزارة التعليم الملتقى الوطني للتميز المدرسي "تميز 2024"
            بحضور
            معالي
            وزير التعليم ومعالي رئيس مجلس إدارة الهيئة؛ وذلك لاستعراض ومناقشة البرنامج الوطني للتقويم المدرسي
            وتكريم
            المدارس
            المتميزة في نتائج التقويم المدرسي. ويأتي هذا الملتقى ضمن جهود الهيئة والوزارة وميدان التعليم المدرسي
            في رحلة
            التحول نحو النموذج السعودي لضمان وضبط جودة التعليم في المملكة.
        </p>
        <p class="text-center text-xl-start text-primary fs-6 mb-lg-0 mb-3">
            وسيشهد الملتقى تكريم المدارس المصنفة في مستوى التميز وفقًا لنتائج التقويم الخارجي؛ وذلك لتشجيع
            التميز في
            مدارس
            التعليم العام وتحفيز التنافس الإيجابي ونشر ثقافة الجودة، بما يسهم في إعداد أجيال متميزة علميا
            ومهاريا،
            وتحقيق
            رؤية السعودية 2030 ومستهدفات برنامج تنمية القدرات البشرية.
        </p>

        <div class="d-inline-flex flex-row justify-content-center align-items-center bg-success rounded-pill px-4 py-3  mt-5" style="background-color: #3994c8 !important;">
            <div class="d-flex justify-content-center align-items-center p-3 rounded-circle"
                style="background-color: #F9F9FE;">
                <img src="assets/media/icons/forum1.svg" width="25" height="25" alt="icon">
            </div>
            <p class="text-center fs-6 text-white fw-bold mb-0 ms-3">
                المدارس الحاصلة على مستوى التميز 2024
            </p>
            <a href="assets/media/files/المدارس-الحاصلة-على-مستوى-التميز-2024.pdf"
                class="btn btn-light fw-semibold rounded-pill p-2 fs-sm border-end-0 ms-3 pe-3" target="_blank">
                <i _ngcontent-hcx-c91="" class="bx bx-down-arrow-alt fw-normal fs-3 me-1 mt-n1"></i>
                تحميل
            </a>
        </div>
    </section>
    <!-- goals -->
    <section style="background-color: #F9F9FE;">
        <div class="container overflow-hidden position-relative mt-xl-5 mt-4 pt-4 mb-xl-5 mb-4 pb-4">
            <div class="row my-xl-5 justify-content-center">
                <div class="col-lg-6 text-center text-md-start order-lg-1 order-2 ">
                    <div class="card p-4 h-100 rounded-4 border-0 bg-white h-100 mt-xl-0 mt-3"
                        style="border-radius: 16px !important;">
                        <div
                            class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                            <div class="d-flex justify-content-center align-items-center mb-4">
                                <div style="width: 50px;height: 50px;">
                                    <img src="assets/media/icons/sec-icon.svg" alt="icon" width="38" height="38">
                                </div>
                                <p class="d-inline-block position-relative text-start fs-3 text-primary mb-0 fw-bold">
                                    أهداف الملتقى
                                </p>
                            </div>
                            <ul class="ps-4 pe-xxl-2 mb-0 text-primary">
                                <li>إحداث حراك على المستوى الوطني؛ لضمان وضبط جودة التعليم العام، وتحسين مخرجاته</li>
                                <li>دعم صناعة القرار في تطوير التعليم وفق بيانات ونتائج التقويم والتصنيف المدرسي.</li>
                                <li>إبراز سمات المدارس المتميزة في ضوء نتائج التقويم والتصنيف المدرسي؛ لتكون نموذجًا
                                    سعوديًّا
                                    يحتذى به.</li>
                                <li>تشجيع المدارس على توفير بيئات تعلم جاذبة، وحاضنة للإبداع والابتكار، وتعزز دافعية
                                    الطلاب
                                    واستمتاعهم بالتعلم.</li>
                                <li>تحفيز التنافسية الإيجابية بين مؤسسات التعليم والمعلمين في تبني أبرز الممارسات
                                    التعليمية
                                    الفاعلة في تحسين مخرجات التعليم</li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-xl-5 col-lg-6 col-sm-8 col-10 mt-xl-0 offset-xl-1 order-lg-2 order-1 text-xl-end">
                    <div class="card p-4 h-100 rounded-4 border-0 bg-white h-100 bg-repeat-0 bg-position-center bg-size-cover"
                        style="border-radius: 16px !important;background-image: url('assets/media/img/sec-img.png');min-height: 280px;">
                    </div>
                    <!-- <div class="d-flex justify-content-center align-items-center rounded-4 mb-xl-0 mb-5">
                    <img src="assets/media/img/sec-img.png" class="img-fluid" alt="img">
                </div> -->
                </div>
            </div>

            <div class="row my-xl-5 justify-content-center">
                <div class="col-lg-12 text-center text-md-start order-lg-1 order-2 ">
                    <div class="card p-4 h-100 rounded-4 border-0 bg-white h-100 mt-xl-0 mt-3"
                        style="border-radius: 16px !important;">
                        <div
                            class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                            <div class="d-flex justify-content-center align-items-center mb-4">
                                <div style="width: 50px;height: 50px;">
                                    <img src="assets/media/icons/sec-icon.svg" alt="icon" width="38" height="38">
                                </div>
                                <p class="d-inline-block position-relative text-start fs-3 text-primary mb-0 fw-bold">
                                    صور الملتقى
                                </p>
                            </div>
                            <lightgallery [settings]="settings" [onInit]="onInit">
                                <a *ngFor="let item of items" [attr.data-lg-size]="item.size" className="gallery-item" [attr.href]="item.src">
                                  <img className="img-responsive" src="{{ item.thumb }}" />
                                </a>
                              </lightgallery>
                    

                        </div>
                    </div>

                </div>

            </div>

            <div class="row justify-content-center g-4  mb-xl-5 mt-xl-0 mt-3">
                <div class="col-xl-4 col-12">
                    <div class="card p-4 pb-5 h-100 rounded-4 border-0 bg-white h-100"
                        style="border-radius: 16px !important;">
                        <div
                            class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                            <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                                style="background-color: #F9F9FE;">
                                <img src="assets/media/icons/calendar-icon.svg" width="25" height="25" alt="icon">
                            </div>
                            <p class="text-center text-lg-start text-primary mb-2 fw-semibold">
                                تاريخ الملتقى
                            </p>
                            <p class="text-center text-lg-start text-primary mb-0">
                                11 ربيع الثاني 1446هـ الموافق 14 أكتوبر 2024
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-12">
                    <div class="card p-4 pb-5 h-100 rounded-4 border-0 bg-white h-100"
                        style="border-radius: 16px !important;">
                        <div
                            class="card-body p-0 w-100 d-flex flex-row justify-content-between align-items-end pb-xl-4">
                            <div class="d-flex flex-column align-items-xl-start align-items-center">
                                <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                                    style="background-color: #F9F9FE;width: 56px;height: 56px;">
                                    <img src="assets/media/icons/pin-true.svg" width="25" height="25" alt="icon">
                                </div>
                                <p class="text-center text-lg-start text-primary mb-2 fw-semibold">
                                    مكان الملتقى
                                </p>
                                <p class="text-center text-lg-start text-primary mb-0">
                                    المملكة العربية السعودية
                                    <br>
                                    مدينة الرياض – فندق فيرمونت
                                </p>
                            </div>
                            <a href="https://maps.app.goo.gl/VkEuKrd5oGN3PxLM9" target="_blank"
                                class="d-flex flex-column align-items-center text-decoration-none p-3 rounded-5 ms-4"
                                style="background-color: #F9F9FE;">
                                <img src="assets/media/icons/location.svg" width="25" height="25" alt="icon"
                                    class="bg-white mb-2">
                                <p class="text-primary mb-0 fs-sm text-center">الوصول للموقع</p>
                            </a>

                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-12">
                    <div class="card p-4 pb-5 h-100 rounded-4 border-0 bg-white h-100"
                        style="border-radius: 16px !important;">
                        <div
                            class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xxl-start align-items-center">
                            <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                                style="background-color: #F9F9FE;">
                                <img src="assets/media/icons/forum2.svg" width="25" height="25" alt="icon">
                            </div>
                            <p class="text-center text-lg-start text-primary mb-2 fw-semibold">
                                البث المباشر
                            </p>
                            <p class="text-center text-lg-start text-primary mb-2 fs-sm">
                                البث المباشر الساعة 9:30ص بتوقيت السعودية
                                على القنوات التالية:
                            </p>
                            <div class="d-flex flex-xl-row flex-column justify-content-center align-items-center w-100">
                                <a href="https://www.youtube.com/live/SZO5RsLIypY" target="_blank"
                                    class="d-flex flex-column justify-content-center align-items-center text-decoration-none p-3 rounded-5 me-xl-2 mb-xl-0 mb-3 w-100 h-100"
                                    style="background-color: #F9F9FE;">
                                    <p class="text-primary mb-0 fs-sm text-center">
                                        اليوتيوب
                                    </p>
                                </a>
                                <a href="" target="_blank"
                                    class="d-flex flex-column justify-content-center text-decoration-none p-3 rounded-5 me-xl-2 mb-xl-0 mb-3 w-100 h-100 text-nowrap"
                                    style="background-color: #F9F9FE;">
                                    <p class="text-primary mb-0 fs-sm text-center">
                                        قناة
                                        السعودية
                                        الآن
                                    </p>
                                </a>
                                <a href="" target="_blank"
                                    class="d-flex flex-column justify-content-center align-items-center text-decoration-none p-3 rounded-5 w-100 h-100 text-nowrap"
                                    style="background-color: #F9F9FE;">
                                    <p class="text-primary mb-0 fs-sm text-center">
                                        قناة
                                        عين
                                    </p>
                                </a>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Forum activities -->
    <section class="container overflow-hidden mt-xl-5 mt-4 pt-4 mb-xl-5">
        <!-- title -->
        <div class="row justify-content-center align-items-center mb-5">
            <div class="d-flex justify-content-center align-items-center rounded-circle mb-4"
                style="background-color: #F9F9FE;height: 92px;width: 92px;">
                <img src="assets/media/icons/forum-icon.svg" class="img-fluid" width="46" height="46" alt="img">
            </div>
            <p class="text-center fs-3 text-primary mb-4 fw-bold">
                فعاليات الملتقى
            </p>
            <p class="text-center text-primary mb-0">
                يتخلل الملتقى الوطني للتميز المدرسي (تميز 2024) عددًا من الفعاليات وهي :
            </p>
        </div>
        <!-- cards -->
        <div class="row justify-content-center align-items-center g-4 pt-3 mb-xl-4">
            <div class="col-xl-3 col-lg-6 col-12">
                <div class="card p-4 pb-5 h-100 rounded-4 border-2 bg-white border-1"
                    style="border-radius: 16px !important;">
                    <div
                        class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                        <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                            style="background-color: #F9F9FE;">
                            <img src="assets/media/icons/forum1.svg" width="25" height="25" alt="icon">
                        </div>
                        <p class="text-center text-lg-start fs-6 text-primary mb-2 fw-bold">
                            تكريم المدارس المتميزة
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-12">
                <div class="card p-4 pb-5 h-100 rounded-4 border-2 bg-white border-1"
                    style="border-radius: 16px !important;">
                    <div
                        class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                        <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                            style="background-color: #F9F9FE;">
                            <img src="assets/media/icons/forum2.svg" width="25" height="25" alt="icon">
                        </div>
                        <p class="text-center text-lg-start fs-6 text-primary mb-2 fw-bold">
                            عروض تقديمية
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-12">
                <div class="card p-4 pb-5 h-100 rounded-4 border-2 bg-white border-1"
                    style="border-radius: 16px !important;">
                    <div
                        class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                        <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                            style="background-color: #F9F9FE;">
                            <img src="assets/media/icons/forum3.svg" width="25" height="25" alt="icon">
                        </div>
                        <p class="text-center text-lg-start fs-6 text-primary mb-2 fw-bold">
                            توقيع اتفاقيات
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-12">
                <div class="card p-4 pb-5 h-100 rounded-4 border-2 bg-white border-1"
                    style="border-radius: 16px !important;">
                    <div
                        class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                        <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                            style="background-color: #F9F9FE;">
                            <img src="assets/media/icons/forum4.svg" width="25" height="25" alt="icon">
                        </div>
                        <p class="text-center text-lg-start fs-6 text-primary mb-2 fw-bold">
                            جلسات حوارية
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- time table -->
        <div class="row justify-content-center align-items-center">
            <div class="col-12">
                <div class="d-flex flex-row justify-content-center align-items-center p-2 rounded-5 mb-4  mt-xl-0 mt-3"
                    style="background-color: #F9F9FE;">
                    <div class="d-flex justify-content-center align-items-center p-3 rounded-circle bg-white mb-xl-0 mb-4"
                        style="width: 56px; height: 56px;">
                        <img src="assets/media/icons/timetable-icon.svg" width="25" height="25" alt="icon">
                    </div>
                    <p class="text-center text-lg-start fs-5 text-primary mb-xl-0 mb-2 ms-xl-4 fw-bold"> الجدول الزمني
                    </p>
                </div>
            </div>
            <div class="col-12">
                <table class="table caption-top table-responsive-xl fs-6">
                    <tbody>
                        <tr>
                            <td class="fw-semibold py-4 text-center"
                                style="background-color: #3FA0D9 !important;color:var(--white) !important;border-radius: 0px 12px 0px 0px;">
                                الموضوع</td>
                            <td class="bg-primary fw-semibold p-4 text-center"
                                style="background-color: #DDDBFA !important;min-width: 200px;border-radius: 12px 0px 0px 0px;">
                                التوقيت</td>
                        </tr>
                        <tr>
                            <td class="text-primary  py-3" style="background-color: var(--white) !important;">
                                الافتتاح
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                9:30- 9:45 ص
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-3" style="background-color: var(--white) !important;">
                                كلمة معالي رئيس مجلس إدارة هيئة تقويم التعليم والتدريب
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                9:45- 9:55 ص
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary  py-3" style="background-color: var(--white) !important;">
                                برنامج التقويم والتصنيف والاعتماد المدرسي
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                9:55- 10:10 ص
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary  py-3" style="background-color: var(--white) !important;">
                                تكريم المدارس الحاصلة على مستوى التميز وفقًا لنتائج التقويم والتصنيف المدرسي.
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                10:10 -11:00 ص
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-3" style="background-color: var(--white) !important;">
                                حلقة نقاش بعنوان: أبرز نتائج التقويم والتصنيف المدرسي ونتائج نافس 2024م
                                <br>
                                (هيئة تقويم التعليم والتدريب)
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                11:00- 11:30 ص
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-4" style="background-color: var(--white) !important;">
                                حلقة نقاش بعنوان: توظيف نتائج التقويمات الوطنية في تحسين الأداء المدرسي (الخطط والدروس
                                المستفادة من الميدان التعليمي)
                                <br>
                                (وزارة التعليم)
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                11:30 ص – 12:00 م
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-3 border-0"
                                style="background-color: #F9F9FE !important;border-radius: 0px 0px 12px 0px;">
                                استراحة الصلاة والغداء
                            </td>
                            <td class="text-primary fw-semibold text-center py-3 border-0"
                                style="background-color: #F9F9FE !important;border-radius: 0px 0px 0px 12px;">
                                12:00 – 1:00 م
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-3 border-0"
                                style="background-color: #F9F9FE !important;border-radius: 0px 0px 12px 0px;">
                                استراحة الصلاة والغداء ، توقيع الاتفاقيات مع المدارس والشركات التعليمية، ومنح الشهادات
                                لجهات
                                الاعتماد الدولية
                            </td>
                            <td class="text-primary fw-semibold text-center py-3 border-0"
                                style="background-color: #F9F9FE !important;border-radius: 0px 0px 0px 12px;">
                                12:00 – 1:00 م
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </section>
</div>

<div *ngIf="_lang.toString() == 'en'">
    <!-- title -->
    <section class="container overflow-hidden mt-4 text-center">
        <p class="text-center fs-3 text-primary mb-4 fw-bold">
            The National Forum for School Excellence (Tamayuz 2024)
        </p>
        <p class="text-center fs-6 mb-4 p-3 rounded-pill d-inline-flex text-center "
            style="color: #3994C8;background-color: #F4FAFE;border: 1px solid #D4EDFF;">
            11 Rabi' al-Thani, 1446,
            October 14, 2024
        </p>
        <p class="text-center text-xl-start text-primary fs-6 mb-3">
            The Education and Training Evaluation Commission, in partnership with the Ministry of Education, will
            organize the National Forum for School Excellence, "Tamayuz 2024". The forum will be attended by His
            Excellency the Minister of Education and His Excellency the Chairman of the Commission's Board of Directors.
            The event will review and discuss the National School Evaluation Program and recognize schools that excelled
            based on their evaluation results. This forum is part of the ongoing efforts by the Commission, the
            Ministry, and the broader education sector to transition toward the Saudi Model of Quality Assurance and
            Control in education within the Kingdom.
        </p>
        <p class="text-center text-xl-start text-primary fs-6 mb-0">
            The forum will feature the recognition of schools classified at the Excellence Level based on external
            evaluation results. It aims to foster excellence in K-12 education, promote healthy and positive
            competition, and cultivate a culture of quality. These efforts contribute to the development of skilled and
            academically outstanding generations, in line with Saudi Vision 2030 and the objectives of the Human
            Capability Development Program.
        </p>
    </section>
    <!-- goals -->
    <section style="background-color: #F9F9FE;">
        <div class="container overflow-hidden position-relative mt-xl-5 mt-4 pt-4 mb-xl-5 mb-4 pb-4">
            <div class="row my-xl-5 justify-content-center">
                <div class="col-lg-6 text-center text-md-start order-lg-1 order-2 ">
                    <div class="card p-4 h-100 rounded-4 border-0 bg-white h-100 mt-xl-0 mt-3"
                        style="border-radius: 16px !important;">
                        <div
                            class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                            <div class="d-flex justify-content-center align-items-center mb-4">
                                <div style="width: 50px;height: 50px;">
                                    <img src="assets/media/icons/sec-icon.svg" alt="icon" width="38" height="38">
                                </div>
                                <p class="d-inline-block position-relative text-start fs-3 text-primary mb-0 fw-bold">
                                    Objectives of the Forum
                                </p>
                            </div>
                            <ul class="ps-4 pe-xxl-2 mb-0 text-primary">
                                <li>
                                    Creating a momentum at the national level to assure and control the quality of K-12
                                    education and improve its outputs.
                                </li>
                                <li>
                                    Supporting data-driven decision-making in the development of education based on the
                                    results of school evaluation and classification.
                                </li>
                                <li>
                                    Highlighting the characteristics of distinguished schools in light of the results of
                                    school evaluation and classification, to serve as a Saudi model to be emulated.
                                </li>
                                <li>
                                    Encouraging schools to provide attractive learning environments that foster
                                    creativity and innovation, and enhance students' motivation and enjoyment of
                                    learning.
                                </li>
                                <li>
                                    Stimulating positive competition among educational institutions and teachers in
                                    adopting the most effective educational practices to improve educational outcomes.
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col-xl-5 col-lg-6 col-sm-8 col-10 mt-xl-0 offset-xl-1 order-lg-2 order-1 text-xl-end">
                    <div class="card p-4 h-100 rounded-4 border-0 bg-white h-100 bg-repeat-0 bg-position-center bg-size-cover"
                        style="border-radius: 16px !important;background-image: url('assets/media/img/sec-img.png');min-height: 280px;">
                    </div>
                    <!-- <div class="d-flex justify-content-center align-items-center rounded-4 mb-xl-0 mb-5">
                    <img src="assets/media/img/sec-img.png" class="img-fluid" alt="img">
                </div> -->
                </div>
            </div>
            <div class="row justify-content-center align-items-center g-4  mb-xl-5 mt-xl-0 mt-3">
                <div class="col-xl-6 col-12">
                    <div class="card p-4 pb-5 h-100 rounded-4 border-0 bg-white h-100"
                        style="border-radius: 16px !important;">
                        <div
                            class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                            <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                                style="background-color: #F9F9FE;">
                                <img src="assets/media/icons/calendar-icon.svg" width="25" height="25" alt="icon">
                            </div>
                            <p class="text-center text-lg-start text-primary mb-2 fw-semibold">
                                Forum Date
                            </p>
                            <p class="text-center text-lg-start text-primary mb-0">
                                11 Rabi' al-Thani, 1446, corresponding to October 14, 2024
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="card p-4 pb-5 h-100 rounded-4 border-0 bg-white h-100"
                        style="border-radius: 16px !important;">
                        <div class="card-body p-0 w-100 d-flex flex-row justify-content-between align-items-end ">
                            <div class="d-flex flex-column align-items-xl-start align-items-center">
                                <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                                    style="background-color: #F9F9FE;width: 56px;height: 56px;">
                                    <img src="assets/media/icons/pin-true.svg" width="25" height="25" alt="icon">
                                </div>
                                <p class="text-center text-lg-start text-primary mb-2 fw-semibold">
                                    Forum Venue
                                </p>
                                <p class="text-center text-lg-start text-primary mb-0">
                                    Kingdom of Saudi Arabia - Riyadh - Fairmont Hotel
                                </p>
                            </div>
                            <a href="https://maps.app.goo.gl/VkEuKrd5oGN3PxLM9" target="_blank"
                                class="d-flex flex-column align-items-center text-decoration-none p-3 rounded-5 ms-4"
                                style="background-color: #F9F9FE;">
                                <img src="assets/media/icons/location.svg" width="25" height="25" alt="icon"
                                    class="bg-white mb-2">
                                <p class="text-primary mb-0 fs-xs text-center">Click here
                                    <br>
                                    for directions
                                </p>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Forum activities -->
    <section class="container overflow-hidden mt-xl-5 mt-4 pt-4 mb-xl-5">
        <!-- title -->
        <div class="row justify-content-center align-items-center mb-5">
            <div class="d-flex justify-content-center align-items-center rounded-circle mb-4"
                style="background-color: #F9F9FE;height: 92px;width: 92px;">
                <img src="assets/media/icons/forum-icon.svg" class="img-fluid" width="46" height="46" alt="img">
            </div>
            <p class="text-center fs-3 text-primary mb-4 fw-bold">
                Forum Activities
            </p>
            <p class="text-center text-primary mb-0">
                The National Forum for School Excellence (Tamayuz 2024) will feature a number of activities, including:
            </p>
        </div>
        <!-- cards -->
        <div class="row justify-content-center align-items-center g-4 pt-3 mb-xl-4">
            <div class="col-xl-3 col-lg-6 col-12">
                <div class="card p-4 pb-5 h-100 rounded-4 border-2 bg-white border-1"
                    style="border-radius: 16px !important;">
                    <div
                        class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                        <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                            style="background-color: #F9F9FE;">
                            <img src="assets/media/icons/forum1.svg" width="25" height="25" alt="icon">
                        </div>
                        <p class="text-center text-lg-start fs-6 text-primary mb-2 fw-bold">
                            Honoring distinguished schools
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-12">
                <div class="card p-4 pb-5 h-100 rounded-4 border-2 bg-white border-1"
                    style="border-radius: 16px !important;">
                    <div
                        class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                        <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                            style="background-color: #F9F9FE;">
                            <img src="assets/media/icons/forum2.svg" width="25" height="25" alt="icon">
                        </div>
                        <p class="text-center text-lg-start fs-6 text-primary mb-2 fw-bold">
                            Presentations
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-12">
                <div class="card p-4 pb-5 h-100 rounded-4 border-2 bg-white border-1"
                    style="border-radius: 16px !important;">
                    <div
                        class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                        <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                            style="background-color: #F9F9FE;">
                            <img src="assets/media/icons/forum3.svg" width="25" height="25" alt="icon">
                        </div>
                        <p class="text-center text-lg-start fs-6 text-primary mb-2 fw-bold">
                            Signing agreements
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-12">
                <div class="card p-4 pb-5 h-100 rounded-4 border-2 bg-white border-1"
                    style="border-radius: 16px !important;">
                    <div
                        class="card-body p-0 w-100 d-flex flex-column justify-content-center align-items-xl-start align-items-center">
                        <div class="d-flex justify-content-center align-items-center p-3 rounded-circle mb-4"
                            style="background-color: #F9F9FE;">
                            <img src="assets/media/icons/forum4.svg" width="25" height="25" alt="icon">
                        </div>
                        <p class="text-center text-lg-start fs-6 text-primary mb-2 fw-bold">
                            Panel discussions
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- time table -->
        <div class="row justify-content-center align-items-center">
            <div class="col-12">
                <div class="d-flex flex-row justify-content-center align-items-center p-2 rounded-5 mb-4  mt-xl-0 mt-3"
                    style="background-color: #F9F9FE;">
                    <div class="d-flex justify-content-center align-items-center p-3 rounded-circle bg-white mb-xl-0 mb-4"
                        style="width: 56px; height: 56px;">
                        <img src="assets/media/icons/timetable-icon.svg" width="25" height="25" alt="icon">
                    </div>
                    <p class="text-center text-lg-start fs-5 text-primary mb-xl-0 mb-2 ms-xl-4 fw-bold">
                        Forum Agenda
                    </p>
                </div>
            </div>
            <div class="col-12">
                <table class="table caption-top table-responsive-xl fs-6">
                    <tbody>
                        <tr>
                            <td class="fw-semibold py-4 text-center"
                                style="background-color: #3FA0D9 !important;color:var(--white) !important;border-radius: 12px 0px 0px 0px;">
                                Topic</td>
                            <td class="bg-primary fw-semibold p-4 text-center"
                                style="background-color: #DDDBFA !important;min-width: 200px;border-radius: 0px 12px 0px 0px;">
                                Time</td>
                        </tr>
                        <tr>
                            <td class="text-primary  py-3" style="background-color: var(--white) !important;">
                                Opening Ceremony
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                9:30 - 9:45 AM
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-3" style="background-color: var(--white) !important;">
                                Address by His Excellency the Chairman of the Commission's Board of Directors
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                9:45 - 9:55 AM
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary  py-3" style="background-color: var(--white) !important;">
                                School Evaluation, Classification, and Accreditation Program
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                9:55 - 10:10 AM
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary  py-3" style="background-color: var(--white) !important;">
                                Honoring Schools that Have Achieved Excellence Level Based on Evaluation and
                                Classification Results
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                10:10 - 11:00 AM
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-3" style="background-color: var(--white) !important;">
                                Panel Discussion: Key Findings from School Evaluation and Classification, and NAFES 2024
                                Results
                                <br>
                                (Education and Training Evaluation Commission)
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                11:00 - 11:30 AM
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-4" style="background-color: var(--white) !important;">
                                Panel Discussion: Utilizing National Evaluation Results to Improve School Performance
                                <br>
                                (Plans and Lessons Learned from the Educational Field - Ministry of Education)
                                ()
                            </td>
                            <td class="text-primary fw-semibold text-center py-3"
                                style="background-color: #FAFAFA !important;">
                                11:30 AM - 12:00 PM
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-3 border-0"
                                style="background-color: #F9F9FE !important;border-radius: 0px 0px 0px 12px;">
                                Prayer and Lunch Break
                            </td>
                            <td class="text-primary fw-semibold text-center py-3 border-0"
                                style="background-color: #F9F9FE !important;border-radius: 0px 0px 12px 0px;">
                                12:00 - 1:00 PM
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary py-3 border-0"
                                style="background-color: #F9F9FE !important;border-radius: 0px 0px 0px 12px;">
                                Signing Agreements with Schools and Educational Companies, and Issuance of Certificates
                                to International Accreditation Bodies
                            </td>
                            <td class="text-primary fw-semibold text-center py-3 border-0"
                                style="background-color: #F9F9FE !important;border-radius: 0px 0px 12px 0px;">
                                12:00 - 1:00 PM
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </section>

</div>
<!-- shareicons -->
<section class="container">
    <app-shareicons></app-shareicons>
</section>