import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Importantlinklist } from 'src/app/models/importantlinklist';
import { ImportantLinks } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-importantlinks',
  templateUrl: './importantlinks.component.html',
  styleUrls: ['./importantlinks.component.css']
})
export class ImportantlinksComponent implements OnInit {

  @Input() title :string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  ImportantLinks: ImportantLinks[] =[];
  _lang = '';

  constructor(private ImportantLinkService: ApiService) { 
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  /** Custom methods */
  getRelatedLink(){
    this.ImportantLinkService.getListTopN('relatedlinks', 100, false, "IsFooterLink", true).pipe(takeUntil(this.destroy$)).subscribe(
      (data: Importantlinklist[]) => {
        data.forEach(element => {
          var obj: ImportantLinks = { 
            title: element.ItemFields.RelatedLinkName,
            url: element.ItemFields.RelatedLinkTarget.replace('http://baseurl.com/', this._lang+'/').toLocaleLowerCase(),
          };
          
          this.ImportantLinks.push(obj);
       });
      }
    );
  }

 /** Component events  */
  ngOnInit(): void {
    this.getRelatedLink();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
     // Unsubscribe from the subject
    this.destroy$.unsubscribe();
   }


}
