<form [formGroup]="myForm" (ngSubmit)="submit()" class="row g-4">
    <input formControlName="GUID" id="GUID" type="hidden" class="form-control" />
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="nationalId">عنوان المقترح البحثي</label>
            <input formControlName="title" id="nationalId" type="title" class="form-control"
                maxlength="200" />
            <div *ngIf="f['title'].touched && f['title'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['title'].errors && f['title'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['title'].errors && f['title'].errors['maxlength']">
                    الرجاء إدخال 100 حرف بحد اقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="major">مجـــال البحث العام</label>
            <input formControlName="major" id="major" type="text" class="form-control"
                maxlength="100" />
            <div *ngIf="f['major'].touched && f['major'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['major'].errors && f['major'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['major'].errors && f['major'].errors['maxlength']">
                    الرجاء إدخال 100 حرف بحد اقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="subField">المجال الفرعي</label>
            <input formControlName="subField" id="subField" type="text" class="form-control"
                maxlength="100" />
            <div *ngIf="f['subField'].touched && f['subField'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['subField'].errors && f['subField'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['subField'].errors && f['subField'].errors['maxlength']">
                    الرجاء إدخال 100 حرف بحد اقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="specialization">المجال الدقيق</label>
            <input formControlName="specialization" id="specialization" type="text" class="form-control"
                maxlength="100" />
            <div *ngIf="f['specialization'].touched && f['specialization'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['specialization'].errors && f['specialization'].errors['required']">
                    حقل مطلوب.
                </div>
                 <div *ngIf="f['title'].errors && f['title'].errors['maxlength']">
                    الرجاء إدخال 100 حرف بحد اقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="suggestedDuration">المدة المقترحة بالأشهر</label>
            <input formControlName="suggestedDuration" id="suggestedDuration" type="text" class="form-control"
                maxlength="2" (keypress)="OnlyNumbers($event)" />
            <div *ngIf="f['suggestedDuration'].touched && f['suggestedDuration'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['suggestedDuration'].errors && f['suggestedDuration'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['suggestedDuration'].errors && f['suggestedDuration'].errors['pattern']">
                    الرجاء إدخال رقم صحيح
                </div>
                <div *ngIf="f['suggestedDuration'].errors && f['suggestedDuration'].errors['maxlength']">
                    الرجاء إدخال رقمين بحد اقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-lg-4">
        <div class="form-group">
            <label for="file">الخطة التنفيذية (EXCEL 2mb)</label>
            <input formControlName="file" id="file" type="file" class="form-control"
                (change)="upload($event)" />
            <div *ngIf="f['file'].touched && f['file'].invalid" class="alert alert-danger">
                <div *ngIf="f['file'].errors && f['file'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f.file.errors?.['inValidExt']" class="error">
                الإمتداد غير صالح  يجب أن يكون xlsx,xls
              </div> 
              <!-- <div *ngIf="f.file.errors?.['inValidSize']" class="error">
              </div>  -->
            </div>
            <div class="alert alert-danger" *ngIf="!isCorrectSize">
                حجم الملف كبير
             </div>   
        </div>
    </div>
    <div class="col-12">
        <div class="progress" *ngIf="progress">
            <div class="progress-bar" [style.width]="progress + '%'">
                {{ progress }}%
            </div>
        </div>
    </div>
    <div class="col-md-12 col-lg-12">
        <div class="form-group">
            <label for="summary">ملخص الفكرة البحثية (أسئلة البحث)</label>
            <textarea formControlName="summary" id="summary" type="text" class="form-control"
                maxlength="300" rows="5" placeholder="300 حرف بحد اقصى "></textarea>
            <div *ngIf="f['summary'].touched && f['summary'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['summary'].errors && f['summary'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['summary'].errors && f['summary'].errors['maxlength']">
                    الرجاء إدخال 300 حرف بحد اقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-lg-12">
        <div class="form-group">
            <label for="summary2">ملخص مراجعة الأدبيات (الأدب النظري)</label>
            <textarea formControlName="summary2" id="summary2" type="text" class="form-control"
                maxlength="300" rows="5" placeholder="300 حرف بحد اقصى "></textarea>    
            <div *ngIf="f['summary2'].touched && f['summary2'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['summary2'].errors && f['summary2'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['summary2'].errors && f['summary2'].errors['maxlength']">
                    الرجاء إدخال 300 حرف بحد اقصى 
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-lg-12">
        <div class="form-group">
            <label for="summary3">منهج البحث وإجراءاته وأساليب التحليل</label>
            <textarea formControlName="summary3" id="summary3" type="text" class="form-control"
                maxlength="300" rows="5" placeholder="300 حرف بحد اقصى "></textarea>    
            <div *ngIf="f['summary3'].touched && f['summary3'].invalid"
                class="alert alert-danger">
                <div *ngIf="f['summary3'].errors && f['summary3'].errors['required']">
                    حقل مطلوب.
                </div>
                <div *ngIf="f['summary3'].errors && f['summary3'].errors['maxlength']">
                    الرجاء إدخال 300 حرف بحد اقصى 
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mt-4 d-flex justify-content-end">
        <div class="d-flex w-lg-25 w-md-50 w-100">
            <button class="btn btn-primary w-100 me-2" type="submit" [disabled]="myForm.invalid || !isCorrectSize">حفظ</button>
            <!-- <button class="btn btn-secondary w-100" type="submit">
                حفظ واضافة باحث مشترك
            </button> -->
        </div>
    </div>
</form>