import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Publiccontentslist } from 'src/app/models/publiccontentslist';
 
import { PublicContents } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-privcay',
  templateUrl: './privcay.component.html',
  styleUrls: ['./privcay.component.css']
})

export class PrivcayComponent implements OnInit {
  PageTitle ='';
  privacypolicy='';
  home ='';
  content ='';
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private apiServices : ApiService, private apiResources: ResourcesService) { }

  async ngOnInit(){
    this.apiServices.getList('PublicContents').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Publiccontentslist[]) => {
        data.forEach(element => {
         if(element.ItemFields.Title === 'privacy' ){
          var obj: PublicContents = {
            ID: element.ItemFields.ID ,
            Title: element.ItemFields.Title ,
            PublicContentTitle: element.ItemFields.PublicContentTitle,
            PublicContentDetails: element.ItemFields.PublicContentDetails
            
          };
          this.content = obj.PublicContentDetails ;
         }
        });
      }
    );

    this.privacypolicy = await this.apiResources.getByKey("privacypolicy");
    this.home = await this.apiResources.getByKey("home");
  }

}
