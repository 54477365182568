import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ScientificActivityList } from 'src/app/models/ScientificActivitiesList';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { ListResponse } from 'src/app/models/ListResponse';
import { ResourcesService } from 'src/app/services/resources.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-scientific-activity',
  templateUrl: './scientific-activity.component.html',
  styleUrls: ['./scientific-activity.component.css']
})
export class ScientificActivityComponent implements OnInit {
  activeTab = 'conferences';
  imptempPublicUrl = environment.imptempPublicUrl;
  imgPublicUrl = environment.imgPublicUrl;
  reasearchList: ScientificActivityList[] = [];
  reasearchList2: ScientificActivityList[] = [];
  searchText: any = '';
  reportsearch: string = '';
  reportdownload: string = '';
  reportquotes: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  modalData = '';
  home = '';
  PageTitle = '';
  close = '';
  LecturesAndDiscussions = '';
  conv = '';
  _lang = '';
  EtecFullName = 'هيئة تقويم التعليم والتدريب';

  constructor(private actRoute: ActivatedRoute, private service: ApiService, config: NgbModalConfig, private modalService: NgbModal
    , private Title: Title, private apiResources: ResourcesService, private loading: LoadingService) {

    // this.actRoute.params.subscribe(params =>
    //   this.activeTab = params["id"] == null ? 'conferences' : params["id"]);
    this.actRoute.params.subscribe(async params => {
      this.activeTab = params["id"] == null ? 'conferences' : params["id"];
      var isRoute = localStorage.getItem('lang') != params["language"] && params["language"];
      await this.changeLang(params["language"]);

      if (isRoute) {
        window.location.reload();
      }
    });

    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  /** Custom methods */
  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }
  getScientificActivitieList() {

    this.service.getList('ScientificActivitieList').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ScientificActivityList[]) => {
        data.forEach(element => {
          if (element.ItemFields?.ActivityType?.LookupId === 1) {
            this.reasearchList.push(element);
          }
          if (element.ItemFields?.ActivityType?.LookupId === 2) {
            this.reasearchList2.push(element);
          }
        });
      }
    );
  }

  getSiteMenu() {

    this.service.getList('SiteMenu').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ListResponse[]) => {
        data.forEach(element => {
          if (element.ItemFields.Title.toLowerCase() === 'scientificactivities') {
            this.PageTitle = element.ItemFields.MenuName;
            this.Title.setTitle(this.EtecFullName + " - " + this.PageTitle);
          }

        });
      }
    );

  }

  // changeRatio(event: MatSelectChange) {
  //   var optionSelected = event.value.split('-');
  //   if (optionSelected[0] === 'ReportTitle' && optionSelected[1] === 'false') {
  //     this.reasearchList.sort((a: any, b: any) => {
  //       if (a.ItemFields.ResearchAndReportsTitle < b.ItemFields.ResearchAndReportsTitle) return -1;
  //       if (a.ItemFields.ResearchAndReportsTitle < b.ItemFields.ResearchAndReportsTitle) return 1;
  //       return 0;
  //     });
  //   }
  //   if (optionSelected[0] === 'ReportTitle' && optionSelected[1] === 'true') {
  //     this.reasearchList.sort((a: any, b: any) => {
  //       if (a.ItemFields.ResearchAndReportsTitle < b.ItemFields.ResearchAndReportsTitle) return 1;
  //       if (a.ItemFields.ResearchAndReportsTitle < b.ItemFields.ResearchAndReportsTitle) return -1;
  //       return 0;
  //     });
  //   }

  //   if (optionSelected[0] === 'Created' && optionSelected[1] === 'false') {
  //     this.reasearchList.sort((a: any, b: any) => {
  //       return <any>new Date(b.ItemFields.Created) - <any>new Date(a.ItemFields.Created);
  //     });
  //   }
  //   if (optionSelected[0] === 'Created' && optionSelected[1] === 'true') {
  //     this.reasearchList.sort((a: any, b: any) => {
  //       return <any>new Date(a.ItemFields.Created) - <any>new Date(b.ItemFields.Created);
  //     });

  //   }
  // }

  open(content: any, citationDetails: string) {
    this.modalData = citationDetails;
    this.modalService.open(content);
  }

  /** Component events  */
  async ngOnInit() {
    this.getScientificActivitieList();
    this.getSiteMenu();

    this.reportsearch = await this.apiResources.getByKey("reportsearch");
    this.reportdownload = await this.apiResources.getByKey("reportdownload");
    this.reportquotes = await this.apiResources.getByKey("reportquotes");
    this.home = await this.apiResources.getByKey("home");
    this.close = await this.apiResources.getByKey("close");
    this.conv = await this.apiResources.getByKey("conv");
    this.LecturesAndDiscussions = await this.apiResources.getByKey("LecturesAndDiscussions");

  }
}
