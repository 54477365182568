<!--begin::breadcrumb-->
<section class="bg-primary">
    <div class="container">
        <div class="row">
            <div class="col mt-4 mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">الرئيسية</a></li>
                        <li class="breadcrumb-item"><a href="#">الأفراد</a></li>
                        <li class="breadcrumb-item"><a routerLink="/centers/3">قياس </a></li>
                        <li class="breadcrumb-item"><a routerLink="/products">المنتجات والخدمات</a></li>
                        <li class="breadcrumb-item"><a href="#"> اختبارات القبول الجامعي</a></li>
                        <li class="breadcrumb-item active" aria-current="page">اختبار القدرات العامة للجامعيين</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<div class="container marketing">
    <div class="row featurette">
        <div class="col-md-7">
            <div class="vcenter-item h-100">
                <div class="row">
                    <div class="col-lg-12">
                        <h2 class="featurette-heading text-primary">{{Product.serviceName}} </h2>

                    </div>
                    <div class="col-lg-12">
                        <h2 class="featurette-heading text-primary">{{Product.serviceKeyword}}</h2>

                    </div>
                    <div class="col-lg-12 mt-5">
                        <p class="lead text-primary">
                            {{Product.ServiceDescription}} 
                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div>
                            <!-- <button class="btn btn-primary d-inline">الجدول الزمني</button>
                            <button class="btn btn-outline-success d-inline">الجدول الزمني</button> -->
                        </div>

                    </div>

                </div>


            </div>

        </div>
        <div class="col-md-5">
            <img class="featurette-image img-fluid mx-auto p-5" src="assets/img/icon/PGATE-Exam.svg">
        </div>
    </div>

    <div class="row  my-5">
        <div class="col-lg-12">
            <div class="card border-2 border-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="d-flex flex-row">
                                <div class="w-50">
                                    <img class="featurette-image img-fluid mx-auto p-5"
                                        src="assets/img/icon/PGATE-Search.svg">
                                </div>
                                <div class="w-50">
                                    <div class="vcenter-item h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="featurette-heading text-primary"> أهداف </h2>
                                            </div>
                                            <div class="col-12">
                                                <h2 class="featurette-heading text-primary"> الاختبار </h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="d-flex flex-row  mt-5 mb-3" *ngFor="let item of Goals">
                                <div class="w-50">
                                    <div class="d-flex flex-row">
                                        <div>
                                            <span
                                                class="list-badge-circle badge bg-primary rounded-circle">{{item.ServiceGoalNumber}}</span>

                                        </div>
                                        <div>
                                            <h5 class="text-primary">
                                                {{item.ServiceGoalName}}

                                            </h5>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row my-5">
        <div class="col-lg-12">
            <div class="card bg-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="d-flex flex-column">
                                <div class="w-100 text-center">
                                    <img width="300" class="featurette-image img-fluid mx-auto p-5"
                                        src="assets/img/icon/PGATE-Target.svg">
                                </div>
                                <div class="w-100 text-center">
                                    <div class="col-12">
                                        <h2 class="featurette-heading text-white"> الفئات </h2>
                                    </div>
                                    <div class="col-12">
                                        <h2 class="featurette-heading text-white"> المستهدفة </h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">

                            <div class="row mt-5">
                                <div class="col-lg-4 my-2" *ngFor="let item of Audnices">
                                    <div class="card h-100">
                                        <div class="card-body text-center p-lg-5">
                                            {{item.serviceAudienceName}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row  my-5">
        <div class="col-lg-12">
            <div class="card border-2 border-primary">
                <div class="card-body  p-lg-5">
                    <div class="row">
                        <div class="col-lg-6" *ngFor="let item of Content">
                            <div class="d-flex flex-row" >
                                <div class="vcenter-item h-100 mt-4">
                                    <img width="200" src="assets/img/icon/PGATE-buzzelz.svg" />
                                </div>
                                <div class="vcenter-item h-100 px-3">
                                    <h1 class="text-primary">
                                        {{item.ServiceContentTitle}}
                                    </h1>
                                </div>
                                <div class="w-50 vcenter-item h-100 mt-3">
                                    <p class="text-primary">
                                        {{item.ServiceContentDetailes}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">
                            <div class=" text-center mt-5">
                                <a href="#" class="btn btn-infoBook">
                                    حمّل الكتيب التعريفي للاختبار


                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row  my-5">
        <div class="col-lg-3" *ngFor="let item of Notes">
            <div class="card bg-primary">
                <div class="card-body">
                    <h3 class="text-center text-white">
                        {{item.serviceNoteTitle}}
                    </h3>
                    <div class="card bg-info mt-4">
                        <div class="card-body">
                            <h5 class="text-center text-white">
                                {{item.serviceNoteDetailes}}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-3">
            <div class="card bg-primary">
                <div class="card-body">
                    <h3 class="text-center text-white">
                        عدد
                        الفرص
                    </h3>
                    <div class="card bg-info mt-4">
                        <div class="card-body">
                            <h5 class="text-center text-white">
                                5 مرات
                                خلال 3 سنوات
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card bg-primary">
                <div class="card-body">
                    <h3 class="text-center text-white">
                        صلاحية الاختبار
                    </h3>
                    <div class="card bg-info  mt-4">
                        <div class="card-body">
                            <h5 class="text-center text-white">
                                5 سنوات
                                من تاريخ الاختبار
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="card bg-primary">
                <div class="card-body">
                    <h3 class="text-center text-white">
                        ظهور
                        النتائج
                    </h3>
                    <div class="card bg-info mt-4">
                        <div class="card-body">
                            <h5 class="text-center text-white">
                                خـــــــــلال
                                3 أيام عمل
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>

    <!-- <div class="row my-3">
        <div class="col-lg-12">
            <h4 class="text-primary text-center">الجدول الزمني</h4>
        </div>
        <div class="col-lg-12">
            <table class="pgat-table table table-borderless">
                <thead>


                    <tr>
                        <th rowspan="2" class="rounded-start">الفترة</th>
                        <th rowspan="2" class="bg-info">نوع الاختبار</th>
                        <th rowspan="2">اللغة</th>
                        <th rowspan="2" class="bg-info">الجنس</th>

                        <th colspan="2">فترة التسجيل المبكر</th>

                        <th colspan="2" class="bg-info">فترة الاختبار</th>
                    </tr>
                    <tr>


                        <th class="pgat-table-color-1">من</th>
                        <th class="pgat-table-color-2">إلى</th>
                        <th class="pgat-table-color-3">من</th>
                        <th class="pgat-table-color-4">إلى</th>
                    </tr>
                <tbody>
                    <tr>
                        <td>
                            الثانية
                        </td>
                        <td>
                            محوسب
                        </td>
                        <td>
                            العربية
                        </td>
                        <td>
                            ذكور
                        </td>
                        <td>
                            1443/8/1
                        </td>
                        <td>
                            1443/8/1
                        </td>
                        <td>
                            1443/8/1
                        </td>
                        <td>
                            1443/8/1
                        </td>

                    </tr>
                    <tr>
                        <td>
                            طوال العام
                        </td>
                        <td>
                            ورقي
                        </td>
                        <td>
                            العربية
                        </td>
                        <td>
                            الكل
                        </td>
                        <td>
                            1443/8/1
                        </td>
                        <td>
                            1443/8/1
                        </td>
                        <td>
                            1443/8/1
                        </td>
                        <td>
                            1443/8/1
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div> -->

    <div class="row mt-3">
        <div class="col-lg-12 p-5">
            <div class="share-card bg-warning">
                <div class="row">
                    <div class="col-auto">
                        <h5 class="font-light text-primary text-center">
                            شارك على
                        </h5>
                    </div>
                    <div class="col pt-1">
                        <a href="#" class="m-1">
                            <img src="./assets/img/icon/social/facebook.svg" width="12">
                        </a>
                        <a href="#" class="m-1">
                            <img src="./assets/img/icon/social/instagram.svg" width="20">
                        </a>
                        <a href="#" class="m-1">
                            <img src="./assets/img/icon/social/linkedin.svg" width="20">
                        </a>
                        <a href="#" class="m-1">
                            <img src="./assets/img/icon/social/twitter.svg" width="20">
                        </a>
                        <a href="#" class="m-1">
                            <img src="./assets/img/icon/social/youtube.svg" width="20">
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>


</div>


<script type="text/javascript">

    $(document).ready(function () {
        $('.responsive').slick({
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });
</script>