<div class="container">
    <!-- date & print -->
    <div class="row ">
        <div class="col">
                <lightgallery [settings]="settings">
                    <a href="https://sgr.etec.gov.sa/assets/media/img/%D9%85%D9%86%D9%87%D8%AC%D9%8A%D8%A9-%D8%A7%D9%84%D8%AA%D8%B5%D9%86%D9%8A%D9%81/ar/01-SGR-%D8%A7%D9%84%D8%A5%D8%B7%D8%A7%D8%B1%20%D8%A7%D9%84%D9%85%D9%81%D8%A7%D9%87%D9%8A%D9%85%D9%8A-V30-3-3-23.jpg"
                        id="lightgallery" class="lightgallery">
                        <img class="img-fluid" style="max-width: 500px;"
                            src="https://sgr.etec.gov.sa/assets/media/img/%D9%85%D9%86%D9%87%D8%AC%D9%8A%D8%A9-%D8%A7%D9%84%D8%AA%D8%B5%D9%86%D9%8A%D9%81/ar/01-SGR-%D8%A7%D9%84%D8%A5%D8%B7%D8%A7%D8%B1%20%D8%A7%D9%84%D9%85%D9%81%D8%A7%D9%87%D9%8A%D9%85%D9%8A-V30-3-3-23.jpg">
                    </a>
                </lightgallery>

        </div>
    </div>
</div>