import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TranscriptsList } from 'src/app/models/TranscriptsList';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transcripts',
  templateUrl: './transcripts.component.html',
  styleUrls: ['./transcripts.component.css']
})
export class TranscriptsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  transcriptList : TranscriptsList[]=[] ;
  home = '';
  _lang = '';
  PageTitle = '';
  branch   = '';
  announcePeriod ='';
  announceNumber = '';
  startDate = '';
  status = '';
  contactName = '';
  contactMobile = '';
  description = '';
  tempPublicUrl = environment.imptempPublicUrl;
  imgPublicUrl = environment.imgPublicUrl;

  constructor(private apiServices: ApiService, private apiRersources: ResourcesService) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
   }

  async ngOnInit(): Promise<void> {
    this.home = await this.apiRersources.getByKey("home");
    this.PageTitle = await this.apiRersources.getByKey("TranscriptsList");
    this.branch = await this.apiRersources.getByKey("Branch");
    this.announcePeriod = await this.apiRersources.getByKey("AnnouncePeriod");
    this.announceNumber = await this.apiRersources.getByKey("AnnounceNumber");
    this.startDate = await this.apiRersources.getByKey("StartDate");
    this.status = await this.apiRersources.getByKey("Status");
    this.contactName = await this.apiRersources.getByKey("ContactName");
    this.contactMobile = await this.apiRersources.getByKey("ContactMobile");
    this.description = await this.apiRersources.getByKey("Description");

    this.apiServices.getList('ExpiredAssets').pipe(takeUntil(this.destroy$)).subscribe(
      (data: TranscriptsList[]) => {
        this.transcriptList = data;
      }
    );
  }

}
