<article class="col-12 my-lg-4 my-3 mt-0" *ngIf="Goals.length>0">
    <div class="card border-1 bg-white shadow-primary overflow-hidden p-5 rounded-5">
        <div class="row g-4 justify-content-center align-items-center">
            <div class="col-lg-3 col-12 ">
                <div class="d-flex flex-row align-items-center justify-content-center">
                    <img src="assets/img/icon/PGATE-Search.svg" width="80" alt="Icon"
                        class="me-2 mb-lg-0 mb-3">
                    <div class="ps-lg-4">
                        <h3 class="h3 mb-0 text-primary fw-semibold" [innerText]="servicegoal"> </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-12">
                <div
                    class="row row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-1 g-lg-5 g-4 service-goals">
                    <div class="col" *ngFor="let item of cateogry(Goals)">
                        <div
                            class="d-flex flex-lg-row flex-column align-items-start justify-content-lg-start justify-content-center">
                            <div class="me-3">
                                <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center rounded-circle"
                                    style="height: 2rem; width: 2rem;">
                                    {{item.ServiceGoalNumber}}</div>
                            </div>
                            <p class="d-inline-block align-middle text-primary fs-sm m-lg-0 mt-2">
                                {{item.ServiceGoalName}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>