<section class="position-relative py-5">
  <div class="container">
    <!-- Related title -->
    <div class="row justify-content-center">
      <h3 class="h3 fw-semibold text-center pb-4 mb-2 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">{{suggestedlinks}}</h3>
    </div>
    <!-- Related item -->
    <div class="row mx-lg-5 px-xl-5 px-lg-4" *ngIf="Relatedlinks.length">
      <swiper [config]='configRelatedlinks' class="swiper-item pt-2 pb-5 mx-n0" dir="rtl">
        <ng-template *ngFor="let item of Relatedlinks" swiperSlide>
          <!-- Item -->
          <article class="card py-4 border-0 shadow-primary h-100 mx-2" data-aos="zoom-in-up" data-aos-duration="600" data-aos-offset="200">
            <div class="px-2 border-0 d-flex justify-content-center align-items-center">
              <img src="assets/media/icons/impLinks1.svg" alt="qiyas logo" width="70" data-aos="flip-left" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-out-cubic" />
            </div>
            <div class="card-body mx-3 my-4 p-0 d-flex align-items-center justify-content-center">
              <h4 class="fs-lg mb-0 text-primary text-center">
                {{item.title}}
              </h4>
              <!-- <p class="fs-6 fw-normal text-primary mt-3 mb-0" style="text-align: justify;">
                  قياس وتحسين مستوى التحصيل العلمي لطلبة المدارس، وتحفيز التميز المدرسي والتنافس الإيجابي بين المدارس
                  ومكاتب وإدارات التعليم,وفق الأدوار التكاملية والتنسيق.
                </p> -->
            </div>
            <div class="card-footer p-0 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center" data-aos="flip-up" data-aos-duration="1800">
              <a  href="{{ item.url }}" class="btn btn-outline-primary p-2 fs-sm border-1" style="width:7.5rem;" >
                 {{BrowseSite}}
              </a>
            </div>
          </article>
        </ng-template>
      </swiper>
    </div>
  </div>
</section>
 


<!-- <div class="card bg-primary h-100 border-0 shadow rounded-5 mx-2 relatedlinks">
  <div class="card-body d-flex flex-column justify-content-center align-items-center">
    <h4 class="h4 mb-3 text-white">
      <i class="bx bx-link-external me-2"></i>
      {{suggestedlinks}}
    </h4>
    <a href="#"
      class="btn btn-light rounded-3 fw-semibold p-3 px-5 mt-3 w-100 d-flex justify-content-center text-decoration-none"
      *ngFor="let item of Relatedlinks" href="{{ item.url }}" target="_blank">
      {{item.title}}
    </a>
  </div>
</div> -->



<!-- OLD -->
<!-- <div class=" link-card">
    <div class="card">
        <div class="card-body p-4 ">
            <h4 class="text-white text-center mb-5" >
                <svg id="bx-link-external" xmlns="http://www.w3.org/2000/svg" width="37.749" height="37.749" viewBox="0 0 37.749 37.749">
                    <path id="Path_6465" data-name="Path 6465" d="M21.092,4.5l6.354,6.354L13.94,24.36l2.728,2.728L30.174,13.582l6.353,6.353V4.5Z" transform="translate(1.222 -4.5)" fill="#fff" />
                    <path id="Path_6466" data-name="Path 6466" d="M38.055,38.055H8.694V8.694h14.68L19.18,4.5H8.694A4.2,4.2,0,0,0,4.5,8.694V38.055a4.2,4.2,0,0,0,4.194,4.194H38.055a4.2,4.2,0,0,0,4.194-4.194V27.569l-4.194-4.194Z" transform="translate(-4.5 -4.5)" fill="#fff" />
                </svg>
                روابط ذات صلة
            </h4>
            <a class="btn btn-light btn-block btn-lg mb-3" *ngFor="let item of Relatedlinks" href="{{ item.url }}" target="_blank">
                {{item.title}} </a>
        </div>
    </div>
</div> -->