<!--begin::Documents-->
<div class="row mb-4">
  <div class="col">
    <div class="d-flex flex-column flex-lg-row justify-content-between text-primary mb-3">
      <div class="d-flex align-items-center w-100 h-100">
        <div class="input-group position-relative w-100">
          <input type="text" class="form-control form-control-lg ps-5" placeholder="{{ searchtxt }}"
            [(ngModel)]="searchText" (keypress)="getTagsResult()" (keyup)="getTagsResult()"
            (keydown)="getTagsResult()" />
          <i
            class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
        </div>
      </div>
    </div>
  </div>
  <!-- filter -->
  <div class="col-12 my-1">
    <h5 class="fs-lg text-primary fw-semibold mb-2 d-none">{{ Category }}</h5>
    <div class="d-flex flex-row flex-wrap justify-content-start w-100" *ngIf="tags.length > 0">
      <div class="me-2 mb-2 nav nav-tabs-alt products-checkbox">
        <input type="checkbox" class="btn-check products-category" name="options2" id="option-all-tags"
          autocomplete="off" [(ngModel)]="tagsAll" (ngModelChange)="changeTage()" />
        <label class="nav-link fs-sm" for="option-all-tags">{{ all }}</label>
      </div>
      <div class="me-2 mb-2 nav nav-tabs-alt products-checkbox" *ngFor="let element of tags; let i = index">
        <input type="checkbox" class="btn-check products-category" name="options2" id="chbox{{ i }}" autocomplete="off"
          [(ngModel)]="tagsStatus[i]" (ngModelChange)="filterChange()" />
        <label class="nav-link fs-sm" for="chbox{{ i }}">{{
          element.trim()
          }}</label>
      </div>
    </div>
  </div>
  <!-- // -->
</div>
<!-- الوثائق -->
<div class="row row-cols-xl-4 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4"
  *ngIf="_ContentDocumentsResponse && _ContentDocumentsResponse.length > 0">
  <div class="col d-flex" *ngFor="let item of _ContentDocumentsResponse | slice : 0 : showCount">
    <div class="card py-4 border-0 shadow-primary w-100 h-100" *ngIf="item.ItemFields?.DocumentLink?.Url">
      <div class="card-body d-flex flex-column justify-content-center align-items-center pb-0">
        <h3 class="fs-lg fw-normal mb-2 overflow-hidden text-center">
          {{ item.ItemFields?.DocumentName }}
        </h3>
        <span *ngIf="item.ItemFields?.DocumentCategory" class="badge bg-light text-primary p-2 rounded-pill"
          [innerText]="item.ItemFields?.DocumentCategory?.LookupValue"></span>
      </div>
      <div
        class="card-footer py-3 px-xl-0 px-4 py-3 mt-3 border-0 rounded-5 d-flex align-items-center justify-content-center">
        <a *ngIf="item.ItemFields?.DocumentLink?.Url"
          href="{{   item.ItemFields?.DocumentLink?.Url?.replace(imptempPublicUrl, imgPublicUrl) }}" target="_blank"
          class="btn btn-warning fw-semibold rounded-3 p-2 fs-sm border-0 w-md-50 w-sm-75 w-75 ms-2">
          <i class="bx bx-down-arrow-alt fw-normal fs-3 me-1 mt-n1"></i>
          PDF</a>
        <!-- <a *ngIf="item.ServiceDocument" href="{{item.ServiceDocument}}" target="_blank"
                                    class="btn btn-warning rounded-3 p-2 fs-sm border-0 d-flex flex-column">
                                    <i class="bx bx-down-arrow-alt fs-3"></i>
                                    PDF
                                </a>
                                <a *ngIf="item.ServiceDocumentWord" href="{{item.ServiceDocumentWord}}" target="_blank"
                                    class="btn btn-warning rounded-3 p-2 fs-sm border-0 ms-3 d-flex flex-column">
                                    <i class="bx bx-down-arrow-alt fs-3"></i>
                                    DOC
                                </a> -->
      </div>
    </div>
  </div>
</div>
<!-- <div class="row">
                    <div class="col d-flex justify-content-center mt-4 mb-2">
                        <p class="m-0 text-primary fw-semibold fs-sm">{{showCount<
                                ServiceDoumentResponse.length?showCount:ServiceDoumentResponse.length }} {{servicesof}}
                                {{ServiceDoumentResponse.length}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center mb-2">

                        <input type="button" value=" {{more}}" (click)="showCount= showCount + 12"
                            *ngIf="showCount < ServiceDoumentResponse.length"
                            class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                            style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
                    </div>
                </div> -->