import { Component } from '@angular/core';
import { map, Subject, takeUntil } from 'rxjs';
import { CenterBusinessDetailTypeList } from 'src/app/models/CenterBusinessDetailTypeList';
import { CenterList } from 'src/app/models/CenterList';
import { Centerresponsibilities } from 'src/app/models/centerresponsibilities';
 
import { Center, ResponsbilityETEC } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-establishing',
  templateUrl: './establishing.component.html',
  styleUrls: ['./establishing.component.css']
})

export class EstablishingComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  centerId: number = 1;
  center: Center;
  CenterBDetailTypeList: CenterBusinessDetailTypeList[] = [];
  ResponsbilityETEC: ResponsbilityETEC[] = [];


  constructor(private centerService: ApiService) {
    this.getCenter();
    this.getCenterBusinessDetailTypes();
    this.getCenterResponsibilities();
  }

  /** Component events  */
  getCenter() {

    this.centerService.getListById('centers', 1).pipe(takeUntil(this.destroy$)).subscribe(
      (element: CenterList) => {
        var obj: Center = {
          centerId: element.ItemId,
          centerName: element.ItemFields.CenterName,
          centerDescription: element.ItemFields?.CenterDescription?.toString(),
          centerVision: element.ItemFields.CenterVision?.toString(),
          centerVisionIcon: '',
          centerMission: element.ItemFields.CenterMission?.toString(),
          centerMissionIcon: '',
          centerOrgnizationalStructureUrl: '',
          centerMainImageCoverUrl: '',
          centerParenetId: -1,
          isActive: element.ItemFields.IsActive1
        };
        this.center = obj;
      }
    );
  }

  getCenterBusinessDetailTypes() {

    this.centerService.getList('CenterBusinessDetailTypes').pipe(takeUntil(this.destroy$)).subscribe(
      (data: CenterBusinessDetailTypeList[]) => {
        this.CenterBDetailTypeList = data.sort((a, b) => a.ItemFields.TypeOrder - b.ItemFields.TypeOrder);
      }
    );
  }

  cloneObj(data: Centerresponsibilities): ResponsbilityETEC {
    return ({
      CenterResponsibilityId: data.ItemFields.CenterResponsibilityId,
      CenterResponsibilityTitle: data.ItemFields.CenterResponsibility
    } as ResponsbilityETEC)
  }

  getCenterResponsibilities() {

    this.centerService.getList('CenterResponsibilities').pipe(takeUntil(this.destroy$),
      map((res: Centerresponsibilities[]) => res.map(re => {
        return this.cloneObj(re)
      }))).subscribe(data => {
        this.ResponsbilityETEC = data.reverse();
      })

    //   this.centerService.getList('CenterResponsibilities').pipe(takeUntil(this.destroy$)
    //   ).subscribe(
    //    (data: Centerresponsibilities[]) => {
    //      data.forEach(element => {
    //        var obj: ResponsbilityETEC = { 
    //         CenterResponsibilityId: element.ItemFields.CenterResponsibilityId,
    //         CenterResponsibilityTitle: element.ItemFields.CenterResponsibility 
    //        };
    //        this.ResponsbilityETEC.push(obj);
    //     });
    //     this.ResponsbilityETEC.reverse();
    //    }
    //  );
  }
}
