<div class="row mb-5">
    <div class="col"> 
        <article class="card border-0 shadow-primary">
            <div class="row g-0 justify-content-center align-items-center">
                    <div class="col-lg-8 col-12">
                    <div
                        class="card-body px-0 pt-sm-0 ps-sm-4 pb-0 m-3 d-flex flex-column justify-content-center align-items-center">
                        <p class="mb-2 fs-sm text-primary text-center">{{cEOonly?.centerLeaderPrefixId}}</p>
                        <h3 class="fs-lg fw-semibold text-primary mb-4 text-center">
                            {{cEOonly?.centerLeaderName}}
                        </h3>
                        <div class="bg-primary text-white p-3 fs-sm text-center w-lg-50 w-100"
                            style="border-radius: 0.7rem;">{{cEOonly?.centerLeaderPosition}}</div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</div>

<div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 g-4">
    <div class="col d-flex align-items-stretch" *ngFor="let item of  boardMembers">
        <article class="card border-0 shadow-primary bg-white w-100">
            <div class="card-header border-0 text-center mt-3 d-none">
                <div class="position-relative bg-position-center bg-repeat-0 bg-size-cover m-auto"
                    style=" min-height: 15rem; max-width: 17rem;border-radius: 0.7rem;" [ngStyle]="{'background-image': 'url(' +'\'' +item.centerLeaderPortrait +'\'' +')'}">
                    <!-- [ngStyle]="{'background-image': 'url(' +'\'' +item.centerLeaderPortrait +'\'' +')'}" -->
                </div>
            </div>
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <p class="mb-2 fs-sm text-primary">{{item?.centerLeaderPrefixId}}</p>
                <h3 class="fs-lg fw-semibold text-primary mb-4 text-center">
                    {{item?.centerLeaderName}}
                </h3>
                <div class="bg-primary text-white p-3 px-2 fs-sm text-center w-100"
                    style="border-radius: 0.7rem;">{{item?.centerLeaderPosition}}
                </div>
            </div>
        </article>
    </div>
    
</div>
 <!--begin::share-->
 <app-shareicons></app-shareicons>