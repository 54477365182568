import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {   Subject, takeUntil } from 'rxjs';
import { Service, ServiceAudience, ServiceContent, ServiceGoal, ServiceNote } from 'src/app/models/ResponseModel';
import { ServiceAudnicesResponse } from 'src/app/models/ServiceAudnicesResponse';
import { ServiceContentResponse } from 'src/app/models/ServiceContentResponse';
import { ServiceGoalsResponse } from 'src/app/models/ServiceGoalsResponse';
import { ServiceNotesResponse } from 'src/app/models/ServiceNotesResponse';
import { ServicesResponse } from 'src/app/models/ServicesResponse';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-product-qiyas',
  templateUrl: './product-qiyas.component.html',
  styleUrls: ['./product-qiyas.component.css']
})
export class ProductQiyasComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  productId: number;
  Product: Service;
  Goals: ServiceGoal[] = [];
  Content: ServiceContent[] = [];
  Notes: ServiceNote[] = [];
  Audnices: ServiceAudience[] = [];

  constructor(private actRoute: ActivatedRoute, private apiServices: ApiService) { }

/** Custom methods */
  getService() {
    this.apiServices.getListById('Services', this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServicesResponse) => {
        var obj: Service = {
          serviceTitle: data.ItemFields.Title,
          serviceId: data.ItemId,
          serviceName: data.ItemFields.ServiceName,
          ServiceCenterId: 1,
          ServiceDescription: data.ItemFields.ServiceDescription!,
          serviceIconUrl: './assets/img/icon/exam/GAT.svg',
          serviceLevel: 0,
          serviceVisitCount: 0,
          serviceDetailsFileUrl: '',
          serviceKeyword: data.ItemFields.ServiceKeyword,
          isActive: data.ItemFields.IsActive1,
          ShowDetailsButton:  data.ItemFields.ShowDetailsButton
        }
        this.Product = obj;


      }
    );

  }

  getServiceGoals() {
    this.apiServices.getListTopN('ServiceGoals', 100, true, "ServiceId", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceGoalsResponse[]) => {
        data.forEach(element => {
          var obj: ServiceGoal = {
            ServiceGoalNumber: element.ItemFields.ServiceGoalNumber,
            ServiceGoalName: element.ItemFields.ServiceGoalName
          };

          this.Goals.push(obj);

        });
      });


  }

  getServiceContent() {
    this.apiServices.getListTopN('ServiceContent', 100, true, "ServiceId", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceContentResponse[]) => {
        data.forEach(element => {
          var obj: ServiceContent = {
            ServiceContentTitle: element.ItemFields.ServiceContentTitle,
            ServiceContentDetailes: element.ItemFields.ServiceContentDetails,
            ServiceContentIcon: element.ItemFields.ServiceContentIcon!
          };

          this.Content.push(obj);
        });
      });


  }

  getServiceNotes() {
    this.apiServices.getListTopN('ServiceNotes', 100, true, "ServiceId", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceNotesResponse[]) => {
        data.forEach(element => {
          var obj: ServiceNote = {
            serviceNoteDetailes: element.ItemFields.ServiceNoteDetails,
            serviceNoteTitle: element.ItemFields.ServiceNoteTitle

          };

          this.Notes.push(obj);
        });
      });


  }

  getServiceAudnices() {
    this.apiServices.getListTopN('ServiceAudiences', 100, true, "ServiceId", this.productId).pipe(takeUntil(this.destroy$)).subscribe(
      (data: ServiceAudnicesResponse[]) => {
        data.forEach(element => {
          var obj: ServiceAudience = {
            serviceAudienceId: element.ItemFields.ServiceAudienceId,
            serviceAudienceName: element.ItemFields.ServiceAudienceName

          };

          this.Audnices.push(obj);
        });
      });


  }

/** Component events  */
  ngOnInit(): void {
    this.actRoute.params.subscribe(params =>
    this.productId = params["id"]);

    this.getService();
    this.getServiceGoals();
    this.getServiceContent();
    this.getServiceNotes();
    this.getServiceAudnices();
  }
  
  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
