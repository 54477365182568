import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.css']
})

export class ComboboxComponent implements OnInit, OnChanges  {

  @Input() list: any[];
  @Input() outSideIndex :number;
  @Output() listChange = new EventEmitter<any[]>();

  @Input() Title: string;
  @Output() Item: EventEmitter<string> = new EventEmitter<string>();
  // two way binding for input text
  inputItem = '';
  all ='';
  // enable or disable visiblility of dropdown
  listHidden = true;
  showError = false;
  selectedIndex = -1;

  // the list to be shown after filtering
  filteredList: any[] = [];

  constructor( private apiResources: ResourcesService) { 
    
  }

  async ngOnInit() {
    this.all =  await this.apiResources.getByKey("all");
    this.filteredList = this.list;
    // this.selectedIndex = this.outSideIndex;
    // this.inputItem = this.all;
  }

  ngOnChanges() {
    //Write your code here
    this.filteredList = this.list;
    this.selectedIndex = this.outSideIndex;
    this.inputItem = '';
    }  
  // modifies the filtered list as per input
  getFilteredList() {

    this.listHidden = false;
    // this.selectedIndex = 0;
    if (!this.listHidden && this.inputItem !== undefined && this.inputItem != this.all) {
      this.filteredList = this.list.filter((item) => item.Title.toLowerCase()
      .includes(this.inputItem.toLowerCase()));
    }
  }

  // select highlighted item when enter is pressed or any item that is clicked
  selectItem(ind : number) {

    if(ind == 0){
      this.inputItem = this.all;
      this.listHidden = true;
      this.selectedIndex = ind;
      this.Item.emit('0');
    }else{
      this.inputItem = this.filteredList[ind-1].Title;
      this.listHidden = true;
      this.selectedIndex = ind;
      this.Item.emit(this.inputItem);
    }
  

  }

  // navigate through the list of items
  onKeyPress(event: any) {

    if (!this.listHidden) {
      if (event.key === 'Escape') {
        this.selectedIndex = -1;
        this.toggleListDisplay(0);
      }

      if (event.key === 'Enter') {

        this.toggleListDisplay(0);
      }
      if (event.key === 'ArrowDown') {

        this.listHidden = false;
        this.selectedIndex = (this.selectedIndex + 1) % this.filteredList.length;
        if (this.filteredList.length > 0 && !this.listHidden) {
          document.getElementsByTagName('list-item')[this.selectedIndex].scrollIntoView();
        }
      } else if (event.key === 'ArrowUp') {

        this.listHidden = false;
        if (this.selectedIndex <= 0) {
          this.selectedIndex = this.filteredList.length;
        }
        this.selectedIndex = (this.selectedIndex - 1) % this.filteredList.length;

        if (this.filteredList.length > 0 && !this.listHidden) {

          document.getElementsByTagName('list-item')[this.selectedIndex].scrollIntoView();
        }
      }
    } 
  }

  // show or hide the dropdown list when input is focused or moves out of focus
  toggleListDisplay(sender: number) {
    console.log('hi');
    if (sender === 1) {
      // this.selectedIndex = -1;
      this.listHidden = false;
      this.getFilteredList();
    } else {
      // helps to select item by clicking
      setTimeout(() => {
       // this.selectItem(this.selectedIndex);
        this.listHidden = true;
        if (!this.list.includes(this.inputItem)) {
          this.showError = true;
          this.filteredList = this.list;
        } else {
          this.showError = false;
        }
      }, 250);
    }
  }

}
