import { Component, OnInit } from '@angular/core';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import { BeforeSlideDetail } from 'lightgallery/lg-events';

@Component({
  selector: 'app-lightgallery',
  templateUrl: './lightgallery.component.html',
  styleUrls: ['./lightgallery.component.css']
})
export class LightgalleryComponent implements OnInit {
  // start settings
  settings = {
    counter: false,
    actualSize: true,
    height: '100%',
    width: '100%',
    zoomFromOrigin: true,
    zoomPosition: 'center',
    mobileSettings: { controls: true,},
    plugins: [lgFullscreen, lgZoom]
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };
  // end settings
  
  constructor() { }
  ngOnInit(): void {
  }

}
