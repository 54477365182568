<div class="row">
    <div class="col">
        <p class="text-primary lh-base fs-sm">
            {{ cenetrLeader?.centerLeaderCurriculumVite }}
        </p>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">

        <p class="text-primary lh-base fs-sm" style="text-align: justify;" [innerText]="center?.centerDescription">
            <!-- {{ center?.centerDescription }} -->
        </p>
        <div class="text-primary" *ngFor="let item of CenterBDetailTypeList">
            <app-center-details [businssType]="item.ItemId" [centerId]="centerId" [typeName]="item.ItemFields.TypeDescription"></app-center-details>
        </div>

       
    </div>
</div>
<!-- <app-shareicons></app-shareicons> -->