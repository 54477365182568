import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';
import { Mediacentervideoslist } from 'src/app/models/mediacentervideoslist';
import { MediaCenterVideos } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ResourcesService } from 'src/app/services/resources.service';


@Component({
  selector: 'app-videolibrary',
  templateUrl: './videolibrary.component.html',
  styleUrls: ['./videolibrary.component.css']
})

export class VideolibraryComponent implements OnInit {

  MediaCenterVideos: MediaCenterVideos[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText: any = '';
  more: string = '';

  ascalph: string = '';
  descalph: string = '';
  ascdatealph: string = '';
  datedescalph: string = '';
  orderby: string = '';
  MediaCenterVideosSearch: MediaCenterVideos[] = [];
  showCount = 6;
  selectedValue: string;
  url: SafeUrl | undefined;
  videoTitle: any;
  show: string = '';
  servicesof: string = '';
  searchtxt: string = '';
  showLoading = false;

  @ViewChild('iframe') iframe: ElementRef;

  constructor(private apiServices: ApiService, private _sanitizer: DomSanitizer, private apiResources: ResourcesService, private sanitizer: DomSanitizer) { 
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }

  /** Custom methods */
  getMediaCenterFiles() {
    this.apiServices.getListTopN('MediaCenterFiles', 0, false, 'MediaCenterCategory', 'مكتبة الفيديو').pipe(takeUntil(this.destroy$)).subscribe(
      (data: Mediacentervideoslist[]) => {
        data.forEach(element => {
          var obj: MediaCenterVideos = {
            ID: element.ItemFields.ID,
            Title: element.ItemFields.Title,
            MediaCenterCategory: element.ItemFields.MediaCenterCategory.LookupValue,
            MCFileName: element.ItemFields.MCFileName,
            MCFileLink: this._sanitizer.bypassSecurityTrustResourceUrl(element.ItemFields.MCFileLink?.Url + '?rel=0'),
            MCFileThumbnails: this.getThumbnail(element.ItemFields?.MCFileLink?.Url, 'big'),
            MCFilesDetails: element.ItemFields.MCFilesDetails,
            isActive: element.ItemFields.IsActive1,
            MediaOrder: element.ItemFields?.MediaOrder
          };

          this.MediaCenterVideos.push(obj);
        });
        this.MediaCenterVideosSearch = this.MediaCenterVideos;
        // this.MediaCenterVideos.sort((a: any, b: any) => {
        //   return <any>new Date(b.newsDate) - <any>new Date(a.newsDate);
        // });
        this.MediaCenterVideos.sort((a: any, b: any) => {
          return b.MediaOrder - a.MediaOrder;
        });
      }
    );
  }
  // changeRatio(event: MatSelectChange) {
  //   var optionSelected = event.value.split('-');
  //   this.MediaCenterVideos.splice(0, this.MediaCenterVideos.length);
  //   //console.log(optionSelected[1]+ " "+ optionSelected[0]);
  //   this.apiServices.getListTopN('MediaCenterVideos', 0, optionSelected[1], optionSelected[0], '').pipe(takeUntil(this.destroy$)).subscribe(
  //     (data: Mediacentervideoslist[]) => {
  //       data.forEach(element => {
  //         var obj: MediaCenterVideos = {
  //           ID: element.ItemFields.ID,
  //           Title: element.ItemFields.Title,
  //           MediaCenterCategory: element.ItemFields.MediaCenterCategory.LookupValue,
  //           MCFileName: element.ItemFields.MCFileName,
  //           MCFileLink: this._sanitizer.bypassSecurityTrustResourceUrl(element.ItemFields.MCFileLink?.Url + '?rel=0'),
  //           // MCFileThumbnails: element.ItemFields.MCFileThumbnails?.Url,
  //           MCFileThumbnails: this.getThumbnail(element.ItemFields.MCFileLink?.Url, 'big'),
  //           MCFilesDetails: element.ItemFields.MCFilesDetails,
  //           isActive: element.ItemFields.IsActive1,
  //         };

  //         this.MediaCenterVideos.push(obj);
  //       });
  //       this.MediaCenterVideosSearch = this.MediaCenterVideos;
  //     }
  //   );
  // }

  getThumbnail(url: any, size: string): string {
    var video, results;

    video = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
    // video   = (results === null) ? url : results[1];

    if (size === 'small') {
      return 'https://img.youtube.com/vi/' + video + '/2.jpg';
    }
    return 'https://img.youtube.com/vi/' + video + '/0.jpg';
  }

  search() {
    this.showCount = 5;
    this.MediaCenterVideos = this.MediaCenterVideosSearch;
    this.MediaCenterVideos = this.MediaCenterVideos.filter(x => x.MCFileName?.toUpperCase().includes(this.searchText.toUpperCase()));
  }

  open(item: MediaCenterVideos) {
    this.showLoading = true;
    this.videoTitle = item.MCFileName;
    this.url = item.MCFileLink;
  }

  stopVideo() {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }
  
  onLoad() {
    this.showLoading = false;
  }
  /** Component events  */
  async ngOnInit() {
    this.more = await this.apiResources.getByKey("more");
    this.ascalph = await this.apiResources.getByKey("ascalph");
    this.descalph = await this.apiResources.getByKey("descalph");
    this.ascdatealph = await this.apiResources.getByKey("ascdatealph");
    this.datedescalph = await this.apiResources.getByKey("datedescalph");
    this.orderby = await this.apiResources.getByKey("orderby");
    this.show = await this.apiResources.getByKey("show");
    this.servicesof = await this.apiResources.getByKey("servicesof");
    this.searchtxt = await this.apiResources.getByKey("search");

    this.getMediaCenterFiles();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

}
