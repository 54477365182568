    <!--begin::statistics-->
    <section class="position-relative py-5 bg-info statistics">
      <div class="container">
        <div class="row mb-5">
          <div class="col d-flex justify-content-center mt-3 mb-4 wow fadeInDown" data-wow-duration="2s">
            <h3 class="h3 text-white fw-semibold mb-0 d-flex justify-content-center" data-aos="fade-up" data-aos-duration="1000">
              {{etecinnumbers}}
            </h3>
          </div>
        </div>
        <div class="row row-cols-2 row-cols-sm-2 row-cols-xl-4 g-4 d-flex justify-content-center align-items-center mb-3">

          <div class="col text-white d-flex flex-column align-items-center justify-content-center shadow-0 rounded-3" *ngFor="let item of statistics">
            <img src="assets/media/icons/stat-icon2.svg" width="80" data-aos="flip-left" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-out-cubic"/>
            <h3 class="h3 text-white fw-semibold pt-3" data-aos="flip-up" data-aos-duration="1000">{{item.numbersText}}</h3>
            <p class="fs-sm fw-normal text-center mb-0 pb-3" data-aos="flip-up" data-aos-duration="1200">{{item.msg}}</p>
          </div>

        </div>
      </div>
    </section>









<!-- <section class="position-relative my-3 py-5 bg-info" style="background-color: #97D2DB;">
    <div class="container">
      <div class="row mb-5">
        <div class="col d-flex justify-content-center wow fadeInDown" data-wow-duration="2s">
          <h3 class="h1 text-white fw-semibold mb-0 d-flex justify-content-center">
            <i class="bx bx-bar-chart-alt-2 text-white pe-3 display-5"></i>
 {{etecinnumbers}}          </h3>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 g-4 d-flex justify-content-center align-items-center" style="padding-bottom:4rem;">
        <div class="col text-primary d-flex flex-column align-items-center justify-content-center shadow-0 rounded-3" *ngFor="let item of statistics">
          <h3 class="display-6 fw-semibold pt-3">{{item.numbersText}}</h3>
          <p class="fs-xl fw-normal mb-0 pb-3">{{item.msg}}</p>
        </div>
      </div>
    </div>
  </section> -->

<!-- <div class="bg-success-light p-5 Center-number">
    <div class="container">
        <div class="row">
            <div class="col-lg-12  mb-5">
                <h3 class="text-center text-white fw-normal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 60 60">
                        <path id="Path_6464" data-name="Path 6464"
                            d="M564.961,17.911h-12V8.437a6.164,6.164,0,0,0-6-6.317h-12a6.164,6.164,0,0,0-6,6.317V24.226h-12a6.164,6.164,0,0,0-6,6.315V58.961a3.082,3.082,0,0,0,3,3.159h54a3.082,3.082,0,0,0,3-3.159V24.226a6.164,6.164,0,0,0-6-6.315m-48,12.63h12V55.8h-12Zm18-3.156V8.437h12V55.805h-12Zm30,28.421h-12V24.226h12Z"
                            transform="translate(-510.96 -2.12)" fill="#fff" />
                    </svg>
                    الهيئة في أرقام
                </h3>
            </div>
            <div class="col-lg-12  mb-5">
                <div class="row">
                    <div class="col-lg-3 text-center statistics" *ngFor="let item of statistics">
                        <h1 class="text-primary"> {{item.numbersText}}</h1>

                        <p class="text-primary">
                            {{item.msg}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->