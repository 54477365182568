import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Service } from 'src/app/models/ResponseModel';
import { ServicesResponse } from 'src/app/models/ServicesResponse';
import { ServiceStructureSectorsList } from 'src/app/models/ServiceStructureSectorsList';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ServicesDto } from 'src/app/models/ServicesDto';
import { CenterList } from 'src/app/models/CenterList';
import { environment } from 'src/environments/environment';
import { ResourcesService } from 'src/app/services/resources.service';
import { SwiperOptions, Pagination, Swiper, Autoplay } from 'swiper';


@Component({
  selector: 'app-productsonhome',
  templateUrl: './productsonhome.component.html',
  styleUrls: ['./productsonhome.component.css']
})
export class ProductsonhomeComponent implements OnInit {
  configServices: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    speed: 1000,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    // navigation: true,
    pagination: { clickable: true },
    breakpoints: {
      '560': {
        slidesPerView: 1
      },
      '768': {
        slidesPerView: 2
      },
      '1000': {
        slidesPerView: 3
      },
      '1200': {
        slidesPerView: 4
      }
    }
  };
  searchText: any = '';
  personalServices: Service[] = [];
  businessServices: Service[] = [];
  valuesPersonal: ServicesDto[] = [];
  valuesbusiness: ServicesDto[] = [];
  centers: CenterList[] = [];
  Sector1 = '';
  Sector2 = '';
  more: string = '';
  search: string = '';
  ourService: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  myControlP = new FormControl('');
  myControlB = new FormControl('');
  filteredOptionsP: Observable<ServicesDto[]>;
  filteredOptionsB: Observable<ServicesDto[]>;
  _lang = '';

  constructor(private apiServices: ApiService, private router: Router, private apiResources: ResourcesService) { }

  /** Custom methods */
  route(product: Service) {
    return "/service/" + product.ServiceCode;
  }

  getTopServices() {

    this.apiServices.getList('centers').pipe(takeUntil(this.destroy$)).subscribe(
      (data) => {
        this.centers = data;

        this.apiServices.getListsItemsFilterdOrderd('Services', 6, false, 'VisitingCount', 'ServiceSectorId', 1, 'Eq', '', 'Eq', '', '').pipe(takeUntil(this.destroy$)).subscribe(
          (data: ServicesResponse[]) => {
            data.forEach(element => {
              var obj: Service = {
                GUID: element.ItemFields?.GUID,
                ServiceCode: element.ItemFields?.ServiceCode,
                serviceTitle: element.ItemFields?.Title,
                serviceId: element.ItemId,
                serviceName: element.ItemFields.Title,
                ServiceCenterId: element.ItemFields?.ServiceCenterId?.LookupId,
                ServiceCenterName: this.centers.find(elm => elm.ItemId == element.ItemFields?.ServiceCenterId?.LookupId)?.ItemFields.CenterShortName,
                ServiceCenterIcon: this.centers.find(elm => elm.ItemId == element.ItemFields?.ServiceCenterId?.LookupId)?.ItemFields.CenterLogoIcon?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
                ServiceDescription: '',
                serviceIconUrl: './assets/img/icon/exam/GAT.svg',
                serviceLevel: 0,
                serviceVisitCount: element.ItemFields.VisitingCount ? element.ItemFields.VisitingCount : 0,
                serviceDetailsFileUrl: '',
                serviceKeyword: element.ItemFields?.ServiceKeyword,
                serviceRegistrationLink: data[0]?.ItemFields?.DetailsButtonLink?.Url,
                serviceRegistrationTitle: data[0]?.ItemFields?.DetailsButtonLabel,
                serviceSectorId: element.ItemFields.ServiceSectorId?.LookupId,
                isActive: element.ItemFields.IsActive1,
                ShowDetailsButton: element.ItemFields.ShowDetailsButton
              };

              if (obj.serviceSectorId === 1) {
                this.personalServices.push(obj);
              }
              else {
                this.businessServices.push(obj);
              }

            });

            this.personalServices = this.personalServices.sort((a, b) => b.serviceVisitCount - a.serviceVisitCount);

            this.personalServices.forEach(element => {
              var dtoObj: ServicesDto =
              {
                serviceName: element.serviceName,
                GUID: element.GUID,
                iconUrl: element.serviceIconUrl
              }
              this.valuesPersonal.push(dtoObj);
            });

            this.filteredOptionsP = this.myControlP.valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value || '*')),
            );
          }
        );

        this.apiServices.getListsItemsFilterdOrderd('Services', 6, false, 'VisitingCount', 'ServiceSectorId', 2, 'Eq', '', 'Eq', '', '').pipe(takeUntil(this.destroy$)).subscribe(
          (data: ServicesResponse[]) => {
            data.forEach(element => {
              var obj: Service = {
                GUID: element.ItemFields.GUID,
                serviceTitle: element.ItemFields.Title,
                ServiceCode: element.ItemFields?.ServiceCode,
                serviceId: element.ItemId,
                serviceName: element.ItemFields.Title,
                ServiceCenterId: element.ItemFields.ServiceCenterId?.LookupId,
                ServiceCenterName: this.centers.find(elm => elm.ItemId == element.ItemFields?.ServiceCenterId?.LookupId)?.ItemFields.CenterShortName,
                ServiceCenterIcon: this.centers.find(elm => elm.ItemId == element.ItemFields?.ServiceCenterId?.LookupId)?.ItemFields.CenterLogoIcon?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
                ServiceDescription: '',
                serviceIconUrl: './assets/img/icon/exam/GAT.svg',
                serviceLevel: 0,
                serviceVisitCount: element.ItemFields.VisitingCount ? element.ItemFields.VisitingCount : 0,
                serviceDetailsFileUrl: '',
                serviceKeyword: element.ItemFields.ServiceKeyword,
                serviceRegistrationLink: data[0]?.ItemFields?.DetailsButtonLink?.Url,
                serviceRegistrationTitle: data[0]?.ItemFields?.DetailsButtonLabel,
                serviceSectorId: element.ItemFields.ServiceCategoryId?.LookupId,
                isActive: element.ItemFields.IsActive1,
                ShowDetailsButton: element.ItemFields.ShowDetailsButton
              };
              if (obj.serviceSectorId === 1) {
                this.personalServices.push(obj);
              }
              else {
                this.businessServices.push(obj);
              }

            });
            this.businessServices = this.businessServices.sort((a, b) => b.serviceVisitCount - a.serviceVisitCount);

            this.businessServices.forEach(element => {
              var dtoObj: ServicesDto =
              {
                serviceName: element.serviceName,
                GUID: element.GUID,
                iconUrl: element.serviceIconUrl
              }
              this.valuesbusiness.push(dtoObj);
            });

            //console.log(this.valuesbusiness);

            this.filteredOptionsB = this.myControlB.valueChanges.pipe(
              startWith(''),
              map(value => this._filterB(value || '*')),
            );

          }
        );

        this.apiServices.getList('ServiceStructureSectors').pipe(takeUntil(this.destroy$)).subscribe(
          (data: ServiceStructureSectorsList[]) => {
            data.forEach(element => {
              if (element.ItemId === 1) {
                this.Sector1 = element.ItemFields?.ServiceSectorName;
              }
              if (element.ItemId === 2) {
                this.Sector2 = element.ItemFields?.ServiceSectorName;
              }
            });
          }
        );


      }
    );
  }

  private _filter(value: string): ServicesDto[] {

    const filterValue = value.toLowerCase();
    return this.valuesPersonal.filter(option => option.serviceName.toLowerCase().includes(filterValue));
  }

  private _filterB(value: string): ServicesDto[] {

    const filterValue = value.toLowerCase();
    return this.valuesbusiness.filter(option => option.serviceName.toLowerCase().includes(filterValue));
  }

  putVisitNo(itemId: string) {
    this.apiServices.putVisitingCount('Services', itemId).subscribe();
  }

  navigateUrl(product: Service) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }

    this.putVisitNo(product.serviceId.toString());
    this.router.navigateByUrl(this._lang+'/service/' + product.ServiceCode);

  }

  displayFn(service: string) {
    this.router.navigateByUrl('/service/' + service);
  }

  /** Component events  */
  async ngOnInit() {
    Swiper.use([Pagination]);
    Swiper.use([Autoplay]);
    await this.apiServices.logInAsync();
    this.getTopServices();
    this.more = await this.apiResources.getByKey("more");
    this.search = await this.apiResources.getByKey("search");
    this.ourService = await this.apiResources.getByKey("ourservices");
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
