<!-- hero -->
<section class="container overflow-hidden  mt-4 pt-4 mb-4 pb-4">
    <div class="d-flex align-items-center rounded-5 position-relative overflow-hidden py-5 px-3 px-sm-4 px-xl-5 mx-md-n3 mx-lg-auto hero-mh bg-primary"
        style="background: radial-gradient(circle, rgba(105,128,188,1) 0%, rgba(61,46,122,1) 55%);">
        <div class="position-absolute bottom-0 start-50 translate-middle-x h-100">
            <img src="assets/media/img/hero-natforum.png" alt="hero img" style="min-width: 1200px;">
        </div>
    </div>
    <div class="position-relative mt-n4 rounded-5 w-100 d-flex justify-content-center p-3 py-4"
        style="background-color: #3994C8;">
        <img src="assets/media/logos/etec-ywlogo.svg" alt="etec logo" width="150" class="me-xl-5 me-4">
        <img src="assets/media/logos/moe-wlogo.svg" alt="moe logo" width="90">
    </div>
</section>


<div *ngIf="_lang.toString() == 'ar'">
    <!-- title -->
    <section class="container overflow-hidden mt-4 text-center">
        <p class="text-center fs-3 text-primary mb-4 fw-bold">
            الملتقى الوطني للتميز المدرسي (تميز 2024)
        </p>
        <p class="text-center fs-6 p-3 rounded-pill d-inline-flex text-center "
            style="color: #3994C8;background-color: #F4FAFE;border: 1px solid #D4EDFF;">
            11 ربيع الثاني 1446هـ الموافق 14 أكتوبر 2024
        </p>
    </section>
</div>
<div *ngIf="_lang.toString() == 'en'">
    <!-- title -->
    <section class="container overflow-hidden mt-4 text-center">
        <p class="text-center fs-3 text-primary mb-4 fw-bold">
            The National Forum for School Excellence (Tamayuz 2024)
        </p>
        <p class="text-center fs-6 mb-4 p-3 rounded-pill d-inline-flex text-center "
            style="color: #3994C8;background-color: #F4FAFE;border: 1px solid #D4EDFF;">
            11 Rabi' al-Thani, 1446,
            October 14, 2024
        </p>
    </section>
</div>

    <div class="container"  >
        <iframe [src]="url"  height="850"></iframe>
    </div>

   