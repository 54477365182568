<article class="col-12 my-lg-4 my-3 mb-lg-0 mb-0" *ngIf="faqList.length > 0">
    <div class="row justify-content-md-start justify-content-center" *ngIf="faqList.length > 0">
        <h3 class="h3 text-center mt-0 mb-4 fw-semibold">{{faq}}</h3>
        <div class="col-12 text-primary" style="text-align: justify;">
            <div class="accordion" id="accordionAlt">
                <!-- Item -->
                <div class="accordion-item border-0 rounded-3 shadow-primary mb-3"
                    *ngFor="let elm of faqList ; let i = index">
                    <h3 class="accordion-header" id="heading{{i}}">
                        <button
                            class="accordion-button shadow-none rounded-3 text-primary collapsed fs-sm"
                            type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse'+i" aria-expanded="false"
                            [attr.aria-controls]="'collapse'+i">
                            {{elm.ItemFields.FAQQuestion}}
                        </button>
                    </h3>
                    <div class="accordion-collapse collapse" id="collapse{{i}}"
                        [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionAlt">
                        <div class="accordion-body text-primary pt-0 fs-sm"
                            [innerHTML]="elm.ItemFields.FAQAnswer">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>