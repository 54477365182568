<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/Privacy.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="text-white h1 fw-semibold m-0 position-relative zindex-5" [innerText]="privacypolicy">
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
        <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="/home" [innerText]="home"></a></li>
                        <li class="breadcrumb-item active" aria-current="page" [innerText]="privacypolicy"></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<!--begin::card-->
<div class="container">
    <div class="row justify-content-md-start justify-content-center mb-2">
        <div class="col-12 text-primary" style="text-align: justify;" [innerHTML]="content"> </div>
    </div>


    <!--begin::share-->
    <app-shareicons></app-shareicons>
</div>