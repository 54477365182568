import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
 
import { ResearchAndReportList } from 'src/app/models/ResearchAndReportList';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.css']
})
export class WorkshopComponent implements OnInit {
  activeTab = 'workshops';
  reasearchList: ResearchAndReportList[] = [];
  reasearchList2: ResearchAndReportList[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private actRoute: ActivatedRoute, private service: ApiService) {

    this.actRoute.params.subscribe(params =>
      this.activeTab = params["id"] == null ? 'workshops' : params["id"]);
  }

 /** Custom methods */
  getResearchAndReports(){
    this.service.getList('ResearchAndReports').pipe(takeUntil(this.destroy$)).subscribe(
      (data: ResearchAndReportList[]) => {
        data.forEach(element => {
         if(element.ItemFields?.RRType?.LookupId === 4 ){
          this.reasearchList.push(element);
         }
         if(element.ItemFields?.RRType?.LookupId === 5 ){
          this.reasearchList2.push(element);
         }
        });
      }
    );
  }

/** Component events  */
  ngOnInit(): void {

    this.getResearchAndReports();
  }
}
