import { Component, Input, OnInit } from '@angular/core';
import { TestTimeTableList } from 'src/app/models/TestTimeTableList';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-test-timetable',
  templateUrl: './test-timetable.component.html',
  styleUrls: ['./test-timetable.component.css']
})
export class TestTimetableComponent implements OnInit {

  @Input() TestTimeTable: TestTimeTableList[] = [];
  @Input() timetable : string
  
  period = '';
  testType = '';
  language  = '';
  sex = '';
  earlyRegistrationPeriod  ='';
  from = '';
  to = '';
  TestPeriod = '';
  constructor(private apiRersources: ResourcesService) { }

  async ngOnInit(): Promise<void> {
    this.period = await this.apiRersources.getByKey("Period");
    this.testType = await this.apiRersources.getByKey("TestType");
    this.language = await this.apiRersources.getByKey("Language");
    this.sex = await this.apiRersources.getByKey("Sex");
    this.earlyRegistrationPeriod = await this.apiRersources.getByKey("EarlyRegistrationPeriod");
    this.TestPeriod = await this.apiRersources.getByKey("TestPeriod");
    this.from = await this.apiRersources.getByKey("From");
    this.to = await this.apiRersources.getByKey("To");
  }

}
