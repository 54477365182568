<!--begin::Newhero-->
<div class="carousel bg-primary py-0 position-relative overflow-hidden shadow-primary"
  style="height: 100vh; min-height: 100vh;">
  <!-- video -->
  <video playsinline autoplay muted loop poster="assets/media/img/etec-building.jpg"
    class="bg-size-cover bg-position-center bg-repeat-0 hero-img" *ngIf="defaultTheme==''">
    <source src="assets/media/video/mp4video.mp4" type="video/mp4" />
  </video>
  <!-- mask -->
  <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-75 zindex-4" *ngIf="defaultTheme==''"></span>
  <div *ngIf="carousels.length"
    class="position-relative text-center zindex-5 overflow-hidden pt-4 py-md-5 d-flex justify-content-center align-items-center"
    style="height: 80vh; min-height: calc(100vh - 53px)">
    <swiper [config]="configCarousel"
      class="container text-center py-xl-5 position-relative zindex-5 d-flex align-items-xl-end align-items-center"
      style="min-height: 40vh" dir="rtl">
      <ng-template swiperSlide *ngFor="let item of carousels">
        <div class="row justify-content-center m-0">
          <div class="col-xl-8 col-lg-9 col-md-10 col-11" *ngIf="!item.link">
            <h1 class="from-start text-center text-hero h1 fw-semibold mb-lg-2" *ngIf="item.head">
              {{ item.head }}
            </h1>
            <h1 class="from-start text-center text-white h1 fw-semibold pb-2 mb-lg-3">
              {{ item.title }}
            </h1>
            <div class="from-end">
              <p class="fs-lg text-light opacity-70 pb-2 mb-lg-3">
                {{ item.Message }}
              </p>
            </div>
          </div>

          <div class="col-xl-8 col-lg-9 col-md-10 col-11" *ngIf="item.link">
            <a href="{{ item.link }}" style="text-decoration: none;" target="_blank">
              <h1 class="from-start text-center text-hero h1 fw-semibold mb-lg-2" *ngIf="item.head">
                {{ item.head }}
              </h1>
              <h1 class="from-start text-center text-white h1 fw-semibold pb-2 mb-lg-3">
                {{ item.title }}
              </h1>
              <div class="from-end">
                <p class="fs-lg text-light opacity-70 pb-2 mb-lg-3">
                  {{ item.Message }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
  <div class="scroll-btn d-lg-block d-none">

    <span class="mouse">
      <span>
      </span>
    </span>

  </div>
<!-- start event -->
    <div class="d-flex justify-content-center w-100 position-absolute nd-logo ms-1" *ngIf="defaultTheme =='event'">
      <img src="../assets/media/ND93/ND93-LOGO.svg" alt="national-day93 logo">
    </div>
  <div class="d-flex position-absolute w-100 h-100 nd-height" *ngIf="defaultTheme =='event'">
    <div class="position-absolute bg-size-contain h-100 orange-wave"></div>
    <div class="position-absolute bg-size-contain h-100 blue-wave"></div>
    <div class="position-absolute bg-size-contain h-100 nd-diriyah-1"></div>
    <div class="position-absolute bg-size-contain h-100 nd-diriyah-2"></div>
  </div>
<!-- end event -->

</div>

