<article class="col-12 my-lg-4 my-3" *ngIf="Steps.length>0">
    <div class="card border-1 bg-white shadow-primary overflow-hidden p-5 rounded-5">
        <div class="row g-4 justify-content-center align-items-center">
            <div class="col-lg-3 col-12 ">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <img src="assets/img/icon/PGATE-Target.svg" width="120" alt="Target Icon"
                        class="mb-2">
                    <div class="pt-3">
                        <p class="h3 mb-0 text-white fw-semibold text-center"
                            [innerText]="servicesteps">
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-12">
                <div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 g-4">
                    <div class="col d-flex justify-content-center" *ngFor="let item of Steps">
                        <div class="card border-0 w-100">
                            <div
                                class="card-body text-center p-4 px-4 w-100 d-flex justify-content-center align-items-center">
                                <h3 class="fs-sm m-0">
                                    {{item.ServiceStepsDetails}}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>