<div class="row d-flex justify-content-center mb-5">
    <div class="col-12">
        <div class="position-relative">
            <input type="text" class="form-control form-control-lg ps-5" placeholder=" {{search}}  .."
                [(ngModel)]="searchText">
            <i class="bx bx-search fs-5 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3"></i>
        </div>
    </div>
</div>
<div class="row" *ngIf="cenetrservices.length < 1">
    <div class="col">
        <p class="h3 d-flex justify-content-center align-content-center">{{noServices}}</p>
    </div>
</div>
<!-- tabs -->
<div class="row">
    <div class="col w-100" *ngIf="cenetrservicesCategory.length > 0">
        <ul class="nav nav-tabs align-content-center justify-content-start pb-2 mb-3 mb-lg-4 d-flex flex-nowrap overflow-auto"
            role="tablist" *ngIf="cenetrservicesCategory[0].ServiceCategory != null">
            <li class="nav-item d-flex" role="presentation"
                *ngFor="let item of cateogry(cenetrservicesCategory);let i = index"
                style="flex: 0 0 150px;max-width: 150px;" (click)='changeUrl(item?.serviceTitle)'>
                <button
                    class="nav-link nav-product text-wrap justify-content-center py-4 w-100 border-2 fs-sm fw-semibold"
                    [ngClass]="ServiceCategoryTag === item.serviceTitle?.toString() || (ServiceCategoryTag === '' && i == 0)  ? 'active':''"
                    id="exam5-tab" data-bs-toggle="tab" [attr.data-bs-target]="'#id' + item.ServiceCategoryId"
                    type="button" role="tab" aria-selected="true"
                    style="border: 2px solid var(--primaryColor) !important;">
                    {{item.ServiceCategory}}
                </button>
            </li>
        </ul>
    </div>
</div>
<!-- product card -->

<div class="tab-content tab-exam active" id="pills-tabContent" *ngIf="cenetrservicesCategory.length > 0">
    <div class="tab-pane fade  "
        [ngClass]="ServiceCategoryTag === item2.serviceTitle?.toString()  ||(ServiceCategoryTag === '' && i == 0)  ? 'active show':''"
        id="id{{item2.ServiceCategoryId}}" role="tabpanel" aria-labelledby="exam1-tab"
        *ngFor="let item2 of cenetrservicesCategory;let i = index">

        <div class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-4">
            <div class="col d-flex"
                *ngFor="let item of findItems(cenetrservices,item2.ServiceCategoryId) | slice:0:showCount | filter:searchText">
                <article class="card pt-2 py-4 border-0 shadow-primary h-100 w-100">
                    <div class="card-header bg-white fs-lg fw-semibold text-primary text-center my-0 mb-3 pt-3 pb-1 px-3 w-100 border-0 rounded-5 d-flex justify-content-center align-items-center overflow-hidden"
                        ngbTooltip="{{item.serviceName}}" placement="bottom"
                        style="display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;cursor: context-menu;min-height: 4.5rem;">
                        {{item.serviceName}}
                    </div>
                    <div class="card-body mx-3 mt-0 mb-4 p-0 d-flex flex-column align-items-center justify-content-start"
                        *ngIf="!item.ShowDetailsButton">

                        <p class="fs-sm fw-normal text-primary overflow-hidden my-0"
                            [innerHTML]="item.ServiceDescription"></p>
                    </div>
                    <div class="card-body mx-3 mt-0 mb-4 p-0 d-flex flex-column align-items-center justify-content-center"
                        *ngIf="item.ShowDetailsButton">
                        <p class="fs-sm fw-normal text-primary overflow-hidden my-0"
                            style="display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;"
                            [innerHTML]="item.ServiceDescription"></p>
                    </div>
                    <div *ngIf="item.ShowDetailsButton"
                        class="card-footer p-0 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                        <a class="btn btn-outline-primary p-2 fs-sm border-1" style="width:7.5rem;"
                            [routerLink]="route(item)" [innerText]="more">
                        </a>
                    </div>
                </article>

            </div>

        </div>
        <!-- counter -->
        <div class="row">
            <div class="col d-flex justify-content-center mt-4 mb-2">
                <p class="m-0 text-primary fw-semibold fs-sm">{{showCount<
                        findItems(cenetrservices,item2.ServiceCategoryId).length?showCount:findItems(cenetrservices,item2.ServiceCategoryId).length
                        }} {{servicesof}} {{findItems(cenetrservices,item2.ServiceCategoryId).length}}</p>
            </div>
        </div>
        <!-- show more -->
        <div class="row">
            <div class="col d-flex justify-content-center mb-2">

                <input type="button" value=" {{more}}" (click)="showCount= showCount + 12"
                    *ngIf="showCount <  findItems(cenetrservices,item2.ServiceCategoryId).length"
                    class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                    style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
            </div>
        </div>
    </div>
</div>

<div class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-4"
    *ngIf="cenetrservicesCategory.length == 0">
    <div class="col d-flex" *ngFor="let item of cenetrservices | filter:searchText">
        <article class="card pt-2 py-4 border-0 shadow-primary h-100 w-100">
            <div class="card-header bg-white fs-lg fw-semibold text-primary text-center my-0 mb-3 pt-3 pb-1 px-3 w-100 border-0 rounded-5 d-flex justify-content-center align-items-center overflow-hidden"
                ngbTooltip="{{item.serviceName}}" placement="bottom"
                style="display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;cursor: context-menu;min-height: 4.5rem;">
                {{item.serviceName}}
            </div>
            <div class="card-body mx-3 mt-0 mb-4 p-0 d-flex flex-column align-items-center justify-content-start"
                *ngIf="!item.ShowDetailsButton">
                <!-- <h4 class="h5 mb-0 text-primary fw-semibold text-center overflow-hidden" placement="bottom"
                    ngbTooltip="{{item.serviceName}}"
                    style="text-align: justify;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;cursor: context-menu;">
                    {{item.serviceName}}
                </h4> -->
                <p class="fs-sm fw-normal text-primary overflow-hidden my-0" [innerHTML]="item.ServiceDescription"></p>
            </div>
            <div class="card-body mx-3 mt-0 mb-4 p-0 d-flex flex-column align-items-center justify-content-center"
                *ngIf="item.ShowDetailsButton">
                <!-- <h4 class="h5 mb-0 text-primary fw-semibold text-center overflow-hidden" placement="bottom"
                    ngbTooltip="{{item.serviceName}}"
                    style="text-align: justify;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;cursor: context-menu;">
                    {{item.serviceName}}
                </h4> -->
                <p class="fs-sm fw-normal text-primary overflow-hidden my-0"
                    style="display:-webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;"
                    [innerHTML]="item.ServiceDescription"></p>
            </div>
            <div *ngIf="item.ShowDetailsButton"
                class="card-footer p-0 m-0 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                <a class="btn btn-outline-primary p-2 fs-sm border-1" style="width:7.5rem;" [routerLink]="route(item)"
                    [innerText]="more">
                </a>
            </div>
        </article>
    </div>
</div>

<!--begin::share-->
<!-- <app-shareicons></app-shareicons> -->