import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.css']
})
export class ConnectComponent implements OnInit {

  constructor(private apiService: ApiService, private loader: LoadingService, private router:Router ) {
    // this.loader.show();
    // const idToken = localStorage.getItem('id_token');

    // if(!idToken){
    //   if(this.apiService.logIn()){
    //     this.router.navigateByUrl('/home');
    //   }
    // }
    // else{
    //   this.router.navigateByUrl('/home');
    // }
  }

  async ngOnInit(): Promise<void> {
    await this.apiService.logInAsync();
  }

}
