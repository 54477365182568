import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
private _loading = new BehaviorSubject<boolean>(false);
public readonly loading$  = this._loading.asObservable();

private _isArabic = new BehaviorSubject<boolean>(true);
public readonly isArabic$  = this._isArabic.asObservable();

  constructor() { }

  show(){
    this._loading.next(true);
  }

  hide(){
    this._loading.next(false);
  }

  setLang(lang: string){
    if(lang){
      localStorage.setItem('lang', lang);
    }
  }
}
