<!-- hero -->
<section class="container overflow-hidden  mt-4 pt-4 mb-4 pb-4">
    <div class="d-flex align-items-center rounded-5 position-relative overflow-hidden py-5 px-3 px-sm-4 px-xl-5 mx-md-n3 mx-lg-auto hero-mh bg-primary"
        style="background: radial-gradient(circle, rgba(105,128,188,1) 0%, rgba(61,46,122,1) 55%);">
        <div class="position-absolute bottom-0 start-50 translate-middle-x h-100">
            <img src="assets/media/img/hero-natforum.png" alt="hero img" style="min-width: 1200px;">
        </div>
    </div>
    <div class="position-relative mt-n4 rounded-5 w-100 d-flex justify-content-center p-3 py-4"
        style="background-color: #3994C8;">
        <img src="assets/media/logos/etec-ywlogo.svg" alt="etec logo" width="150" class="me-xl-5 me-4">
        <img src="assets/media/logos/moe-wlogo.svg" alt="moe logo" width="90">
    </div>
</section>

<div *ngIf="_lang.toString() == 'ar'">
    <!-- title -->
    <section class="container overflow-hidden mt-4 text-center">
        <p class="text-center fs-3 text-primary mb-4 fw-bold">
            الملتقى الوطني للتميز المدرسي (تميز 2024)
        </p>
        <p class="text-center fs-6 p-3 rounded-pill d-inline-flex text-center "
            style="color: #3994C8;background-color: #F4FAFE;border: 1px solid #D4EDFF;">
            11 ربيع الثاني 1446هـ الموافق 14 أكتوبر 2024
        </p>
    </section>
</div>
<div *ngIf="_lang.toString() == 'en'">
    <!-- title -->
    <section class="container overflow-hidden mt-4 text-center">
        <p class="text-center fs-3 text-primary mb-4 fw-bold">
            The National Forum for School Excellence (Tamayuz 2024)
        </p>
        <p class="text-center fs-6 mb-4 p-3 rounded-pill d-inline-flex text-center "
            style="color: #3994C8;background-color: #F4FAFE;border: 1px solid #D4EDFF;">
            11 Rabi' al-Thani, 1446,
            October 14, 2024
        </p>
    </section>
</div>


<div class="container" *ngIf="isView && isRegistered == 0">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-12">
                <div class="mb-2 d-flex w-100">
                    <article class="card position-relative p-0 h-100 w-100 border-0">
                        <div class="card-header w-100 border-0 p-0 m-0 mb-3">
                            <div class="d-flex flex-row align-items-center mb-2 bg-primary p-3 rounded-5">
                                <i class="bx bx-id-card text-warning fs-3 me-2"></i>
                                <h3 class="fs-lg text-white m-0">التسجيل </h3>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="row row-cols-lg-2 row-cols-1">
                                <div class="col">
                                    <div class="input-group mb-3 position-relative">
                                        <label class="form-label fs-sm fw-semibold w-100" for="first_name">الاسم
                                            الأول</label>
                                        <div class="position-relative w-100">
                                            <input formControlName="first_name" class="form-control ps-5"
                                                id="first_name" type="text" placeholder="الاسم الأول" name="first_name">
                                            <i
                                                class="bx bx-user fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                                        </div>
                                        <div *ngIf="f['first_name'].touched && f['first_name'].invalid"
                                            class="text-danger fs-xs">
                                            <div *ngIf="f['first_name'].errors && f['first_name'].errors['required']">
                                                حقل مطلوب
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="input-group mb-3 position-relative">
                                        <label class="form-label fs-sm fw-semibold w-100" for="last_name">الاسم
                                            الأخير</label>
                                        <div class="position-relative w-100">
                                            <input formControlName="last_name" class="form-control ps-5" id="last_name"
                                                type="text" placeholder="الاسم الأخير" name="last_name">
                                            <i
                                                class="bx bx-user fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                                        </div>
                                        <div *ngIf="f['last_name'].touched && f['last_name'].invalid"
                                            class="text-danger fs-xs">
                                            <div *ngIf="f['last_name'].errors && f['last_name'].errors['required']">
                                                حقل مطلوب
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="input-group mb-3 position-relative">
                                        <label class="form-label fs-sm fw-semibold w-100" for="email">
                                            البريد الإلكتروني
                                        </label>
                                        <div class="position-relative w-100">
                                            <input formControlName="email" class="form-control ps-5" id="email"
                                                name="email" type="email" placeholder="	البريد الإلكتروني"
                                                style="direction: rtl;">
                                            <i
                                                class="bx bx-envelope fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                                        </div>
                                        <div *ngIf="f['email'].touched && f['email'].invalid" class="text-danger fs-xs">
                                            <div *ngIf="f['email'].errors && f['email'].errors['required']">
                                                حقل مطلوب
                                            </div>
                                            <div *ngIf="f['email'].errors && f['email'].errors['pattern']">
                                                عنوان البريد الالكتروني غير صحيح
                                            </div>
                                            <div *ngIf="f['email'].errors && f['email'].errors['maxlength']">
                                                الرجاء إدخال 100 حرف كحد أقصى
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">

                                    <div class="input-group mb-3 position-relative">
                                        <label class="form-label fs-sm fw-semibold w-100" for="phone">رقم الجوال</label>
                                        <div class="position-relative w-100">
                                            <input class="form-control ps-5" id="phone" type="text"
                                                placeholder="رقم الجوال" name="phone" maxlength="14"
                                                formControlName="phone">
                                            <i
                                                class="bx bx-mobile-alt fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                                        </div>
                                        <div *ngIf="f['phone'].touched && f['phone'].invalid" class="text-danger fs-xs">
                                            <div *ngIf="f['phone'].errors && f['phone'].errors['required']">
                                                حقل مطلوب
                                            </div>
                                            <div
                                                *ngIf="f['phone'].errors && (f['phone'].errors['pattern'] || f['phone'].errors['minlength'])">
                                                رقم جوال غير صحيح
                                            </div>
                                            <div *ngIf="f['phone'].errors && f['phone'].errors['maxlength']">
                                                الرجاء إدخال 14 رقم كحد أقصى
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">

                                    <div class="input-group mb-3 position-relative">
                                        <label class="form-label fs-sm fw-semibold w-100" for="company">الجهة</label>
                                        <div class="position-relative w-100">
                                            <input formControlName="company" class="form-control ps-5" id="company"
                                                type="text" placeholder="الجهة" name="company">
                                            <i
                                                class="bx bxs-institution fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                                        </div>
                                        <div *ngIf="f['company'].touched && f['company'].invalid" class="text-danger fs-xs">
                                            <div *ngIf="f['company'].errors && f['company'].errors['required']">
                                                حقل مطلوب
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col">
                                    <div class="input-group mb-3 position-relative">
                                        <label class="form-label fs-sm fw-semibold w-100" for="position">المنصب</label>
                                        <div class="position-relative w-100">
                                            <input class="form-control ps-5" id="position" type="text"
                                                placeholder="المنصب" formControlName="position" name="position">
                                            <i
                                                class="bx bx-id-card fs-xl text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5 opacity-50"></i>
                                        </div>
                                        <div *ngIf="f['position'].touched && f['position'].invalid" class="text-danger fs-xs">
                                            <div *ngIf="f['position'].errors && f['position'].errors['required']">
                                                حقل مطلوب
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="input-group mb-1 position-relative">
                                        <label class="form-label fs-sm fw-semibold w-100" for="image">الصورة
                                            الشخصية</label>
                                        <input formControlName="image" class="form-control" type="file" id="image"
                                            placeholder="قم بتحميل الصورة الشخصية" (change)="upload($event)">
                                    </div>
                                    <div *ngIf="f['image'].touched && f['image'].invalid"
                                    class="text-danger fs-xs">
                                    <div *ngIf="f['image'].errors && f['image'].errors['required']">
                                        حقل مطلوب
                                    </div>
                                    <div *ngIf="f.image.errors?.['inValidExt']" class="error">
                                        الإمتداد غير صالح يجب أن يكون png,jpeg
                                    </div>
                                    <div *ngIf="myForm.get('image')?.hasError('fileSizeExceeded')">
                                        حجم الملف كبير
                                    </div>
                                </div>
                                <div class="text-danger fs-xs" *ngIf="!isCorrectSize">
                                  الملف المرفق غير صحيح 
                                </div>

                                    <div *ngIf="_lang.toString() == 'ar'">
                                        <p class="text-secondary fs-xs">
                                            *مواصفات الصورة: التخطيط مربع نسبة الصورة الأبعاد
                                            800x800 بكسل تنسيقات PNG & JPEG لا يتجاوز حجمها 3 ميجابايت
                                        </p>
                                    </div>
                                    <div *ngIf="_lang.toString() == 'en'">
                                        <h3 class="text-start fs-xs text-primary mb-4 fw-bold">
                                            *Image Specifications: Layout Square Image Ratio Dimensions 800x800 pixels
                                            PNG & JPEG formats 3MB maximum size
                                        </h3>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </article>
                </div>
                <div class="d-flex justify-content-center w-100 mb-4">
                    <button type="submit" [disabled]="myForm.invalid || ( !isCorrectSize && hasFile)"
                        class="btn btn-primary fs-sm border-2 w-lg-25 w-sm-50 w-100 ms-2">إرسال</button>
                </div>
                <div class="text-secondary fs-xs">
                    {{message}}
                </div>
            </div>
        </div>
    </form>
</div>

<div class="container" *ngIf="isView && isRegistered == 1 ">
    <div class="card card-light card-hover border-0 rounded-4 h-100 shadow">
        <div class="card-body d-flex flex-column justify-content-center align-items-center p-5">
            <div class="mb-4 text-warning">
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor"
                    class="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                    <path
                        d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2">
                    </path>
                </svg>
            </div>
            <div *ngIf="_lang.toString() == 'ar'">
                <h3 class="text-center fs-3 text-primary mb-4 fw-bold">
                    تم قبول الدعوة من قبل
                </h3>
            </div>
            <div *ngIf="_lang.toString() == 'en'">
                <h3 class="text-center fs-3 text-primary mb-4 fw-bold">
                    The invitation has been accepted.
                </h3>
            </div>

        </div>
    </div>
</div>

<div class="container" *ngIf="!isView && !isSuccess">
    <div class="card card-light card-hover border-0 rounded-4 h-100 shadow">
        <div class="card-body d-flex flex-column justify-content-center align-items-center p-5">
            <div class="mb-4 text-warning">
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor"
                    class="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                    <path
                        d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2">
                    </path>
                </svg>
            </div>
            <div *ngIf="_lang.toString() == 'ar'">
                <h3 class="text-center fs-3 text-primary mb-4 fw-bold">
                    الصفحة مخصصة للدعوات فقط
                </h3>
            </div>
            <div *ngIf="_lang.toString() == 'en'">
                <h3 class="text-center fs-3 text-primary mb-4 fw-bold">
                    This page is for invitations only.
                </h3>
            </div>
        </div>
    </div>

</div>

<div class="container" *ngIf="!isView && isSuccess">
    <div class="card card-light card-hover border-0 rounded-4 h-100 shadow">
        <div class="card-body d-flex flex-column justify-content-center align-items-center p-5">
            <div class="mb-4 text-success">
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
            </div>
            <div *ngIf="_lang.toString() == 'ar'">
                <h3 class="text-center fs-4 text-primary mb-4 fw-bold">
                    {{message}}
                </h3>
            </div>
            <div *ngIf="_lang.toString() == 'en'">
                <h3 class="text-center fs-4 text-primary mb-4 fw-bold">
                   {{messageEnglish}}
                </h3>
            </div>
        </div>
    </div>

</div>