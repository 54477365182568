

<main class="page-wrapper" #contentArea>
    <app-header></app-header>
    <router-outlet (activate)="onActivate()"></router-outlet>
    <!-- <router-outlet ></router-outlet> -->

    <!-- <ngx-loading-x [show]="true" [bgLogoUrl]="logo"></ngx-loading-x> -->
    <ng-http-loader  [entryComponent]="spinnerComponent"></ng-http-loader>   
    <!-- <mat-progress-spinner [mode]="'indeterminate'" *ngIf="loading$ | async"></mat-progress-spinner> -->
       
   <!-- <div id="preloader" *ngIf="loading$ | async">
        <img src="assets/media/logos/etec-OI.svg" width="65" />
        <div id="loader"></div>
    </div>
  -->
</main>
<app-footer></app-footer>
<!-- <div class="scroll-up" (click)="goTop()">
    <button>Go up</button>
</div> -->

<!-- <div>
    <app-header></app-header>
    <router-outlet></router-outlet>
    <mat-progress-spinner [mode]="'indeterminate'" *ngIf="loading$ | async"></mat-progress-spinner>
    <app-footer></app-footer>
</div> -->