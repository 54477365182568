import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, Subject, takeUntil, throwIfEmpty } from 'rxjs';
import { CenterLeaderList } from 'src/app/models/CenterLeaderList';
 
import { Center, CenterLeader, MenuResponse } from 'src/app/models/ResponseModel';
import { ApiService } from 'src/app/services/api.service';
import { ResourcesService } from 'src/app/services/resources.service';
 

@Component({
  selector: 'app-centers',
  templateUrl: './centers.component.html',
  styleUrls: ['./centers.component.css']
})
export class CentersComponent implements OnInit {
  centerId!: number;
  center!: Center;
  centerLeader!: CenterLeader;
  aboutcenter ='';
  ceo = '';

  destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(private centerService: ApiService, private actRoute: ActivatedRoute, private apiResources: ResourcesService) {
    this.centerId = this.actRoute.snapshot.params['id'];
    actRoute.params.subscribe(params =>
      this.centerId = params["id"]);

    // this.centerService.getList('centers').pipe(takeUntil(this.destroy$)).subscribe(
    //   (data: CenterList[]) => {
    //     data.forEach(element => {
    //       // console.log(element.ItemId + ' ' + this.centerId );
    //       if (element.ItemId == this.centerId) {
    //         var obj: Center = {
    //           centerId: element.ItemId,
    //           centerName: element.ItemFields.CenterName,
    //           centerDescription: element.ItemFields.CenterDescription?.toString(),
    //           centerVision: element.ItemFields.CenterVision?.toString(),
    //           centerVisionIcon: '',
    //           centerMission: element.ItemFields.CenterMission?.toString(),
    //           centerMissionIcon: '',
    //           centerOrgnizationalStructureUrl: '',
    //           centerMainImageCoverUrl: '',
    //           centerParenetId: -1,
    //           isActive: element.ItemFields.IsActive1
    //         };
    //         this.center = obj;
    //         //console.log(data);
    //       }
    //     });


    //   }
    // );

    // this.centerService.getList('centersleader').pipe(takeUntil(this.destroy$)).subscribe(
    //   (data: CenterLeaderList[]) => {
    //     data.forEach(element => {
    //       if (element.ItemFields.CenterId.LookupId == this.centerId) {
    //         var obj: CenterLeader = {
    //           centerLeaderId: element.ItemId,
    //           centerLeaderPosition: element.ItemFields.CentersLeaderPositionId.LookupValue,
    //           centerLeaderPrefixId: element.ItemFields.CentersLeaderPrefixId.LookupValue,
    //           centerLeaderName: element.ItemFields.CentersLeaderName,
    //           centerLeaderPortrait: element.ItemFields?.CentersLeaderPortrait,
    //           centerLeaderCurriculumVite: element.ItemFields.CentersLeaderCurriculumVitae,
    //           Sequence: element.ItemFields.Sequence,
    //           isActive: element.ItemFields.IsActive1,
    //         };
    //         this.centerLeader = obj;
    //       }
    //     });
    //   }
    // );

    this.centerService.getListById('centers' ,this.centerId).pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.center = data;
    });


    this.centerService.getListTopN('centersleader', 1, true, 'CenterId', this.centerId).pipe(takeUntil(this.destroy$), map((res: CenterLeaderList[]) => res.map(re => {
      return this.cloneCenterLeaderObj(re)
    }))).subscribe(data => {
        this.centerLeader = data[0];
    });
}


cloneCenterLeaderObj(element :CenterLeaderList ): CenterLeader {
  return ({
    centerLeaderId: element.ItemId,
    centerLeaderPosition: element.ItemFields.CentersLeaderPositionId.LookupValue,
    centerLeaderPrefixId: element.ItemFields.CentersLeaderPrefixId.LookupValue,
    centerLeaderName: element.ItemFields.CentersLeaderName,
    centerLeaderPortrait: element.ItemFields?.CentersLeaderPortrait,
    centerLeaderCurriculumVite: element.ItemFields.CentersLeaderCurriculumVitae,
    Sequence: element.ItemFields.Sequence,
    isActive: element.ItemFields.IsActive1,
  } as CenterLeader)
}

  async ngOnInit(): Promise<void> {
  this.aboutcenter =  await this.apiResources.getByKey("aboutcenter"); 
  this.ceo =  await this.apiResources.getByKey("ceo"); 
}

ngOnDestroy() {
  this.destroy$.next(true);
  // Unsubscribe from the subject
  this.destroy$.unsubscribe();
}
}
