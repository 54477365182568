import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NewsList } from 'src/app/models/NewsList';
import { ApiService } from 'src/app/services/api.service';
import { News } from 'src/app/models/ResponseModel';
import { environment } from 'src/environments/environment';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-relatednews',
  templateUrl: './relatednews.component.html',
  styleUrls: ['./relatednews.component.css']
})
export class RelatednewsComponent implements OnInit {
  @Input() keywords: string;

  AllNews: News[] = [];
  relatedNews: News[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText: any = '';
  selectedValue: string;
  more: string = '';

  constructor(private apiServices: ApiService, private apiResources: ResourcesService) { }

  /** Custom methods */
  getRelatedNews() {
    this.apiServices.getList('news').pipe(takeUntil(this.destroy$)).subscribe(

      (data: NewsList[]) => {
        data.forEach(element => {
          var obj: News = {
            newsId: element.ItemId,
            newsDate: element.ItemFields.NewsDate,
            newsTitle: element.ItemFields.NewsTitle,
            newsDetails: this.cutString(element.ItemFields.NewsDetails?.replace(/<[^>]*>/g, '')),
            newsUrl: element.ItemFields?.NewsFile?.Url?.replace(environment.imptempPublicUrl, environment.imgPublicUrl),
            NewsKeyword: element.ItemFields?.NewsKeyword,
            isActive: element.ItemFields.IsActive1,
            NewsCode:  element.ItemFields.NewsCode
          };
          this.AllNews.push(obj);
        });

        this.AllNews.sort((a: any, b: any) => {
          return <any>new Date(b.newsDate) - <any>new Date(a.newsDate);
        });
        this.relatedNews = this.AllNews.filter(x => x.NewsKeyword?.toUpperCase().includes(this.keywords));
        this.relatedNews.slice(0, 3);
      }
    );
  }

  private cutString(str: string) {
    var output = ' ';
    var data = str.split(' ');
    var i = 0;
    for (var i = 0; i < 25; i++) {

      output = output.concat(data[i], ' ');
    };
    return output;
  }

  /** Component events  */
  async ngOnInit() {
    this.getRelatedNews();

    this.more = await this.apiResources.getByKey("more");
  }

  ngOnChanges() {
    // this.apiServices.getList('news').pipe(takeUntil(this.destroy$)).subscribe(

    //   (data: NewsList[]) => {
    //    data.forEach(element => {
    //       var obj: News = { 
    //         newsId: element.ItemId,
    //         newsDate: element.ItemFields.NewsDate,
    //         newsTitle:  element.ItemFields.NewsTitle,
    //         newsDetails: this.cutString(element.ItemFields.NewsDetails),
    //         newsUrl: element.ItemFields?.NewsFile?.Url?.replace(environment.imptempPublicUrl,environment.imgPublicUrl),
    //         NewsKeyword : element.ItemFields?.NewsKeyword,
    //         isActive: element.ItemFields.IsActive1,
    //       };
    //       this.AllNews.push(obj);
    //    });

    //     this.AllNews.sort((a: any, b: any) => {
    //       return <any>new Date(b.newsDate) - <any>new Date(a.newsDate);
    //     });
    //    this.relatedNews =  this.AllNews.filter(x => x.NewsKeyword?.toUpperCase().includes(this.keywords));
    //    this.relatedNews.slice(0,3);
    //   }
    // );
    this.getRelatedNews();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }
}
