<div class="container">
    <div class="row">
        <div class="col-lg-12 p-5">

            <lightgallery [settings]="settings" *ngIf="centerOrgUrl">
                <a href="{{centerOrgUrl}}"
                    id="lightgallery" class="lightgallery">
                    <img class="img-fluid" 
                        [src]="centerOrgUrl">
                </a>
            </lightgallery>
        </div>
    </div>

    <!--begin::shareicons-->
    <app-shareicons></app-shareicons>
</div>