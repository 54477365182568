import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ResourcesService } from 'src/app/services/resources.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-sefbadge',
  templateUrl: './sefbadge.component.html',
  styleUrls: ['./sefbadge.component.css']
})
export class SEFBadgeComponent implements OnInit {
  _lang = '';
  token = ''
  url :SafeUrl | undefined;
  constructor(private http: HttpClient, private actRoute: ActivatedRoute
    , private apiResources: ResourcesService, private sanitizer: DomSanitizer) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }

    this.actRoute.queryParams.subscribe(async params => {
      this.token = params["t"];
      this.url =  this.sanitizer.bypassSecurityTrustResourceUrl(`https://etec-event.com/badge/?t=${this.token}`);
    });
  }

  async changeLang(lang: string) {
    this.apiResources.clearResource();
  }

  async ngOnInit() {

    // if(this.token != ''){
    //   this.http
    //   .post(`https://etec-event.com/badge`, {'token_key':this.token})
    //   .subscribe((data) => {
    //     console.log(Object.values(data)[2]);
       
    //     this.url = this.sanitizer.bypassSecurityTrustResourceUrl(Object.values(data)[2]);
    //   });
    // }

  }

}
