import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, throwError } from 'rxjs';
import { fileExtensionValidator, fileSizeValidator } from 'src/app/file-extension-validator.directive';
import { environment } from 'src/environments/environment';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-sefregistration',
  templateUrl: './sefregistration.component.html',
  styleUrls: ['./sefregistration.component.css']
})
export class SEFRegistrationComponent implements OnInit {

  myForm = new FormGroup({
    token_key: new FormControl(''),
    first_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    last_name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    email: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]),
    phone: new FormControl('', [Validators.required, Validators.maxLength(14), Validators.pattern(/^((?:[+?0?0?966]+)(?:\s?5)(?:\s?\d{8}))$/), Validators.minLength(10)]),
    company: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    position: new FormControl('', [ Validators.maxLength(100)]),
    //image: new FormControl('', [fileExtensionValidator('PNG,JPEG,png,jpeg'), fileSizeValidator(3000)]),
    image: new FormControl(''),

  });

  file: any;
  isCorrectSize: boolean = true
  isCorrectExt: boolean = false
  token = ''
  message = ''
  messageEnglish = ''
  _lang = '';
  requiredfield = '';
  email = '';
  invalidemail = '';
  mobilenumber = '';
  invalidmobilenumber = '';
  isView: boolean = false
  isRegistered: number = 0
  isSuccess: boolean = false
  hasFile : boolean = false
  constructor(private http: HttpClient, private actRoute: ActivatedRoute, private apiResources: ResourcesService) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }

    this.actRoute.queryParams.subscribe(async params => {
      this.token = params["t"];
    });
  }

  async changeLang(lang: string) {
    this.apiResources.clearResource();
  }

  get f() {
    return this.myForm.controls;
  }

  async ngOnInit() {
    this.requiredfield = await this.apiResources.getByKey("requiredfield");
    this.email = await this.apiResources.getByKey("email");
    this.invalidemail = await this.apiResources.getByKey("invalidemail");
    this.mobilenumber = await this.apiResources.getByKey("mobilenumber");
    this.invalidmobilenumber = await this.apiResources.getByKey("invalidmobilenumber");

    if(this.token != ''){
      this.http
      .post(`https://etec-event.com/api/v1/get-info`, {'token_key':this.token})
      .subscribe((data:any) => {
        this.isView = data.success;
        this.isRegistered = data?.info.registered;
        this.myForm.get('first_name')?.setValue(data.info.first_name);
        this.myForm.get('last_name')?.setValue(data.info.last_name);
        this.myForm.get('email')?.setValue(data.info.email);
        this.myForm.get('phone')?.setValue(data.info.phone);
        this.myForm.get('company')?.setValue(data.info.company);
        this.myForm.get('position')?.setValue(data.info.position);
      });
    }
  }

  onSubmit() {

    const formData = new FormData();
    this.myForm.get('token_key')?.patchValue(this.token);
    console.log(this.myForm.get('first_name')?.value!)
    if(this.myForm.get('image')?.value! != ''){
      formData.append("image", this.file);
    }
    
    formData.append("first_name", this.myForm.get('first_name')?.value!);
    formData.append("last_name", this.myForm.get('last_name')?.value!);
    formData.append("email", this.myForm.get('email')?.value!);
    formData.append("phone", this.myForm.get('phone')?.value!);
    formData.append("company", this.myForm.get('company')?.value!);
    formData.append("position", this.myForm.get('position')?.value!);
    formData.append("token_key", this.myForm.get('token_key')?.value!);

    this.http
      .post(`https://etec-event.com/api/v1/update-honored-info`, formData)
      .subscribe((data: any) => {
        console.log(data.status);
        this.isSuccess= true;
        this.isView = false;
        this.message = !data.success? 'حصل خطأ.. الرجاء المحاولة مرة أخرى':'شكرًا لكم على التسجيل في "الملتقى الوطني للتميز المدرسي 2024". سوف تتلقون رسالة برمز الدخول قريبًا.';  //  data?.message;
        this.messageEnglish = !!data.success? 'Error.. try again':'Thank you for registering for the National Forum for School Excellence 2024. You will receive an email with your access code shortly.';  //  data?.message;
      });
  }

  upload(event: any) {
    this.hasFile = true;
    this.isCorrectSize = true;
    const file = event.target.files[0];
    this.file = file;
    
    if (file.size > environment.maxFileSize) {
      this.isCorrectSize = false;

    } 

    //from here 
    const fileExt = this.myForm?.get('image')?.value!.split('.').pop();
      'PNG,JPEG,png,jpeg,JPG,jpg'.split(',').forEach(ext => {
          if (ext.trim() == fileExt) {
              this.isCorrectExt = true;
          }
      });

      this.isCorrectSize =  this.isCorrectSize && this.isCorrectExt;
  }
}
// const invalid = [];
// const controls = this.myForm.controls;
// for(const name in controls){
//   console.log(name);
//   if(this.myForm?.get(name)?.invalid){
//     console.log(name);
//   }
// }
// console.log(document.getElementsByClassName('ng-invalid'));
// console.log(this.myForm.invalid);
