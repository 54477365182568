import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CenterBusinessDetailsList } from 'src/app/models/CenterBusinessDetailsList';
 
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-center-details',
  templateUrl: './center-details.component.html',
  styleUrls: ['./center-details.component.css']
})
export class CenterDetailsComponent implements OnInit {

  @Input() businssType : number; 
  @Input() centerId : number; 
  @Input() typeName : string; 

  cId : number;
  ResponsbilityETEC: CenterBusinessDetailsList[] =[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  
  constructor(private centerService: ApiService) { }

  /** Custom methods */
  getCenterBusiness(){
    this.centerService.getListWithTwoConditionsTopN('CenterBusinessDetails',100,false,'CenterId',this.cId,'CBDetailTypeId', this.businssType, 'Eq','And','Eq' ).pipe(takeUntil(this.destroy$)).subscribe(
      (data: CenterBusinessDetailsList[]) => {
        this.ResponsbilityETEC = data.reverse();
      }
    );
  }

  /** Component events  */
  ngOnInit(): void {
    
  }

  ngOnChanges() {
    this.cId = this.centerId;
    this.getCenterBusiness();
  }
  
  
  ngOnDestroy() {
    this.destroy$.next(true);
     // Unsubscribe from the subject
    this.destroy$.unsubscribe();
   }
  
}
