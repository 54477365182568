<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="/{{_lang}}/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item"><a [routerLink]="serviceSectorURL"
                                [innerText]="Product?.serviceSector" class="fs-sm fw-normal"> </a></li>
                        <li class="breadcrumb-item"><a class="fs-sm fw-normal"
                                routerLink="/{{_lang}}/{{ centerCode?.toLocaleLowerCase() }}">{{centerName}}
                            </a></li>
                        <li class="breadcrumb-item" aria-current="page" *ngIf="Product?.ServiceCategory">
                            <a class="fs-sm fw-normal"
                                routerLink="/{{_lang}}/{{ centerCode?.toLocaleLowerCase() }}/services/{{Product?.serviceDetailsFileUrl}}">
                                {{ Product?.ServiceCategory}}</a>
                        </li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page">{{ Product?.serviceTitle
                            }}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<div id="dvToPrint" *ngIf="Product">
    <!--begin::hero-->
    <section id="hero" class="position-relative d-flex align-items-center mb-0">

        <div class="container position-relative zindex-5 py-5">
            <div class="row d-flex justify-content-md-start justify-content-center">
                <div
                    class="col-lg-6 col-12  d-flex flex-column text-lg-start justify-content-center text-center order-lg-1 order-2">
                    <!-- Text -->
                    <h3 class="text-primary h3 fw-semibold mb-3" [innerHTML]="Product?.serviceName">


                    </h3>
                    <!-- <a href="service/UniversityStudents#Go">GO to </a>
                    <a href="service/UniversityStudents#Go2">GO to </a> -->
                    <p class="text-primary fs-sm mb-4 fw-normal" style="text-align: justify;"
                        [innerHTML]="Product?.ServiceDescription">
                    </p>
                    <div class="d-flex flex-column flex-md-row" *ngIf="Product?.serviceRegistrationLink">
                        <!-- <button class="btn btn-primary w-lg-25 w-100 mt-2 me-md-2 me-0" style="height:45px;"
                            (click)="scrollToElement()">الجدول
                            الزمني</button> -->
                        <a *ngIf="Product?.serviceRegistrationLink && Product?.ShowDetailsButton"
                            href="{{ Product?.serviceRegistrationLink }}"
                            class="btn btn-info p-2 fs-sm border-1 fw-normal" style="min-width:8rem;" target="_blank">
                            {{ Product?.serviceRegistrationTitle }}</a>
                    </div>
                </div>
                <div
                    class="col-lg-6 col-12 d-flex justify-content-lg-end justify-content-center order-lg-2 order-1 mb-lg-0 mb-4">
                    <!-- <img src="assets/media/icons/ServiceDetails-icon.svg" alt="img"
                        class="img-fluid img-respon me-lg-5" /> -->
                    <img src="{{tagImage}}" alt="img" class="img-fluid img-respon me-lg-5" />
                </div>
            </div>
        </div>
    </section>
    <!-- Service-Details Container -->
    <div class="container">
        <!-- Nav pills -->
        <div class="row">
            <div class="col w-100">
                <ul class="nav nav-tabs align-content-center justify-content-xl-start justify-content-start pb-2 mb-3 mb-lg-4 d-flex flex-nowrap overflow-auto"
                    id="pills-tab" role="tablist">
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="goalsShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{goalsActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-servicegoal-tab" data-bs-toggle="pill" data-bs-target="#pills-servicegoal"
                            type="button" role="tab" aria-controls="pills-servicegoal" aria-selected="true"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="getServiceGoals();">
                            <i class="bx bxs-bullseye fs-2 mb-2"></i>
                            {{servicegoal}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="audnicesShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2  {{audnicesActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-serviceaudience-tab" data-bs-toggle="pill" data-bs-target="#pills-serviceaudience"
                            type="button" role="tab" aria-controls="pills-serviceaudience" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;"
                            (click)="this.getServiceAudnices()">
                            <i class="bx bx-group fs-2 mb-2"></i>
                            {{serviceaudience}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="serviceTargetedSectorShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{serviceTargetedSectorActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-ServiceTargetedSector-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-ServiceTargetedSector" type="button" role="tab"
                            aria-controls="pills-ServiceTargetedSector" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;"
                            (click)="getServiceTargetedSector()">
                            <i class="bx bx-buildings fs-2 mb-2"></i>
                            {{ServiceTargetedSectorsTitle}}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" *ngIf="stepsShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{stepsActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-servicesteps-tab" data-bs-toggle="pill" data-bs-target="#pills-servicesteps"
                            type="button" role="tab" aria-controls="pills-servicesteps" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="getServiceSteps()">
                            <i class="bx bx-file-blank fs-2 mb-2"></i>
                            {{servicesteps}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="contentShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{contentActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-infoAboutService-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-infoAboutService" type="button" role="tab"
                            aria-controls="pills-infoAboutService" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="getServiceContent()">
                            <i class="bx bx-info-circle fs-2 mb-2"></i>
                            {{infoAboutService}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="notesShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{notesActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-Notes-tab" data-bs-toggle="pill" data-bs-target="#pills-Notes" type="button"
                            role="tab" aria-controls="pills-Notes" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="getServiceNotes()">
                            <i class="bx bx-comment-detail fs-2 mb-2"></i>
                            {{notes}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="prerequirementsShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{prerequirementsActive}}  d-flex flex-column fs-sm fw-semibold"
                            id="pills-serviceprerequirments-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-serviceprerequirments" type="button" role="tab"
                            aria-controls="pills-serviceprerequirments" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;"
                            (click)="getServicePrerequirements()">
                            <i class="bx bx-file-blank fs-2 mb-2"></i>
                            {{serviceprerequirments}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="serviceDoumentShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{serviceDoumentActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-servicedocuments-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-servicedocuments" type="button" role="tab"
                            aria-controls="pills-servicedocuments" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="getServiceDoument()">
                            <i class="bx bx-file-blank fs-2 mb-2"></i>
                            {{servicedocuments}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="serviceVideoShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{serviceVideoActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-video-tab" data-bs-toggle="pill" data-bs-target="#pills-video" type="button"
                            role="tab" aria-controls="pills-video" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="getVideos()">
                            <i class="bx bx-video fs-2 mb-2"></i>
                            {{videos}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="testTimeTableShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{testTimeTableActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-timetable-tab" data-bs-toggle="pill" data-bs-target="#pills-timetable"
                            type="button" role="tab" aria-controls="pills-timetable" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="getTestTimetable()">
                            <i class="bx bx-time fs-2 mb-2"></i>
                            {{timetable}}
                        </button>
                    </li>
                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="faqListShow && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{faqListActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-faq-tab" data-bs-toggle="pill" data-bs-target="#pills-faq" type="button"
                            role="tab" aria-controls="pills-faq" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="getFaq()">
                            <i class="bx bx-conversation fs-2 mb-2"></i>
                            {{faq}}
                        </button>
                    </li>

                    <li class="nav-item d-flex" role="presentation" style="flex: 0 0 110px;max-width: 110px;"
                        *ngIf="false && showTabs">
                        <button
                            class="nav-link nav-product text-wrap justify-content-center py-3 w-100 border-2 {{relatedServiceGuidActive}} d-flex flex-column fs-sm fw-semibold"
                            id="pills-sguid-tab" data-bs-toggle="pill" data-bs-target="#pills-sguid" type="button"
                            role="tab" aria-controls="pills-sguid" aria-selected="false"
                            style="border: 2px solid var(--primaryColor) !important;" (click)="route('faq')">
                            <i class="bx bx-desktop fs-2 mb-2"></i>
                            {{relatedServiceGuide}}
                        </button>
                    </li>

                </ul>
            </div>
        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade {{goalsActive=='active'?'show':''}} {{goalsActive}}" id="pills-servicegoal"
                role="tabpanel" aria-labelledby="pills-servicegoal-tab" tabindex="0">
                <!--begin::goals-->
                <app-goals [Goals]="Goals" [servicegoal]="servicegoal"></app-goals>
            </div>
            <div class="tab-pane fade {{audnicesActive=='active'?'show':''}} {{audnicesActive}}"
                id="pills-serviceaudience" role="tabpanel" aria-labelledby="pills-serviceaudience-tab" tabindex="0">
                <!--begin::Audnices-->
                <app-audience [Audnices]="Audnices" [serviceaudience]="serviceaudience"></app-audience>
            </div>
            <div class="tab-pane fade {{serviceTargetedSectorActive=='active'?'show':''}} {{serviceTargetedSectorActive}}"
                id="pills-ServiceTargetedSector" role="tabpanel" aria-labelledby="pills-ServiceTargetedSector-tab"
                tabindex="0">
                <!--begin::ServiceTargetedSector-->
                <app-targeted-sector [ServiceTargetedSector]="ServiceTargetedSector"></app-targeted-sector>
            </div>
            <div class="tab-pane fade {{stepsActive=='active'?'show':''}} {{stepsActive}}" id="pills-servicesteps"
                role="tabpanel" aria-labelledby="pills-servicesteps-tab" tabindex="0">
                <!--begin::steps-->
                <app-steps [Steps]="Steps" [servicesteps]="servicesteps"></app-steps>
            </div>
            <div class="tab-pane fade {{contentActive=='active'?'show':''}} {{contentActive}}"
                id="pills-infoAboutService" role="tabpanel" aria-labelledby="pills-infoAboutService-tab" tabindex="0">
                <!--begin::content-->
                <app-content [Content]="Content" [infoAboutService]="infoAboutService"></app-content>
            </div>
            <div class="tab-pane fade {{notesActive=='active'?'show':''}} {{notesActive}}" id="pills-Notes"
                role="tabpanel" aria-labelledby="pills-Notes-tab" tabindex="0">
                <!--begin::notes-->
                <app-notes [Notes]="Notes"></app-notes>
            </div>
            <div class="tab-pane fade {{prerequirementsActive=='active'?'show':''}} {{prerequirementsActive}}"
                id="pills-serviceprerequirments" role="tabpanel" aria-labelledby="pills-serviceprerequirments-tab"
                tabindex="0">
                <!-- <article class="col-12 my-lg-4 my-3 mt-0" *ngIf="Prerequirements.length >0">
                    <div class="card border-1 bg-white shadow-primary overflow-hidden rounded-5 mb-4 p-4 pb-5 px-5">
                        <div class="row">
                            <div class="col">
                                <h3 class="h3 text-center mt-4 fw-semibold" [innerText]="serviceprerequirments"> </h3>
                                <p class="text-primary fs-sm fw-normal" style="text-align: justify;">
                                   
                                </p>
                                <div class="row justify-content-start mt-4" *ngIf="Prerequirements?.length === 1">
                                    <div class="col">
                                        <p class="text-primary align-middle mb-0 fs-sm lh-base"
                                            style="text-align: justify;"
                                            [innerText]="Prerequirements[0].ServicePrerequirementsDetails"></p>
                                    </div>
                                </div>
                                <div class="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-5 justify-content-start my-4"
                                    *ngIf="Prerequirements.length > 1">
                                    <div class="col" *ngFor="let item of Prerequirements ; index as i">

                                        <div class="d-flex flex-row align-items-center">
                                            <div class="me-2">
                                                <div class="d-flex align-items-center justify-content-center bg-primary text-white m-0 fw-semibold fs-sm text-center"
                                                    style="height: 2rem; width: 2rem;border-radius: 50%;">{{i+1}}
                                                </div>
                                            </div>
                                            <p class="d-inline-block align-middle text-primary m-0 lh-base"
                                                [innerText]="item.ServicePrerequirementsDetails">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article> -->
                <app-prerequirements [Prerequirements]="Prerequirements"
                    [serviceprerequirments]="serviceprerequirments"></app-prerequirements>
            </div>
            <div class="tab-pane fade {{serviceDoumentActive=='active'?'show':''}} {{serviceDoumentActive}}"
                id="pills-servicedocuments" role="tabpanel" aria-labelledby="pills-servicedocuments-tab" tabindex="0">
                <!--begin::Documents-->
                <div class="row mb-4" *ngIf="ServiceDoument.length > 0">
                    <div class="col">
                        <div class="d-flex flex-column flex-lg-row justify-content-between text-primary mb-3">

                            <div class="d-flex align-items-center w-100 h-100">
                                <div class="input-group position-relative  w-100">
                                    <input type="text" class="form-control form-control-lg ps-5"
                                        placeholder='{{searchtxt}}' [(ngModel)]="searchText"
                                        (keypress)="getTagsResult()" (keyup)="getTagsResult()"
                                        (keydown)="getTagsResult()">
                                    <i
                                        class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- filter -->
                    <div class="col-12 my-1">
                        <h5 class="fs-lg text-primary fw-semibold mb-2 d-none">{{Category}}</h5>
                        <div class="d-flex flex-row flex-wrap justify-content-start w-100" *ngIf="tags.length > 0">
                            <div class="me-2 mb-2 nav nav-tabs-alt products-checkbox">
                                <input type="checkbox" class="btn-check products-category" name="options2"
                                    id="option-all-tags" autocomplete="off" [(ngModel)]="tagsAll"
                                    (ngModelChange)="changeTage()">
                                <label class="nav-link fs-sm" for="option-all-tags">{{all}}</label>
                            </div>
                            <div class="me-2 mb-2 nav nav-tabs-alt products-checkbox"
                                *ngFor="let element of tags; let i = index">
                                <input type="checkbox" class="btn-check products-category" name="options2"
                                    id="chbox{{i}}" autocomplete="off" [(ngModel)]="tagsStatus[i]"
                                    (ngModelChange)="filterChange()">
                                <label class="nav-link fs-sm" for="chbox{{i}}">{{element.trim()}}</label>
                            </div>
                        </div>
                    </div>
                    <!-- // -->
                </div>
                <!-- الوثائق -->
                <div class="row row-cols-xl-4 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
                    <div class="col d-flex" *ngFor="let item of ServiceDoumentResponse| slice:0:showCount ">
                        <div class="card py-4 border-0 shadow-primary w-100 h-100"
                            *ngIf="item.ServiceDocument || item.ServiceDocumentWord">
                            <div class="card-body d-flex flex-column justify-content-center align-items-center pb-0">
                                <h3 class="fs-lg fw-normal mb-2 overflow-hidden text-center">
                                    {{item.DocumentName}}
                                </h3>
                                <span *ngIf="item.ServiceDocumentCategory"
                                    class="badge bg-light text-primary p-2 rounded-pill text-wrap"
                                    [innerText]="item.ServiceDocumentCategory"></span>

                            </div>
                            <div
                                class="card-footer py-3 px-xl-0 px-4 py-3 mt-3 border-0 rounded-5 d-flex align-items-center justify-content-center">
                                <a *ngIf="item.ServiceDocument" href="{{item.ServiceDocument}}" target="_blank"
                                    class="btn btn-warning fw-semibold rounded-3 p-2 fs-sm border-0 w-md-50 w-sm-75 w-75">
                                    <i class="bx bx-down-arrow-alt fw-normal fs-3 me-1 mt-n1"></i>
                                    PDF</a>
                                <a *ngIf="item.ServiceDocumentWord" href="{{item.ServiceDocumentWord}}" target="_blank"
                                    class="btn btn-warning fw-semibold rounded-3 p-2 fs-sm border-0 w-md-50 w-sm-75 w-75 ms-2">
                                    <i class="bx bx-down-arrow-alt fw-normal fs-3 me-1 mt-n1"></i>
                                    DOC</a>
                                <!-- <a *ngIf="item.ServiceDocument" href="{{item.ServiceDocument}}" target="_blank"
                                    class="btn btn-warning rounded-3 p-2 fs-sm border-0 d-flex flex-column">
                                    <i class="bx bx-down-arrow-alt fs-3"></i>
                                    PDF
                                </a>
                                <a *ngIf="item.ServiceDocumentWord" href="{{item.ServiceDocumentWord}}" target="_blank"
                                    class="btn btn-warning rounded-3 p-2 fs-sm border-0 ms-3 d-flex flex-column">
                                    <i class="bx bx-down-arrow-alt fs-3"></i>
                                    DOC
                                </a> -->
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center mt-4 mb-2">
                        <p class="m-0 text-primary fw-semibold fs-sm">{{showCount<
                                ServiceDoumentResponse.length?showCount:ServiceDoumentResponse.length }} {{servicesof}}
                                {{ServiceDoumentResponse.length}}</p>
                    </div>
                </div>
                <!-- show more -->
                <div class="row">
                    <div class="col d-flex justify-content-center mb-2">

                        <input type="button" value=" {{more}}" (click)="showCount= showCount + 12"
                            *ngIf="showCount < ServiceDoumentResponse.length"
                            class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                            style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
                    </div>
                </div>
            </div>
            <div class="tab-pane fade {{testTimeTableActive=='active'?'show':''}} {{testTimeTableActive}}"
                id="pills-timetable" role="tabpanel" aria-labelledby="pills-timetable-tab" tabindex="0">
                <app-test-timetable [TestTimeTable]="TestTimeTable" [timetable]="timetable"></app-test-timetable>
            </div>
            <div class="tab-pane fade {{faqListActive=='active'?'show':''}} {{faqListActive}}" id="pills-faq"
                role="tabpanel" aria-labelledby="pills-faq-tab" tabindex="0">
                <!--  Faq -->
                <app-faq-list [faqList]="faqList" [faq]="faq"></app-faq-list>
            </div>
            <div class="tab-pane fade {{serviceVideoActive =='active'?'show':''}} {{serviceVideoActive}}"
                id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab" tabindex="0">
                <!--begin::ServiceTargetedSector-->

                <div class="row mb-4" *ngIf="ServiceVideoResponse.length > 0">
                    <div class="col">
                        <div class="d-flex flex-column flex-lg-row justify-content-between text-primary mb-3">

                            <div class="d-flex align-items-center w-100 h-100">
                                <div class="input-group position-relative  w-100">
                                    <input type="text" class="form-control form-control-lg ps-5"
                                        placeholder='{{searchtxt}}' [(ngModel)]="searchText2"
                                        (keypress)="getVideoTagsResult()" (keyup)="getVideoTagsResult()"
                                        (keydown)="getVideoTagsResult()">
                                    <i
                                        class="bx bx-search fs-6 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- filter -->
                    <div class="col-12 my-1">
                        <h5 class="fs-lg text-primary fw-semibold mb-2 d-none">{{Category}}</h5>
                        <div class="d-flex flex-row flex-wrap justify-content-start w-100" *ngIf="videoTags.length > 0">
                            <div class="me-2 mb-2 nav nav-tabs-alt products-checkbox">
                                <input type="checkbox" class="btn-check products-category" name="options3"
                                    id="option-all-tags2" autocomplete="off" [(ngModel)]="videoTagsAll"
                                    (ngModelChange)="changeTageVideo()">
                                <label class="nav-link fs-sm" for="option-all-tags2">{{all}}</label>
                            </div>
                            <div class="me-2 mb-2 nav nav-tabs-alt products-checkbox"
                                *ngFor="let element of videoTags; let i = index">
                                <input type="checkbox" class="btn-check products-category" name="options3"
                                    id="chbox_{{i}}" autocomplete="off" [(ngModel)]="videoTagsStatus[i]"
                                    (ngModelChange)="filterChangeVideo()">
                                <label class="nav-link fs-sm justify-content-center"
                                    for="chbox_{{i}}">{{element}}</label>
                            </div>
                        </div>
                    </div>
                    <!-- // -->
                </div>
                <div class="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
                    <div class="col d-flex" *ngFor="let item of ServiceVideoResponse | slice:0:showVdCount">
                        <article class="card w-100 h-100 border-0 shadow rounded-5">
                            <div class="card-header my-3 pb-0 bg-white border-0 rounded-5 overflow-hidden"
                                style="min-height: 250px;height: 250px;">
                                <div class="position-relative">
                                    <span
                                        class="position-absolute top-0 start-0 w-100 h-100 bg-dark rounded-3 opacity-25"></span>
                                    <div
                                        class="d-flex align-items-center justify-content-center position-absolute top-0 start-0 w-100 h-100 zindex-2">
                                        <!-- img btn modal -->
                                        <button type="button" class="btn btn-video btn-icon bg-primary stretched-link"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal" data-backdrop="static"
                                            data-keyboard="false" (click)="open(item)">
                                            <i class="bx bx-play text-white"></i>
                                        </button>
                                        <!-- end img btn modal -->
                                    </div>
                                    <!-- youtube thumbnail -->
                                    <img src="{{getThumbnail(item.ItemFields?.MediaLink?.Url, 'big')}}"
                                        class="card-img-top rounded-9" alt="Image">
                                    <!-- end youtube thumbnail -->
                                </div>
                            </div>
                            <div class="card-body d-flex flex-column justify-content-center align-items-start pb-0">
                                <h3 class="fs-lg mb-2 overflow-hidden" [innerText]="item.ItemFields?.MediaLinkName">

                                </h3>
                                <span *ngIf="item.ItemFields?.ServiceMediaCategory"
                                    class="badge bg-light text-primary p-2 rounded-pill"
                                    [innerText]="item.ItemFields?.ServiceMediaCategory?.LookupValue"></span>
                            </div>
                            <div
                                class="card-footer py-3 border-0 rounded-5 d-flex align-items-center justify-content-end">
                                <button type="button" class="btn btn-secondary p-2 fs-sm border-0"
                                    data-bs-toggle="modal" [innerText]="show" data-bs-target="#exampleModal"
                                    data-backdrop="static" data-keyboard="false" (click)="open(item)"
                                    style="width: 6rem;">
                                </button>
                            </div>
                        </article>
                    </div>

                    <div class="row">
                        <div class="col d-flex justify-content-center mt-4 mb-2">
                            <p class="m-0 text-primary fw-semibold fs-sm">{{showVdCount<
                                    ServiceVideoResponse.length?showVdCount:ServiceVideoResponse.length }}
                                    {{servicesof}} {{ServiceVideoResponse.length}}</p>
                        </div>
                    </div>
                    <!-- show more -->
                    <div class="row">
                        <div class="col d-flex justify-content-center mb-2">

                            <input type="button" value=" {{more}}" (click)="showVdCount= showVdCount + 12"
                                *ngIf="showVdCount < ServiceVideoResponse.length"
                                class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                                style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade {{relatedServiceGuidActive =='active'?'show':''}} {{relatedServiceGuidActive}}"
                id="pills-sguid" role="tabpanel" aria-labelledby="pills-sguid-tabb" tabindex="0">
                <!--begin::guid-->
                <div class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 g-4 mt-3">
                    <div class="col d-flex" *ngFor="let service of ServiceGuid">
                        <article class="card position-relative py-4 border-1 shadow-primary h-100 w-100">
                            <!-- <span class="btn btn-light pe-none rounded-0 position-absolute top-0 start-0 pb-2 ms-3"
                                    style="border-radius: 0px 0px 15px 15px !important;">
                                    الاكثر استخداماً
                                </span> -->
                            <div class="card-header border-0 d-flex flex-column position-relative p-0 my-3 mx-3">
                                <div class="d-flex flex-row align-items-center mb-2">
                                    <i class="bx bx-file text-secondary fs-3 me-2"></i>
                                    <span
                                        class="fs-sm text-primary">{{service.ItemFields?.ServiceStructureTag?.LookupValue}}</span>
                                </div>
                                <h3 class="fs-lg text-primary m-0">{{service.ItemFields?.ServiceGuideTitle}}</h3>
                            </div>
                            <div class="card-body pt-0 pb-0 p-3">
                                <p class="fs-sm fw-normal text-primary overflow-hidden mb-0"
                                    [innerText]="service.ItemFields?.ServiceGuideDescription">
                                </p>
                            </div>
                            <div
                                class="card-footer p-0 m-0 mt-4 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                                <button class="btn btn-outline-primary p-2 fs-sm border-1" style="width: 7.5rem;"
                                    (click)="navigateUrl(service)">{{more}}
                                </button>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <script type="text/javascript">

                $(document).ready(function () {
                    $('.responsive').slick({
                        dots: true,
                        infinite: false,
                        speed: 300,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3,
                                    infinite: true,
                                    dots: true
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                            // You can unslick at a given breakpoint now by adding:
                            // settings: "unslick"
                            // instead of a settings object
                        ]
                    });
                });
            </script>
        </div>

        <!-- shareicons -->
        <app-shareicons [dynamicUrl]="_currentUrl"></app-shareicons>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" id="exampleModalLabel" [innerText]="videoTitle"></h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="stopVideo()"></button>
            </div>
            <div class="modal-body">
                <!-- youtube iframe -->
                <div class="mask" *ngIf="showLoading">
                    <app-spinner></app-spinner>
                </div>

                <iframe (load)="onLoad()" id="video2" class="rounded-3 yvideo" width="100%" height="400" [src]="url"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- end youtube iframe -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="stopVideo()">{{close}}</button>
            </div>
        </div>
    </div>
</div>