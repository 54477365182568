<main>
    <nav class="bg-primary" aria-label="breadcrumb">

        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">الرئيسية</a></li>
                <li class="breadcrumb-item"><a href="#"> البيانات والتقارير</a></li>
                <li class="breadcrumb-item"><a href="#"> فعاليات علمية</a></li>
                <li class="breadcrumb-item"><a href="#">   المحاضرات وورش العمل</a></li>
                <li class="breadcrumb-item"><a href="#"> ندوة اللغة العربية</a></li>

            </ol>
        </div>

    </nav>
    <div>
        <app-carousel></app-carousel>
        <div class="container ">
            <div class="card card-tab shadow-sm border-0">
                <div class="card-body card-body-padding">
                    <div class="row">
                        <div class="col d-flex align-items-center justify-content-between">
                            <span class="fs-base fw-normal text-primary">
                                <i class="bx bx-calendar fs-base me-2">

                                </i>2022-05-12 </span>
                                <button href="javascript:void(0);" type="button" printsectionid="dvToPrint" ngxprint="" class="btn btn-outline-primary btn-icon border-0 ms-1" style="width: 2rem; height: 2rem;" ><i class="bx bx-printer fs-xl"></i></button></div></div>
                    <h2 class="text-primary my-5"> ندوة اللغة العربية  </h2>
                    <div class="col-lg-12">
                        <p class="text-primary">
                            <br>
                            <br>
                            ​​​لماذا هذه الندوة:


                            <br>
                            <br>
                            تضافرت للغة العربية عوامل موضوعية جعلتها تحتل مكانة مرموقة لدى أهلها، ولدى المسلمين، ولدى العالم أجمع. فأهلها يرونها وعاء تاريخهم وحضارتهم وتراثهم. والمسلمون يرونها لغة القرآن الذي لا يمكن فهمه ولا تدبره دونها، ولا يمكن أن يُقيموا صلواتهم ولا دعاءهم دونها. والعالم يرى اللغة العربية لغة أمة لها حضارة وتراث أدبي وعلمي عريق، وتربطه بأهلها اليوم مصالح إستراتيجية: اقتصادية وثقافية؛ ولهذه العوامل مجتمعة اهتم الجميع بتعليمها ونشرها قديمًا وحديثًا. واليوم تعدُّ اللغة العربية من لغات العالم المهمة؛ فهي إحدى اللغات الست التي يتخاطب بها في المحافل الدولية، وتدرَّس في كثير من الجامعات العريقة في أوروبا والأمريكيتين وروسيا والصين واليابان وأستراليا.

                            <br>
                            <br>
                            أهداف الندوة:


                            <br>
                            <br>
                            الهدف الرئيس من هذه الندوة هو دعم مقياس " اختبار اللغة العربية المُقنّن لغير الناطقين بها" والتعريف به عالميًّا. وقد ارتأت لجنتها العلمية أن من أفضل سبل تحقيق هذا الهدف دعوة الأطراف المعنية بتعليم اللغة العربية لغير أهلها للتعريف بجهود كل طرف واستفادة كل واحد من خبرة الآخر في مجال اختصاصه. والطرف الأول في هذا تمثله كليات اللغة العربية والدراسات الإسلامية وأقسام اللغة العربية والمعاهد الدولية لتعليم اللغة العربية لغير الناطقين بها. فهذه الهيآت التعليمية تدرس طلابها اللغة العربية من أجل تحقيق مستوى معين من الكفاية في المهارات اللغوية الأساسية. أما الطرف الثاني فهي هيئة تقويم التعليم والتدريب التي يتمثَّل دورها​ في قياس ما تحقَّق من هذه الكفايات لدى الدارسين في هذه البرامج.
                            <br>
                            <br>
                  


                      ​​​
                        </p>
                    </div>
                    <div class="col-lg-12">
                        <p class="text-primary">
                            الجهات المشاركة
                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div class="row seminars-table">
                            <div class="col-lg-12 seminars-table-thead">
                                الجامعات الأمريكية

                            </div>
                            <div class="col-lg-12 seminars-table-sub-thead">
                                <div class="row">
                                    <div class="col-lg-6 seminars-table-td">
                                        الجامعة
                                    </div>
                                    <div class="col-lg-6 seminars-table-td">
                                        المتحدث
                                    </div>
                                </div>
                            </div>
                            <div class="row seminars-body">
                                <div class="col-lg-6 seminars-table-td">
                                    جامعة جورج تاون
                                </div>
                                <div class="col-lg-6 seminars-table-td">
                                    د.هناء كيلاني
                                </div>
                            </div>
                            <div class="row seminars-body">
                                <div class="col-lg-6 seminars-table-td">
                                    جامعة إنديانا

                                </div>
                                <div class="col-lg-6 seminars-table-td">
                                    د. سلمان العاني

                                </div>
                            </div>
                            <div class="row seminars-body">
                                <div class="col-lg-6 seminars-table-td">
                                    جامعة بريجهام يونغ


                                </div>
                                <div class="col-lg-6 seminars-table-td">
                                    د.كيرك بيلناب


                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-12 mt-4">
                        <p class="text-primary">
                            جدول الندوة

                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div class="row seminars-table">
                            <div class="col-lg-12 seminars-table-thead">
                                الجامعات الأمريكية

                            </div>
                            <div class="col-lg-12 ">
                                <div class="row">
                                       <div class="col-lg-4">
                                    <div class="row seminars-table">
                         
                                        <div class="col-lg-12 seminars-table-sub-thead">
                                            <div class="row">
                                                <div class="col-lg-6 seminars-table-td">
                                                    من	
                                                </div>
                                                <div class="col-lg-6 seminars-table-td">
                                                    إلى	
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row seminars-body">
                                            <div class="col-lg-6 seminars-table-td">
                                                8:30	
                                                                                        </div>
                                            <div class="col-lg-6 seminars-table-td">
                                                9:00	
                                                                                        </div>
                                        </div>
                                        <div class="row seminars-body">
                                            <div class="col-lg-6 seminars-table-td">
                                                8:30	            
                                            </div>
                                            <div class="col-lg-6 seminars-table-td">
                                                9:00	            
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div class="col-lg-8 pl-0" >
                                    <div class="row m-0">
                                        <div class="col-lg-12 seminars-table-sub-thead">
                                            البرنامج
    
                                        </div>
                                        <div class="row seminars-body">
                                            <div class="col-lg-6 seminars-table-td">
                                                الافتتاحية	
                                                                                    </div>
                                            <div class="col-lg-6 seminars-table-td">
                                                رئيس المركز
                                            </div>
                                        </div>
                                        <div class="row seminars-body">
                                            <div class="col-lg-6 seminars-table-td">
                                                اختبار اللغة العربية المقنن لغير الناطقين بها
    
                                            </div>
                                            <div class="col-lg-6 seminars-table-td">
                                                د. عبدالرحمن الشمراني
    
            
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                                </div>
                                
                            </div>
                   
                        
                        </div>
                    </div>
                    <app-shareicons></app-shareicons>
                </div>

            </div>
        </div>
    </div>
</main>