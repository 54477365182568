<button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close" (click)="ChangeToDark()">
    <i class="bx bx-show-alt text-center fs-base" style="width:25px;"></i>
    التباين
</button>
<button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close"
    *ngIf="isEvent && !this.eventTheme" (click)="ChangeToEvent()">
    <i class="bx bxs-palette text-center fs-base" style="width:25px;"></i>
    {{eventName}}
</button>
<button class="btn btn-outline-light me-1 rounded-3" data-bs-dismiss="offcanvas" aria-label="Close"
    *ngIf="isEvent && this.eventTheme" (click)="ChangeToEvent()">
    <i class="bx bxs-palette text-center fs-base" style="width:25px;"></i>
    الوضع الافتراضي
</button>