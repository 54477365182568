<!--begin::services-->
<section class="container">
    <div class="row justify-content-center">
        <!-- title -->
        <!-- <div class="col-12 mb-4 d-flex flex-row align-items-center">
            <i class="bx bx-window-alt text-primary fs-2 me-3"></i>
            <h3 class="fs-lg text-primary m-0">{{serviceGuid}}</h3>
        </div> -->
        <!-- search bar -->
        <div class="col-12 mb-4">
            <div class="position-relative">
                <input type="text" class="form-control form-control-lg ps-5" placeholder='{{searchtxt}}'
                    [(ngModel)]="searchText" (keypress)="search()" (keyup)="search()" (keydown)="search()">
                <i
                    class="bx bx-search fs-5 text-secondary position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            </div>
        </div>
    </div>
    <!-- services cards -->
    <div class="row row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 g-4 mt-3">
        <!--  -->
        <div class="col d-flex" *ngFor="let service of response | slice:0:showCount ">
            <article class="card position-relative py-4 border-1 shadow-primary h-100 w-100">
                <div class="card-header border-0 d-flex flex-column position-relative p-0 my-3 mx-3">
                    <div class="d-flex flex-row align-items-center mb-2">
                        <img _ngcontent-cdf-c85="" style="width: 30px !important;" alt="img" class="img-fluid me-3" src="{{service.ItemFields.ServiceGuidIcon}}">
                        <span class="fs-sm text-primary">{{service.ItemFields?.ServiceStructureTag?.LookupValue}}</span>
                    </div>
                    <h3 class="fs-lg text-primary m-0">{{service.ItemFields?.ServiceGuideTitle}}</h3>
                </div>
                <div class="card-body pt-0 pb-0 p-3">
                    <p class="fs-sm fw-normal text-primary overflow-hidden mb-0"
                        style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;"
                        [innerText]="service.ItemFields?.ServiceGuideDescription">
                    </p>
                </div>
                <div
                    class="card-footer p-0 m-0 mt-4 w-100 border-0 rounded-5 d-flex align-items-center justify-content-center">
                    <button class="btn btn-outline-primary p-2 fs-sm border-1" style="width: 7.5rem;"
                        (click)="navigateUrl(service)">{{more}}
                    </button>
                </div>
            </article>
        </div>
    </div>

    <!-- counter -->
    <div class="row">
        <div class="col d-flex justify-content-center mt-4 mb-2" *ngIf="response">
            <p class="m-0 text-primary fw-semibold fs-sm">{{showCount< response.length?showCount:response.length }}
                    {{servicesof}} {{response?.length}}</p>
        </div>
    </div>
    <!-- show more -->
    <div class="row">
        <div class="col d-flex justify-content-center mb-2" *ngIf="response">

            <input type="button" value=" {{more}}" (click)="showCount= showCount + 12"
                *ngIf="showCount < response.length" class="btn btn-light shadow-none border-0 fs-sm fw-semibold"
                style="width:7.5rem;background-color: var(--disable) !important;color: var(--primaryColor)!important;" />
        </div>
    </div>
    <!-- // -->
</section>