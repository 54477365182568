import { Component, Input, OnInit } from '@angular/core';
import { ServicePrerequirements } from 'src/app/models/ResponseModel';

@Component({
  selector: 'app-prerequirements',
  templateUrl: './prerequirements.component.html',
  styleUrls: ['./prerequirements.component.css']
})
export class PrerequirementsComponent implements OnInit {

  @Input() Prerequirements: ServicePrerequirements[] = []
  @Input() serviceprerequirments : string
  
  constructor() { }

  ngOnInit(): void {
  }

}
