import { Component, Input, OnInit } from '@angular/core';
import { Publiccontentslist } from 'src/app/models/publiccontentslist';

@Component({
  selector: 'app-dynamicnav',
  templateUrl: './dynamicnav.component.html',
  styleUrls: ['./dynamicnav.component.css']
})
export class DynamicnavComponent implements OnInit {
  @Input() hasSubContent = false;
  @Input() chilContentList: Publiccontentslist[];
  @Input() activeId :number ;

  childWithDocs: Publiccontentslist[];
  childWithOutDocs: Publiccontentslist[];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.childWithDocs = this.chilContentList.filter(m => m.ItemFields?.Title == 'docs');
    this.childWithOutDocs =  this.chilContentList.filter(m => m.ItemFields?.Title != 'docs');
    console.log(this.childWithDocs);
  }
}
